import { reactive } from 'vue'
import { FamilyRelationshipRole, PersonName, PersonSet, ViewPerson } from "./ResearchDataModel"
import { useAssertionStore } from './AssertionStore'
import { rd } from './ResearchDataApi'
import { AsyncData, LoadMode } from "@webapp/util/AsyncData"
import { AsyncDataStore, VersionedPartialKeyList } from "@webapp/util/AsyncDataStore"
import { defineStore } from "pinia"
import { DateTime } from "luxon"
import { DateTimeUtil } from "@webapp/util/LuxonUtil"
import _, { flatten, groupBy, identity, uniq } from "lodash"
import { assignExisting, isDefined } from "@webapp/util/TypeScriptUtil"
import { ViewChild, ViewGraph } from './ViewGraph'
import { AncestorPathItem, RelationPath } from './RelationPath'
import { plainToPersonDisplayProperties, usePersonStore } from './PersonStore'
import { useViewFamilyStore } from './ViewFamilyStore'
import { some } from '@webapp/util/IterableUtil'

const maxGenerations = 100 // prevent infinite loops
const viewPersonExpand = "displayProperties"
export const viewGraphExpand = `persons/${viewPersonExpand},families/displayProperties` // don't expand father/mother

export const useViewPersonStore = defineStore("viewPersonStore", () => {
    const asyncViewPersons = reactive(new Map<string, AsyncData<ViewPerson>>())
    const asyncSpouseFamilyKeys = reactive(new Map<string, AsyncData<VersionedPartialKeyList>>())
    const asyncParentFamilyKeys = reactive(new Map<string, AsyncData<VersionedPartialKeyList>>())
    const commonAncestors = reactive(new Map<string, Map<string, string | undefined>>())

    const personStore = usePersonStore()
    const viewFamilyStore = useViewFamilyStore()
    const assertionStore = useAssertionStore()

    function getAsyncPerson(id: string | undefined, loadMode = LoadMode.TrackOnly) {
        return id ? getAsyncPersonList([id], loadMode)[0] : undefined
    }

    function getAsyncPersonList(ids: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const loadPersonsAsync = async (idsToLoad: string[], requestTime: DateTime) => {
            const plainItems = await rd.getPlainByIdsAsync("viewpersons", idsToLoad, viewPersonExpand)
            return processExpandedViewPersons(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncItemsByIds(asyncViewPersons, ids, "ViewPerson", loadMode, loadPersonsAsync)
    }

    async function getPersonListAsync(ids: (string | undefined)[]) {
        const asyncViewPersons = getAsyncPersonList(ids, LoadMode.EnsureLoaded)
        return (await Promise.all(asyncViewPersons.map(a => a.whenLoadCompleted))).filter(isDefined)        
    }

    async function searchByNamesAsync(names: string[]) {
        const requestTime = DateTime.utc()
        const expand = rd.injectExpandItems(viewPersonExpand)
        const plainItems = await rd.getPlainAsync("viewpersons", { q: `name:"${names.join(',')}"`, expand }) as any[]
        addViewPersonsToStore(plainItems, requestTime)

        // return reactive async proxies from store map
        return plainItems.map(p => asyncViewPersons.get(p.id!)!)
    }

    function getLoadedPerson(id: string | undefined) {
        return asyncViewPersons.get(id ?? '')?.data
    }

    function areDetailsLoaded(personId: string) {
        const vp = asyncViewPersons.get(personId)
        if (!vp || !vp.loadComplete)
            return false

        // check matches loaded
        const matchIds = vp.data?.matchIds ?? []
        if (!AsyncDataStore.areItemsLoaded(personStore.asyncPersons, matchIds))
            return false

        // check assertion key lists loaded
        const asyncAssertionKeyLists = AsyncDataStore.getAsyncItemsIfAllLoaded(personStore.asyncAssertionKeys, matchIds)
        if (!asyncAssertionKeyLists)
            return false

        // check assertions loaded
        const assertionIds = asyncAssertionKeyLists.flatMap(akl => akl.data?.keys ?? [])
        return assertionStore.areExpandedAssertionsLoaded(assertionIds)
    }

    async function ensureDetailsLoadedAsync(personId: string) {
        if (areDetailsLoaded(personId)) {
            console.log(`Details for ${personId} already loaded`)
            return
        }
        const expand = "displayProperties,matches/displayProperties,assertions/place,assertions/site"
        const requestTime = DateTime.utc()
        const plainViewPersons = await rd.getPlainByIdsAsync("viewpersons", [personId], expand)

        processExpandedViewPersons(plainViewPersons, requestTime)
        processViewPersonAssertions(plainViewPersons, requestTime)
    }

    function hasLoadedAncestors(personId: string) {
        return some(getLoadedAncestors(personId, 1))
    }

    /**
     * Enumerates the loaded ancestors of the specified person, optionally including ancestors' other spouses and descendants.
     * The result MAY include duplicates.
     */
    function *getLoadedAncestors(startPersonId: string, generations: number, downGenerations = 0): Iterable<ViewPerson> {
        if (generations > 0) {
            const parentFams = getLoadedParentFamilies(startPersonId).parentFamilies
            const parentIds = uniq(parentFams.flatMap(pf => pf.spouseIds))
            for (const parentId of parentIds) {
                const parent = asyncViewPersons.get(parentId)!.data
                if (parent) {
                    yield parent
                }
            }
            if (downGenerations > 0) {
                const parentSpouseFams = uniq(parentIds.flatMap(id => viewFamilyStore.getLoadedSpouseFamilies(id).spouseFamilies))
                for (const parentSpouseFam of parentSpouseFams) {
                    for (const descendant of viewFamilyStore.getLoadedDescendants(parentSpouseFam.id!, downGenerations)) {
                        if (descendant.id != startPersonId) { // don't return the same person on the way down
                            yield descendant
                        }
                    }
                }
            }
            for (const parentId of parentIds) {
                for (const ancestor of getLoadedAncestors(parentId, generations - 1, downGenerations)) {
                    yield ancestor
                }
            }
        }
    }

    function areAncestorsLoaded(startPersonId: string, generations: number, downGenerations = 0): boolean {
        if (generations > 0) {
            const startPersonName = asyncViewPersons.get(startPersonId)?.data?.displayName ?? "unknown"

            // are parent family relationships loaded?
            const asyncParentFamilyKeyList = asyncParentFamilyKeys.get(startPersonId)
            if (!asyncParentFamilyKeyList || asyncParentFamilyKeyList.shouldLoad()) {
                console.log(`areAncestorsLoaded: Parent family IDs of ${startPersonName} (${startPersonId}) not loaded`)
                return false
            }
            if (!asyncParentFamilyKeyList.data) {
                return true // can't load
            }
            if (!asyncParentFamilyKeyList.data.isComplete) {
                console.log(`areAncestorsLoaded: Parent family IDs of ${startPersonName} (${startPersonId}) not complete`)
                return false
            }

            // are parent families loaded?
            const parentFamilyIds = asyncParentFamilyKeyList.data.keys
            const asyncParentFamilies = parentFamilyIds.map(id => viewFamilyStore.asyncViewFamilies.get(id))
            if (asyncParentFamilies.some(a => !a || a.shouldLoad())) {
                console.log(`areAncestorsLoaded: One or more of ${parentFamilyIds.length} parent families of ${startPersonName} (${startPersonId}) not loaded`)
                return false
            }
            const asyncParentFamiliesCanLoad = asyncParentFamilies.filter(isDefined).filter(a => a.data)
            
            // are parents loaded?
            const parentIds = uniq(flatten(asyncParentFamiliesCanLoad.map(a => a.data!.spouseIds)))

            const asyncParents = parentIds.map(id => asyncViewPersons.get(id))
            if (asyncParents.some(a => !a || a.shouldLoad())) {
                console.log(`areAncestorsLoaded: One or more of ${parentIds.length} parents of ${startPersonName} (${startPersonId}) not loaded`)
                return false
            }

            // are parent descendants (spouses, children) loaded?
            if (parentIds.some(id => !areDescendantsLoaded(id, downGenerations))) {
                return false
            }

            if (generations > 1) {
                // are other requested ancestors loaded? (recursive)
                return parentIds.map(id => areAncestorsLoaded(id, generations - 1, downGenerations)).every(identity)
            }
        }
        return true // all loaded
    }

    async function ensureAncestorsLoadedAsync(personId: string | undefined, generations: number, downGenerations = 0, reload = false) {
        if (!personId || (!reload && areAncestorsLoaded(personId, generations, downGenerations))) {
            console.log(`Ancestors of ${personId} already loaded, will not reload`)
            return
        }
        const requestTime = DateTime.utc()
        const path = `viewpersons/${personId}/ancestors`
        // NOTE: when loading ancestors, we always load 2 generations down (ancestor descendants) as well
        const generationsWithSiblings = `${generations}d${downGenerations}`
        const graph = <ViewGraph> await rd.getPlainAsync(path, { generations: generationsWithSiblings, expand: viewGraphExpand })
        console.log(`Loaded ${graph.persons.length} ancestors of ${personId}: ${graph.persons.slice(0, 100).map(p => PersonName.getDisplayName(p.displayProperties.name))}`)
        addViewGraphToStores(graph, requestTime, reload)

        if (!areAncestorsLoaded(personId, generations, downGenerations)) {
            console.log(`ensureAncestorsLoaded: Ancestors of ${personId} still not loaded after loading`)
        }
    }

    function getAsyncSpouseFamiliesKeyList(personId: string | undefined, loadMode = LoadMode.TrackOnly) {
        const asyncKeyList = AsyncDataStore.getOrAddAsyncItems(asyncSpouseFamilyKeys, [personId], "ViewPerson.SpouseFamilyKeys").at(0)
        if (asyncKeyList && AsyncDataStore.shouldLoadPartialKeyList(asyncKeyList, loadMode)) {
            const requestTime = DateTime.utc()                
            const path = `viewpersons/${personId}/spouses`
            const loadAsync = rd.getPlainAsync(path, { expand: viewGraphExpand }).then(data => {
                const graph = <ViewGraph> data
                console.log(`Loaded ${graph.families.length} spouse families of person ${personId}`)
                addViewGraphToStores(graph, requestTime, loadMode == LoadMode.Reload)
                return {
                    keys: graph.families.map(f => f.id),
                    modifiedDate: requestTime,
                    isComplete: true
                } as VersionedPartialKeyList
            })
            AsyncData.trackLoad(asyncKeyList, requestTime, loadAsync)
        }
        return asyncKeyList
    }

    function getAsyncParentFamiliesKeyList(personId: string | undefined) {
        // TODO: support LoadMode parameter and implement loading (for now must be loaded via ensureAncestorsLoadedAsync)
        return AsyncDataStore.getOrAddAsyncItems(asyncParentFamilyKeys, [personId], "ViewPerson.ParentFamilyKeys").at(0)
    }

    function hasLoadedDescendants(personId: string) {
        for (const vp of getLoadedDescendants(personId, 1)) {
            if (vp.id != personId) {
                return true
            }
        }
        return false
    }

    function *getLoadedDescendants(personId: string, generations: number): Iterable<ViewPerson> {
        const spouseFamilyIds = asyncSpouseFamilyKeys.get(personId)?.data?.keys ?? []
        for (const spouseFamilyId of spouseFamilyIds) {
            for (const descendant of viewFamilyStore.getLoadedDescendants(spouseFamilyId, generations)) {
                yield descendant
            }
        }
    }

    function areDescendantsLoaded(startPersonId: string, generations: number): boolean {
        if (generations > 0) {

            const startPersonName = asyncViewPersons.get(startPersonId)?.data?.displayName ?? "unknown"

            // are spouse family relationships loaded?
            const asyncSpouseFamilyKeyList = asyncSpouseFamilyKeys.get(startPersonId)
            if (!asyncSpouseFamilyKeyList || asyncSpouseFamilyKeyList.shouldLoad()) {
                console.log(`areDescendantsLoaded: Spouse family IDs of ${startPersonName} (${startPersonId}) not loaded`)
                return false
            }
            if (!asyncSpouseFamilyKeyList.data) {
                return true // can't load
            }
            if (!asyncSpouseFamilyKeyList.data?.isComplete) {
                console.log(`areDescendantsLoaded: Spouse family IDs of ${startPersonName} (${startPersonId}) not complete`)
                return false                    
            }

            // are spouse families loaded?
            const spouseFamilyIds = asyncSpouseFamilyKeyList!.data!.keys
            const asyncSpouseFamilies = spouseFamilyIds.map(id => viewFamilyStore.asyncViewFamilies.get(id))
            if (asyncSpouseFamilies.some(a => !a || a.shouldLoad())) {
                console.log(`areDescendantsLoaded: One or more spouse families of ${startPersonName} (${startPersonId})not loaded`)
                return false
            }
            const asyncSpouseFamiliesCanLoad = asyncSpouseFamilies.filter(isDefined).filter(a => a.data)

            // are spouses loaded?
            const spouseIds = uniq(asyncSpouseFamiliesCanLoad.map(a => a.data!.spouseOf(startPersonId)).filter(isDefined))
            const asyncSpouses = spouseIds.map(id => asyncViewPersons.get(id))
            if (asyncSpouses.some(a => !a || a.shouldLoad())) {
                console.log(`areDescendantsLoaded: One or more spouses of ${startPersonName} (${startPersonId}) not loaded`)
                return false
            }

            // are child relationships loaded?
            const asyncChildKeyLists = spouseFamilyIds.map(id => viewFamilyStore.asyncChildKeys.get(id))
            if (asyncChildKeyLists.some(a => !a || a.shouldLoad() || !a.data?.isComplete)) {
                console.log(`areDescendantsLoaded: Child IDs of one or more spouse families of ${startPersonName} (${startPersonId}) not loaded`)
                return false
            }
            
            // are children loaded?
            const childIds = uniq(flatten(asyncChildKeyLists.filter(isDefined).map(a => a.data?.keys ?? [])))
            const asyncChildren = childIds.map(id => asyncViewPersons.get(id))
            if (asyncChildren.some(a => !a || a.shouldLoad())) {
                console.log(`areDescendantsLoaded: One or more children of ${startPersonName} (${startPersonId}) not loaded`)
                return false
            }
            const asyncChildrenCanLoad = asyncChildren.filter(isDefined).filter(a => a.data)

            if (generations > 1) {
                // are child spouses and other requested descendants loaded? (recursive)
                return asyncChildrenCanLoad.every(a => areDescendantsLoaded(a.key, generations - 1))
            }
        }
        return true // all loaded
    }

    async function ensureDescendantsLoadedAsync(personId: string | undefined, generations: number, reload = false) {
        if (!personId || (!reload && areDescendantsLoaded(personId, generations))) {
            console.log(`Descendants of ${personId} already loaded, will not reload`)
            return
        }
        const requestTime = DateTime.utc()
        const path = `viewpersons/${personId}/descendants`
        const graph = <ViewGraph> await rd.getPlainAsync(path, { generations, expand: viewGraphExpand })
        console.log(`Loaded ${graph.persons.length} descendants of person ${personId}: ${graph.persons.map(p => PersonName.getDisplayName(p.displayProperties.name))}`)
        addViewGraphToStores(graph, requestTime, reload)

        if (!areDescendantsLoaded(personId, generations)) {
            console.log(`ensureDescendantsLoaded: Descendants of ${personId} still not loaded after loading`)
        }
    }

    /**
     * Gets the common ancestor of startPersonId and toPersonId, if it is known and loaded. Uses cached common ancestor if available.
     */
    function getLoadedCommonAncestor(startPersonId: string, toPersonId: string): { personId?: string } | undefined {
        let personCommonAncestors = commonAncestors.get(startPersonId)
        let ancestorId = personCommonAncestors?.get(toPersonId)
        if (!ancestorId) {
            // not cached, so try to find relation path using previously loaded data
            const commonAncestor = findLoadedCommonAncestor(startPersonId, toPersonId)
            if (!commonAncestor) {
                console.log(`Failed to find relation path from ${startPersonId} to ${toPersonId}, but some data not loaded so not sure`)
                return undefined // not found, but may still exist
            }

            ancestorId = commonAncestor.personId // undefined if no common ancestor exists

            // add result to cache
            if (!personCommonAncestors) {
                personCommonAncestors = new Map<string, string | undefined>()
                commonAncestors.set(startPersonId, personCommonAncestors)
            }
            personCommonAncestors.set(toPersonId, ancestorId)
        }
        return { personId: ancestorId }
    }

    async function ensureRelationPathLoadedAsync(startPersonId: string, toPersonId: string, reload = false) {
        if (!reload && getLoadedRelationPath(startPersonId, toPersonId)) {
            console.log(`Relation path from ${startPersonId} to ${toPersonId} already loaded, will not reload`)
            return
        }
        const requestTime = DateTime.utc()
        const path = `viewpersons/${startPersonId}/relationpaths`
        const graph = <ViewGraph> await rd.getPlainAsync(path, { to: toPersonId, expand: viewGraphExpand })
        console.log(`Loaded relation path from ${startPersonId} to ${toPersonId}: ${graph.persons.map(p => PersonName.getDisplayName(p.displayProperties.name))}`)
        addViewGraphToStores(graph, requestTime, reload)
    }

    /**
     * Gets the relation path from startPersonId to toPersonId, if it is loaded. Uses cached common ancestor if available.
     */
    function getLoadedRelationPath(startPersonId: string, toPersonId: string): RelationPath | undefined {
        if (startPersonId === toPersonId) {
            return {
                startPersonPath: [],
                commonAncestorId: startPersonId,
                toPersonPath: [],
            }
        }
        let startPersonCommonAncestors = commonAncestors.get(startPersonId)
        let commonAncestorId = startPersonCommonAncestors?.get(toPersonId)
        if (!commonAncestorId) {
            // not cached, so try to find relation path using previously loaded data
            const commonAncestor = findLoadedCommonAncestor(startPersonId, toPersonId)
            if (!commonAncestor) {
                console.log(`Failed to find relation path from ${startPersonId} to ${toPersonId}, but some data not loaded so not sure`)
                return undefined // no relation path found, but could still exist
            }
            if (!commonAncestor.personId) {
                return undefined // no relation path (definitive)
            }

            // found common ancestor
            commonAncestorId = commonAncestor.personId

            // add to common ancestors cache
            if (!startPersonCommonAncestors) {
                startPersonCommonAncestors = new Map<string, string | undefined>()
                commonAncestors.set(startPersonId, startPersonCommonAncestors)
            }
            startPersonCommonAncestors.set(toPersonId, commonAncestorId)
        }

        // get shortest paths to common ancestor
        const pathFromStartPersonToCommon = getLoadedPathToAncestor(startPersonId, commonAncestorId, maxGenerations)
        const pathFromToPersonToCommon = getLoadedPathToAncestor(toPersonId, commonAncestorId, maxGenerations)
        if (!pathFromStartPersonToCommon || !pathFromToPersonToCommon) {
            throw `Failed to get paths to common ancestor ${commonAncestorId}`
        }

        return {
            startPersonPath: pathFromStartPersonToCommon,
            commonAncestorId,
            toPersonPath: pathFromToPersonToCommon
        }
    }

    /**
     * Gets a shortest loaded path from personId to ancestorId, if one exists.
     */
    function getLoadedPathToAncestor(personId: string, ancestorId: string, maxGenerations: number): AncestorPathItem[] | undefined {
        if (personId === ancestorId) {
            return []
        }
        const loadedParentFamilies = getLoadedParentFamilies(personId)
        if (loadedParentFamilies.parentFamilies.length === 0) {
            return undefined // no path found
        }
        const familyWithAncestor = loadedParentFamilies.parentFamilies.find(f => f.hasSpouse(ancestorId))
        if (familyWithAncestor) {
            return [{ 
                ancestorId: personId, 
                parentFamilyId: familyWithAncestor.id!, 
            }] // found path
        }
        if (maxGenerations > 0) {
            // try to find path from parents to ancestor
            const parentRoles = [FamilyRelationshipRole.Father, FamilyRelationshipRole.Mother]
            const parentsChecked = new Set<string>()
            for (const parentFamily of loadedParentFamilies.parentFamilies) {
                for (const role of parentRoles) {
                    const parentId = parentFamily.getSpouseId(role)
                    if (parentId && !parentsChecked.has(parentId)) {
                        const parentPath = getLoadedPathToAncestor(parentId, ancestorId, maxGenerations - 1)
                        if (parentPath) {
                            return [{ 
                                ancestorId: personId, 
                                parentFamilyId: parentFamily.id!,
                            },
                            ...parentPath]
                        }
                        parentsChecked.add(parentId)
                    }
                }
            }
        }
        return undefined // no path found
    }

    /**
     * Traverse the ancestors of startPersonId and toPersonId, looking for an ancestor in common. If found, returns
     * an object containing the common ancestor's id. If not found and all ancestors were loaded, returns an object
     * with common ancestor id set to undefined (conclusive result that no common ancestor exists). If not found and 
     * not all parents were loaded, returns undefined (inconclusive result).
     */
    function findLoadedCommonAncestor(startPersonId: string, toPersonId: string): { personId?: string } | undefined {
        const startPersonAncestorIds = new Set<string>()
        const toPersonAncestorIds = new Set<string>()

        const startPersonGenIds = new Set<string>([startPersonId])
        const toPersonGenIds = new Set<string>([toPersonId])

        let allParentIdsComplete = true

        for (let i = 0; i < maxGenerations; i++) {
            
            // compare new stuff <-> new stuff and new stuff <-> old stuff, but not old stuff <-> old stuff
            const commonAncestorId = [...startPersonGenIds].filter(id => toPersonGenIds.has(id))
                .concat([...startPersonGenIds].filter(id => toPersonAncestorIds.has(id)))
                .concat([...toPersonGenIds].filter(id => startPersonAncestorIds.has(id)))
                .at(0)

            if (commonAncestorId) {
                return { personId: commonAncestorId } // found common ancestor
            }

            // add new stuff to old stuff
            startPersonGenIds.forEach(id => startPersonAncestorIds.add(id))
            toPersonGenIds.forEach(id => toPersonAncestorIds.add(id))

            // get new stuff
            const nextStartPersonGenIds = new Set<string>()
            for (const id of startPersonGenIds) {
                const loadedParentIds = getLoadedParentIds(id)
                if (!loadedParentIds.isComplete) {
                    allParentIdsComplete = false
                }
                loadedParentIds.parentIds.forEach(id => nextStartPersonGenIds.add(id))
            }
            const nextToPersonGenIds = new Set<string>()
            for (const id of toPersonGenIds) {
                const loadedParentIds = getLoadedParentIds(id)
                if (!loadedParentIds.isComplete) {
                    allParentIdsComplete = false
                }
                loadedParentIds.parentIds.forEach(id => nextToPersonGenIds.add(id))
            }

            if (nextStartPersonGenIds.size === 0 && nextToPersonGenIds.size === 0) {
                break // no more ancestors to check
            }

            startPersonGenIds.clear()
            nextStartPersonGenIds.forEach(id => startPersonGenIds.add(id))

            toPersonGenIds.clear()
            nextToPersonGenIds.forEach(id => toPersonGenIds.add(id))
        }

        if (allParentIdsComplete) {
            return {} // we checked and there is no common ancestor
        }

        return undefined // not found, but not all parent ids loaded, so not sure
    }

    /**
     * Gets all loaded parent families of the specified person.
     */
    function getLoadedParentFamilies(personId: string) {
        const parentFamilyKeyList = asyncParentFamilyKeys.get(personId)?.data
        if (!parentFamilyKeyList) {
            return { parentFamilies: [], isComplete: false } // parent family ids not loaded
        }
        const parentFamilies = parentFamilyKeyList.keys.map(id => viewFamilyStore.asyncViewFamilies.get(id)?.data)
        const loadedParentFamilies = parentFamilies.filter(isDefined)
        const isComplete =  parentFamilyKeyList.isComplete && parentFamilies.every(isDefined)

        return { parentFamilies: loadedParentFamilies, isComplete }
    }

    /**
     * Gets the ids of all parents in the loaded parent families of the specified person.
     */
    function getLoadedParentIds(personId: string) {
        const loadedParentFamilies = getLoadedParentFamilies(personId)

        return { 
            parentIds: [...new Set(loadedParentFamilies.parentFamilies.map(f => f.spouseIds).flat())], 
            isComplete: loadedParentFamilies.isComplete
        }
    }

    async function definePersonSetsAsync(sets: string[][], groupId: string) {
        await rd.postPlainAsync(`groups/${groupId}/personsets/define`, { personSets: sets })
    }

    async function extendPersonSetAsync(minimalSets: string[][], groupId: string) {
        await rd.postPlainAsync(`groups/${groupId}/personsets/extend`, minimalSets)
    }

    async function getPersonSetsAsync(personId: string | undefined) {
        if (!personId)
            return []
        
        const plainSets = (await rd.getPlainAsync(`persons/${personId}/personsets`)) as any[]
        return plainSets.map(s => plainToPersonSet(s))
    }

    function clearCacheFor(personId: string | undefined) {
        // extra work to do here because we add an entry to the store for each match
        asyncViewPersons.get(personId ?? '')?.data?.matchIds.forEach(id => {
            asyncViewPersons.delete(id)
            personStore.asyncPersons.delete(id)
        })
    }

    function addViewPersonsToStore(plainViewPersons: any[], requestTime: DateTime) {
        const matchMap = processExpandedViewPersons(plainViewPersons, requestTime)
        AsyncDataStore.addItemMapToStore(asyncViewPersons, "ViewPerson", matchMap, requestTime)
    }

    function processExpandedViewPersons(plainViewPersons: any[], requestTime: DateTime) {
        personStore.processExpandedPersonDisplayProperties(plainViewPersons.map(p => p.displayProperties), requestTime)
        personStore.addPersonsToStore(flatten(plainViewPersons.map(p => p.matches).filter(isDefined)), requestTime)

        const viewPersons = plainViewPersons.map(plainToViewPerson)

        // add an entry for EVERY match to the store (all returning the same view person)
        return new Map(flatten(viewPersons.map(vp => vp.matchIds.map(id => [id, vp]))))
    }

    /**
     * Adds assertion key lists and assertions for each view person match to the store. Assertion lists are assumed 
     * to be complete.
     */
    function processViewPersonAssertions(plainViewPersons: any[], requestTime: DateTime) {
        // group all assertions by subject (person) id
        const allAssertions = plainViewPersons.flatMap(p => p.assertions as any[] ?? [])
        const plainAssertionMap = new Map(Object.entries(groupBy(allAssertions, a => a.subjectId as string)))

        // ensure there is an entry for every person
        for (const personId of plainViewPersons.flatMap(vp => vp.matchIds as string[])) {
            if (!plainAssertionMap.has(personId)) {
                plainAssertionMap.set(personId, []) // no assertions for this person
            }
        }

        personStore.processExpandedPersonAssertions(plainAssertionMap, requestTime)
    }

    function addViewGraphToStores(graph: ViewGraph, requestTime: DateTime, reload: boolean) {
        addViewPersonsToStore(graph.persons, requestTime)
        viewFamilyStore.addViewFamiliesToStore(graph.families, requestTime)

        const spouseRoles = [FamilyRelationshipRole.Father, FamilyRelationshipRole.Mother]
        const childrenByFamily = new Map<string, ViewChild[]>()

        for (const gvp of graph.persons) {
            const getSpouseFamilyKeys = () => gvp.familyRelationships
                .filter(r => spouseRoles.includes(r.role)).map(r => r.familyId)

            AsyncDataStore.addPartialKeyListToStore(asyncSpouseFamilyKeys, gvp.id, 
                "ViewPerson.SpouseFamilyKeys", getSpouseFamilyKeys, gvp.spouseFamiliesComplete ?? false, requestTime, reload)

            const childRels = gvp.familyRelationships.filter(r => r.role == FamilyRelationshipRole.Child)
            const parentFamilyKeys = childRels.map(r => r.familyId)
                
            AsyncDataStore.addPartialKeyListToStore(asyncParentFamilyKeys, gvp.id, 
                "ViewPerson.ParentFamilyKeys", () => parentFamilyKeys, gvp.parentFamiliesComplete ?? false, requestTime, reload)

            for (const childRel of childRels) {
                let children = childrenByFamily.get(childRel.familyId)
                if (!children) {
                    children = []
                    childrenByFamily.set(childRel.familyId, children)
                }
                children.push({ id: gvp.id, childOrder: childRel.childOrder })
            }
        }

        for (const gvf of graph.families) {
            const children = childrenByFamily.get(gvf.id) ?? []
            children.sort((a, b) => (a.childOrder ?? 0) - (b.childOrder ?? 0))
            const childKeys = children.map(c => c.id)
            AsyncDataStore.addPartialKeyListToStore(viewFamilyStore.asyncChildKeys, gvf.id, 
                "ViewFamily.ChildKeys", () => childKeys, gvf.childrenComplete ?? false, requestTime, reload)
        }
    }

    return {
        asyncViewPersons,
        asyncSpouseFamilyKeys,
        asyncParentFamilyKeys,
        commonAncestors,

        getAsyncPerson,
        getAsyncPersonList,
        getPersonListAsync,
        searchByNamesAsync,
        getLoadedPerson,
        areDetailsLoaded,
        ensureDetailsLoadedAsync,
        areAncestorsLoaded,
        ensureAncestorsLoadedAsync,
        hasLoadedAncestors,
        getLoadedAncestors,
        areDescendantsLoaded,
        ensureDescendantsLoadedAsync,
        hasLoadedDescendants,
        getLoadedDescendants,
        getAsyncSpouseFamiliesKeyList,
        getAsyncParentFamiliesKeyList,
        getLoadedCommonAncestor,
        ensureRelationPathLoadedAsync,
        getLoadedRelationPath,
        findLoadedCommonAncestor,
        getLoadedPathToAncestor,
        getLoadedParentFamilies,
        getLoadedParentIds,
        definePersonSetsAsync,
        extendPersonSetAsync,
        getPersonSetsAsync,
        clearCacheFor,

        addViewPersonsToStore,
        addViewGraphToStores,
    }
})

function plainToViewPerson(p: any) {
    const viewPerson = assignExisting(new ViewPerson(), p)
    viewPerson.displayProperties = plainToPersonDisplayProperties(p.displayProperties)
    return viewPerson
}

function plainToPersonSet(p: any) {
    const set = assignExisting(new PersonSet(), p)
    set.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate)
    return set
}
