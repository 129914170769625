<template>
  <CompactItem 
    class="family-group-item" 
    :select-mode="props.selectMode"
    :pageUrl="familyGroupRef?.pageUrl"
    @click="emit('click')">
    <template #icon><img src="@/assets/icons/everyone_500.png"/></template>
    <template #title>
      <div class="item-title-row">
        <div class="item-title">{{ familyGroupRef?.name }}</div>
        <div class="invite-status" v-if="inviteRef" :class="inviteRef.status.toLowerCase()">
          {{ inviteRef.statusText }}
        </div>
        <div class="member-status" v-if="showStatusRef" :class="memberStatusRef">
          {{ memberStatusText[memberStatusRef] }}
        </div>
      </div>
    </template>
    <template #subtitle>Group managed by {{ ownerRef?.displayName }}</template>
  </CompactItem>
</template>

<style lang="scss" scoped>
.family-group-item {
  .item-icon img {
    width: 40px;
    height: 40px;
  }
}

::v-deep .item-title-row {
  display: flex;
  gap: 0.5rem;

  .invite-status, .member-status {
    margin-top: -1px;
    padding: 0 0.25rem 0;
    font-size: 0.75rem;
    line-height: 1.5;
    text-transform: uppercase;
    color: white;
  }

  .invite-status {
    &.pending {
      background-color: var(--bs-warning);
      color: #333;
    }

    &.approved {
      background-color: var(--bs-success);
    }

    &.rejected {
      background-color: #888;
    }

    &.expired {
      background-color: #bbb;    
    }
  }

  .member-status {
    &.useronly {
      background-color: var(--bs-info);
    }

    &.profileonly {
      background-color: var(--bs-info);
    }
  }
}
</style>

<script lang="ts" setup>
import { computed, ref, watchEffect } from 'vue';
import { DataGroupInvite, FamilyGroup, InvitationResult, PrincipalType } from '@/gp/GroupAdminModel'
import { useFamilyGroupStore } from '@/gp/FamilyGroupStore'
import { useDataGroupInviteStore } from '@/gp/DataGroupInviteStore'
import { useUserStore } from '@/gp/UserStore'
import { LoadMode } from '@/util/AsyncData'
import CompactItem from '@/util/CompactItem.vue'
import { useFamilyGroupMemberStore } from '@/gp/FamilyGroupMemberStore';
import { useDataGroupMemberStore } from '@/gp/DataGroupMemberStore';

const props = defineProps({
  familyGroupId: String,
  dataGroupInviteId: String,
  userId: String,
  small: Boolean,
  selectMode: Boolean,
})

const emit = defineEmits([
  'click',
])

const familyGroupRef = ref<FamilyGroup>()
const inviteRef = ref<DataGroupInvite>()

const familyGroupStore = useFamilyGroupStore()
const familyGroupMemberStore = useFamilyGroupMemberStore()
const dataGroupMemberStore = useDataGroupMemberStore()
const dataGroupInviteStore = useDataGroupInviteStore()
const userStore = useUserStore()

const memberStatusText = {
  useronly: 'User only',
  profileonly: 'Profile only',
  member: 'Member',
}

watchEffect(() => {
  if (props.familyGroupId) {
    familyGroupRef.value = familyGroupStore.getAsyncGroup(props.familyGroupId)?.data
    inviteRef.value = undefined
  }
  else if (props.dataGroupInviteId) {
    inviteRef.value = dataGroupInviteStore.getAsyncInvite(props.dataGroupInviteId, LoadMode.EnsureLoaded)?.data
    familyGroupRef.value = inviteRef.value?.principalType == PrincipalType.FamilyGroup
      ? familyGroupStore.getAsyncGroup(inviteRef.value?.principalId)?.data
      : undefined
  }
  else {
    familyGroupRef.value = undefined
    inviteRef.value = undefined
  }
})
const ownerRef = computed(() => userStore.getAsyncUser(familyGroupRef.value?.ownerId)?.data)
const userRef = computed(() => userStore.getUserList([props.userId], LoadMode.EnsureLoaded).at(0))
const userMemberRef = computed(() => familyGroupMemberStore.getMembersForUser(props.userId, LoadMode.EnsureLoaded)
  .find(fm => fm.familyGroupId == props.familyGroupId))
const profileMemberRef = computed(() => dataGroupMemberStore.getMembersForDataGroups([userRef.value?.profileDataGroupId], LoadMode.EnsureLoaded)
  .find(dm => dm.principalType == PrincipalType.FamilyGroup && dm.principalId == props.familyGroupId))
const memberStatusRef = computed(() => 
  !profileMemberRef.value ? 'useronly' :
  !userMemberRef.value ? 'profileonly' :
  'member')
const showStatusRef = computed(() => !!props.userId && memberStatusRef.value != 'member')
</script>