<template>
  <Modal ref="modalRef" @ok="changePlan">
    <template #title>Change my plan</template>
    <div class="modal-body">
      <div>
        <template v-if="isNewRef">Are you sure you want to sign up for this plan?</template>
        <template v-else>Are you sure you want to switch to this plan?</template>
      </div>
      <SubscriptionPlanCard :plan="newPlanRef"/>
      <div class="notes">
        <p v-if="!isUpgradeRef">
          Your subscription will continue on the current plan until your next billing cycle ({{ subRef?.currentPeriodEnd?.toLocaleString() }})
        </p>
      </div>
      <div>Billing changes</div>
      <ul class="list-unstyled billing">
        <li v-for="item in billingItemsRef" :key="item.item" class="billing-item" :class="item.id">
          <div class="item">{{ item.item }}</div>
          <div class="amount">{{ item.amount.toFixed(item.precision ?? 2) }}</div>
          <div class="unit">{{ item.unit }}</div>
        </li>
      </ul>
    </div>
    <template #okText>Change plan</template>
  </Modal>
</template>

<style lang="scss" scoped>
.modal-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.notes {
  font-size: 0.875rem;
  color: #888;
}

.billing {
  max-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: #888;

  .billing-item {
    display: grid;
    grid-template-columns: 1fr 3rem 2rem;
    column-gap: 0.25rem;

    .amount {
      text-align: right;
    }

    &.total {
      border-top: 1px #ddd solid;
      padding-top: 0.5rem;
    }
  }
}
</style>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useSubscriptionStore } from '@/gp/SubscriptionStore'
import { defaultPlanId, subscriptionPlans } from '@/gp/GroupAdminModel'
import { LoadMode } from '@/util/AsyncData'
import Modal from '@/util/Modal.vue'
import SubscriptionPlanCard from '@/account/SubscriptionPlanCard.vue'

defineExpose({
  open,
})

const emit = defineEmits([
  'add'
])

const newPlanIdRef = ref<string>()
const modalRef = ref<InstanceType<typeof Modal>>()

const subStore = useSubscriptionStore()

const subRef = computed(() => {
  const keyList = subStore.getAsyncKeyListForSelf(LoadMode.EnsureLoaded)?.data
  const subs = subStore.getLoadedSubscriptionList(keyList?.keys ?? [])
  return subs?.at(0) // HACK: assume max 1 subscription for now
})
const currentPlanIdRef = computed(() => subRef.value?.items.at(0)?.price?.productId ?? defaultPlanId)
const currentPlanRef = computed(() => subscriptionPlans.find(p => p.id == currentPlanIdRef.value)!)
const newPlanRef = computed(() => subscriptionPlans.find(p => p.id == newPlanIdRef.value)!)
const currentPriceRef = computed(() => currentPlanRef.value.price ?? 0)
const newPriceRef = computed(() => newPlanRef.value.price ?? 0)
const isNewRef = computed(() => !subRef.value)
const isUpgradeRef = computed(() => newPriceRef.value > currentPriceRef.value)

interface BillingItem {
  id: string
  item: string
  amount: number
  precision?: number
  unit?: string
}

const billingItemsRef = computed(() => {
  const currentTermDays = subRef.value?.currentPeriodEnd?.diff(subRef.value.currentPeriodStart!, 'days').days ?? 0
  const remainingDays = subRef.value?.currentPeriodEnd?.diffNow('days').days ?? 0
  const items: BillingItem[] = []

  let total = 0
  if (isUpgradeRef.value && currentPriceRef.value > 0) {
    const currentTermPrice = currentPriceRef.value * currentPlanRef.value.termMonths!
    const credit = Math.round(currentTermPrice * (remainingDays / currentTermDays))
    items.push({ 
      id: 'creditDays', 
      item: 'Remaining time on current plan', 
      amount: remainingDays, 
      precision: 0, 
      unit: "days"
    })
    items.push({ 
      id: 'credit', 
      item: `Credit for remaining term`, 
      amount: credit, 
      unit: 'USD' 
    })
    total -= credit;
  }
  if (newPriceRef.value > 0) {
    const newTermPrice = newPriceRef.value * newPlanRef.value.termMonths!    
    items.push({ 
      id: 'planCharge', 
      item: `New plan (${newPriceRef.value.toFixed(2)} x ${newPlanRef.value.termMonths} months)`, 
      amount: newTermPrice, 
      unit: "USD" 
    })
    total += newTermPrice
  }
  items.push({ 
    id: 'total', 
    item: 'Total billed today', 
    amount: total, 
    unit: 'USD' 
  })
  return items;
})

function open(newPlanId: string) {
  newPlanIdRef.value = newPlanId
  modalRef.value!.open()
}

async function changePlan() {
  if (subRef.value) {
    if (newPlanRef.value.id == defaultPlanId) {
      await subStore.cancelAsync(subRef.value.id!)
    }
    else {
      await subStore.changePlanAsync(subRef.value!.id!, newPlanRef.value.id!)
    }
    modalRef.value!.close()
  }
  else {
    emit('add') // let caller close modal
  }
}
</script>