<template>
  <div class="person-selector">
    <div class="selected-person" v-if="props.modelValue">
      <PersonCard :person-id="props.modelValue" layout="small" show-profile-type select-mode :disabled="props.disabled" @click="removeSelectedPerson"></PersonCard>
    </div>
    <div v-else>
      <SearchInput ref="searchInputRef" 
        v-model="searchTextRef" 
        :search-results="searchResultIdsRef" 
        :placeholder="placeholderRef"
        :disabled="props.disabled"
        v-slot="{ item }"
        @focus="emit('focus')"
        @result-selected="searchResultSelected">
        <PersonListItem :personId="item"></PersonListItem>
      </SearchInput>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.selected-person {
  margin-top: 0.5rem;
  height: 34px;
  display: flex;
  gap: 0.35rem;
  align-items: center;

  .btn-close {
    margin-left: auto;
    padding: 0;
    background-size: contain;
    opacity: 30%;

    &[disabled] {
      opacity: 15%;
    }
  }
}
</style>

<script lang="ts" setup>
import { computed, defineProps, nextTick, ref } from 'vue'
import { AsyncFilter } from '@/util/LiveSearch'
import { useLivePersonSearch } from '@/rd/LivePersonSearch'
import { ViewPerson } from '@/rd/ResearchDataModel'
import PersonCard from './PersonCard.vue'
import SearchInput from "@/util/SearchInput.vue"
import PersonListItem from './PersonListItem.vue'

const props = defineProps<{
  modelValue: string | undefined
  placeholder?: string
  disabled?: boolean
  postFilter?: AsyncFilter<ViewPerson>
}>()

const emit = defineEmits([
  'update:modelValue',
  'focus'
])

defineExpose({
  focus: () => searchInputRef.value?.focus(),
  reset
})

const placeholderRef = computed(() => props.placeholder ?? "Type a name")

const { searchTextRef, searchResultsRef } = useLivePersonSearch(props.postFilter)
const searchResultIdsRef = computed(() => searchResultsRef.value.map(vp => vp.id!))

const removeButtonRef = ref<HTMLButtonElement>()
const searchInputRef = ref<InstanceType<typeof SearchInput>>()

async function searchResultSelected(id: string) {
  emit('update:modelValue', id)
  await nextTick()
  removeButtonRef.value?.focus()
}

async function removeSelectedPerson() {
  emit('update:modelValue', undefined)
  await nextTick()
  searchInputRef.value?.focus()
}

function reset() {
  searchTextRef.value = ""
  searchInputRef.value?.reset()
}

</script>