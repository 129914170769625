<template>
    <div class="doc-page">
      <h1 class="with-subhead">Terms of Service</h1>
      <p class="subhead">Updated Aug 14, 2023</p>
      
      <h4>Acceptance</h4>
      <p>By accessing and using TreeCrossing.com, you accept these Terms of Service.</p>

      <h4>What belongs to us</h4>
      <p>Tree Crossing retains all rights, ownership, copyrights, trademarks for non-user-submitted content of TreeCrossing.com.</p>

      <h4>What belongs to you</h4>
      <p>
        Tree Crossing does not claim ownership of 
        text, documents, genealogical data, photos, graphics, audio, video or other information ("Content") 
        that you submit only for private sharing on TreeCrossing.com.
      </p>
      <p>
        If you consent to make specific Content publicly available on TreeCrossing.com, you grant Tree Crossing 
        the worldwide, royalty-free and non-exclusive license to 
        use, distribute, reproduce, modify, adapt, publicly perform and publicly display such Content.
      </p>
      <p>
        Tree Crossing does not make content you submit publicly available without your consent.
        <!-- such as through features intended to provide you benefit by giving limited public or privately shared access to certain portions of your data. -->
      </p>
      <p>
        This license terminates at the time you remove or Tree Crossing removes such Content from TreeCrossing.com.
      </p>
      
      <h4>Your responsibility</h4>
      <p>
        You affirm you have the right to the Content that you submit. 
        Copyrighted Content remains the property of its creator.
        You agree not to violate the rights of others by your submission of any Content to the site.
      </p>

      <h4>Privacy</h4>
      <p>Please refer to our <a href="/privacy">Privacy Notice</a></p>

      
      <h4>Contributions to Tree Crossing</h4>
      <p>
        By submitting ideas, suggestions, documents, and/or proposals ("Contributions") to Tree Crossing through its suggestion or feedback 
        webpages and email, you acknowledge and agree that:
        (a) your Contributions do not contain confidential or proprietary information; 
        (b) Tree Crossing is not under any obligation of confidentiality, express or implied, with respect to the Contributions; 
        (c) Tree Crossing shall be entitled to use or disclose (or choose not to use or disclose) such Contributions for any purpose, in any way, in any media worldwide; 
        (d) Tree Crossing may have something similar to the Contributions already under consideration or in development; 
        (e) your Contributions automatically become the property of Tree Crossing without any obligation of Tree Crossing to you; and 
        (f) you are not entitled to any compensation or reimbursement of any kind from Tree Crossing under any circumstances, except where specified in writing.
      </p>

      <h4>Third Party Services</h4>
      <p>
        Tree Crossing complies with the <a href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</a>. 
        This includes compliance with the <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Limited Use Policy</a>, 
        which means that if you choose to allow Tree Crossing access to your Google data, Tree Crossing will limit the use of your data to features specific to your user experience, 
        and does not transfer or sell the data to other third parties, or use the data for other than the intended purpose.
      </p>

      <h4>Indemnity</h4>
      <p>
        You agree to indemnify and hold Tree Crossing and its subsidiaries, affiliates, officers, agents, employees, partners and licensors 
        harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of Content you submit, 
        post, transmit, modify or otherwise make available through TreeCrossing.com, your use of TreeCrossing.com, your connection to TreeCrossing.com, 
        your violation of the Terms of Service, or your violation of any rights of another.
      </p>

      <h4>Limitation of liability</h4>
      <p>
        You expressly understand and agree that Tree Crossing and its subsidiaries, affiliates, officers, employees, agents, partners and licensors 
        shall not be liable to you for any punitive, indirect, incidental, special, consequential or exemplary damages, including, but not limited to, 
        damages for loss of profits, goodwill, use, data or other intangible losses (even if Tree Crossing has been advised of the possibility of 
        such damages), resulting from: 
        (a) the use or the inability to use TreeCrossing.com; 
        (b) the cost of procurement of substitute goods and services; 
        (c) unauthorized access to or alteration of your transmissions or data; 
        (d) statements or conduct of any third party on TreeCrossing.com; or 
        (e) any other matter relating to Tree Crossing.</p>

      <h4>Data protection laws</h4>
      <p>
        If you are located outside the United States of America, you consent to the processing in the United States of America of any personal data 
        that you submit.
        You also agree to comply with all applicable laws regarding the transmission of personal information to or from the United States 
        of America and the country in which you are located.
      </p>

    </div>
  </template>
  <style lang="scss" scoped>
  h4 {
    margin-top: 2rem;
    color: rgb(214, 96, 33);
  }
  p {
    max-width: 900px;
  }
  </style>