<template>
  <div class="earth" ref="earthElemRef" @mousedown="onMouseDown" @mouseup="onMouseUp">
    <div v-if="earthLoadedRef" class="earth-shadow"></div>
  </div>
</template>
  
<style lang="scss" scoped>
.earth {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: grab;

  .earth-shadow {
    position: absolute;
    width: 90%;
    height: 90%;
    margin: 5%;
    border-radius: 50%;
    box-shadow: -2px 3px 8px 0px rgb(0 0 0 / 0.3);
    z-index: -1;
  }
}
</style>

<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

const props = defineProps({
  focusLongitude: Number,
  focusLatitude: Number,
})

const earthElemRef = ref<HTMLDivElement>()
const earthLoadedRef = ref(false)
const enableControls = ref(true)

const earthTexture = require('@/assets/earth/earth-texture.jpg') // eslint-disable-line @typescript-eslint/no-var-requires
const scene = new THREE.Scene()
const renderer = new THREE.WebGLRenderer({ antialias: true })
renderer.setClearColor(0x00000000, 0)
const camera = new THREE.OrthographicCamera(-1.1, 1.1, 1.1, -1.1, -1.1, 1.1) // 10% padding for markers
camera.position.set(1, 0, 0) // look at prime meridian by default
const s = new THREE.Spherical().setFromVector3(camera.position)
console.log(`Camera spherical position: ${s.radius}, ${s.phi}, ${s.theta}`)

const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 1.0)
scene.add(directionalLight)

addEarth()
const marker = addMarker()

const controls = new OrbitControls(camera, renderer.domElement)
controls.enablePan = false
controls.enableZoom = false
controls.enableDamping = true
controls.dampingFactor = 0.04
controls.rotateSpeed = 0.4

// limit "tipping"
controls.minPolarAngle = Math.PI * 0.35
controls.maxPolarAngle = Math.PI * 0.65

onMounted(() => {
  const container = earthElemRef.value!
  renderer.setSize(container.clientWidth, container.clientHeight)
  container.appendChild(renderer.domElement)

  // start animation loop
  const animate = () => {
    requestAnimationFrame(animate)

    if (enableControls.value) {
      controls.update()
    }

    // move light with camera
    const lightPosition = new THREE.Vector3(1, 1, 0.5) // relative to camera
    camera.localToWorld(lightPosition)
    directionalLight.position.copy(lightPosition)

    renderer.render(scene, camera)
  }
  animate()
})

watch (props, () => {
  if (props.focusLatitude && props.focusLongitude) {
    marker.visible = true
    setMarkerLocation(props.focusLatitude, props.focusLongitude, marker)
    focusOnLocation(props.focusLatitude, props.focusLongitude)
  }
  else {
    marker.visible = false
  }
})

function addEarth() {
  const geometry = new THREE.SphereGeometry(1, 32, 32)
  const textureLoader = new THREE.TextureLoader()
  const texture = textureLoader.load(earthTexture, () => {
    const material = new THREE.MeshStandardMaterial({ map: texture })
    const mesh = new THREE.Mesh(geometry, material)
    scene.add(mesh)
    earthLoadedRef.value = true
  })
}

function addMarker() {
  const geometry = new THREE.SphereGeometry(0.05, 8, 8)
  const material = new THREE.MeshStandardMaterial({ color: 0xff0000 })
  const mesh = new THREE.Mesh(geometry, material)
  mesh.visible = false
  scene.add(mesh)
  return mesh
}

function setMarkerLocation(latitude: number, longitude: number, mesh: THREE.Mesh) {
  const polar = latLongToUnitPolar(latitude, longitude)
  polar.radius = 1.05 // just above the earth surface
  mesh.position.setFromSpherical(polar)
  mesh.visible = true
}

function focusOnLocation(latitude: number, longitude: number) {
  const startPolar = new THREE.Spherical().setFromVector3(camera.position)
  const targetPolar = latLongToUnitPolar(latitude, longitude)
  targetPolar.phi = THREE.MathUtils.clamp(targetPolar.phi, controls.minPolarAngle, controls.maxPolarAngle)
  const currentPolar = startPolar.clone()
  const startTime = performance.now()
  const duration = 1000 // ms

  //enableControls.value = false // don't let user mess with camera while animating

  function animateCameraPosition() {
    const elapsed = performance.now() - startTime
    const t = Math.min(elapsed / duration, 1.0)
    const easedT = THREE.MathUtils.smoothstep(t, 0, 1)
    // animate phi and theta separately so the transition path is consistent with manual camera control
    currentPolar.phi = THREE.MathUtils.lerp(startPolar.phi, targetPolar.phi, easedT)
    currentPolar.theta = THREE.MathUtils.lerp(startPolar.theta, targetPolar.theta, easedT)
    camera.position.setFromSpherical(currentPolar)
    camera.lookAt(0, 0, 0)

    if (t < 1) {
      requestAnimationFrame(animateCameraPosition)
    }
    else {
      //enableControls.value = true // allow user to move camera again
    }
  }
  animateCameraPosition()
}

function latLongToUnitPolar(latitude: number, longitude: number) {
  // earth is centered on the origin, with north pole at the top (+y) and prime meridian at the right (+x)
  const phi = THREE.MathUtils.degToRad(90 - latitude) // phi goes from 0 at the top (+y) to PI at the bottom (-y)
  const theta = THREE.MathUtils.degToRad(longitude + 90) // theta starts at 0 at the front (+z) and goes to the right (+x)
  return new THREE.Spherical(1, phi, theta)
}

function clampPolarAngle(position: THREE.Vector3, minAngle: number, maxAngle: number) {
  const spherical = new THREE.Spherical().setFromVector3(position)
  spherical.phi = Math.max(minAngle, Math.min(maxAngle, spherical.phi))
  position.setFromSpherical(spherical)
  return position
}

function onMouseDown(e: MouseEvent) {
  earthElemRef.value!.style.cursor = 'grabbing';
}

function onMouseUp(e: MouseEvent) {
  earthElemRef.value!.style.cursor = 'grab';
}
</script>
