import { reactive } from 'vue'
import { AssertionTypeDefinition } from "./ResearchDataModel"
import { rd } from './ResearchDataApi'
import { AsyncData, LoadMode } from "@webapp/util/AsyncData"
import { AsyncDataStore, VersionedKeyList } from "@webapp/util/AsyncDataStore"
import { defineStore } from "pinia"
import { DateTime } from "luxon"
import _ from "lodash"
import { assignExisting, isDefined } from "@webapp/util/TypeScriptUtil"

export const useAssertionTypeDefinitionStore = defineStore("assertionTypeDefinitionStore", () => {
    const asyncDefinitions = reactive(new Map<string, AsyncData<AssertionTypeDefinition>>())
    const asyncDefinitionKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())

    function getAsyncDefinition(type: string | undefined, loadMode = LoadMode.TrackOnly) {
        return type ? getAsyncDefinitionList([type], loadMode)[0] : undefined
    }

    function getAsyncDefinitionList(types: string[], loadMode = LoadMode.TrackOnly) {
        // must return requested async items immediately
        // should request and load all items if not already loaded
        // PROBLEM: getAsyncItemsByIds will discard unrequested items
        // PROBLEM: getAsyncItemsByIds puts items in a "Loading" state before calling loadListAsync
        // PROBLEM: getAsyncKeyListForRelatedItems will not load items in a "Loading" state, even if returned
        const loadListAsync = async (sourceKeys: string[], requestTime: DateTime) => {
            const asyncKeyList = getAsyncKeyListForCommon(loadMode)
            await asyncKeyList.whenLoadCompleted
            const loadedItems = sourceKeys.map(key => asyncDefinitions.get(key)?.data)
            return AsyncDataStore.createItemMap(loadedItems, "AssertionTypeDefinition", x => x as AssertionTypeDefinition, d => d.type!)
        }
        return AsyncDataStore.getAsyncItemsByIds(asyncDefinitions, types, "AssertionTypeDefinition", loadMode, loadListAsync)
    }

    function getAsyncKeyListForCommon(loadMode = LoadMode.TrackOnly) {
        const loadAsync = async (sourceKeys: string[], requestTime: DateTime) => {
            const plainItems = await rd.getPlainCollectionAsync("assertiontypes")
            return AsyncDataStore.createItemMap(plainItems, "AssertionTypeDefinition", plainToAssertionTypeDefinition, d => d.type!)
        }
        return AsyncDataStore.getAsyncKeyListForRelatedItems("common", "Root", asyncDefinitionKeys,
            asyncDefinitions, "AssertionTypeDefinition", loadMode, loadAsync)!
    }

    function getDefinitionsForCommon(loadMode = LoadMode.TrackOnly) {
        const asyncKeyList = getAsyncKeyListForCommon(loadMode)
        return asyncKeyList.data?.keys.map(key => asyncDefinitions.get(key)?.data).filter(isDefined) ?? []
    }

    function plainToAssertionTypeDefinition(p: any) {
        const definition = assignExisting(new AssertionTypeDefinition(), p)
        definition.useDate = p.useDate ?? false
        definition.useValue = p.useValue ?? false
        definition.useLocation = p.useLocation ?? false
        definition.subjectTypes ??= []
        definition.relatedItemTypes ??= []
        return definition
    }

    return {
        asyncDefinitions,
        asyncDefinitionKeys,

        getAsyncDefinition,
        getAsyncDefinitionList,
        getAsyncKeyListForCommon,
        getDefinitionsForCommon,
    }
})
