<template>
  <div class="assertion-set" :class="{ ['card-style']: props.cardStyle }">
    <AssertionRow v-if="assertionIds.length" 
      :assertionId="assertionIds.at(0)" 
      :baseDate="props.baseDate" 
      no-attrib 
      :enable-edit="props.profile" 
      @click="onClick">
    </AssertionRow>
    <div v-if="!props.profile || assertionIds.length > 1" ref="setMemberListRef" class="collapse">
      <ul class="list-unstyled">
        <li v-for="id in assertionIds" :key="id">
          <AssertionRow :assertionId="id" :baseDate="props.baseDate"></AssertionRow>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.assertion-set {
  padding: 0 0.5em;
  padding-bottom: 2px;

  //&.card-style {
    border-bottom: 1px solid rgb(237, 239, 240);
  //}

  ul {
    background-color: #f3f3f3;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
</style>

<script setup lang="ts">
import { ref } from 'vue'
import { FuzzyDate } from '@/rd/FuzzyDate'
import { Collapse } from 'bootstrap'
import AssertionRow from './AssertionRow.vue';

const props = defineProps<{
  assertionIds: string[],
  baseDate: FuzzyDate | undefined,
  profile?: boolean,
  cardStyle?: boolean,
}>()

const emit = defineEmits([
  "edit",
])

const setMemberListRef = ref<HTMLUListElement>()

function onClick(id: string) {
  if (props.profile) {
    emit('edit', id)
  }
  else if (setMemberListRef.value) {
    new Collapse(setMemberListRef.value) // auto toggles on init
  }
}

</script>
