import './env' // DO THIS FIRST!

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import { Environment } from './util/Environment'
import { AppInsightsPlugin, AppInsightsPluginOptions } from 'vue3-application-insights'
import { generateW3CId } from "@microsoft/applicationinsights-core-js"
import { redact } from './util/RedactRules'
import 'bootstrap'
import './main.scss'
import { RouteLocationNormalized } from 'vue-router'

const appName = 'webapp'

const appInsightsOptions: AppInsightsPluginOptions = {
    connectionString: Environment.get('APPINSIGHTS_CS'),
    appName,
    trackAppErrors: true,
    onLoaded: (appInsights) => {
        // adapted from setupPageTracking function in vue3-application-insights
        const getRouteName = (route: RouteLocationNormalized) => {
            return route.name
                ? `.${route.name as string}` // use '.' delimiter for named routes
                : route.matched.at(-1)!.path // paths always start with '/' delimiter
        }
        router.beforeEach((route) => {
            const routeName = getRouteName(route)
            appInsights.context.telemetryTrace.traceID = generateW3CId()
            appInsights.context.telemetryTrace.name = routeName
            appInsights.startTrackPage(`${appName}${routeName}`)
        })
        router.afterEach((route) => {
            const routeName = getRouteName(route)
            const url = new URL(location.protocol + '//' + location.host + route.fullPath)
            if (route.meta.redact) {
                for (const p of route.meta.redact as string[]) {
                    if (url.searchParams.has(p)) {
                        url.searchParams.set(p, 'REDACTED')
                    }
                }                
            }
            appInsights.stopTrackPage(`${appName}${routeName}`, url.toString())
        })

        appInsights.addDependencyInitializer((dep) => redact(dep.item))
    },
}

createApp(App)
    .use(createPinia())
    .use(router)
    .use(AppInsightsPlugin, appInsightsOptions)
    .mount('#app')
