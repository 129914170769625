<template>
  <div class="subscription-plan-card">
    <div class="plan-name" :class="planType(props.plan.id!)">{{ props.plan.displayName }}</div>
    <SubscriptionPrice :plan="props.plan" />
  </div>
</template>

<style lang="scss" scoped>
$borderColor: #ddd;
$borderRadius: 4px;

.subscription-plan-card {
  max-width: 180px;
  border-radius: $borderRadius;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  display: flex;
  flex-direction: column;
  align-items: center;

  $memColor: #dae3f3;
  $genColor: #f6d0ba;
  $plusColor: #f2ab7c;
  $proColor: #ed7d31;

  .plan-name {
    width: 100%;
    height: 1.8rem;
    border: 1px $borderColor solid;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    text-align: center;

    &.mem {
      background-color: $memColor;
    }
    &.gen {
      background-color: $genColor;
    }
    &.plus {
      background-color: $plusColor;
    }
    &.pro {
      background-color: $proColor;
    }
  }

  .subscription-price {
    width: 100%;
    border: 1px $borderColor solid;
    border-top: none;
    border-bottom-left-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
  }
}
</style>

<script lang="ts" setup>
import { SubscriptionPlan } from '@/gp/GroupAdminModel'
import SubscriptionPrice from './SubscriptionPrice.vue'

const props = defineProps<{
    plan: SubscriptionPlan
}>()

function planType(planId: string) {
  return planId.split('.')[0] // "mem.1" => "mem"
}

</script>