<template>
  <div class="modal" ref="modalRef" data-bs-backdrop="static" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <form class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">
            Change spouse
          </h3>
          <button type="button" class="btn-close" v-bind="{ disabled: busyRef }" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ProfileCreatorSelector
            ref="creatorSelectorRef"
            :relationship="spouseRelRef"
            :related-item-id="personIdRef"
            v-model:use-existing="useExistingRef"
            v-model:existing-person-id="newSpouseIdRef"
            :validate-existing="validateExisting">
          </ProfileCreatorSelector>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" :disabled="!canChangeRef" @click="changeSpouse">
            Change
          </button>
          <button type="button" class="btn btn-outline-primary" :disabled="busyRef" data-bs-dismiss="modal">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-body {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { FamilyRelationshipRole, ViewFamily } from '@/rd/ResearchDataModel'
import { NewProfileRelationship, setProfileSpouseAsync } from './ProfileManager'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { Modal } from 'bootstrap'
import ProfileCreatorSelector from './ProfileCreatorSelector.vue'
import { CompositeId } from '@/rd/CompositeId'
import { LoadMode } from '@/util/AsyncData'

defineExpose({
  open,
})

const viewPersonStore = useViewPersonStore()

const profileGroupIdRef = ref<string>()
const viewFamilyRef = ref<ViewFamily>()
const spouseRoleRef = ref(FamilyRelationshipRole.Father)
const useExistingRef = ref(false)
const newSpouseIdRef = ref<string>()
const busyRef = ref(false)
const modalRef = ref<HTMLElement>()
const creatorSelectorRef = ref<InstanceType<typeof ProfileCreatorSelector>>()

const personRoleRef = computed(() => spouseRoleRef.value == FamilyRelationshipRole.Father ? FamilyRelationshipRole.Mother : FamilyRelationshipRole.Father)
const personIdRef =  computed(() => viewFamilyRef.value?.getSpouseId(personRoleRef.value))
const spouseRelRef = computed(() => spouseRoleRef.value == FamilyRelationshipRole.Father ? NewProfileRelationship.Husband : NewProfileRelationship.Wife)
const oldSpouseIdRef = computed(() => viewFamilyRef.value?.getSpouseId(spouseRoleRef.value))
const canChangeRef = computed(() => creatorSelectorRef.value?.isValid && !busyRef.value)

function open(profileGroupId: string, viewFamily: ViewFamily, spouseRole: FamilyRelationshipRole) {
  if (spouseRole == FamilyRelationshipRole.Child)
    throw `Unexpected spouse role ${spouseRole}`

  if (modalRef.value) {
    profileGroupIdRef.value = profileGroupId
    viewFamilyRef.value = viewFamily
    spouseRoleRef.value = spouseRole
    creatorSelectorRef.value?.reset()
    Modal.getOrCreateInstance(modalRef.value)?.show()
  }
}

function validateExisting(personId: string) {
  if (personId && personId == oldSpouseIdRef.value) {
    return 'This is the current spouse.'
  }
}

async function changeSpouse() {
  busyRef.value = true
  try {
    const profileFamilyId = viewFamilyRef.value?.matchIds.find(id => CompositeId.hasGroupId(id, profileGroupIdRef.value!))
    if (!profileFamilyId)
      throw `Profile family in group ${profileGroupIdRef.value} not found`

    const viewSpouseId = await creatorSelectorRef.value?.getOrCreateProfileAsync()
    const viewSpouse = await viewPersonStore.getAsyncPerson(viewSpouseId, LoadMode.EnsureLoaded)?.whenLoadCompleted
    if (!viewSpouse)
      throw `Spouse ${viewSpouseId} not found`

    await setProfileSpouseAsync(profileFamilyId, viewSpouse.id!, spouseRoleRef.value)

    Modal.getOrCreateInstance(modalRef.value!)?.hide()
  }
  finally {
    busyRef.value = false
  }
}

</script>