<template>
  <div class="construction">
    <span>Under construction</span>
  </div>
</template>

<style lang="scss" scoped>
.construction {
  height: 1.5rem;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: repeating-linear-gradient(
    135deg,
    yellow,
    yellow 15px,
    black 15px,
    black 30px
  );

  span {
    padding: 0.1rem 0.5rem;
    background: yellow;
    font-weight: bold;
    text-transform: uppercase;
  }
}
</style>

<script lang="ts" setup>
</script>