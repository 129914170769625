import { defineStore } from "pinia";
import { Contact, contactProviders } from "@/manage/contacts/ContactProviders"
import { useLocalStorage } from "@vueuse/core"
import { watch } from "vue"
import _ from "lodash";

export const useContactStore = defineStore("contactStore", {
    state: () => {
        const s = {
            contacts: new Map<string, Contact>(),
            contactProviderId: useLocalStorage('tc-contact-provider-id', ''),
            contactsLoaded: false,
        }

        watch(s.contactProviderId, () => { 
            s.contacts.clear()
            s.contactsLoaded = false
        })
        return s
    },
    actions: {
        async ensureContactsLoadedAsync() {
            if (!this.contactsLoaded) {
                this.contacts.clear() // just to be sure
                const cp = contactProviders.find(cp => cp.id == this.contactProviderId)
                if (cp) {
                    const newContacts = await cp.getContactsAsync()
                    newContacts.forEach(c => this.contacts.set(c.id, c))
                    console.log(`Loaded ${newContacts.length} contacts from ${cp.name}`)
                }
            }
        },

        findByEmailAddress(emailAddress: string) {
            for (const c of this.contacts.values()) {
                if (c.emailAddresses.some(e => e.value.localeCompare(emailAddress, undefined, { sensitivity: 'base' }) == 0))
                    return c
            }
            return undefined
        },
    }
})
