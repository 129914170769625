import { reactive } from 'vue'
import { ItemType, MapTo, ScopeStatistics, Workspace, WorkspaceSyncOptions } from "./ResearchDataModel"
import { rd } from './ResearchDataApi'
import { AsyncData, LoadMode } from "@webapp/util/AsyncData"
import { AsyncDataStore, VersionedKeyList } from "@webapp/util/AsyncDataStore"
import { defineStore } from "pinia"
import { DateTime } from "luxon"
import { DateTimeUtil } from "@webapp/util/LuxonUtil"
import { groupBy } from "lodash"
import { assignExisting, isDefined } from "@webapp/util/TypeScriptUtil"
import { listToItemPermissions } from '@webapp/gp/Permissions'
import { ItemPermissions } from '@webapp/gp/GroupAdminModel'
import { useGroupStore } from './GroupStore'
import { useSyncStore } from './SyncStore'
import { PatchChange } from '@webapp/util/Api'

const workspaceExpand = "lastSync"

export const useWorkspaceStore = defineStore("workspaceStore", () => {
    const asyncWorkspaces = reactive(new Map<string, AsyncData<Workspace>>())
    const asyncWorkspaceKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())
    const asyncSyncKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())

    const groupStore = useGroupStore()
    const syncStore = useSyncStore()

    function getAsyncWorkspace(id: string | undefined, loadMode = LoadMode.TrackOnly) {
        return id ? getAsyncWorkspaceList([id], loadMode)[0] : undefined
    }

    function getAsyncWorkspaceList(ids: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const loadAsync = async (idsToLoad: string[], requestTime: DateTime) => {
            const plainItems = await rd.getPlainByIdsAsync("workspaces", idsToLoad, workspaceExpand)
            return processExpandedWorkspaces(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncItemsByIds(asyncWorkspaces, ids, "Workspace", loadMode, loadAsync)
    }

    async function getWorkspaceListAsync(ids: (string | undefined)[]) {
        const asyncWorkspaces = getAsyncWorkspaceList(ids, LoadMode.EnsureLoaded)
        return (await Promise.all(asyncWorkspaces.map(a => a.whenLoadCompleted))).filter(isDefined)
    }

    function getAsyncKeyListsForGroups(groupIds: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        return getRelatedWorkspaces(groupIds, "Group", groupStore.asyncWorkspaceKeys, loadMode, 
            id => `groups/${id}/workspaces`, workspaceExpand)
    }

    function getAsyncKeyListForAll(loadMode = LoadMode.TrackOnly) {
        const loadAsync = async (sourceKeys: string[], requestTime: DateTime) => {
            const plainItems = await rd.getPlainCollectionAsync("workspaces", workspaceExpand)
            return processExpandedWorkspaces(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncKeyListForRelatedItems("all", "Root", asyncWorkspaceKeys,
            asyncWorkspaces, "Workspace", loadMode, loadAsync)!
    }

    function getAsyncWorkspacesForGroups(groupIds: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const asyncKeyLists = getAsyncKeyListsForGroups(groupIds, loadMode)
        const workspaceIds = asyncKeyLists.flatMap(a => a.data?.keys ?? [])
        return getAsyncWorkspaceList(workspaceIds)
    }

    function getAllAsyncWorkspaces(loadMode = LoadMode.TrackOnly) {
        return getAsyncWorkspaceList(getAsyncKeyListForAll(loadMode).data?.keys ?? [])
    }

    async function getAllWorkspacesAsync() {
        const keyList = await getAsyncKeyListForAll(LoadMode.EnsureLoaded).whenLoadCompleted
        if (!keyList)
            return []
        
        const allWorskpaces = getAsyncWorkspaceList(keyList!.keys, LoadMode.EnsureLoaded).map(a => a.data).filter(isDefined) // should be loaded
        addWorkspaceKeyListsForAllGroupsToStore(allWorskpaces, keyList!.modifiedDate!)
        return allWorskpaces
    }

    async function getWorkspacesForGroupsAsync(groupIds: string[]) {
        const keyLists = await getAsyncKeyListsForGroups(groupIds, LoadMode.EnsureLoaded)
        const workspaceIds = keyLists.flatMap(a => a.data?.keys ?? [])
        return getWorkspaceListAsync(workspaceIds)
    }

    async function getPermissionsAsync(id: string | undefined) {
        if (!id)
            return ItemPermissions.None
        const p = await rd.getPlainAsync(`workspaces/${id}/permissions`)
        return listToItemPermissions(p.toString())
    }

    async function getStatisticsAsync(ids: (string | undefined)[]) {
        const definedIds = ids.filter(isDefined)
        const list = await rd.getPlainByIdsAsync(id => `workspaces/${id}/statistics`, definedIds)
        return list.map(p => <ScopeStatistics> p)
    }

    function processExpandedWorkspaces(plainWorkspaces: any[], requestTime: DateTime) {
        syncStore.addSyncsToStore(plainWorkspaces.map(p => p.lastSync).filter(isDefined), requestTime)
        return AsyncDataStore.createItemMap(plainWorkspaces, "Workspace", plainToWorkspace, w => w.id!)
    }

    function getRelatedWorkspaces(sourceItemKeys: (string | undefined)[],
        sourceTypeName: string,
        relatedKeysStoreMap: Map<string, AsyncData<VersionedKeyList>>,
        loadMode: LoadMode,
        relatedItemsPathBuilder: (id: string) => string,
        expand?: string) {

        const definedSourceItemKeys = sourceItemKeys.filter(isDefined)
        if (!definedSourceItemKeys.length) {
            return []
        }

        const loadAsync = async (sourceKeys: string[], requestTime: DateTime) => {
            // TODO: this API returns a flattened list of all workspaces for all groups, but getPlainByIdsAsync assumes
            // that the result should be an "item" (in this case a list) for each group, so when a list of N group ids is
            // passed, it returns an array of N workspace lists, when what we want is a flat list of all workspaces.
            // For now, just be sure we only call this with a single group id, and flatten the result.
            if (definedSourceItemKeys.length > 1)
                throw new Error("getRelatedWorkspaces only supports a single group id")
                
            const plainItemLists = await rd.getPlainByIdsAsync(relatedItemsPathBuilder, definedSourceItemKeys, workspaceExpand)

            const logKey = definedSourceItemKeys.length == 1 ? definedSourceItemKeys[0] : `(${sourceItemKeys.length} keys)`
            console.log(`Loaded ${plainItemLists.length} workspace lists at ${relatedItemsPathBuilder(logKey)}`)

            const plainItems = plainItemLists.flat()
            return processExpandedWorkspaces(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncKeyListsForRelatedItems(sourceItemKeys, sourceTypeName, relatedKeysStoreMap,
            asyncWorkspaces, "Workspace", loadMode, loadAsync)
    }

    async function mapItemIdsAsync(workspaceId: string, itemType: ItemType, itemIds: string[], mapTo: MapTo) {
        const query = { type: ItemType[itemType], to: MapTo[mapTo] }
        const p = await rd.postPlainAsync(`workspaces/${workspaceId}/map`, itemIds, query)
        return p as string[]
    }

    function addWorkspaceKeyListsForAllGroupsToStore(allWorkspaces: Workspace[], requestTime: DateTime) {
        const workspacesByGroup = groupBy(allWorkspaces, w => w.viewGroupId)
        for (const [groupId, workspaces] of Object.entries(workspacesByGroup)) {
            const workspaceIds = workspaces.map(w => w.id!)
            AsyncDataStore.addKeyListToStore(groupStore.asyncWorkspaceKeys, groupId, "Group.WorkspaceKeys", workspaceIds, requestTime)
        }
    }

    async function addAsync(workspace: Workspace) {
        const addCoreAsync = async () => {
            const p = await rd.postPlainAsync(`workspaces`, workspace)
            return plainToWorkspace(p) // current user is owner on add
        }
        return await AsyncDataStore.addToStoreMapAsync(asyncWorkspaces, "Workspace", w => w.id, addCoreAsync)
    }

    async function patchAsync(id: string, changes: PatchChange[]) {
        const patchCoreAsync = async (id: string, changes: PatchChange[]) => {
            const p = await rd.patchPlainAsync(`workspaces/${id}`, changes)
            return plainToWorkspace(p)
        }
        await AsyncDataStore.patchFromStoreAsync(asyncWorkspaces, id, changes, patchCoreAsync)
        getAsyncWorkspace(id, LoadMode.Reload) // trigger reload, but don't wait for it
    }

    return {
        asyncWorkspaces,
        asyncWorkspaceKeys,
        asyncSyncKeys,

        getAsyncWorkspace,
        getAsyncWorkspaceList,
        getWorkspaceListAsync,
        getAsyncKeyListsForGroups,
        getAsyncKeyListForAll,
        getAsyncWorkspacesForGroups,
        getAllAsyncWorkspaces,
        getAllWorkspacesAsync,
        getWorkspacesForGroupsAsync,
        getPermissionsAsync,
        getStatisticsAsync,
        processExpandedWorkspaces,
        getRelatedWorkspaces,
        mapItemIdsAsync,
        
        addWorkspaceKeyListsForAllGroupsToStore,

        addAsync,
        patchAsync,
    }
})

function plainToWorkspace(p: any) {
    const w = assignExisting(new Workspace(), p)
    w.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate);
    w.lastSyncId = p.lastSync?.id
    return w;
}
