// MS Graph is ODATA.  GET requests, filters, etc, use ODATA syntax:
// https://learn.microsoft.com/en-us/graph/query-parameters?tabs=http
//
// https://developer.microsoft.com/en-us/graph/graph-explorer

export class MSGraphApi {
    private accessToken = ''

    constructor(accessToken?: string) {
        if(accessToken)
            this.accessToken = accessToken;
    }

    async getMe(){
        let result;
        await fetch('https://graph.microsoft.com/v1.0/me', {
            headers: {
                Authorization: `Bearer ${this.accessToken}`
            }
            })
            .then(response => response.json())
            .then(data => result = data)
            .catch(error => console.error('Error fetching user info:', error));

        return result
    }

    async getContactsAsync(pageSize = 10){

        // TODO: paging.  use the response "@odata.nextLink": "https://graph.microsoft.com/v1.0/me/contacts?%24select=displayName%2cgivenName%2csurname%2cemailAddresses%2cphoto&%24top=5&%24skip=5"
        // or, keep track of Top and Skip in order to know where we are at
        // recommend @odata.nextLink so that we know when we are at the end of paging

        try {
            const path = 'https://graph.microsoft.com/v1.0/me/contacts'
            const query = '$select=displayName,givenName,surname,emailAddresses,photo&top=' + pageSize
            const response = await fetch(`${path}?${query}`, { headers: { Authorization: `Bearer ${this.accessToken}` }})
    
            return (await response.json()).value as MSContact[]
        }
        catch (error) {
            console.error('Error fetching user info:', error)
            return []
        }
    }

    async searchContactsAsync(query: string){

        let result;

        await fetch('https://graph.microsoft.com/v1.0/me/contacts?$filter=contains(displayName,\''+query+'\')&$select=displayName,givenName,surname,emailAddresses,photo', {
            headers: {
                Authorization: `Bearer ${this.accessToken}`
            }
            })
            .then(response => response.json())
            .then(data => result = data)
            .catch(error => console.error('Error fetching user info:', error));

        return result
    }

    setAccessToken(token: string){
        this.accessToken = token
    }
}

export interface MSContact {
    readonly id: string
    readonly displayName: string
    readonly surname: string
    readonly givenName: string
    readonly middleName: string
    readonly emailAddresses: MSEmailAddress[]
}

export interface MSEmailAddress {
    readonly name: string
    readonly address: string
}
