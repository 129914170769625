<template>
  <div class="user-activity">
    Coming soon
  </div>
</template>

<style lang="scss" scoped>
</style>

<script setup lang="ts">
</script>
