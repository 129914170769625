import { toValue } from 'vue'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useUserStore } from '@/gp/UserStore'
import { useFamilyGroupMemberStore } from '@/gp/FamilyGroupMemberStore'
import { useFamilyGroupInviteStore } from '@/gp/FamilyGroupInviteStore'
import { useDataGroupMemberStore } from '@/gp/DataGroupMemberStore'
import { useViewFamilyStore } from '@/rd/ViewFamilyStore'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { isDefined } from "@/util/TypeScriptUtil"
import { LoadMode } from '@/util/AsyncData'
import _ from 'lodash'
import { CompositeId } from '@/rd/CompositeId'
import { ViewPerson } from '@/rd/ResearchDataModel'
import { DataGroup, DataGroupInvite, DataGroupMember, DataGroupType, FamilyGroup, FamilyGroupInvite, FamilyGroupMember, GroupMemberRole, InvitationResult, PrincipalType, User } from '@/gp/GroupAdminModel'
import { ProfileUtils } from './ProfileUtils'
import { TokenManager } from '@/auth/TokenManager'
import { useDataGroupInviteStore } from '@/gp/DataGroupInviteStore'

export class PotentialMember {
    relationship: RelationshipCategory
    viewPerson?: ViewPerson
    personGroups: DataGroup[] = []
    groupMembers = new Map<string, DataGroupMember>()
    member?: FamilyGroupMember
    user?: User
    sharingInvite?: DataGroupInvite
    lastUserInvite?: FamilyGroupInvite

    /**
     * Gets a value that can be used as a key for this potential member in a collection of other potential members.
     */
    get key() { return this.member?.key ?? this.viewPerson?.id ?? this.user?.id }

    /**
     * Gets the display name for this potential member (user display name if available, otherwise view person display name).
     */
    get displayName() { return this.user?.displayName ?? this.viewPerson?.displayName ?? '' }

    /**
     * Gets the member user's profile person id.
     */
    get memberProfilePersonId() { return this.personGroups.find(pg => pg.id == this.user?.profileDataGroupId)?.startItemId }

    /**
     * Gets the groups of the view person matches that are primary persons in a profile group.
     */
    get profileGroups() { 
        return this.profilePersonIds.map(id => this.personGroups.find(pg => CompositeId.hasGroupId(id, pg.id!)))
            .filter(isDefined)
    }

    /**
     * Gets the ids of the view person matches that are primary persons in a profile group.
     */
    get profilePersonIds() { return this.viewPerson ? ProfileUtils.getProfilePersonIds(this.viewPerson.matchIds, this.personGroups) : [] }

    /**
     * Gets the ids of the profile persons that are shared with the family group.
     */
    get sharedProfilePersonIds() { return this.profilePersonIds.filter(id => this.isProfileShared(id)) }
    
    /**
     * Gets the ids of the profile persons that are not shared with the family group.
     */
    get unsharedProfilePersonIds() { return this.profilePersonIds.filter(id => !this.isProfileShared(id)) }

    /**
     * Gets whether this potential member has at least one profile person that is shared with the family group.
     */
    get isShared() { return this.sharedProfilePersonIds.length > 0 }

    /**
     * Gets whether this potential member can be invited to the family group (assuming appropriate permissions
     * on both the profile person and the family group).
     */
    get canInvite() { return !this.member && (!this.lastUserInvite || this.lastUserInvite.result == InvitationResult.Cancelled) && 
        this.profilePersonIds.length == 1 && this.isShared && !this.viewPerson?.assumeDeceased }

    constructor(relationship: RelationshipCategory, viewPerson?: ViewPerson, member?: FamilyGroupMember, user?: User) {
        this.relationship = relationship
        this.viewPerson = viewPerson
        this.member = member
        this.user = user
    }

    /**
     * Gets a list of objects describing the matches for this view person that are primary persons in a profile group
     */
    getProfilePersons() {
        if (!this.viewPerson) {
            throw 'viewPerson is undefined'
        }
        return this.viewPerson.matchIds.map(id => {
            const dataGroupId = CompositeId.getGroupId(id)
            if (!dataGroupId) {
                throw `Unable to get group id for person ${id}`
            }
            const dataGroup = this.personGroups.find(pg => pg.id == dataGroupId)
            if (!dataGroup) {
                throw `Unable to get data group ${dataGroupId}`
            }
            const groupMember = this.groupMembers.get(dataGroupId)
            return { personId: id, dataGroup, groupMember }
        }).filter(p => ProfileUtils.isProfilePerson(p.personId, p.dataGroup))
    }

    isProfileShared(profilePersonId: string) {
        return !!this.getProfileGroupMember(profilePersonId)
    }

    getProfileGroupMember(profilePersonId?: string) {
        return this.groupMembers.get(CompositeId.getGroupId(profilePersonId) ?? '')
    }

    isPersonLinkableByUser(personId: string, userId?: string) {
        const groupId = CompositeId.getGroupId(personId)
        const personGroup = this.personGroups.find(g => g.id == groupId)
        return !!personGroup && 
            personGroup.ownerId == (userId ?? TokenManager.userId) &&
            ProfileUtils.isProfilePerson(personId, personGroup)
    }

    getPersonLinkableByUser(userId?: string) {
        const resolvedUserId = userId ?? TokenManager.userId
        const linkablePersonIds = this.viewPerson?.matchIds.filter(id => this.isPersonLinkableByUser(id, resolvedUserId)) ?? []
        return linkablePersonIds.length == 1 ? linkablePersonIds[0] : undefined
    }

    canUserInvite(userId?: string) {
        return this.canInvite && !!this.getPersonLinkableByUser(userId ?? TokenManager.userId)
    }
}

export enum RelationshipCategory { // includes spouses
    Ancestors,
    Scope,
    Child,
    Grandchild,
    None,
}

const MaxDescendantGenerations = 3

export async function getPotentialMemberForUserAsync(familyGroup: FamilyGroup, user: User) {
    const viewFamilyStore = useViewFamilyStore()
    const viewPersonStore = useViewPersonStore()
    const dataGroupStore = useDataGroupStore()
    const dataGroupMemberStore = useDataGroupMemberStore()
    const familyGroupMemberStore = useFamilyGroupMemberStore()
    const dataGroupInviteStore = useDataGroupInviteStore()
    const familyGroupInviteStore = useFamilyGroupInviteStore()

    const getProfileGroup = dataGroupStore.getGroupListAsync([user.profileDataGroupId])
    const getProfileMembers = dataGroupMemberStore.getMembersForDataGroupAsync(user.profileDataGroupId)
    await Promise.all([getProfileGroup, getProfileMembers])

    const profileGroup = (await getProfileGroup).at(0)
    const profileMember = (await getProfileMembers)
        .find(dm => dm.principalType == PrincipalType.FamilyGroup && dm.principalId == familyGroup.id)

    if (!profileGroup)
        throw `User ${user.id} profile group ${user.profileDataGroupId} not found`

    const viewPerson = (await viewPersonStore.getPersonListAsync([profileGroup?.startItemId])).at(0)
    if (!viewPerson)
        throw `User ${user.id} profile person ${profileGroup.startItemId} not found`

    let relCategory = RelationshipCategory.None
    if (await viewFamilyStore.ensureDescendantsLoadedAsync(familyGroup.scopeFamilyId, MaxDescendantGenerations)) {
        const scopeFamily = viewFamilyStore.getAsyncFamily(familyGroup.scopeFamilyId)?.data // loaded with descendants
        if (scopeFamily) {
            const relPath = viewPersonStore.getLoadedRelationPath(viewPerson.id!, scopeFamily.spouseIds[0])
            relCategory = 
                !relPath ? RelationshipCategory.None :
                relPath.startPersonPath.length == 0 && relPath.toPersonPath.length > 0 ? RelationshipCategory.Ancestors :
                relPath.startPersonPath.length == 0 && relPath.toPersonPath.length == 0 ? RelationshipCategory.Scope :
                relPath.startPersonPath.length == 1 && relPath.toPersonPath.length == 0 ? RelationshipCategory.Child :
                relPath.startPersonPath.length == 2 && relPath.toPersonPath.length == 0 ? RelationshipCategory.Grandchild :
                RelationshipCategory.None
        }
    }

    const matchGroupIds = _.uniq(viewPerson.matchIds.map(id => CompositeId.getGroupId(id)!))
    const getMatchGroups = dataGroupStore.getGroupListAsync(matchGroupIds)
    const getMembers = familyGroupMemberStore.getMembersForUserAsync(user.id)
    const getSharingInvites = dataGroupInviteStore.getInvitesForFamilyGroupAsync(familyGroup.id)
    const getUserInvites = familyGroupInviteStore.getInvitesForFamilyGroupAsync(familyGroup.id)
    await Promise.all([getMatchGroups, getMembers, getSharingInvites, getUserInvites])

    const fgMem = (await getMembers).find(fm => fm.familyGroupId == familyGroup.id)

    const pm = new PotentialMember(relCategory, viewPerson, fgMem, user)

    pm.personGroups.push(...(await getMatchGroups))
    
    if (profileMember) {
        pm.groupMembers.set(user.profileDataGroupId!, profileMember)
    }

    setPotentialMemberInvites(pm, (await getSharingInvites), (await getUserInvites))

    return pm
}

/**
 * Gets a list of potential members for the specified family group, including current members and descendant profiles
 * (whether shared with the group or not).
 */
export async function getPotentialMembersAsync(familyGroup: FamilyGroup) {
    if (!familyGroup.id)
        throw 'Family group has no id'

    // TODO: handle no scope family (get group members only)
    if (!familyGroup.scopeFamilyId)
        return []

    const ancestorPotentialMembers = await getAncestorPotentialMembersAsync(familyGroup.scopeFamilyId)
    console.log(`Loaded ${ancestorPotentialMembers.length} ancestor potential members for family group ${familyGroup.id}`)

    const descendantPotentialMembers = await getDescendantPotentialMembersAsync(familyGroup.scopeFamilyId)
    console.log(`Loaded ${descendantPotentialMembers.length} descendant potential members for family group ${familyGroup.id}`)

    const otherPotentialMembers = [...new Set([...ancestorPotentialMembers, ...descendantPotentialMembers])]

    return await getPotentialMembersCoreAsync(familyGroup.id, otherPotentialMembers, true)
}

/**
 * Gets a potential member for the specified profile group.
 */
export async function getPotentialMemberForProfileGroupAsync(familyGroupId: string, profileGroupId: string) {
    const dataGroupStore = useDataGroupStore()
    const profileGroup = await dataGroupStore.getAsyncGroup(profileGroupId, LoadMode.EnsureLoaded)?.whenLoadCompleted
    if (!profileGroup || profileGroup.groupType != DataGroupType.Profile)
        throw `Data group ${profileGroupId} is not a profile group`

    const viewPersonStore = useViewPersonStore()
    const profilePerson = await viewPersonStore.getAsyncPerson(profileGroup.startItemId!, LoadMode.EnsureLoaded)?.whenLoadCompleted

    const pm = new PotentialMember(RelationshipCategory.None, profilePerson)

    return (await getPotentialMembersCoreAsync(familyGroupId, [pm]))[0]
}

async function getPotentialMembersCoreAsync(familyGroupId: string, otherPotentialMembers: PotentialMember[], includeAllCurrent = false) {
    if (otherPotentialMembers.find(pm => !pm.viewPerson?.id))
        throw "Potential member does not have a valid view person"

    const dataGroupInviteStore = useDataGroupInviteStore()
    const familyGroupInviteStore = useFamilyGroupInviteStore()

    // load required data in parallel
    const getMemberPotentialMembers = getMemberPotentialMembersAsync(familyGroupId)
    const getSharedProfilePotentialMembers = getSharedProfilePotentialMembersAsync(familyGroupId)
    const getSharingInvites = dataGroupInviteStore.getInvitesForFamilyGroupAsync(familyGroupId)
    const getUserInvites = familyGroupInviteStore.getInvitesForFamilyGroupAsync(familyGroupId)
    await Promise.all([
        getMemberPotentialMembers,
        getSharedProfilePotentialMembers,
        getSharingInvites,
        getUserInvites,
    ])
    const memberPotentialMembers = await getMemberPotentialMembers
    const sharedProfilePotentialMembers = await getSharedProfilePotentialMembers
    const sharingInvites = await getSharingInvites
    const userInvites = await getUserInvites
    
    console.log(`Loaded ${memberPotentialMembers.length} members for family group ${familyGroupId}`)
    console.log(`Loaded ${sharedProfilePotentialMembers.length} shared profiles for family group ${familyGroupId}`)
    console.log(`Loaded ${sharingInvites.length} sharing invites for family group ${familyGroupId}`)
    console.log(`Loaded ${userInvites.length} user invites for family group ${familyGroupId}`)

    const memberMap = new Map(memberPotentialMembers.map(pm => [pm.viewPerson!.id!, pm]))
    const sharedProfileMap = new Map(sharedProfilePotentialMembers.map(pm => [pm.viewPerson!.id!, pm]))

    // members are required to share their profile with the family group, so merge (and remove) any info about members
    // from the shared profile potential members into the member potential members, so they now contain both member 
    // and profile info
    // after this operation, there should be NO potential members in sharedProfileMap that are also group members
    mergePotentialMembers(sharedProfileMap, memberMap.values())

    // merge (and remove) any profile info about "other" potential members into the potential members in the "other" list
    // after this operation, there should be NO potential members in sharedProfileMap that are also in the "other" list
    mergePotentialMembers(sharedProfileMap, otherPotentialMembers)

    // merge any info about member potential members into the potential members in the "other" list
    mergePotentialMembers(memberMap, otherPotentialMembers)

    // this is the list we'll return
    const potentialMembers = [...otherPotentialMembers]

    // add any remaining current members and shared profiles if requested
    if (includeAllCurrent) {
        potentialMembers.push(...memberMap.values())
        potentialMembers.push(...sharedProfileMap.values())
    }

    // TODO: also get users for non-member descendants?
    
    await loadPersonGroupsAsync(potentialMembers)

    await loadUserProfileUsersAsync(potentialMembers)

    // remove potential members that don't have any profile persons (e.g. research group profile only)
    _.remove(potentialMembers, pm => pm.getProfilePersons().length == 0)
    
    // add last invite to potential members
    for (const pm of potentialMembers) {
        setPotentialMemberInvites(pm, sharingInvites, userInvites)
    }
            
    const sortedPotentialMembers = _.sortBy(potentialMembers, pm => pm.displayName)
    
    return sortedPotentialMembers
}

function setPotentialMemberInvites(pm: PotentialMember, sharingInvites: DataGroupInvite[], userInvites: FamilyGroupInvite[]) {
    const profilePersons = pm.getProfilePersons()

    const relatedSharingInvites = sharingInvites.filter(inv =>
        profilePersons.some(p => CompositeId.hasGroupId(p.personId, inv.dataGroupId!)))
    pm.sharingInvite = _.sortBy(relatedSharingInvites, inv => inv.lastSentDate).at(-1)

    const relatedUserInvites = userInvites.filter(inv => 
        (inv.recipientUserId && inv.recipientUserId == pm.user?.id) || 
        (inv.profileDataGroupId && profilePersons.some(p => CompositeId.hasGroupId(p.personId, inv.profileDataGroupId!))))
    pm.lastUserInvite = _.maxBy(relatedUserInvites, inv => inv.lastSentDate!)
}

async function getAncestorPotentialMembersAsync(scopeFamilyId: string) {
    const viewFamilyStore = useViewFamilyStore()
    const viewPersonStore = useViewPersonStore()

    const scopeFamily = await viewFamilyStore.getAsyncFamily(scopeFamilyId, LoadMode.EnsureLoaded)?.whenLoadCompleted
    await viewPersonStore.ensureAncestorsLoadedAsync(scopeFamily?.fatherId, 3)
    await viewPersonStore.ensureAncestorsLoadedAsync(scopeFamily?.motherId, 3)

    return [
        ...viewPersonStore.getLoadedAncestors(scopeFamily?.fatherId ?? '', 3),
        ...viewPersonStore.getLoadedAncestors(scopeFamily?.motherId ?? '', 3),
    ].map(vp => new PotentialMember(RelationshipCategory.Ancestors, vp))
}
    
async function getDescendantPotentialMembersAsync(scopeFamilyId: string) {
    const viewFamilyStore = useViewFamilyStore()
    const viewPersonStore = useViewPersonStore()

    await viewFamilyStore.ensureDescendantsLoadedAsync(scopeFamilyId, MaxDescendantGenerations)

    const scopePersonIds = viewFamilyStore.getAsyncFamily(scopeFamilyId)?.data?.spouseIds ?? []
    const childPersonIds = viewFamilyStore.asyncChildKeys.get(scopeFamilyId)?.data?.keys ?? []
    const childSpouseFamilyIds = _.uniq(childPersonIds.flatMap(ch => viewPersonStore.asyncSpouseFamilyKeys.get(ch)?.data?.keys ?? []))
    const childSpouseFamilies = childSpouseFamilyIds.flatMap(sf => viewFamilyStore.getAsyncFamily(sf)?.data).filter(isDefined)
    const childSpouseIds = _.uniq(childSpouseFamilies.flatMap(fam => fam.spouseIds))
    const grandchildPersonIds = childSpouseFamilyIds.flatMap(sf => viewFamilyStore.asyncChildKeys.get(sf)?.data?.keys ?? [])
    const grandchildSpouseFamilyIds = _.uniq(grandchildPersonIds.flatMap(ch => viewPersonStore.asyncSpouseFamilyKeys.get(ch)?.data?.keys ?? []))
    const grandchildSpouseFamilies = grandchildSpouseFamilyIds.flatMap(sf => viewFamilyStore.getAsyncFamily(sf)?.data).filter(isDefined)
    const grandchildSpouseIds = _.uniq(grandchildSpouseFamilies.flatMap(fam => fam.spouseIds))
    
    const descendantRelationships = new Map<string, RelationshipCategory>()
    scopePersonIds.forEach(id => descendantRelationships.set(id, RelationshipCategory.Scope))
    childPersonIds.forEach(id => descendantRelationships.set(id, RelationshipCategory.Child))
    childSpouseIds.forEach(id => descendantRelationships.set(id, RelationshipCategory.Child))
    grandchildPersonIds.forEach(id => descendantRelationships.set(id, RelationshipCategory.Grandchild))
    grandchildSpouseIds.forEach(id => descendantRelationships.set(id, RelationshipCategory.Grandchild))
    
    const descendantViewPersonIds = [...descendantRelationships.keys()]
    const descendantViewPersons = viewPersonStore.getAsyncPersonList(descendantViewPersonIds).map(a => a.data).filter(isDefined)

    console.log(`Found ${descendantViewPersons.length} descendant potential members for scope family ${scopeFamilyId}`)

    return descendantViewPersons.map(vp => {
        const rel = descendantRelationships.get(vp.id!) ?? RelationshipCategory.None
        return new PotentialMember(rel, vp)
    })    
}

async function getMemberPotentialMembersAsync(familyGroupId: string) {
    const familyGroupMemberStore = useFamilyGroupMemberStore()
    const userStore = useUserStore()
    const dataGroupStore = useDataGroupStore()
    const viewPersonStore = useViewPersonStore()

    // NOTE: this call loads FG member IDs plus member/user/profiledatagroup (and implicitly group owner, since it is always the user)
    const asyncMemberIds = await familyGroupMemberStore.getAsyncKeyListForFamilyGroup(familyGroupId, LoadMode.EnsureLoaded).whenLoadCompleted
    
    const memberIds = asyncMemberIds?.keys ?? []
    const members = familyGroupMemberStore.getAsyncMemberList(memberIds).map(a => a.data).filter(isDefined) // loaded inline with FG member list

    const profileGroupIds = members.map(m => userStore.getAsyncUser(m.userId)!.data!.profileDataGroupId) // users loaded inline with FG members
    const profileGroups = dataGroupStore.getAsyncGroupList(profileGroupIds).map(a => a.data).filter(isDefined) // profile groups loaded inline with FG members
    const profilePersonIds = new Map(profileGroups.map(pg => [pg.id!, pg.startItemId!]))

    await viewPersonStore.getPersonListAsync([...profilePersonIds.values()]) // ensure loaded

    return members.map(m => {
        const user = userStore.getAsyncUser(m.userId)!.data!
        const profilePersonId = profilePersonIds.get(user.profileDataGroupId!)
        const viewPerson = viewPersonStore.getAsyncPerson(profilePersonId!)?.data // loaded above
        return new PotentialMember(RelationshipCategory.None, viewPerson, m, user)
    })
}

async function getSharedProfilePotentialMembersAsync(familyGroupId: string) {
    const dataGroupMemberStore = useDataGroupMemberStore()
    const dataGroupStore = useDataGroupStore()
    const viewPersonStore = useViewPersonStore()

    const dataGroupMembers = await dataGroupMemberStore.getMembersForFamilyGroupAsync(familyGroupId)
    const memberMap = new Map(dataGroupMembers.map(m => [m.dataGroupId!, m]))

    const profileGroups = (await dataGroupStore.getGroupListAsync([...memberMap.keys()])) // should be loaded with members
        .filter(dg => dg.groupType == DataGroupType.Profile)

    await viewPersonStore.getPersonListAsync(profileGroups.map(pg => pg.startItemId!)) // ensure loaded

    return profileGroups.map(pg => {
        const viewPerson = viewPersonStore.getAsyncPerson(pg.startItemId!)?.data // loaded above
        const groupMember = memberMap.get(pg.id!)
        const pm = new PotentialMember(RelationshipCategory.None, viewPerson)
        pm.groupMembers.set(pg.id!, groupMember!)
        return pm
    })
}

function mergePotentialMembers(fromMemberMap: Map<string, PotentialMember>, toMembers: Iterable<PotentialMember>) {
    for (const to of toMembers) {
        // merge potential members that represent the same view person
        const from = fromMemberMap.get(to.viewPerson!.id!)
        if (from) {
            if (to.groupMembers.size > 0)
                throw `Potential member ${to.key} has existing group members`
            to.groupMembers = from.groupMembers

            if (to.member && from.member && to.member.key != from.member.key)
                throw `Potential members ${to.key} and ${from.key} have conflicting group members`
            to.member = to.member ?? from.member

            if (to.user && from.user && to.user.id != from.user.id)
                throw `Potential members ${to.key} and ${from.key} have conflicting users`
            
            to.user = from.user ?? to.user

            fromMemberMap.delete(to.viewPerson!.id!)
        }
    }
}

async function loadPersonGroupsAsync(potentialMembers: PotentialMember[]) {
    const dataGroupStore = useDataGroupStore()

    const dataGroupIds = [...new Set(potentialMembers.flatMap(pm => pm.viewPerson!.groupIds))]
    const dataGroups = await dataGroupStore.getGroupListAsync(dataGroupIds)

    console.log(`Loaded ${dataGroups.length} data groups for ${potentialMembers.length} potential members`)

    const dataGroupsById = new Map(dataGroups.map(g => [g.id!, g]))
    for (const pm of potentialMembers) {
        pm.personGroups.push(...pm.viewPerson!.groupIds.map(id => dataGroupsById.get(id)).filter(isDefined))
    }
}

async function loadUserProfileUsersAsync(potentialMembers: PotentialMember[]) {
    const userStore = useUserStore()

    const ownerUserIds = [...new Set(potentialMembers.flatMap(pm => pm.profileGroups.map(pg => pg.ownerId!)))]
    const ownerUsers = userStore.getAsyncUserList(ownerUserIds).map(a => a.data).filter(isDefined) // loaded with data groups
    const usersByProfileGroupId = new Map(ownerUsers.map(u => [u.profileDataGroupId!, u]))

    for (const pm of potentialMembers) {
        const userProfileUsers = pm.profileGroups.map(pg => usersByProfileGroupId.get(pg.id!)).filter(isDefined)
        pm.user ??= userProfileUsers.at(0)
    }
}
   
export function getUnsharedOwnedProfiles(pm: PotentialMember) {
    return pm.getProfilePersons().filter(p => p.dataGroup.ownerId == TokenManager.userId && !p.groupMember)
}

export async function shareOwnProfilesAsync(familyGroupId: string, potentialMembers: PotentialMember[]) {
    const dataGroupMemberStore = useDataGroupMemberStore()

    const profileGroupIds = potentialMembers.flatMap(pm => getUnsharedOwnedProfiles(pm).map(p => p.dataGroup.id!))
    
    console.log(`Sharing ${profileGroupIds.length} owned profiles with family group ${familyGroupId}`)

    for (const batch of _.chunk(profileGroupIds, 5)) {
        const addBatch = batch.map(id => {
            const dm = new DataGroupMember()
            dm.dataGroupId = id
            dm.principalType = PrincipalType.FamilyGroup
            dm.principalId = toValue(familyGroupId)
            dm.role = GroupMemberRole.Viewer
            return dataGroupMemberStore.addAsync(id, dm)
        })
        await Promise.all(addBatch)
    }
}
