import { customList, CountryProperty } from 'country-codes-list'

const nameToCode = new Map<string,string>(Object.entries(customList('countryNameEn' as CountryProperty, "{countryCode}")))
const aliasToName: any = {
    "USA": "United States of America",
    "United States": "United States of America",
}

nameToCode.set("Scotland", "gb-sct")
nameToCode.set("England", "gb-eng")
nameToCode.set("Wales", "gb-wls")

export interface LatLong {
    latitude: number
    longitude: number
}

export class GeoUtil {
    static getDistance(lat1: number, long1: number, lat2: number, long2: number) {
        const R = 6371 // radius of the earth in km
        const dLat = GeoUtil.degToRad(lat2-lat1)
        const dLon = GeoUtil.degToRad(long2-long1) 
        const a = 
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(GeoUtil.degToRad(lat1)) * Math.cos(GeoUtil.degToRad(lat2)) * 
            Math.sin(dLon/2) * Math.sin(dLon/2)
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a))
        const d = R * c // distance in km
        return d
    }

    static degToRad(deg: number) {
        return deg * (Math.PI/180)
    }

    static kmToMi(km: number) {
        return km * 0.621371
    }
}

export class CountryCodes {
    static fromName(countryName: string | undefined) {
        if (!countryName)
            return undefined

        const normalizedName = aliasToName[countryName] ?? countryName
        return nameToCode.get(normalizedName)
    }
}