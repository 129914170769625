<template>
  <div class="family-selector">
    <div class="selected-family" v-if="props.modelValue">
      <FamilyCard :family-id="props.modelValue" show-profile-type select-mode @click="removeSelectedFamily"></FamilyCard>
    </div>
    <div v-else>
      <SearchInput ref="searchInputRef" 
        v-model="searchTextRef" 
        :search-results="searchResultIdsRef" 
        :placeholder="placeholderRef"
        :disabled="props.disabled"
        v-slot="{ item }"
        @focus="emit('focus')"
        @result-selected="searchResultSelected">
        <FamilyListItem :family-id="item"></FamilyListItem>
      </SearchInput>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.selected-family {
  margin: 0 0.5rem;

  .family-list-item {
    margin: 0;
  }
}

.search-input {
  .family-list-item {
    margin: 0.25rem 0;
  }
}
</style>

<script lang="ts" setup>
import { computed, defineProps, nextTick, ref, watch } from 'vue'
import { useLivePersonSearch } from '@/rd/LivePersonSearch'
import { ViewPerson, livingWindowStart } from '@/rd/ResearchDataModel'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { LoadMode } from '@/util/AsyncData'
import FamilyCard from './FamilyCard.vue'
import SearchInput from "@/util/SearchInput.vue"
import FamilyListItem from './FamilyListItem.vue'

const props = defineProps<{
  modelValue: string | undefined
  livingOnly?: boolean
  disabled?: boolean
  placeholder?: string
  postFilter?: (vps: ViewPerson[]) => Promise<ViewPerson[]>
}>()

const emit = defineEmits([
  'update:modelValue',
  'focus'
])

defineExpose({
  focus: () => searchInputRef.value?.focus(),
  reset
})

const viewPersonStore = useViewPersonStore()

const searchResultIdsRef = ref<string[]>([])
const removeButtonRef = ref<HTMLButtonElement>()
const searchInputRef = ref<InstanceType<typeof SearchInput>>()

const placeholderRef = computed(() => props.placeholder ?? "Type a name")
const { searchTextRef, searchResultsRef } = useLivePersonSearch(props.postFilter)

watch(searchResultsRef, async () => {
  const familyIds = await Promise.all(
    searchResultsRef.value.map(vp => viewPersonStore.getAsyncSpouseFamiliesKeyList(vp.id!, LoadMode.EnsureLoaded)?.whenLoadCompleted)
  )
  searchResultIdsRef.value = familyIds.flatMap(list => list?.keys ?? [])
})

async function searchResultSelected(id: string) {
  emit('update:modelValue', id)
  await nextTick()
  removeButtonRef.value?.focus()
}

async function removeSelectedFamily() {
  emit('update:modelValue', undefined)
  await nextTick()
  searchInputRef.value?.focus()
}

function reset() {
  searchTextRef.value = ""
  searchInputRef.value?.reset()
}

</script>