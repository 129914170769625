export interface LatestTimestampStore {
    getLatestTimestamp(): number;
    setLatestTimestamp(t: number): void;
}

export class LocalLatestTimestampStore implements LatestTimestampStore {
    private readonly storageKey: string;

    getLatestTimestamp() {
        return parseInt(window.localStorage.getItem(this.storageKey) ?? '0');
    }
    
    setLatestTimestamp(t: number) {
        window.localStorage.setItem(this.storageKey, t.toString());
    }

    constructor(storageKey: string) {
        this.storageKey = storageKey;
    }
}
