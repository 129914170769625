<template>
  <div ref="stripeElemRef">
  </div>
</template>

<style lang="scss" scoped>
</style>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import { loadStripe, Stripe, StripeCardElement } from '@stripe/stripe-js'
import { Environment } from '@/util/Environment'
import { PaymentCardInfoSubmitResult } from './PaymentCardInfo'

const emit = defineEmits([
  'change',
])

let stripe: Stripe | null
let stripeCard: StripeCardElement | null
const stripeElemRef = ref<HTMLElement>()
const readyRef = ref(false)

defineExpose({
  ready: readyRef,
  submitAsync,
})

onMounted(async () => {
  if (!Environment.isDevelopment()) {
    stripe = await loadStripe(Environment.get('STRIPE_PUBLIC_KEY'))
    if (!stripe)
      throw 'Failed to load payment provider'

    const elements = stripe.elements()
    stripeCard = elements.create('card')
    stripeCard.mount(stripeElemRef.value!)
    stripeCard.on('change', e => emit('change', e))

    readyRef.value = true    
  }
})

onUnmounted(() => {
  stripeCard?.destroy()
})

async function submitAsync(intentId: string, clientSecret: string): Promise<PaymentCardInfoSubmitResult> {
  if (!readyRef.value)
    return { error: { message: 'Payment provider not ready' } }

  const result = await stripe!.confirmCardPayment(
    clientSecret, { payment_method: { card: stripeCard! } })
  
  return result
}

</script>