import { computed, onMounted, onBeforeUnmount, ref, watch, getCurrentInstance } from 'vue'
import { Modal } from 'bootstrap'

export interface PageTitleOptions {
    page: string
    item?: string | (() => string | undefined)
    itemOnly?: boolean
}

export function usePageTitle(page: string | (() => string), item?: string | (() => string | undefined), itemOnly = false) {

    const component = getCurrentInstance()
    const pageAndItem = computed(() => {
        const pageName = (typeof page === 'string') ? page : page?.()
        const itemName = (typeof item === 'string') ? item : item?.()
        return itemName ? (itemOnly ? itemName : `${pageName} - ${itemName}`) : pageName
    })

    const isMounted = ref(false)

    onMounted(() => {
        isMounted.value = true
    }, component)

    onBeforeUnmount(() => {
        // this keeps us from setting the title in async code that runs
        // after the page has been unmounted (so is no longer the current page)
        isMounted.value = false
    }, component)

    watch([pageAndItem, isMounted], () => {
        if (isMounted.value) {
            document.title = `${pageAndItem.value} - Tree Crossing`
        }
    })

    return pageAndItem
}

export function getSignInUrlWithRedirect(redirectUri: string) {
    return `/signin?redirect_uri=${encodeURIComponent(redirectUri)}`
}

export function getSafeRedirectUri() {
    // SECURITY: do not allow redirect to external sites
    const urlParams = new URLSearchParams(window.location.search)
    const uri = urlParams.get('redirect_uri')
    return uri?.startsWith("/") ? uri : undefined // must be a relative path
}

export function notImplemented() {
    alert("Sorry, this feature is not available yet.")
}

export function hideModalAsync(modalElem: HTMLElement) {
    const modal = Modal.getOrCreateInstance(modalElem)

    return new Promise<void>((resolve) => {
        function onHidden() {
            modalElem.removeEventListener('hidden.bs.modal', onHidden)
            resolve()
        }
        modalElem.addEventListener('hidden.bs.modal', onHidden)
        modal.hide()
    })
}
