<template>
  <div class="payment-card">
    <div class="card-chip"></div>
    <div class="card-number">xxxx xxxx xxxx {{ props.paymentMethod.lastDigits }}</div>
    <div class="card-exp">exp {{ props.paymentMethod.expirationDate?.toFormat('MM/yy') }}</div>
    <div class="card-holder">{{ props.paymentMethod.billingName }}</div>
    <div class="card-logo" :class="props.paymentMethod.brand">
      <img :src="logoRef"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  $cardHeight: 120px;

.payment-card {
  position: relative;
  height: 120px;
  width: calc($cardHeight * 1.59); // credit card aspect ratio (3.375 / 2.125)
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 0 0.5rem 0.5rem 1rem;
  background-color: #eee;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  display: flex;
  flex-direction: column;
  justify-content: end;
  font-size: 0.65rem;
  color: #888;

  .card-chip {
    height: 22px;
    width: 30px;
    border-radius: 3px;
    background-color: #e4e4e4;
  }

  .card-number {
    font-size: 0.875rem;
    color: #333;
  }

  .card-holder {
    text-transform: uppercase;
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
  }

  .card-logo {
    position: absolute;
    top: 8px;
    right: 8px;
    height: 20px;
    width: 60px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &.Visa {
      width: 40px;
      img {
        height: 200%;
        filter: brightness(0.8);
      }
    }

    &.Mastercard {
      width: 30px;
      
      img {
        height: 100%;
        filter: saturate(0) contrast(0.25) brightness(1.5);
      }
    }

    &.Discover img {
      height: 60%;
      filter: saturate(0) contrast(0.25) brightness(1.5);
    }

    &.AmericanExpress {
      height: 30px;
      width: 30px;

      img {
        height: 100%;
        filter: saturate(0) contrast(0.25) brightness(1.5);
      }
    }
  }
}
</style>

<script lang="ts" setup>
import { PaymentMethod } from '@/gp/GroupAdminModel'
import { computed } from 'vue';

const props = defineProps<{
  paymentMethod: PaymentMethod
}>()

const logos: any = { // 'any' prevents TS from complaining about arbitrary string as index
  "Visa": require('@/assets/logos/Visa.svg'),
  "Mastercard": require('@/assets/logos/Mastercard.png'),
  "Discover": require('@/assets/logos/Discover.png'),
  "AmericanExpress": require('@/assets/logos/AmericanExpress.png'),
}

const logoRef = computed(() => logos[props.paymentMethod.brand ?? ''])

</script>
