import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/gp/UserStore'
import { useFamilyGroupCreator } from '@/manage/FamilyGroupCreator'
import { useExploreStore } from '@/explore/ExploreStore'
import { LoadMode } from '@/util/AsyncData'

export function useShareAction() {
    const router = useRouter()
    const userStore = useUserStore()
    const { defaultShareWorkspaceRef } = useFamilyGroupCreator()
    const exploreStore = useExploreStore()
    
    const selfRef = computed(() => userStore.getAsyncSelf(LoadMode.EnsureLoaded)?.data)
    const showShareActionRef = computed(() => 
        (selfRef.value?.limitsDisabled || (selfRef.value?.limits?.sharingLimit ?? 0) > 0) &&
        // TODO: check sharing usage too
        exploreStore.hasViewedTree && 
        defaultShareWorkspaceRef.value && 
        !router.currentRoute.value.path.startsWith('/groups')
    )

    return {
        showShareActionRef,
        shareActionUrl: "/groups/new"
    }
}
