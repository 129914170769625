<template>
  <CompactItem class="user-item" :select-mode="props.selectMode" :pageUrl="userRef?.pageUrl" @click="onClick">
    <template #icon>
      <div>
        <img class="person-icon" src="@/assets/icons/person_gray_150.png"/>
        <img class="icon-overlay" src="@/assets/tree-crossing-icon-128.png"/>
      </div>
    </template>
    <template #title>{{ userRef?.displayName }}</template>
    <template #subtitle>User</template>
  </CompactItem>
</template>

<style lang="scss" scoped>
.user-item :deep(.item-icon) {
  position: relative;

  .person-icon {
    opacity: 60%;
  }

  .icon-overlay {
    position: absolute;
    bottom: -2px;
    left: -2px;
    width: 16px;
    height: 16px;
  }
}
</style>

<script lang="ts" setup>
import { computed } from 'vue';
import { useUserStore } from '@/gp/UserStore'
import CompactItem from '@/util/CompactItem.vue'

const props = defineProps({
  userId: String,
  small: Boolean,
  selectMode: Boolean,
})

const emit = defineEmits([
  'click'
])

const userStore = useUserStore()

const userRef = computed(() => userStore.getAsyncUser(props.userId)?.data) // should be loaded by parent component

function onClick() {
  emit('click')
}
</script>