<template>
  <div class="invite-recipient">
    <div v-if="useContactsRef && selectedContactRef" class="selected-contact">
      <div>
        {{ getContactDisplayName(selectedContactRef) ?? addressRef }}
        <span class="address">({{ addressRef }})</span>
      </div>
      <button type="button" class="btn-close" @click="removeContact"></button>
    </div>
    <AutoCompleteInput v-else-if="useContactsRef"
      ref="contactSearchInputRef"      
      placeholder="contact name or address"
      :search="searchContacts"
      :item-component="ContactSearchItem"
      @result-selected="contactSelected">
    </AutoCompleteInput>
    <input type="email" v-else ref="addressInputRef" class="form-control" v-model="addressRef" required placeholder="email address" />
  </div>
</template>

<style lang="scss" scoped>
.invite-recipient {
  margin-bottom: 0.25rem;
  height: 34px;
}

.selected-contact {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: start;

  .address {
    color: #888;
  }

  .btn-close {
    padding: 0;
    background-size: contain;
    opacity: 30%;
  }
}
</style>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useManageStore } from '@/manage/ManageStores'
import AutoCompleteInput from "@/util/AutoCompleteInput.vue"
import ContactSearchItem from "@/manage/contacts/ContactSearchItem.vue"
import { Contact, getContactDisplayName } from '@/manage/contacts/ContactProviders'
import { useContactStore } from '@/manage/contacts/ContactStore'
import { AddressUtil } from '@/util/AddressUtil'

const props = defineProps<{
  profileId?: string
}>()

defineExpose({
  focus,
  validate,
})

const manageStore = useManageStore()
const contactStore = useContactStore()

const useContactsRef = computed(() => !!contactStore.contactProviderId)
const addressRef = computed({
  get: () => manageStore.inviteAddresses.get(props.profileId ?? ''),
  set: value => manageStore.inviteAddresses.set(props.profileId!, value)
})
const selectedContactRef = ref<Contact>()

const contactSearchInputRef = ref<InstanceType<typeof AutoCompleteInput>>()
const addressInputRef = ref<HTMLInputElement>()

function searchContacts(query: string) {
  //console.log(`Searching contacts for ${searchTextRef.value}`)
  
  // copy to address in case they type an email directly, not a search term
  addressRef.value = query

  const rx = new RegExp(query, 'i')
  const prx = new RegExp('^' + query, 'i')

  const results = [] as ContactResult[]
  for (const c of contactStore.contacts.values()) {
    if (c.emailAddresses.length) {
      let score = 0
      for (const n of c.names) {
        if (score < 2) {
          score = Math.max(score,
            getMatchScore(n.givenName, rx, prx), 
            getMatchScore(n.familyName, rx, prx), 
            getMatchScore(n.middleName, rx, prx), 
            getMatchScore(n.displayName, rx, prx)
          )
        }
      }
      if (score < 2) {
        score = Math.max(score, ...c.emailAddresses.map(e => getMatchScore(e.value, rx, prx)))
      }
      if (score > 0) {
        results.push({ value: c.emailAddresses[0].value, contact: c, score })
      }
    }
  }
  results.sort((a, b) => b.score - a.score)
  return results
}

function getMatchScore(value: string | undefined, rx: RegExp, prx: RegExp) {
  return !value ? 0 : prx.test(value) ? 2 : rx.test(value) ? 1 : 0
}

function contactSelected(item: ContactResult) {
  addressRef.value = item.value
  selectedContactRef.value = item.contact
  console.log(`Contact selected: ${item.value}`)
}

function removeContact() {
  addressRef.value = ''
  selectedContactRef.value = undefined
}

function focus() {
  if (useContactsRef.value) {
    contactSearchInputRef.value?.focus()
  }
  else {
    addressInputRef.value?.focus()
  }
}

function validate() {
  const isValid = AddressUtil.isValidEmail(addressRef.value)
  if (isValid) {
    addressInputRef.value?.classList.remove('is-invalid')
  }
  else {
    addressInputRef.value?.classList.add('is-invalid')
  }
  contactSearchInputRef.value?.setValid(isValid)
  return isValid
}

interface ContactResult {
  value: string
  contact: Contact
  score: number
}

</script>