<template>
  <div ref="containerRef" class="auto-complete-input">
  </div>
</template>

<style lang="scss">
// use our font, not the autocomplete defaults
.aa-Autocomplete {
  --aa-font-family: 'Noto Sans', sans-serif;
}

// make the input look like a bootstrap form-control
.aa-Form {
  border-color: #ddd;
  border-radius: 0.375rem;
  --aa-search-input-height: 34px;
  //--aa-primary-color-rgb: 0, 0, 0;

  &:focus-within {
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.15rem rgba(13, 110, 253, 0.15);
  }

  .aa-SubmitButton {
    width: 2rem;
    padding: 0 0.5rem;
  }

  .aa-SubmitIcon {
    width: 16px;
    fill: #aaa;
  }

  &.is-invalid {
    border-color: #dc3545;

    &:focus-within {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.15rem rgba(220, 53, 69, 0.15);
    }
  }

  .aa-ClearButton {
    padding: 0 0.5rem;
  }
}

// make the results menu look like the rest of the app
.aa-Panel {
  z-index: 1100; // higher than a bootstrap modal so it can be used inside one
  --aa-font-family: 'Noto Sans', sans-serif;

  .aa-PanelLayout {
    padding: 0.25rem 0.5rem;
  }

  .aa-Item {
    min-height: 1.5rem;

    &[aria-selected="true"] {
      background-color: #eee;
    }
  }
}
</style>

<script lang="ts" setup>
import { ref, onMounted, createVNode, h, Fragment, render, watch, onUnmounted, VNodeProps } from 'vue'
import { AutocompleteApi, autocomplete } from '@algolia/autocomplete-js'
import '@algolia/autocomplete-theme-classic'
import { PromiseOrValue } from './TypeScriptUtil';

const props = defineProps<{
  placeholder: string
  disabled?: boolean
  search: (query: string) => PromiseOrValue<{ value: string }[]>
  itemComponent: object
  getComponentProps?: (item: { value: string }) => Record<string, unknown> | VNodeProps
}>()

const emit = defineEmits([
  'resultSelected',
  'focus',
  'keydown',
])

defineExpose({
  focus,
  setValid,
})

const containerRef = ref<HTMLElement | null>(null)
let autocompleteApi: AutocompleteApi<{ value: string }> | undefined = undefined

onMounted(() => {
  autocompleteApi = autocomplete({
    container: containerRef.value!,
    placeholder: props.placeholder,
    getSources({ query }) {
      return [{
        sourceId: 'default', 
        getItems: () => searchOnce(query),
        templates: {
            item({ item }) { return createVNode(props.itemComponent, props.getComponentProps?.(item) ?? { item }) },
        },
        onSelect({ item }) {
          //console.log(`Autocomplete select result: ${item.value}`)
          emit('resultSelected', item)
        },
      }]
    },
    renderer: { createElement: h, Fragment, render },
    onStateChange({ state }) {
      //console.log(`Autocomplete input: ${state.query}`)
    },
    onReset({ state, event }) {
      //console.log(`Autocomplete reset`)
      event.preventDefault()
      event.stopPropagation()
    },
  });
});

onUnmounted(() => {
  autocompleteApi?.destroy()
})

let lastQuery = ''
let lastResults = [] as { value: string }[]

async function searchOnce(query: string) {
  if (query === lastQuery)
    return lastResults

  lastQuery = query
  lastResults = await props.search(query)
  return lastResults
}

function focus() {
  const input = containerRef.value?.querySelector('input.aa-Input') as HTMLInputElement | undefined
  input?.focus()
}

function setValid(isValid: boolean) {
  const form = containerRef.value?.querySelector('form.aa-Form') as HTMLFormElement | undefined
  if (isValid) {
    form?.classList.remove('is-invalid')
  }
  else {
    form?.classList.add('is-invalid')
  }
}

</script>