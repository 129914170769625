<template>
  <ul :class="listClassRef" class="site-nav">
    <li :class="itemClassRef" class="hide-md" v-if="userRef">
      <SiteSearch />
    </li>
    <li :class="itemClassRef" v-if="userRef">
      <router-link :class="linkClassRef" to="/home">Home</router-link>
    </li>
    <li :class="itemClassRef" v-if="selfViewPersonRef?.pageUrl">
      <router-link :class="linkClassRef" :to="selfViewPersonRef.pageUrl">Explore my family</router-link>
    </li>
    <li v-if="props.asMenu"><hr class="dropdown-divider"></li>
    <li :class="itemClassRef" v-if="userRef">
      <router-link :class="linkClassRef" to="/trees">Trees</router-link>
    </li>
    <li :class="itemClassRef" v-if="userRef">
      <router-link :class="linkClassRef" :to="{ name: 'account.familyGroups' }">
        Groups
        <span v-if="notificationCountRef > 0" class="badge notify-badge rounded-pill">
          {{ notificationCountRef }}
        </span>
      </router-link>
    </li>
    <li :class="itemClassRef" v-if="showGetStartedRef">
      <router-link class="btn btn-primary me-3" to="/#try">Try it for free</router-link>
    </li>
    <li :class="itemClassRef" v-if="showGetStartedRef">
      <router-link :class="linkClassRef" to="/plans">Plans</router-link>
    </li>
    <li v-if="props.asMenu"><hr class="dropdown-divider"></li>
    <li :class="itemClassRef">
      <router-link :class="linkClassRef" to="/support">Support</router-link>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.site-nav {
  font-size: 0.875rem;
}

@media (min-width: 768px) {
  .site-nav {
    flex-direction: row;
    gap: 1rem;
    font-size: 1rem;
  }
}
</style>

<script lang="ts" setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/gp/UserStore'
import { useDataGroupStore } from "@/gp/DataGroupStore"
import { useViewPersonStore } from "@/rd/ViewPersonStore"
import { useFamilyGroupInviteStore } from '@/gp/FamilyGroupInviteStore'
import { InvitationResult } from '@/gp/GroupAdminModel'
import { LoadMode } from '@/util/AsyncData'
import SiteSearch from './SiteSearch.vue'

const props = defineProps<{
  asMenu?: boolean
}>()

const router = useRouter()
const userStore = useUserStore()
const dataGroupStore = useDataGroupStore()
const viewPersonStore = useViewPersonStore()
const inviteStore = useFamilyGroupInviteStore()

const listClassRef = computed(() => props.asMenu ? "dropdown-menu" : "navbar-nav")
const itemClassRef = computed(() => props.asMenu ? undefined : "nav-item")
const linkClassRef = computed(() => props.asMenu ? "dropdown-item" : "nav-link")
const userRef = computed(() => userStore.getAsyncSelf(LoadMode.EnsureLoaded)?.data)
const selfPersonIdRef = computed(() => dataGroupStore.getAsyncGroup(userRef.value?.profileDataGroupId, LoadMode.EnsureLoaded)?.data?.startItemId)
const selfViewPersonRef = computed(() => viewPersonStore.getAsyncPerson(selfPersonIdRef.value, LoadMode.EnsureLoaded)?.data)

const showGetStartedRef = computed(() => !userRef.value && !router.currentRoute.value.path.startsWith('/groupinvites'))

const pendingInvitesRef = computed(() => inviteStore.getInvitesForUser(undefined, LoadMode.EnsureLoaded)
  .filter(inv => inv.result == InvitationResult.Pending))

const notificationCountRef = computed(() => pendingInvitesRef.value.length)
</script>
