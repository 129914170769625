<template>
  <div class="profile-spouse-rel">
    <ProfileRelationshipCard :person-id="viewSpouseIdRef" spouse>
    </ProfileRelationshipCard>
    <div class="action-row">
      <button type="button" v-if="canEditRef" class="btn btn-inline btn-link action" @click="onEdit">
        Change
      </button>
      <button type="button" v-if="canRemoveRef" class="btn btn-inline btn-link action" @click="onRemove">
        Remove
      </button>
    </div>
    <ExpandableItem v-model:expanded="areMatchesExpandedRef" class="expander">
      <template #item>
        <div class="expander-label">
          Relationship defined in <span class="count"> ({{ matchProfileIdsRef.length }})</span>
        </div>
      </template>
      <template #content>
        <div class="matches-area">
          <ul class="list-unstyled matches-list">
            <li v-for="id in matchProfileIdsRef" :key="id">
              <PersonCard
                :person-id="id"
                profile 
                layout="small"
                :placeholder="id == props.personId">
              </PersonCard>
            </li>
          </ul>
        </div>
      </template>
    </ExpandableItem>
    <ProfileChildren
      :profile-person-id="props.personId"
      :family="props.spouseFamily"
      @add-child="emit('addChild')">
    </ProfileChildren>
    <Modal ref="messageModalRef" info>
      <template #title>Remove spouse</template>
      {{ messageModalTextRef }}
    </Modal>
    <Modal ref="multipleDefsModalRef" @ok="onMultipleDefsConfirmed">
      <template #title>Remove spouse</template>
      This spouse relationship is also defined in at least one other profile.
      You can remove the relationship here, but it will still be present for you and others because it is also defined elsewhere.
      <template #okText>Continue</template>
    </Modal>
    <ProfileSpouseRelationshipModal ref="relationshipModalRef">
    </ProfileSpouseRelationshipModal>
  </div>
</template>

<style lang="scss" scoped>
.profile-spouse-rel {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.defined-in {
  margin-left: 2rem;
  margin-bottom: 0.25rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
}

.count {
  font-size: 0.75rem;
  color: #aaa;
}

.action-row {
  margin-left: 2rem;
  display: flex;
  gap: 1rem;

  .action {
    font-size: 0.875rem;
  }
}

.expander {
  margin-left: 2rem;
  font-size: 0.875rem;
}

.expander-label {
  color: #888;
}

.matches-area {
  margin-left: 1.25rem;
  padding: 0.5rem 0; // margin-top causes jumping when expanding (??)

  .list-header {
    margin-bottom: 0.5rem;
    font-size: 0.75rem;
    color: #888;
  }

  .matches-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .action-row {
    margin-top: 0.5rem;
    margin-left: 0;
  }
}
</style>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { FamilyAndChildren } from '@/rd/ViewFamilyStore'
import { CompositeId } from '@/rd/CompositeId'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useAssertionStore } from '@/rd/AssertionStore'
import { ProfileUtils } from './ProfileUtils'
import { useFamilyStore } from '@/rd/FamilyStore'
import { ItemPermissions } from '@/gp/GroupAdminModel'
import PersonCard from './PersonCard.vue'
import ExpandableItem from '@/util/ExpandableItem.vue'
import Modal from '@/util/Modal.vue'
import ProfileChildren from './ProfileChildren.vue'
import ProfileSpouseRelationshipModal from './ProfileSpouseRelationshipModal.vue'
import ProfileRelationshipCard from './ProfileRelationshipCard.vue'
import { FamilyRelationshipRole } from '@/rd/ResearchDataModel'
import { hideModalAsync } from '@/util/AppUtil'

const props = defineProps<{
  personId: string
  spouseFamily: FamilyAndChildren
}>()

const emit = defineEmits([
  "addChild",
  "remove",
])

const viewPersonStore = useViewPersonStore()
const familyStore = useFamilyStore()
const dataGroupStore = useDataGroupStore()
const assertionStore = useAssertionStore()

const areMatchesExpandedRef = ref(false)
const messageModalTextRef = ref('')

const relationshipModalRef = ref<InstanceType<typeof ProfileSpouseRelationshipModal>>()
const messageModalRef = ref<InstanceType<typeof Modal>>()
const multipleDefsModalRef = ref<InstanceType<typeof Modal>>()

const profileGroupIdRef = computed(() => CompositeId.getGroupId(props.personId)!)
const viewPersonRef = computed(() => viewPersonStore.getLoadedPerson(props.personId))
const viewSpouseIdRef = computed(() => props.spouseFamily.family.spouseOf(viewPersonRef.value?.id ?? ''))
const personRoleRef = computed(() => props.spouseFamily.family.roleOf(viewPersonRef.value?.id ?? ''))
const spouseRoleRef = computed(() => personRoleRef.value == FamilyRelationshipRole.Father ? FamilyRelationshipRole.Mother : FamilyRelationshipRole.Father)
const matchProfileIdsRef = computed(() => {
  // spouse relationship matches are all view family matches that belong to a profile for either the father or mother
  // - this DOES include matches that belong to a research group, in which case the father's profile is considered the relationship owner
  // - this does NOT include matches that belong to a child's profile
  const families = familyStore.getLoadedFamilyList(props.spouseFamily.family.matchIds)
  const dataGroups = dataGroupStore.getLoadedGroupList(props.spouseFamily.family.groupIds)
  return ProfileUtils.getProfileSpouseFamilyIds(families, dataGroups)
})
const permissionsRef = computed(() => dataGroupStore.getLoadedPermissions(profileGroupIdRef.value))
const canEditRef = computed(() => (permissionsRef.value & ItemPermissions.Modify) > 0)
const canRemoveRef = computed(() => canEditRef.value && matchProfileIdsRef.value.includes(props.personId))
const familyAssertionsRef = computed(() => assertionStore.getLoadedForFamily(props.spouseFamily.family.id!))

function onEdit() {
  relationshipModalRef.value?.open(profileGroupIdRef.value, props.spouseFamily.family, spouseRoleRef.value)
}

function onRemove() {
  if (props.spouseFamily.childIds.length) {
    messageModalTextRef.value = 
      `This spouse family still has ${props.spouseFamily.childIds.length} children. All children must be removed before you can delete the family.`
    messageModalRef.value?.open()
  }
  else if (familyAssertionsRef.value.length) {
    messageModalTextRef.value = 
      `This spouse family still has ${familyAssertionsRef.value.length} details defined. All family details must be removed before you can delete the family.`
    messageModalRef.value?.open()
  }
  else if (props.spouseFamily.family.matchIds.length > 1) {
    multipleDefsModalRef.value?.open()
  }
  else {
    emit('remove', props.spouseFamily)
  }
}

async function onMultipleDefsConfirmed() {
  if (multipleDefsModalRef.value) {
    await hideModalAsync(multipleDefsModalRef.value!.$el)
  }
  emit('remove', props.spouseFamily)
}

</script>
