<template>
  <div class="app" :class="{ loading: loadingRef }">
    <SiteHeader v-if="showHeaderRef"></SiteHeader>
    <div class="content" :class="{ embedded: appHostStore.isEmbedded, 'container-md': useContainerRef }">
      <router-view/>
    </div>
    <div class="footer">
      <SiteFooter v-if="showFooterRef"></SiteFooter>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .loading {
    display: none;
  }
}

.content {
  flex-grow: 1;

  &:not(.embedded) {
    padding-bottom: 3rem;
  }
}

.footer {
  position: relative;
  bottom: 0;
}
</style>

<script setup lang="ts">
import { computed, onMounted, ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { TokenManager } from '@/auth/TokenManager'
import { useAppHostStore } from './AppHostStore'
import SiteHeader from '@/site/SiteHeader.vue'
import SiteFooter from '@/site/SiteFooter.vue'

const router = useRouter()
const appHostStore = useAppHostStore()

watchEffect(() => {
  if (router.currentRoute.value.query.embed == 'true') {
    appHostStore.isEmbedded = true // set the first time we see it, but don't expect it to be present on every page
  }
})

const loadingRef = ref(true)
const showHeaderRef = computed(() => !appHostStore.isEmbedded)

const pagesWithFooter = [ '/', '/contact', '/about', '/privacy', '/terms', '/learn/faq', '/learn/sync', '/learn/research', '/plans', '/support'  ]
const showFooterRef = computed(() => pagesWithFooter.includes(router.currentRoute.value.path))

const fullWidthPages = [ '/', '/home' ]
const useContainerRef = computed(() => !fullWidthPages.includes(router.currentRoute.value.path))

onMounted(async () => {
  TokenManager.autoRefresh() // async (fire-and-forget)

  window.addEventListener('load', () => {
    loadingRef.value = false
  })
})
</script>
