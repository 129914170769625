<template>
  <div>
    <ul class="news-list list-unstyled">
      <li v-for="item in newsItemsRef" :key="item.id">
        <img v-if="useImg(item)" :src="useImg(item)" class="news-icon" :class="item.type" />
        <component v-else :is="useIcon(item)" class="news-icon" :class="item.type" />
        <div class="news-content">
          <router-link :to="item.url" class="link-dark news-text">{{ item.displayText }}</router-link>
          <small>{{ DateTimeUtil.toRelativePastDate(item.timestamp) }}</small>
        </div>
      </li>
    </ul>
    <!-- <p><a href="#">More...</a></p> -->
  </div>
</template>

<style lang="scss" scoped>
.news-list {
  line-height: 1.2;
  
  li {
    display: flex;
    gap: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.news-icon {
  height: 20px;

  &.FamilyGroupInvite {
    filter: brightness(0) saturate(100%) invert(63%) sepia(76%) saturate(5097%) hue-rotate(184deg) brightness(100%) contrast(94%);
  }

  &.FirstSync, &.Sync {
    color: green;
  }
}

.news-text {
  display: -webkit-box;
  font-size: 0.85rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>

<script lang="ts" setup>
import { ref, Ref, watch } from 'vue'
import { DateTimeUtil } from '@/util/LuxonUtil'
import { NewsItem } from '@/explore/news/NewsItem'
import { useGroupAdminNews } from './GroupAdminNews'
import { useResearchDataNews } from './ResearchDataNews'
import { UploadIcon } from '@zhuowenli/vue-feather-icons'
import mailImg from '@/assets/icons/mail_gray_60.png'
import _ from 'lodash'

const groupAdminNews = useGroupAdminNews()
const researchDataNews = useResearchDataNews()

const refreshRef = ref(0)
const newsItemsRef = ref([]) as Ref<NewsItem[]>

watch(refreshRef, async () => {
  const allNews = (await Promise.all([
    groupAdminNews.getNewsAsync(),
    researchDataNews.getNewsAsync()
  ])).flat()

  // CONSIDER: include event precedence

  newsItemsRef.value = _.sortBy(allNews, n => n.timestamp.toMillis()).reverse().slice(0, 5) // most recent first

}, { immediate: true })

function useImg(item: NewsItem) {
  switch (item.type) {
    case 'FamilyGroupInvite': return mailImg
  }
}

function useIcon(item: NewsItem) {
  switch (item.type) {
    case 'FirstSync':
    case 'Sync':
      return UploadIcon
  }
}

</script>