import { reactive } from 'vue'
import { Site } from "./ResearchDataModel"
import { AsyncData, LoadMode } from "@webapp/util/AsyncData"
import { AsyncDataStore } from "@webapp/util/AsyncDataStore"
import { defineStore } from "pinia"
import { DateTime } from "luxon"
import { DateTimeUtil } from "@webapp/util/LuxonUtil"
import _ from "lodash"
import { assignExisting } from "@webapp/util/TypeScriptUtil"


export const useSiteStore = defineStore("siteStore", () => {
    const asyncSites = reactive(new Map<string, AsyncData<Site>>())

    function trackAsyncSite(siteId: string | undefined) {
        return siteId ? trackAsyncSites([siteId])[0] : undefined
    }

    function trackAsyncSites(siteIds: string[]) {
        AsyncDataStore.getAsyncItemsToLoad(asyncSites, siteIds, "Site", LoadMode.TrackOnly)
        return siteIds.map(id => asyncSites.get(id)!)
    }

    function addSitesToStore(plainSites: any[], requestTime: DateTime) {
        const siteMap = AsyncDataStore.createItemMap(plainSites, "Site", plainToSite, s => s.id!)
        AsyncDataStore.addItemMapToStore(asyncSites, "Site", siteMap, requestTime)
    }

    return {
        asyncSites,

        trackAsyncSite,
        trackAsyncSites,
        addSitesToStore,
    }
})

function plainToSite(p: any) {
    const site = assignExisting(new Site(), p)
    site.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate)
    return site
}
