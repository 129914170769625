<template>
  <div class="profile-sharing-editor">
    <div v-if="isResearchRef">
      <label class="form-label">This profile belongs to this tree</label>
      <ResearchGroupItem :data-group-id="dataGroupRef?.id"></ResearchGroupItem>
    </div>
    <div v-if="!personRef?.restricted">
      <div v-if="profileTypeRef == ProfileType.Research">
        <small>Anyone that can view the tree this profile belongs to.</small>
      </div>
      <div>
        <h5>Shared with</h5>
        <ul class="list-unstyled shared-list">
          <li v-if="familyGroupMembersRef.length == 0" class="empty-list">
            None
          </li>
          <li v-for="dm in familyGroupMembersRef" :key="dm.id">
            <FamilyGroupItem :family-group-id="dm.principalId" small></FamilyGroupItem>
            <div class="item-actions">
              <button type="button" v-if="canEditRef" class="btn btn-inline btn-link" @click="onRemoveFamilyGroup(dm)">
                Remove
              </button>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="canEditSharingRef">
        <h5>Sharing requests</h5>
        <div class="area-options">
          <label class="form-label">
            <input type="checkbox" v-model="showClosedRequestsRef" class="form-check-input">&nbsp;
            Show approved and expired
          </label>
        </div>
        <ul class="list-unstyled shared-list">
          <li v-if="dataGroupInvitesRef.length == 0" class="empty-list">
            <template v-if="showClosedRequestsRef">
              No requests
            </template>
            <template v-else>
              No pending requests
            </template>
          </li>
          <li v-for="inv in dataGroupInvitesRef" :key="inv.id">
            <FamilyGroupItem 
              :data-group-invite-id="inv.id"
              select-mode
              @click="onSelectDataGroupInvite(inv.id!)">
            </FamilyGroupItem>
          </li>
        </ul>
        <div class="actions">
          <button type="button" v-if="canEditSharingRef" class="btn btn-primary" @click="onShare">
            <PlusIcon size="1.2x" class="mb-1"/> Share this profile
          </button>
        </div>
      </div>
    </div>
    <DataGroupShareModal ref="dataGroupShareModalRef" profile @request-share="onRequestShare"/>
    <DataGroupInviteModal ref="dataGroupInviteModalRef"/>
    <DataGroupMemberRemoveModal ref="removeFamilyGroupModalRef" profile/>
  </div>
</template>

<style lang="scss" scoped>
h5 {
  font-size: 1rem;
  font-weight: normal;
}

.shared-list {
  margin-left: 1rem;
  margin-bottom: 1.5rem;

  li {
    margin-bottom: 0.5rem;
  }
}

.item-actions {
  margin-top: 4px;
  margin-left: calc(44px + 0.5rem); // from CompactItem
  font-size: 0.875rem;
}
</style>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useDataGroupMemberStore } from '@/gp/DataGroupMemberStore'
import { useDataGroupInviteStore } from '@/gp/DataGroupInviteStore'
import { useUserStore } from '@/gp/UserStore'
import { usePersonStore } from '@/rd/PersonStore'
import { LoadMode } from '@/util/AsyncData'
import { ProfileUtils, ProfileType } from './ProfileUtils'
import { CompositeId } from '@/rd/CompositeId'
import { DataGroupMember, DataGroupType, InvitationResult, ItemPermissions, PrincipalType } from '@/gp/GroupAdminModel'
import FamilyGroupItem from './FamilyGroupItem.vue'
import DataGroupShareModal from './DataGroupShareModal.vue'
import DataGroupInviteModal from './DataGroupInviteModal.vue'
import DataGroupMemberRemoveModal from './DataGroupMemberRemoveModal.vue'

const props = defineProps<{
  personId: string
}>()

const showClosedRequestsRef = ref(false)

const dataGroupShareModalRef = ref<InstanceType<typeof DataGroupShareModal>>()
const dataGroupInviteModalRef = ref<InstanceType<typeof DataGroupInviteModal>>()
const removeFamilyGroupModalRef = ref<InstanceType<typeof DataGroupMemberRemoveModal>>()

const dataGroupStore = useDataGroupStore()
const dataGroupMemberStore = useDataGroupMemberStore()
const dataGroupInviteStore = useDataGroupInviteStore()
const userStore = useUserStore()
const personStore = usePersonStore()

const permissionsRef = computed(() => dataGroupStore.getAsyncPermissions(profileGroupIdRef.value, LoadMode.EnsureLoaded)?.data ?? ItemPermissions.None)
const dataGroupRef = computed(() => dataGroupStore.getAsyncGroup(profileGroupIdRef.value, LoadMode.EnsureLoaded)?.data)
const familyGroupMembersRef = computed(() => dataGroupMemberStore.getMembersForDataGroups([profileGroupIdRef.value], LoadMode.EnsureLoaded)
  .filter(dm => dm.principalType == PrincipalType.FamilyGroup))
const isDataGroupAdminRef = computed(() => (permissionsRef.value & ItemPermissions.Administrator) == ItemPermissions.Administrator)
const dataGroupInvitesRef = computed(() => !isDataGroupAdminRef.value ? [] :
  dataGroupInviteStore.getInvitesForDataGroup(profileGroupIdRef.value, LoadMode.EnsureLoaded)
    .filter(inv => inv.result == InvitationResult.Pending))
const personRef = computed(() => personStore.getAsyncPerson(props.personId)?.data)

const profileGroupIdRef = computed(() => CompositeId.getGroupId(props.personId)!)
const canEditRef = computed(() => (permissionsRef.value & ItemPermissions.Modify) > 0)
const canEditSharingRef = computed(() => (permissionsRef.value & ItemPermissions.ModifyPermissions) > 0)
const ownerRef = computed(() => userStore.getAsyncUser(dataGroupRef.value?.ownerId)?.data) // loaded with group
const isResearchRef = computed(() => dataGroupRef.value?.groupType == DataGroupType.Research)
const profileTypeRef = computed(() => ProfileUtils.getProfileType(personRef.value, dataGroupRef.value, ownerRef.value))

function onSelectDataGroupInvite(inviteId: string) {
  dataGroupInviteModalRef.value?.openEdit(inviteId)
}

function onRequestShare(familyGroupId: string) {
  dataGroupInviteModalRef.value?.openNew(profileGroupIdRef.value, familyGroupId)
}

function onShare() {
  const familyGroupIds = familyGroupMembersRef.value.map(m => m.principalId!)
  familyGroupIds.push(...dataGroupInvitesRef.value.map(inv => inv.principalId!))
  dataGroupShareModalRef.value?.open(profileGroupIdRef.value, familyGroupIds)
}

function onRemoveFamilyGroup(dm: DataGroupMember) {
  removeFamilyGroupModalRef.value?.open(dm)
}

</script>