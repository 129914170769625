import { Gender } from "./ResearchDataModel"
import { useViewPersonStore } from "./ViewPersonStore"

/**
 * A path describing the relationship from a start person to a common ancestor, and a to person to the same common ancestor.
 * If the to person is a direct ancestor of the start person, the to person is the common ancestor, and the to person path 
 * will be empty. If the to person is a direct descendant of the start person, the start person is the common ancestor and 
 * the start person path will be empty. If the start and to persons are the same, both paths will be empty.
 */
export interface RelationPath {
    startPersonPath: AncestorPathItem[]
    commonAncestorId: string
    toPersonPath: AncestorPathItem[]
}

/**
 * A single item in an ancestor path.
 */
export interface AncestorPathItem {
    /** The id of the person at this position in the path */
    ancestorId: string
    /** The parent family of this person in the path, in which the next ancestor in the path (or the common ancestor) is a spouse */
    parentFamilyId: string
}

export function getShortOrdinalText(n: number) {
    const tag = new Intl.PluralRules('en', { type: 'ordinal' }).select(n)
    switch (tag) {
        case 'one': return `first`
        case 'two': return `${n}nd`
        case 'few': return `${n}rd`
        default: return `${n}th`
    }
}

export function useRelationTextBuilder() {
    const viewPersonStore = useViewPersonStore()

    function getRelationText(path: RelationPath) {
        // if up > down, then [ancestor's] [cousin]
        // if up = down, then [cousin]
        // if up < down, then [cousin's] [descendant]
        // Examples:
        // 0-0 = self ('s self)
        // 2-0 = grandparent ('s self)
        // 2-1 = parent's sibling (or aunt/uncle)
        // 3-1 = grandparent's sibling (or great aunt/uncle)
        // 4-1 = 2nd great-grandparent's sibling
        // 4-2 = great-grandparent's first cousin
        // 3-3 = (self's) 2nd cousin
        // 2-3 = first cousin's child
        // 1-2 = (self's) sibling's child (or niece/nephew)
        // 0-2 = (self's) grandchild

        if (path.startPersonPath.length > path.toPersonPath.length) {
            const ancestorPathLength = path.startPersonPath.length - path.toPersonPath.length
            const ancestorId = path.startPersonPath.at(ancestorPathLength)?.ancestorId ?? path.commonAncestorId
            const ancestorRelationText = getAncestorRelationText(ancestorPathLength, ancestorId)

            if (path.toPersonPath.length > 0) {
                const cousinId = path.toPersonPath[0].ancestorId
                const cousinRelationText = getCousinRelationText(path.toPersonPath.length, cousinId)
                return `${ancestorRelationText}'s ${cousinRelationText}` // e.g. 3-1 = grandparent's sibling
            }
            return ancestorRelationText // e.g. 2-0 = grandparent
        }
        else if (path.startPersonPath.length == path.toPersonPath.length) {
            const cousinId = path.toPersonPath.at(0)?.ancestorId ?? path.commonAncestorId
            return getCousinRelationText(path.startPersonPath.length, cousinId) // e.g. 2-2 = first cousin
        }
        else {
            const descendantPathLength = path.toPersonPath.length - path.startPersonPath.length
            const descendantId = path.toPersonPath[0].ancestorId
            const descendantRelationText = getDescendantRelationText(descendantPathLength, descendantId)

            if (path.startPersonPath.length > 0) {
                const cousinId = path.toPersonPath.at(path.startPersonPath.length)?.ancestorId ?? path.commonAncestorId
                const cousinRelationText = getCousinRelationText(path.startPersonPath.length, cousinId)
                return `${cousinRelationText}'s ${descendantRelationText}` // e.g. 1-2 = sibling's child
            }
            return descendantRelationText // e.g. 0-2 = grandchild
        }
    }

    function getAncestorRelationText(pathLength: number, ancestorId: string) {
        if (pathLength < 1)
            throw 'Relation path length should be at least 1'

        const ancestor = viewPersonStore.getAsyncPerson(ancestorId)?.data
        const ancestorGender = ancestor?.displayProperties.gender

        if (pathLength == 1)
            return ancestorGender ? (ancestorGender == Gender.Male ? 'father' : 'mother') : 'parent'
    
        const greats = pathLength - 2
        const grandparent = ancestorGender ? (ancestorGender == Gender.Male ? 'grandfather' : 'grandmother') : 'grandparent'
        switch (greats) {
            case 0: return grandparent
            case 1: return `great-${grandparent}`
            default: return `${getShortOrdinalText(greats)} great-${grandparent}`
        }
    }

    function getCousinRelationText(pathLength: number, cousinId: string) {
        if (pathLength < 0)
            throw 'Cousin path length should be at least 0'

        const cousin = viewPersonStore.getAsyncPerson(cousinId)?.data
        const cousinGender = cousin?.displayProperties.gender

        switch (pathLength) {
            case 0: return 'you'
            case 1: return cousinGender ? (cousinGender == Gender.Male ? 'brother' : 'sister') : 'sibling'
            case 2: return 'first cousin'
            default: return `${getShortOrdinalText(pathLength)} cousin`
        }
    }

    function getDescendantRelationText(pathLength: number, descendantId: string) {
        if (pathLength < 1)
            throw 'Descendant path length should be at least 1'

        const descendant = viewPersonStore.getAsyncPerson(descendantId)?.data
        const descendantGender = descendant?.displayProperties.gender

        if (pathLength == 1)
            return descendantGender ? (descendantGender == Gender.Male ? 'son' : 'daughter') : 'child'

        const greats = pathLength - 2
        const grandchild = descendantGender ? (descendantGender == Gender.Male ? 'grandson' : 'granddaughter') : 'grandchild'
        switch (greats) {
            case 0: return grandchild
            case 1: return `great-${grandchild}`
            default: return `${getShortOrdinalText(greats)} great-${grandchild}`
        }
    }

    return {
        getRelationText
    }
}