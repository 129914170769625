import { isReactive, isReadonly } from "vue"

export class ReactiveUtil {

    static checkIsMutableProxy(obj: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
        if (!isReactive(obj)) {
            throw "ReactiveUtil.ObjectNotReactive"
        }
        if (isReadonly(obj)) {
            throw "ReactiveUtil.ReadonlyObject"
        }
    }
}
