import { FuzzyDate } from "@/rd/FuzzyDate"
import { RelationPath } from "@/rd/RelationPath"
import { DateTime } from "luxon"

export interface TodayItem {
    id: string
    date: FuzzyDate
    personId: string
    displayText: string
    relationPath?: RelationPath
    image?: any
    pageUrl: string
}

export function isSameMonthDay(date: FuzzyDate, compareDate: DateTime) {
    return date.month == compareDate.month && date.day == compareDate.day
}