<template>
  <div class="family-list-item">
    <PersonListItem :person-id="viewFamilyRef?.fatherId"></PersonListItem>
    <PersonListItem :person-id="viewFamilyRef?.motherId"></PersonListItem>
  </div>
</template>

<style lang="scss" scoped>
.family-list-item {
  margin-bottom: 0.5rem;
}
</style>

<script lang="ts" setup>
import { computed } from 'vue'
import { useViewFamilyStore } from '@/rd/ViewFamilyStore'
import PersonListItem from './PersonListItem.vue'

const props = defineProps<{
  familyId: string
}>()

const viewFamilyStore = useViewFamilyStore()

const viewFamilyRef = computed(() => viewFamilyStore.getAsyncFamily(props.familyId)?.data) // should be loaded
</script>
