<template>
  <div class="contact">
    <div>{{ displayNameRef }}</div>
    <div class="email">{{ props.item.value }}</div>
  </div>
</template>

<style lang="scss" scoped>
.contact {
  padding: 0.15rem 0;
  font-size: 0.85rem;

  .email {
    color: #888;
  }
}
</style>

<script lang="ts" setup>
import { computed } from 'vue'
import { Contact, getContactDisplayName } from '@/manage/contacts/ContactProviders'

const props = defineProps<{
  item: { value: string, contact: Contact },
}>()

const displayNameRef = computed(() => getContactDisplayName(props.item.contact) ?? props.item.value)

</script>
