import { DateTime, Info, Duration } from 'luxon'
import { HistoricalDateFormat } from './HistoricalDateEnums'

export class FuzzyDate {
    private static YearOffset = 6000; // -5999...3999 => 1...9999 (1900 => 7900)
    private static EmptyYear = 0 - this.YearOffset // -6000 => 0 after adding offset
    
    static MinYear = FuzzyDate.EmptyYear + 1 // -5999
    static MaxYear = FuzzyDate.EmptyYear + 9999 // 3999
    static Empty = new FuzzyDate()

    readonly isApproximate: boolean
    readonly sortValue: number
    readonly emptyLastValue: number

    get year() { 
        const yearWithOffset = FuzzyDate.zeroToUndefined(Math.floor(this.sortValue/10000))
        return yearWithOffset ? yearWithOffset - FuzzyDate.YearOffset : undefined
    }
    get month() { return FuzzyDate.zeroToUndefined(Math.floor((this.sortValue % 10000)/100)) }
    get day() { return FuzzyDate.zeroToUndefined(this.sortValue % 100) }

    get isEmpty() { return !this.year }
    get precision() {
        if (!this.year)
            return undefined;
        if (!this.month)
            return FuzzyDatePrecision.Year;
        if (!this.day)
            return FuzzyDatePrecision.Month;
        return FuzzyDatePrecision.Day;
    }

    constructor(year?: number, month?: number, day?: number, approx = false) {
    
        function rangeCheck(value: number | undefined, min: number, max: number, name: string) {
            if (value && (value < min || value > max))
                throw `FuzzyDate ${name} value ${value} is not in the range ${min}-${max}`
        }

        rangeCheck(year, FuzzyDate.MinYear, FuzzyDate.MaxYear, 'year');
        rangeCheck(month, 1, 12, 'month');
        rangeCheck(day, 1, 31, 'day');

        this.sortValue = 
            (((year ?? FuzzyDate.EmptyYear) + FuzzyDate.YearOffset) * 10000) + 
            ((month ?? 0) * 100) + 
            (day ?? 0)
        this.isApproximate = approx

        // replace 0000/00/00 with 9999/99/99
        this.emptyLastValue = this.sortValue +
            (year ? 0 : 99990000) +
            (month ? 0 : 9900) +
            (day ? 0 : 99)
    }

    private static zeroToUndefined(x: number) {
        return x == 0 ? undefined : x
    }

    getSortValue(sortEmptyLast: boolean) {
        return sortEmptyLast ? this.emptyLastValue : this.sortValue
    }

    static today() {
        const dt = DateTime.local()
        return new FuzzyDate(dt.year, dt.month, dt.day)
    }

    static parse(value: string | undefined) {
        if (!value)
            return FuzzyDate.Empty

        // protocol format
        const m = value.match(/^(A)?([+|-]\d{1,4})(?:-(\d\d?)(?:-(\d\d?))?)?$/)
        if (!m)
            throw `FuzzyDate format '${value}' is not valid`

        const approx = !!m[1]
        const yr = +(m[2])
        const mo = m[3] ? +(m[3]) : 0
        const da = m[4] ? +(m[4]) : 0
        return new FuzzyDate(yr, mo, da, approx)
    }

    toString(format = HistoricalDateFormat.Protocol) {
        if (!this.year)
            return ''
        
        switch (format) {
            case HistoricalDateFormat.Protocol:
                // Ex: A2001-02-03
                return (this.isApproximate ? 'A' : '')
                    + (this.year >= 0 ? '+' : '-')
                    + Math.abs(this.year).toString()
                    + (this.month ? '-' + this.month.toString().padStart(2, '0') : '')
                    + (this.day ? '-' + this.day.toString().padStart(2, '0') : '')

            case HistoricalDateFormat.ShortDisplay:
                // Ex: ~3 Feb 2001
                return (this.isApproximate ? '~' : '')
                    + (this.day ? this.day.toString() + ' ' : '')
                    + (this.month ? Info.months("short")[this.month - 1] + ' ' : '') // NOTE: month indexes are 0-11
                    + this.year.toString()

            case HistoricalDateFormat.LongDisplay:
                // Ex: abt. 3 Feb 2001
                return (this.isApproximate ? 'abt ' : '')
                    + (this.day ? this.day.toString() + ' ' : '')
                    + (this.month ? Info.months("short")[this.month - 1] + ' ' : '') // NOTE: moment month values are 0-11
                    + this.year.toString()
        }
    }

    diff(otherDate: FuzzyDate) {
        if (!this.year || !otherDate.year)
            throw 'FuzzyDate cannot diff empty values';

        let dy = this.year - otherDate.year;
        let dm = 0;
        let dd = 0;

        let dir = Math.sign(dy);
        dy = Math.abs(dy);

        if (this.month && otherDate.month) {
            dm = this.month - otherDate.month;
            if (dir < 0) {
                dm = -dm; // direction already negative, so flip month diff
            }
            if (dir === 0) {
                dir = Math.sign(dm); // no direction yet, so give month diff a chance
                dm = Math.abs(dm);
            }
            if (dm < 0) {
                dy--;
                dm += 12;
            }
            if (this.day && otherDate.day) {
                dd = this.day - otherDate.day;
                if (dir < 0) {
                    dd = -dd; // direction already negative, so flip day diff
                }
                if (dir === 0) {
                    dir = Math.sign(dd); // no direction yet, so give day diff a chance
                    dd = Math.abs(dd);
                }
                if (dd < 0) {
                    dm--;
                    const lowDate = (dir > 0 ? otherDate : this);
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- checked year and month above
                    dd += DateTime.utc(lowDate.year!, lowDate.month!).daysInMonth!;
                }
            }
        }
        return Duration.fromObject({
            years: dy * dir,
            months: dm * dir,
            days: dd * dir
        });
    }
}

export enum FuzzyDatePrecision {
    Year = "Year",
    Month = "Month",
    Day = "Day"
}
