<template>
  <div class="person-name-highlight">
    <router-link v-if="relatedRef" :to="relatedRef.pageUrl">
      <div class="visual">
        <div class="name-badge">
          {{ props.highlight.value }}
        </div>
      </div>
      <div class="highlight-text">
        {{ props.highlight.text }}
      </div>
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
.person-name-highlight a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.visual {
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.name-badge {
  min-width: 60px;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.15);
  padding: 0 0.25rem;
  color: #333;
}

.highlight-text {
  text-align: center;
  font-size: 0.75rem;
  color: #888;
}
</style>

<script lang="ts" setup>
import { computed } from 'vue'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { HighlightItem } from './HighlightItem'

const props = defineProps<{
  highlight: HighlightItem,
}>()

const viewPersonStore = useViewPersonStore()

const relatedRef = computed(() => viewPersonStore.getAsyncPerson(props.highlight.personId)?.data)
</script>