import { reactive } from 'vue'
import { Assertion, AssertionType, ItemType, NewAssertion } from "./ResearchDataModel"
import { usePersonStore } from './PersonStore'
import { useFamilyStore } from './FamilyStore'
import { rd } from './ResearchDataApi'
import { AsyncData, LoadMode } from "@webapp/util/AsyncData"
import { AsyncDataStore } from "@webapp/util/AsyncDataStore"
import { defineStore } from "pinia"
import { DateTime } from "luxon"
import { DateTimeUtil } from "@webapp/util/LuxonUtil"
import _ from "lodash"
import { assignExisting, isDefined } from "@webapp/util/TypeScriptUtil"
import { HistoricalDate } from './HistoricalDate'
import { SortDate } from './SortDate'
import { PatchChange } from '@webapp/util/Api'
import { CompositeId } from './CompositeId'
import { usePlaceStore } from './PlaceStore'
import { useSiteStore } from './SiteStore'
import { useViewPersonStore } from './ViewPersonStore'
import { useViewFamilyStore } from './ViewFamilyStore'


export const useAssertionStore = defineStore("assertionStore", () => {
    const asyncAssertions = reactive(new Map<string, AsyncData<Assertion>>())

    const personStore = usePersonStore()
    const familyStore = useFamilyStore()
    const placeStore = usePlaceStore()
    const siteStore = useSiteStore()
    const viewPersonStore = useViewPersonStore()
    const viewFamilyStore = useViewFamilyStore()

    function getAsyncAssertion(assertionId: string | undefined, loadMode = LoadMode.TrackOnly) {
        return assertionId ? getAsyncAssertionList([assertionId], loadMode)[0] : undefined
    }

    function getAsyncAssertionList(assertionIds: string[], loadMode = LoadMode.TrackOnly) {
        if (loadMode != LoadMode.TrackOnly) {
            throw `Load mode not implemented`
        }
        AsyncDataStore.getAsyncItemsToLoad(asyncAssertions, assertionIds, "Assertion", loadMode)
        return assertionIds.map(id => asyncAssertions.get(id)!)
    }

    function areLoadedForPerson(personId: string) {
        const keyLists = AsyncDataStore.getAsyncItemsIfAllLoaded(personStore.asyncAssertionKeys, [personId])
        if (keyLists) {
            const assertionIds = keyLists[0].data?.keys ?? []
            return AsyncDataStore.areItemsLoaded(asyncAssertions, assertionIds)
        }
        return false
    }

    function getLoadedForPerson(personId: string) {
        const keyLists = AsyncDataStore.getAsyncItemsIfAllLoaded(personStore.asyncAssertionKeys, [personId])
        const assertionIds = keyLists?.[0].data?.keys ?? []
        return getAsyncAssertionList(assertionIds).map(a => a.data).filter(isDefined)
    }

    function getLoadedForFamily(familyId: string) {
        const assertionIds = familyStore.asyncAssertionKeys.get(familyId)?.data?.keys ?? []
        return getAsyncAssertionList(assertionIds).map(a => a.data).filter(isDefined)
    }

    function areExpandedAssertionsLoaded(assertionIds: string[]) {
        // check assertions loaded
        const assertions = AsyncDataStore.getAsyncItemsIfAllLoaded(asyncAssertions, assertionIds)
        if (!assertions)
            return false

        // check places loaded
        const placeIds = [...new Set(assertions.map(a => a.data?.placeId).filter(isDefined))]
        if (!AsyncDataStore.areItemsLoaded(placeStore.asyncPlaces, placeIds))
            return false

        // check sites loaded
        const siteIds = [...new Set(assertions.map(a => a.data?.siteId).filter(isDefined))]
        if (!AsyncDataStore.areItemsLoaded(siteStore.asyncSites, siteIds))
            return false

        return true
    }

    async function addAsync(groupId: string, assertion: NewAssertion) {
        if (CompositeId.getGroupId(assertion.subjectId) != groupId)
            throw `Assertion subject ${assertion.subjectId} does not belong to group ${groupId}`
        if (assertion.subjectType != ItemType.Person)
            throw `Assertion subject added to unexpected type: ${assertion.subjectType}`

        const addCoreAsync = async () => {
            const protocolItem = {
                ...assertion,
                date: assertion.date?.toString(),
                sortDate: assertion.sortDate?.toString(),
            }
            const p = await rd.postPlainAsync(`groups/${groupId}/assertions`, protocolItem)
            return plainToAssertion(p)
        }            
        const newKey = await AsyncDataStore.addToStoreMapAsync(asyncAssertions, "Assertion", a => a.id, addCoreAsync)

        // reload person assertion key list
        const personStore = usePersonStore()
        await personStore.loadDetailsAsync(assertion.subjectId!, LoadMode.Reload)

        return newKey
    }

    async function patchAsync(id: string, changes: PatchChange[]) {
        const patchCoreAsync = async (id: string, changes: PatchChange[]) => {
            const p = await rd.patchPlainAsync(`assertions/${id}`, changes);
            return plainToAssertion(p);
        }
        await AsyncDataStore.patchFromStoreAsync(asyncAssertions, id, changes, patchCoreAsync)
        // TODO: clear affected cached items
    }

    async function deleteAsync(id: string) {
        const asyncAssertion = asyncAssertions.get(id)
        if (!asyncAssertion)
            throw `Assertion ${id} not found in store`

        const subjectVP = viewPersonStore.asyncViewPersons.get(asyncAssertion.data?.subjectId ?? '')?.data
        const relatedVF = viewFamilyStore.asyncViewFamilies.get(asyncAssertion.data?.relatedItemId ?? '')?.data

        await rd.deleteAsync(`assertions/${id}`)

        AsyncData.setDeleted(asyncAssertion)
        if (asyncAssertion.data) {
            AsyncData.invalidate(personStore.asyncAssertionKeys.get(asyncAssertion.data.subjectId ?? ''))
        }
        if (subjectVP?.id) {
            AsyncData.invalidate(viewPersonStore.asyncParentFamilyKeys.get(subjectVP.id))
        }
        if (relatedVF?.id) {
            AsyncData.invalidate(viewFamilyStore.asyncChildKeys.get(relatedVF.id))
        }
    }

    function addAssertionsToStore(plainAssertions: any[], requestTime: DateTime) {
        const assertionMap = processExpandedAssertions(plainAssertions, requestTime)
        AsyncDataStore.addItemMapToStore(asyncAssertions, "Assertion", assertionMap, requestTime)
        return assertionMap
    }

    function processExpandedAssertions(plainAssertions: any[], requestTime: DateTime) {
        placeStore.addPlacesToStore(plainAssertions.map(p => p.place).filter(isDefined), requestTime)
        siteStore.addSitesToStore(plainAssertions.map(p => p.site).filter(isDefined), requestTime)
        return AsyncDataStore.createItemMap(plainAssertions, "Assertion", plainToAssertion, a => a.id!)
    }

    return {
        asyncAssertions,

        getAsyncAssertion,
        getAsyncAssertionList,
        areLoadedForPerson,
        getLoadedForPerson,
        getLoadedForFamily,
        areExpandedAssertionsLoaded,
        addAsync,
        patchAsync,
        deleteAsync,
        addAssertionsToStore,
    }
})

function plainToAssertion(p: any) { // eslint-disable-line @typescript-eslint/no-explicit-any
    const assertion = assignExisting(new Assertion(), p)
    if (!assertion.subjectType) {
        assertion.subjectType = ItemType.Person
    }
    if (assertion.relatedItemId && !assertion.relatedItemType) {
        assertion.relatedItemType = ItemType.Person
    }
    assertion.date = HistoricalDate.parse(p.date)
    assertion.sortDate = SortDate.parse(p.sortDate)
    assertion.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate)
    return assertion
}
