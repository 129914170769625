<template>
  <div class="spouse-connector" :class="{ dimmed: props.dimmed, ['connector-' + layoutRef]: true }">
    <div class="family-dot"></div>
  </div>
</template>

<style lang="scss" scoped>
$transition-duration: 0.3s;

.spouse-connector {
  $dot-size: 14px;

  position: relative;
  margin-left: 1rem;
  width: calc($dot-size + 2px);
  border-bottom: 2px solid #ddd;
  transition: margin $transition-duration;
  transition-property: margin, width;

  .family-dot {

    position: absolute;
    top: calc(($dot-size / -2) + 1px);
    left: calc(($dot-size / -2) - 1px);
    width: $dot-size;
    height: $dot-size;
    border-radius: 50%;
    background-color: #bbb;
    transition: top $transition-duration;
    transition-property: top, left, width, height;
  }

  &.dimmed {
    opacity: 40% !important; // override element style set by animation
  }

  &.connector-tiny {
    $dot-size: 10px;

    margin-left: 0.5rem;
    width: calc($dot-size + 2px);

    .family-dot {
      top: calc(($dot-size / -2) + 1px);
      left: calc(($dot-size / -2) - 1px);
      width: $dot-size;
      height: $dot-size;
    }
  }
}
</style>

<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  dimmed?: boolean,
  layout?: 'full' | 'small' | 'tiny' | 'dot',
}>()

const layoutRef = computed(() => props.layout || 'full')

</script>