
const typeIcons: any = {
    "Name": require("@/assets/icons/nametag_gray_60.png"),
    "Birth": require("@/assets/icons/sunrise_gray_60.png"),
    "Residence": require("@/assets/icons/house_gray_60.png"),
    "Education": require("@/assets/icons/scroll_gray_60.png"),
    "Occupation": require("@/assets/icons/hammer_gray_60.png"),
    "Marriage": require("@/assets/icons/rings_gray_60.png"),
    "Death": require("@/assets/icons/sunset_gray_60.png"),
    "Burial": require("@/assets/icons/grave_gray_60.png"),
}

export class AssertionTypeIcons {
    static hasIcon(type?: string) {
        return !!type && !!typeIcons[type]
    }
    
    static getIcon(type?: string): string {
        return type ? typeIcons[type] : ''
    }
}
