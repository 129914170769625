<template>
  <div ref="collapseElem" class="collapse">
    <div class="alert alert-sm page-alert" :class="alertTypeClass" role="alert">
      <check-icon></check-icon>
      <slot></slot>
      <button type="button" class="btn-close float-end" @click="hide" aria-label="Close"></button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { CheckIcon } from '@zhuowenli/vue-feather-icons'
import { Collapse } from 'bootstrap'

const props = defineProps({
  alertType: String,
})
defineExpose({
  show,
  hide,
})

const collapseElem = ref<HTMLElement | null>(null)
const alertTypeClass = computed(() => "alert-" + props.alertType)

function show() {
  new Collapse(collapseElem.value!).show()
  setTimeout(() => hide(), 5000);
}

function hide() {
  new Collapse(collapseElem.value!).hide()
}
</script>
