<template>
  <Modal ref="modalElem" title="Confirm remove" ok-text="Remove" ok-style="danger" busy-text="Removing..." @ok="onOk">
    <p>{{ confirmMessageRef }}</p>
    <UserItem v-if="pmRef?.member" :user-id="pmRef.member.userId" />
    <PersonCard v-else :person-id="profileIdRef" profile layout="small" />
    <div v-if="pmRef?.lastUserInvite?.result == InvitationResult.Pending" class="alert alert-warning">
      This profile has a pending invitation. Removing the profile will cancel the invitation.
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
p, .user-item, .person-card {
  margin-bottom: 1rem;
}

.user-item, .person-card {
  margin-left: 1rem;
}

.alert {
  margin: 0;
  font-size: 0.875rem;
}
</style>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { PotentialMember } from './FamilyGroupMemberManager'
import { InvitationResult } from '@/gp/GroupAdminModel'
import Modal from '@/util/Modal.vue'
import UserItem from '@/manage/UserItem.vue'
import PersonCard from '@/manage/PersonCard.vue'

defineExpose({
  open,
  close,
})

const emit = defineEmits([
  'ok',
])

const pmRef = ref<PotentialMember>()
const profileIdRef = ref<string>()

const confirmMessageRef = computed(() => pmRef.value?.member 
  ? "Remove this user from the group?" 
  : "Remove this profile from the group?")

const modalElem = ref<InstanceType<typeof Modal>>()

function open(pm: PotentialMember, profileId: string) {
  pmRef.value = pm
  profileIdRef.value = profileId

  modalElem.value?.open()
}

function close() {
  modalElem.value?.close()
}

function onOk() {
  emit('ok', pmRef.value, profileIdRef.value)
}
</script>
