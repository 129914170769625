<template>
  <div class="highlights-list">
    <ul class="list-unstyled">
      <li v-for="h in highlightsRef" :key="h.id">
        <div class="visual">
          <img v-if="h.image" :src="h.image" />
          <div v-if="typeof h.value == 'number'" class="big-text" :style="{ fontSize: getNumberFontSize(h.value) }">{{ h.value }}</div>
        </div>
        <div class="text-area">
          <router-link v-if="h.personId" :to="ViewPerson.getPageUrl(h.personId)" class="link-dark text">{{ h.text }}</router-link>
          <div v-else class="text">{{ h.text }}</div>
          <div v-if="h.relationPath" class="relation-text">your {{ getRelationText(h.relationPath) }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
li {
  margin-bottom: 0.75rem;
  display: grid;
  grid-template-columns: 3rem auto;
  color: #333;
  align-items: center;

  .visual {
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #444;

    img {
      height: 1.5rem;
      opacity: 40%;
    }

    .flag {
      width: 2rem;
      height: 1.5rem; // 4:3 ratio
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.375) !important;
    }

    .big-text {
      line-height: 1;
    }
  }

  .text-area {
    font-size: 0.85rem;
    line-height: 1.2;
  }

  .relation-text {
    color: #bbb;
  }
}
</style>

<script lang="ts" setup>
import { Ref, ref, watch } from 'vue';
import { useFamilyHighlights } from './FamilyHighlights'
import { useExploreStore } from '@/explore/ExploreStore'
import { ViewPerson } from '@/rd/ResearchDataModel'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { HighlightItem } from './HighlightItem'
import { useRelationTextBuilder } from '@/rd/RelationPath'
import _ from 'lodash';

const exploreStore = useExploreStore()
const familyHighlights = useFamilyHighlights()
const viewPersonStore = useViewPersonStore()
const { getRelationText } = useRelationTextBuilder()

const highlightsRef = ref([]) as Ref<HighlightItem[]>

watch([
  () => exploreStore.startPersonId,
  () => exploreStore.initialTreeLoaded,
], () => {
  if (exploreStore.startPersonId && exploreStore.initialTreeLoaded) {

    const allHighlights = familyHighlights.getTreeFamilyHighlights()
    highlightsRef.value = _.shuffle(allHighlights).slice(0, 4)

    for (const item of highlightsRef.value) {
      if (item.personId) {
        item.relationPath = viewPersonStore.getLoadedRelationPath(exploreStore.startPersonId, item.personId)
      }
    }
  }
}, { immediate: true })

function getNumberFontSize(value: number) {
  if (value < 100) {
    return '2rem'
  } else if (value < 1000) {
    return '1.3rem'
  } else {
    return '1.1rem'
  }
}

</script>