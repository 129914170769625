import { useViewPersonStore } from "@/rd/ViewPersonStore"
import { HighlightItem } from "./HighlightItem"
import { PersonName } from "@/rd/ResearchDataModel"
import { useRelationTextBuilder } from "@/rd/RelationPath"
import { getPossessivePhrase } from "@/util/LanguageUtil"
import _ from "lodash"

export function usePersonNameHighlights() {
    const viewPersonStore = useViewPersonStore()
    const { getRelationText } = useRelationTextBuilder()

    function getNameHighlights(personId: string): HighlightItem[] {
        const person = viewPersonStore.getAsyncPerson(personId)?.data // should be loaded
        if (!person) return []

        const found = [] as HighlightItem[]

        const selfName = new PersonName(person.displayProperties.name)
        const selfGivenNames = selfName.givenNames

        if (selfGivenNames.length) {
            // NOTE: should be at least 2 generations loaded, often more, but don't spend time looking more than 3 up/1 down
            for (const vp of viewPersonStore.getLoadedAncestors(personId, 3, 1)) {
                const otherName = new PersonName(vp.displayProperties.name)
                const otherGivenNames = otherName.givenNames
                if (selfGivenNames.length > 1  && selfName.given == otherName.given) {
                    const relationPath = viewPersonStore.getLoadedRelationPath(personId, vp.id!)!
                    const relationText = getPossessivePhrase(person.displayProperties.gender, getRelationText(relationPath))
                    found.push({
                        id: `sameName:given:all:${vp.id}`,
                        text: `same given name as ${relationText}`,
                        interest: 4,
                        value: selfName.given,
                        personId: vp.id,
                        relationPath: relationPath,
                    })
                }
                else {
                    for (let s = 0; s < selfGivenNames.length; s++) {
                        for (let o = 0; o < otherGivenNames.length; o++) {
                            if (selfGivenNames[s] == otherGivenNames[o]) {
                                const relationPath = viewPersonStore.getLoadedRelationPath(personId, vp.id!)!
                                const relationText = getPossessivePhrase(person.displayProperties.gender, getRelationText(relationPath))
                                const text = (s == 0 && o == 0) ? `same first name as ${relationText}`
                                    : (s == 0 && o > 0) ? `first name is middle name of ${relationText}`
                                    : (s > 0 && o == 0) ? `middle name is first name of ${relationText}`
                                    : `same middle name as ${relationText}`
                                found.push({
                                    id: `sameName:given:${s}:${o}:${vp.id}`,
                                    text,
                                    interest: s == 0 && o == 0 ? 3 : o == 0 ? 2 : 1, // first/first = 3, middle/first = 2, ...
                                    value: selfGivenNames[s],
                                    personId: vp.id,
                                    relationPath: relationPath,
                                })
                            }
                        }
                    }
                }
            }
        }

        return _.sortBy(found, h => h.interest).reverse()
    }

    return {
        getNameHighlights
    }
}
