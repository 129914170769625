<template>
  <div class="signin-page container" :class="{ embedded: isEmbeddedRef }">
    <h1 v-if="!isEmbeddedRef">Sign in</h1>
    <form>
      <div v-if="isEmbeddedRef" class="instruction">Please sign in</div>
      <!-- <div class="alert alert-primary alert-sm with-icon">
        <InfoIcon size="2x"></InfoIcon>
        <div>We're in private preview. If you're not approved yet, this won't work.</div>
      </div> -->
      <div class="row">
        <div class="col-sm-8">
          <div>
            <label for="addressInput" class="form-label">Email address</label>
            <input type="text" class="form-control" id="addressInput" ref="addressInputRef" :readonly="!canEditRef" v-model="addressRef"/>
          </div>
          <div>
            <button type="submit" class="btn btn-primary mt-3" :disabled="!canSendCodeRef" @click="requestCode">
              <Spinner v-if="busyRef"></Spinner>
              Send a sign in code
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="other-actions">
      <router-link to="/signup">I don't have an account</router-link>
      <!-- <router-link v-if="isDevEnv" to="/testsignin" class="dev-only">Test sign in</router-link> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.signin-page.embedded {
  max-width: 500px;
}

.signin-page:not(.embedded) {
  max-width: 600px;
  min-height: 500px;
  margin-top: 4rem;
  border: 1px solid #ddd;
  border-radius: 1rem;
  padding: 1rem 2rem 2rem 2rem;
}

form {
  margin-bottom: 2rem;

  &> .row > [class^="col-"] {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.instruction {
  line-height: 2;
  font-size: 14pt;
  margin-bottom: 0.5rem;
}

#addressInput {
  max-width: 350px;
}

.other-actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  font-size: 0.875rem;
}
</style>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useAppHostStore } from '@webapp/AppHostStore'
import { getSafeRedirectUri, usePageTitle } from '@webapp/util/AppUtil' 
import { useSignInStore } from '@webapp/auth/SignInStore'
import { IdentityApi } from '@webapp/auth/IdentityApi'
import { AddressUtil } from '@webapp/util/AddressUtil'
import { Environment } from '@webapp/util/Environment'
import Spinner from '@webapp/util/Spinner.vue'

usePageTitle("Sign in")

const appHostStore = useAppHostStore()
const isEmbeddedRef = computed(() => appHostStore.isEmbedded)
const signInStore = useSignInStore()
const router = useRouter()

const addressRef = ref(signInStore.address)
const canEditRef = computed(() => !busyRef.value)
const canSendCodeRef = computed(() => !busyRef.value && AddressUtil.isValidEmail(addressRef.value))
const busyRef = ref(false)
const isDevEnv = Environment.isDevelopment()

const addressInputRef = ref<HTMLInputElement | null>(null)

async function requestCode(e: Event) {
  e.preventDefault()
  busyRef.value = true
  
  await IdentityApi.sendSignInCodeAsync(addressRef.value)

  busyRef.value = false

  // use store to pass address securely (not in URL)
  signInStore.address = addressRef.value
  signInStore.isNewUser = false
  
  const redirectUri = getSafeRedirectUri()
  router.push({ path: "/signincode", query: { redirect_uri: redirectUri } })
}

onMounted(() => {
  addressInputRef.value!.focus()
  addressInputRef.value!.select()
})

</script>
