<template>
  <Modal ref="modalRef" @ok="removeSpouse">
    <template #title>Remove spouse</template>
    <p>Are you sure you want to remove this spouse?</p>
    <div class="spouse-card">
      <SpouseConnector></SpouseConnector>
      <PersonCard 
        :person-id="spouseIdRef"
        layout="small"
        show-profile-type
        show-profile-count="multiple"
        select-mode>
      </PersonCard>
    </div>
    <p>Only the relationship will be deleted.</p>
    <template #okText>Remove</template>
  </Modal>
</template>

<style lang="scss" scoped>
.spouse-card {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  .person-card {
    width: 400px;
  }
}
</style>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { FamilyAndChildren } from '@/rd/ViewFamilyStore'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { useFamilyStore } from '@/rd/FamilyStore'
import { CompositeId } from '@/rd/CompositeId'
import { AsyncUtil } from '@/util/AsyncUtil'
import Modal from '@/util/Modal.vue'
import SpouseConnector from './SpouseConnector.vue'
import PersonCard from './PersonCard.vue'

const viewPersonStore = useViewPersonStore()
const familyStore = useFamilyStore()

const personIdRef = ref<string>()
const spouseFamilyRef = ref<FamilyAndChildren>()
const modalRef = ref<InstanceType<typeof Modal>>()

const viewPersonRef = computed(() => viewPersonStore.getLoadedPerson(personIdRef.value))
const spouseIdRef = computed(() => spouseFamilyRef.value?.family.spouseOf(viewPersonRef.value?.id ?? ''))

defineExpose({
  open,
})

const emit = defineEmits([
  'ok',
])

function open(personId: string, spouseFamily: FamilyAndChildren) {
  personIdRef.value = personId
  spouseFamilyRef.value = spouseFamily
  modalRef.value?.open()
}

async function removeSpouse() {
  const profileGroupId = CompositeId.getGroupId(personIdRef.value)!
  const familyId = spouseFamilyRef.value?.family.matchIds.find(id => CompositeId.hasGroupId(id, profileGroupId))
  if (!familyId)
    throw `Family match not found in profile ${profileGroupId}`

  // TODO: delete fa/mo placeholder person sets
  // TODO: delete fa/mo placeholder family (server should block if referenced by any family details)
  
  await AsyncUtil.atLeast(500, familyStore.deleteAsync(familyId))

  modalRef.value?.close()
}
</script>
