<template>
  <div ref="modalRef" class="modal" data-bs-backdrop="static" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">
            Welcome!
          </h3>
          <button type="button" class="btn-close" :disabled="busy" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div>
          </div>
          <template v-if="loadingRef">
            <LoadingIndicator></LoadingIndicator>
          </template>
          <template v-else>
            <section>
              <h5>Let's get your preferred name right.</h5>
              <div class="help-text">
                This name identifies you when you're interacting with other users on the site.
                Make sure it's the name most family members will recognize.
                You can also change this later in your <router-link to="/account" @click="onNavigate">account settings</router-link>.
              </div>
              <div class="name-editor">
                <div>
                  <label for="givenNameInput" class="form-label">Given name</label>
                  <input type="text" class="form-control" id="givenNameInput" v-model="editUser.givenName"/>
                </div>
                <div>
                  <label for="familyNameInput" class="form-label">Surname</label>
                  <input type="text" class="form-control" id="familyNameInput" v-model="editUser.surname"/>
                </div>
              </div>
            </section>
            <section>
              <div class="help-text">
                Your birth name is the name people will see in the family tree.
                If you want to change this, you can <router-link :to="userProfileGroupRef!.pageUrl" @click="onNavigate">edit your profile</router-link>.
              </div>
              <div class="profile-name">
                <label class="form-label">Full birth name</label>
                <div class="form-input-value">{{ userProfilePersonRef?.displayName }}</div>
              </div>
            </section>
          </template>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" :disabled="!enableDoneRef" @click="save">Done</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h5 {
    font-size: 1rem;
    font-weight: 400;
  }

  .subhead {
    font-size: 0.875rem;
    color: #888;
  }

  .profile-name {
    margin: 1rem;
  }

  .help-text {
    font-size: 0.875rem;
    color: #888;
  }

  .name-editor {
    margin: 1rem;
    display: flex;
    gap: 1rem;
  }
}

.modal-footer {
  button {
    min-width: 80px;
  }
}
</style>

<script lang="ts" setup>
import { computed, reactive, ref } from 'vue'
import { User } from '@/gp/GroupAdminModel'
import { useUserStore } from '@/gp/UserStore'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { LoadMode } from '@/util/AsyncData'
import { useEditMode } from '@/util/EditMode'
import { Modal as BsModal } from 'bootstrap'
import { PatchChange } from '@/util/Api'
import PersonCard from './PersonCard.vue'
import { usePersonStore } from '@/rd/PersonStore'
import Spinner from '@/util/Spinner.vue'
import LoadingIndicator from '@/util/LoadingIndicator.vue'

defineExpose({
  open
})

const userStore = useUserStore()
const dataGroupStore = useDataGroupStore()
const personStore = usePersonStore()

const userRef = computed(() => userStore.getAsyncSelf()?.data) // loaded by app
const userProfileGroupRef = computed(() => dataGroupStore.getAsyncGroup(userRef.value?.profileDataGroupId)?.data)
const userProfilePersonRef = computed(() => personStore.getAsyncPerson(userProfileGroupRef.value?.startItemId, LoadMode.EnsureLoaded)?.data)
const loadingRef = computed(() => !userProfilePersonRef.value || !userRef.value)
const editUser = reactive(new User())
const editProperties = [
  'givenName',
  'surname',
] as (keyof User)[]
const allowSaveRef = computed(() => !!editUser.givenName || !!editUser.surname)
const modalRef = ref<HTMLElement>()

const { isEditing, canEdit, canEditValues, busy, edit, saveText, hasChanges, canSave, saveChanges, canCancel, cancel } = 
  useEditMode<User>(userRef, editUser, editProperties, true, allowSaveRef)

const enableDoneRef = computed(() => !hasChanges || allowSaveRef.value)

function open() {
  // HACK: Not sure why getInstance isn't sufficient here, but sometimes it returns null because apparently the BS Modal
  // hasn't been initialized yet. Calling getOrCreateInstance seems to fix it.
  BsModal.getOrCreateInstance(modalRef.value!).show()
  edit()
}

async function save() {
  await saveChanges(async () => {
    const changes = [
      new PatchChange('/metadata/nameConfirmed', 'true')
    ]
    
    for (const key of editProperties) {
      if (editUser[key] != userRef.value![key]) {
        changes.push(new PatchChange(`/${key}`, editUser[key] as string)) // all edit properties are strings or string enums
      }
    }
      
    await userStore.patchAsync(editUser.id!, changes)
  })
  BsModal.getInstance(modalRef.value!)?.hide()
}

function onNavigate() {
  BsModal.getInstance(modalRef.value!)?.hide()
}
</script>
