import { FuzzyDate } from './FuzzyDate'
import { HistoricalDateType, HistoricalDateFormat } from './HistoricalDateEnums'
import { SortDate } from './SortDate'
import { DateTime } from 'luxon'
import _ from "lodash"

export class HistoricalDate {

    static Empty = new HistoricalDate()

    // NOTE: all properties should be read-only
    readonly type: HistoricalDateType = HistoricalDateType.Date
    readonly date1?: FuzzyDate
    readonly date2?: FuzzyDate
    readonly text?: string
    readonly sortValue?: string

    constructor(text?: string)
    constructor(date1: FuzzyDate, date2?: FuzzyDate, type?: HistoricalDateType);
    constructor(date1OrText?: FuzzyDate | string, date2?: FuzzyDate, type?: HistoricalDateType) {
        if (date1OrText) {
            if (typeof date1OrText == "string") {
                this.type = HistoricalDateType.Freeform
                this.text = date1OrText
            }
            else {
                if (date1OrText.isEmpty)
                    throw 'HistoricalDate.Date1Required'

                type = type ?? HistoricalDateType.Date

                switch (type) {
                    case HistoricalDateType.Date:
                    case HistoricalDateType.After:
                    case HistoricalDateType.Before:
                        if (date2 && !date2?.isEmpty)
                            throw `HistoricalDate.Date2NotAllowed: date2 was specified (${date2}) but is not allowed with date type ${type}`
                        break
                    case HistoricalDateType.Between:
                    case HistoricalDateType.Span:
                        if (!date2 || date2.isEmpty)
                            throw 'HistoricalDate.Date2Required'
                        break
                    case HistoricalDateType.Freeform:
                        throw 'HistoricalDate.FreeformNotAllowed'
                    default:
                        throw 'HistoricalDate.UnknownType'
                }
    
                this.date1 = date1OrText;
                this.date2 = date2;
                this.type = type;
            }
        }

        if (!this.isEmpty && this.type != HistoricalDateType.Freeform) {
            this.sortValue = new SortDate(this.date1, this.date2, this.type).toString()
        }
    }

    get isEmpty() { return !this.date1 && !this.text }

    toString(format = HistoricalDateFormat.Protocol) {
        if (this.isEmpty)
            return '';

        const d1 = (this.date1?.toString(format) ?? '')
        const d2 = (this.date2?.toString(format) ?? '')

        if (format == HistoricalDateFormat.Protocol) {
            switch (this.type) {
                case HistoricalDateType.Date: return d1
                case HistoricalDateType.After: return d1 + '/'
                case HistoricalDateType.Before: return '/' + d1
                case HistoricalDateType.Between: return d1 + '/' + d2
                case HistoricalDateType.Span: return 'S' + d1 + '/' + d2
                case HistoricalDateType.Freeform: return 'T' + this.text!
                default:
                    throw 'HistoricalDate.UnknownType'
            }
        } else { // display format (round-trippable)
            const useLongFmt = (format == HistoricalDateFormat.LongDisplay)
            switch (this.type) {
                case HistoricalDateType.Date: return d1
                case HistoricalDateType.After: return (useLongFmt ? 'after ' : 'aft ') + d1
                case HistoricalDateType.Before: return (useLongFmt ? 'before ' : 'bef ') + d1
                case HistoricalDateType.Between: return d1 + ' - ' + d2
                case HistoricalDateType.Span: return '[' + d1 + ' - ' + d2 + ']'
                case HistoricalDateType.Freeform: return this.text!
                default:
                    throw 'HistoricalDate.UnknownType'
            }
        }
    }

    clone() {
        return new HistoricalDate(this.toString())
    }

    static fromDate(year: number, month?: number, day?: number, approx = false) {
        return new HistoricalDate(new FuzzyDate(year, month, day, approx))
    }

    static parse(value?: string) {
        if (!value) {
            return new HistoricalDate()
        }
        if (value[0] === 'T') {
            return new HistoricalDate(value.slice(1))
        }

        const m = value.match(/^(S)?([^/]+)?(\/)?([^/]+)?$/)
        if (!m) {
            throw `HistoricalDate format '${value}' is not valid`
        }

        const isSpan = !!m[1]
        const date1 = m[2] ? FuzzyDate.parse(m[2]) : undefined
        const hasSlash = !!m[3]
        const date2 = m[4] ? FuzzyDate.parse(m[4]) : undefined

        if (!isSpan && !date1 && hasSlash && date2) {
            // swap date2 -> date1
            return new HistoricalDate(date2, date1, HistoricalDateType.Before)
        }

        const type =
            !isSpan && date1 && !hasSlash && !date2 ? HistoricalDateType.Date :
            !isSpan && date1 && hasSlash && !date2 ? HistoricalDateType.After :
            !isSpan && date1 && hasSlash && date2 ? HistoricalDateType.Between :
            isSpan && date1 && hasSlash && date2 ? HistoricalDateType.Span :
            function() { throw 'HistoricalDate.BadFormat' }()

        return new HistoricalDate(date1, date2, type)
    }

    /*
        Parses user input into a HistoricalDate. Recognizes multiple representations for each concept.
    */
    static parseUserInput(value: string | undefined) {
        if (!value)
            return new HistoricalDate();

        if (typeof value !== 'string')
            throw '\'value\' is not a string';

        value = value.trim();
        if (!value)
            return new HistoricalDate(); // empty date

        let tokens = value.split(/(\s+|\[|\]|~)/)
        tokens = tokens.filter(t => t && !t.match(/^\s+$/)) // drop whitespace and empty tokens
        let sp = '';
        let d1 = '';
        let d2 = '';
        let needSecondDate = false;
        let needCloseBracket = false;

        let t = tokens.shift()

        if (!t) {
            throw "no tokens"
        }
        if (t.match(/^(after|aft|aft\.|>)$/i)) {
            d2 = '/'; // result will be 'd1/'
            t = tokens.shift()
        }
        else if (t.match(/^(before|bef|bef\.|<|-|to)$/i)) {
            d1 = '/'; // result will be '/d1'
            t = tokens.shift();
        }
        else if (t.match(/^(between|bet|bet\.|btw|btw\.|bt|bt\.)$/i)) {
            needSecondDate = true;
            t = tokens.shift();
        }
        else if (t.match(/^(from|fr|fr\.|\[)$/i)) {
            needSecondDate = true;
            needCloseBracket = (t === '[');
            sp = 'S';
            t = tokens.shift();
        }

        let dt = HistoricalDate.parseFuzzyDate(t, tokens);
        if (!dt)
            return new HistoricalDate('T' + value);

        d1 += dt;
        t = tokens.shift();

        if (needSecondDate && !t)
            return new HistoricalDate('T' + value);

        if (t && t.match(/^(-|to)$/i)) {
            if (d2) // 'after' specified, cannot also specify 'to'
                return new HistoricalDate('T' + value);

            d2 = '/';
            t = tokens.shift();

            dt = HistoricalDate.parseFuzzyDate(t, tokens);

            if (!dt && needSecondDate)
                return new HistoricalDate('T' + value);

            if (dt) {
                d2 += dt;
                t = tokens.shift();
            }

            if (needCloseBracket) {
                if (!t || t !== ']')
                    return new HistoricalDate('T' + value);
                t = tokens.shift();
            }
        }

        if (t) // more tokens
            return new HistoricalDate('T' + value)

        return HistoricalDate.parse(sp + d1 + d2)
    }

    static parseFuzzyDate(t: string | undefined, tokens: string[]) {
        if (!t)
            return undefined

        // convert user input to protocol format, then let the constructor parse that

        let abt = ''
        if (tokens.length > 0 && t.match(/^(about|abt|abt\.|approx|approx\.|circa|ca|ca\.|~)$/)) {
            abt = 'A'
            t = tokens.shift()
            if (!t) {
                return undefined
            }
        }

        let dt = DateTime.fromFormat(t, 'yyyy')
        if (dt.isValid)
            return abt + dt.toFormat('+yyyy')

        // normalize separators to '-'
        t = t.replace('/', '-').replace('.', '-')

        dt = DateTime.fromFormat(t, 'yyyy-M')
        if (dt.isValid)
            return abt + dt.toFormat('+yyyy-MM')

        dt = DateTime.fromFormat(t, 'yyyy-M-d')
        if (dt.isValid)
            return abt + dt.toFormat('+yyyy-MM-dd')

        dt = DateTime.fromFormat(t, 'M-yyyy')
        if (dt.isValid)
            return abt + dt.toFormat('+yyyy-MM')

        dt = DateTime.fromFormat(t, 'M-d-yyyy')
        if (dt.isValid)
            return abt + dt.toFormat('+yyyy-MM-dd')

        // TODO: prefer d/m/y format over m/d/y based on user locale
        dt = DateTime.fromFormat(t, 'd-M-yyyy')
        if (dt.isValid)
            return abt + dt.toFormat('+yyyy-MM-dd')

        if (tokens.length > 0) {
            const t2 = t + ' ' + tokens[0]
            dt = DateTime.fromFormat(t2, 'MMM yyyy')
            if (!dt.isValid) {
                dt = DateTime.fromFormat(t2, 'MMMM yyyy')
            }
            if (dt.isValid) {
                tokens.shift()
                return abt + dt.toFormat('+yyyy-MM')
            }

            if (tokens.length > 1) {
                const t3 = t2 + ' ' + tokens[1];
                dt = DateTime.fromFormat(t3, 'd MMM yyyy')
                if (!dt.isValid) {
                    dt = DateTime.fromFormat(t3, 'd MMMM yyyy')
                }
                if (dt.isValid) {
                    tokens.shift()
                    tokens.shift()
                    return abt + dt.toFormat('+yyyy-MM-dd')
                }
            }
        }

        return undefined
    }

}
