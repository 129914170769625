<template>
  <div class="family-card" :layout="layoutRef" :selected="selectedRef" :class="{ dimmed: props.dimmed }">
    <div class="father">
      <ParentConnector v-if="!props.noConnector" :layout="layoutRef" :dimmed="props.dimmed"></ParentConnector>
      <div v-else></div>
      <ProfileRelationshipCard v-if="props.profile"
        :person-id="viewFamilyRef?.fatherId">
      </ProfileRelationshipCard>
      <PersonCard v-else
        :personId="fatherIdRef"
        :layout="layoutRef"
        :ensure-loaded="ensureLoaded"
        :show-profile-type="props.showProfileType"
        show-profile-count="multiple"
        :link-singleton="props.linkSingleton"
        :select-mode="props.selectMode"
        v-model:selectedId="selectedFatherIdRef"
        :disabled="props.dimmed"
        @click="onSelected(FamilyRelationshipRole.Father)">
      </PersonCard>
    </div>
    <div class="mother">
      <ParentConnector v-if="!props.noConnector" :layout="layoutRef" :dimmed="props.dimmed" mother></ParentConnector>
      <div v-else></div>
      <ProfileRelationshipCard v-if="props.profile"
        :person-id="viewFamilyRef?.motherId">
      </ProfileRelationshipCard>
      <PersonCard v-else 
        :personId="motherIdRef"
        :layout="layoutRef"
        :ensure-loaded="ensureLoaded"
        :show-profile-type="props.showProfileType"
        show-profile-count="multiple"
        :link-singleton="props.linkSingleton"
        :select-mode="props.selectMode"
        v-model:selectedId="selectedMotherIdRef"
        :disabled="props.dimmed"
        @click="onSelected(FamilyRelationshipRole.Mother)">
      </PersonCard>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.family-card {
  & > .father, & > .mother { // prevent styles from leaking into ParentConnector
    display: flex;
    max-width: 500px;

    .person-card {
      min-width: 0;
    }
  }
}

</style>

<script setup lang="ts">
import { computed, PropType } from 'vue'
import { useViewFamilyStore } from '@/rd/ViewFamilyStore'
import { LoadMode } from '@/util/AsyncData'
import { FamilyRelationshipRole } from '@/rd/ResearchDataModel'
import ParentConnector from './ParentConnector.vue'
import PersonCard from '@/manage/PersonCard.vue'
import ProfileRelationshipCard from './ProfileRelationshipCard.vue'

const props = defineProps({
  familyId: String,
  layout: String as PropType<"small" | "tiny" | "dot">,
  ensureLoaded: Boolean,
  profile: Boolean,
  noConnector: Boolean,
  showProfileType: Boolean,
  linkSingleton: Boolean,
  showMarriageYear: Boolean,
  dimmed: Boolean,
  selectMode: Boolean,
  selectedId: String,
})

const emit = defineEmits([
  'click',
  'update:selectedId',
])

const viewFamilyStore = useViewFamilyStore()

const loadModeRef = computed(() => props.ensureLoaded ? LoadMode.EnsureLoaded : LoadMode.TrackOnly)
const layoutRef = computed(() => props.layout ?? 'small')

const viewFamilyRef = computed(() => viewFamilyStore.getAsyncFamily(props.familyId, loadModeRef.value)?.data)
// NOTE: if we have a valid family id but can't see the family, use fake father and mother ids, so they will also 
// appear as valid (but not visible), instead of as unspecified spouses
const fatherIdRef = computed(() => viewFamilyRef.value?.fatherId ?? (props.familyId ? '000-000' : undefined))
const motherIdRef = computed(() => viewFamilyRef.value?.motherId ?? (props.familyId ? '000-000' : undefined))
const marriageYearRef = computed(() => viewFamilyRef.value?.displayProperties.marriageDate.date1?.year)

const showMarriageYearRef = computed(() => layoutRef.value != 'tiny' && props.showMarriageYear)

// person-card for each spouse must be bound to a selected person id, but family card selection represents 
// the whole family, so provide a property the person-card can bind to that is equal to the spouse id if the 
// family is selected

const selectedRef = computed({
  get() { return props.selectMode && props.selectedId == props.familyId },
  set(newValue) { emit('update:selectedId', newValue ? props.familyId : undefined) }
})

const selectedFatherIdRef = computed({
  get() { return selectedRef.value ? viewFamilyRef.value?.fatherId : undefined },
  set(newValue) { selectedRef.value = !!newValue }
})

const selectedMotherIdRef = computed({
  get() { return selectedRef.value ? viewFamilyRef.value?.motherId : undefined },
  set(newValue) { selectedRef.value = !!newValue }
})

function onSelected(spouseRole: FamilyRelationshipRole) {
  emit('click', spouseRole)
}
</script>
