export function getPossessivePhrase(subjectGender: ('Male' | 'Female' | undefined), obj: string) {
    switch (subjectGender) {
        case 'Male': return `his ${obj}`
        case 'Female': return `her ${obj}`
        default: return `their ${obj}`
    }
}

export function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export function pluralize(count: number, singular: string, plural: string, zero?: string) {
    return count == 1 ? singular : (count == 0 && zero ? zero : plural)
}

export function pluralizeWithCount(count: number, singular: string, plural: string, zero?: string) {
    return count == 1 ? `1 ${singular}` : `${count} ${plural}`
}
