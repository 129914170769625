import { AsyncFilter, useLiveSearch } from '@webapp/util/LiveSearch'
import { ViewPerson } from './ResearchDataModel'
import { useViewPersonStore } from './ViewPersonStore'

export function useLivePersonSearch(postFilter?: AsyncFilter<ViewPerson>) {
    const viewPersonStore = useViewPersonStore()

    async function searchForViewPersonsAsync(text: string, postFilter?: AsyncFilter<ViewPerson>): Promise<ViewPerson[]> {
        // note: commas are not allowed in name searches, because they are used to separate names in the request
        const names = text.replace(',', ' ').split(' ').map(n => n.trim()).filter(n => n.length > 0)
        const persons = (await viewPersonStore.searchByNamesAsync(names)).map(a => a.data!)
        if (postFilter) {
            return await postFilter(persons)
        }
        return persons
    }

    return useLiveSearch<ViewPerson>(t => searchForViewPersonsAsync(t, postFilter))
}
