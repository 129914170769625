<template>
  <Modal ref="modalRef" :ok-disabled="!canSubmitRef" @ok="onOk">
    <template #title>Payment method</template>
    <form>
      <LocalPaymentCardInfo v-if="Environment.isDevelopment()" ref="cardInfoRef" @change="onChangeInfo"/>
      <StripePaymentCardInfo v-else ref="cardInfoRef" @change="onChangeInfo" />
      <div v-if="errorRef" class="alert alert-danger">
        {{ errorRef }}
      </div>
    </form>
    <template #okText>Update</template>
  </Modal>
</template>

<style lang="scss" scoped>
.alert {
  margin-top: 1rem;
  padding: 0.5rem;
  font-size: 0.875rem;
}
</style>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useSubscriptionStore } from '@/gp/SubscriptionStore'
import { Environment } from '@/util/Environment'
import { PaymentCardInfo, PaymentCardInfoChange } from './PaymentCardInfo'
import Modal from '@/util/Modal.vue'
import LocalPaymentCardInfo from './LocalPaymentCardInfo.vue'
import StripePaymentCardInfo from './StripePaymentCardInfo.vue'

const props = defineProps<{
  subscriptionId?: string
}>()

const intentIdRef = ref<string>()
const clientSecretRef = ref<string>()
const errorRef = ref<string>()
const modalRef = ref<InstanceType<typeof Modal>>()
const cardInfoRef = ref<PaymentCardInfo>()

const subStore = useSubscriptionStore()

const subRef = computed(() => subStore.getLoadedSubscription(props.subscriptionId))
const readyRef = computed(() => !!cardInfoRef.value?.ready && !!intentIdRef.value && !!clientSecretRef.value)
const canSubmitRef = computed(() => readyRef.value && !errorRef.value)

defineExpose({
  open,
})

function open(intentId: string, clientSecret: string) {
  intentIdRef.value = intentId
  clientSecretRef.value = clientSecret
  modalRef.value?.open()
}

function onChangeInfo(e: PaymentCardInfoChange) {
  console.log(`onChangeInfo: ${e.error?.message} `)
  errorRef.value = e.error?.message
}

async function onOk() {
  if (!cardInfoRef.value?.ready || !intentIdRef.value || !clientSecretRef.value)
    return

  const result = await cardInfoRef.value.submitAsync(intentIdRef.value, clientSecretRef.value)
  errorRef.value = result.error?.message ?? 'An error occurred'
  
  if (!result.error) {
    modalRef.value?.close()
  }
}
</script>
