<template>
  <header class="navbar navbar-light bg-light sticky-top shadow-sm">
    <nav class="container-lg">
      <div class="left-group">
        <button type="button" class="navbar-toggler hide-md" data-bs-toggle="offcanvas" data-bs-target="#mainNavDrawer" aria-controls="mainNavDrawer" aria-expanded="false" aria-label="Toggle navigation">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
          </svg>
        </button>
        <div id="mainNavDrawer" ref="mainNavDrawerRef" class="offcanvas offcanvas-start" tabindex="-1">
          <div class="offcanvas-header">
            <img src="@/assets/logos/tree-crossing-logo-160h.png" >
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <SiteNav />
          </div>
        </div>
        <div v-if="userRef" class="dropdown nav-dropdown show-md" data-bs-auto-close="outside">
          <button type="button" class="btn btn-inline show-md navbar-button" data-bs-toggle="dropdown" data-bs-target="#mainNavCollapse" aria-expanded="false" aria-controls="mainNavCollapse">
            <img src="@/assets/logos/tree-crossing-logo-160h.png"/>
          </button>
          <SiteNav as-menu />
        </div>
        <div v-if="!userRef" class="navbar-brand show-md">
          <img src="@/assets/logos/tree-crossing-logo-160h.png"/>
        </div>
        <div v-if="showShareActionRef" class="share-action">
          <router-link class="btn btn-primary btn-sm" :to="shareActionUrl">
            <span class="hide-md">Share</span>
            <span class="show-md">Share my tree</span>
          </router-link>
        </div>
      </div>
      <div class="navbar-brand hide-md" :class="{ pushed: showShareActionRef }">
        <router-link :to="homeLinkRef">
          <img src="@/assets/tree-crossing-icon-128.png"/>
        </router-link>
      </div>
      <SiteSearch v-if="userRef" class="show-md" />
      <div class="right-group">
        <SiteNav v-if="!userRef" class="show-md" />
        <UserMenu v-if="userRef" class="user-menu" />
        <router-link v-else class="nav-link" to="/signin">Sign in</router-link>
      </div>
    </nav>
  </header>
</template>

<style lang="scss" scoped>
.left-group, .right-group {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.navbar-brand {
  a {
    display: block;
  }

  img {
    display: block;
    height: 30px;
  }

  &.pushed {
    padding-right: 4rem;
  }
}

.navbar-toggler {
  border: none;
  padding: 0;

  &:focus {
    box-shadow: none;
  }
}

.nav-dropdown {
  & > button img {
    height: 30px;
  }
}

@media (max-width: 767px) {
  .navbar-brand {
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 768px) {
  .navbar-brand {
    order: 1;
  }

  #mainNavDrawer {
    order: 2;
  }
}

.offcanvas {
  max-width: 90%;
  --bs-offcanvas-transition: transform 0.2s ease-in-out; // a little faster (default: 0.3s)

  .offcanvas-header {
    img {
      height: 30px;
    }
  }
}

.share-action {
  justify-self: start;

  a {
    white-space: nowrap;
  }
}
</style>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/gp/UserStore'
import { LoadMode } from '@/util/AsyncData'
import { Offcanvas } from 'bootstrap'
import { useShareAction } from './ShareAction'
import UserMenu from '@/site/UserMenu.vue'
import SiteNav from './SiteNav.vue'
import SiteSearch from './SiteSearch.vue'

const mainNavDrawerRef = ref<HTMLElement>()

const userStore = useUserStore()
const router = useRouter()
const { showShareActionRef, shareActionUrl } = useShareAction()

const userRef = computed(() => userStore.getAsyncSelf(LoadMode.EnsureLoaded)?.data)
const homeLinkRef = computed(() => userRef.value ? '/home' : '/')

router.afterEach(() => {
  if (mainNavDrawerRef.value) {
    Offcanvas.getOrCreateInstance(mainNavDrawerRef.value)?.hide()
  }
})
</script>
