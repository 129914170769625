export enum HistoricalDateType {
    Date = 'date',
    After = 'after',
    Before = 'before',
    Between = 'between',
    Span = 'span',
    Freeform = 'freeform',
}

export enum HistoricalDateFormat {
    Protocol = "p",
    ShortDisplay = "d",
    LongDisplay = "D",
}
