<template>
  <div class="family-connector" :class="{ ['connector-' + layoutRef]: true, spacer: props.spacer }" :dimmed="props.dimmed">
    <div class="horizontal-bar father"></div>
    <div class="vertical-bar"></div>
    <div class="horizontal-bar mother"></div>
    <div class="family-dot"></div>
  </div>
</template>

<style lang="scss" scoped>
.family-connector {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .horizontal-bar {
    border-top: 1px solid #ddd;
  }

  .vertical-bar {
    flex-grow: 1;
    border-left: 1px solid #ddd;
  }

  .family-dot {
    position: absolute;
    align-self: center;
    z-index: 1;
    border-radius: 50%;
    background-color: #bbb;
  }

  &.connector-small {
    padding: calc(1.3rem - 2px) 0; // half card height - vertical bar width
    width: 1rem;

    &.spacer {
      //height: 3.7rem;
    }

    .horizontal-bar {
      width: 1rem;
      border-width: 2px;
    }

    .vertical-bar {
      border-width: 2px;
    }

    .family-dot {
      top: calc(50% - (14px / 2));
      left: calc((-14px / 2) + 1px);
      width: 14px;
      height: 14px;
    }
  }

  &.connector-dot {
    padding: 7px 0;
    width: 6px;

    .horizontal-bar {
      width: 6px;
    }

    .family-dot {
      top: calc(50% - (6px / 2));
      left: calc((-6px / 2) + 1px);
      width: 6px;
      height: 6px;
    }
  }
}
</style>

<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  dimmed?: boolean,
  layout?: 'full' | 'small' | 'tiny' | 'dot',
  spacer?: boolean,
}>()

const layoutRef = computed(() => props.layout || 'full')

</script>
