<template>
  <div class="signin-code-page container" :class="{ embedded: isEmbeddedRef }">
    <h1 v-if="!isEmbeddedRef">Sign in</h1>
    <form>
      <div class="instruction">Check your email and enter the code we sent you.</div>
      <div class="row">
        <div class="col-sm-5">
          <div>
            <label for="codeInput" class="form-label">Sign in code</label>
            <input type="text" class="form-control code-input" id="codeInput" ref="codeInputRef" :readonly="busyRef" v-model="codeRef"/>
          </div>
          <div>
            <button type="submit" class="btn btn-primary" @click="signIn">Sign in</button>
          </div>
        </div>
      </div>
    </form>
    <div class="other-actions">
      <router-link :to="retryPageRef">Use a different email address</router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.signin-code-page.embedded {
  max-width: 500px;
}

.signin-code-page:not(.embedded) {
  max-width: 600px;
  min-height: 500px;
  margin-top: 4rem;
  border: 1px solid #ddd;
  border-radius: 1rem;
  padding: 1rem 2rem 2rem 2rem;
}

form {
  margin-bottom: 2rem;

  &> .row > [class^="col-"] {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.instruction {
  line-height: 2;
  font-size: 14pt;
  margin-bottom: 0.5rem;
}

.other-actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  font-size: 0.875rem;
}
</style>

<script setup lang="ts">
import { ref, computed, onMounted, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { useSignInStore } from './SignInStore'
import { useAppHostStore } from '@webapp/AppHostStore'
import { getSafeRedirectUri, usePageTitle } from '@webapp/util/AppUtil'
import { IdentityApi } from '@webapp/auth/IdentityApi'
import { TokenManager } from './TokenManager'
import { Environment } from '@webapp/util/Environment'

const props = defineProps<{
  noRedirect: boolean
}>()

const emit = defineEmits([
  'authenticated'
])

usePageTitle("Sign in")

const appHostStore = useAppHostStore()
const isEmbeddedRef = computed(() => appHostStore.isEmbedded)
const signInStore = useSignInStore()
const router = useRouter()

const codeRef = ref("")
const busyRef = ref(false)
const isDevEnv = (Environment.isDevelopment())

const codeInputRef = ref<HTMLInputElement>()
const retryPageRef = computed(() => signInStore.isNewUser ? "/signup" : "/signin")

async function signIn(e: Event) {
  e.preventDefault()

  if (signInStore.address) {
    busyRef.value = true

    const response = await IdentityApi.signInAsync(signInStore.address, codeRef.value)

    // SECURITY: set window location (instead of using router) to force app state to reset (new user -> new state)
    if (appHostStore.isEmbedded && !props.noRedirect) {
      // give host a chance to grab the access token (doesn't work for iframe "hosts")
      window.location.href = "/authredirect?access_token=" + encodeURIComponent(response.access_token)
    }
    else {
      await TokenManager.exchangeToken(response.access_token)
      if (props.noRedirect) {
        emit('authenticated')
      }
      else {
        window.location.href = getSafeRedirectUri() ?? "/home"
      }
    }
  }
}

onMounted(() => {
  codeInputRef.value!.focus()
  codeInputRef.value!.select()
})

</script>
