<template>
  <div class="research-group-list-page">
    <h1 class="with-subhead">Trees</h1>
    <ResearchGroupList show-all/>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script setup lang="ts">
import { computed } from 'vue'
import { usePageTitle } from '@/util/AppUtil'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { DataGroupType } from '@/gp/GroupAdminModel'
import { LoadMode } from '@/util/AsyncData'
import ResearchGroupList from './ResearchGroupList.vue'

usePageTitle("Trees")

</script>