<template>
  <div class="user-page">
    <div class="page-title">
      <img src="@/assets/icons/person_gray_150.png" class="page-title-icon"/>
      <div class="page-title-text-area">
        <h1>{{ title }}</h1>
      </div>
    </div>
    <ResultIndicator ref="resetSuccessAlert" alert-type="success">
      This user has been reset.
    </ResultIndicator>
    <div v-if="isUserSupervisorRef" class="alert alert-primary alert-sm page-alert supervise-alert">
      <span>
        <LockIcon/> You manage this child account.
      </span>
      <button type="button" class="btn btn-primary btn-sm" @click="onSwitchToUser(userIdRef)">
        Switch to this user
      </button>
    </div>
    <div v-if="isSupervisedRef" class="alert alert-primary alert-sm page-alert supervise-alert">
      <span>
        <LockIcon/> Your account is managed by a parent.
      </span>
    </div>
    <div>
      <ul class="nav nav-pills account-tabs">
        <li class="nav-item">
          <button type="button" id="contactTab" class="nav-link active" data-bs-toggle="tab" data-bs-target="#contactPane" role="tab" aria-controls="contactPane" aria-selected="false">
            Contact info
          </button>
        </li>
        <li class="nav-item">
          <button type="button" id="profileTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#profilePane" role="tab" aria-controls="profilePane" aria-selected="true">
            Profile
          </button>
        </li>
        <li class="nav-item">
          <button type="button" id="activityTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#activityPane" role="tab" aria-controls="activityPane" aria-selected="true">
            Activity
          </button>
        </li>
        <li v-if="showResearchGroupsRef" class="nav-item">
          <button type="button" id="researchGroupsTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#researchGroupsPane" role="tab" aria-controls="researchGroupsPane" aria-selected="true">
            Trees
          </button>
        </li>
        <li class="nav-item">
          <button type="button" id="familyGroupsTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#familyGroupsPane" role="tab" aria-controls="familyGroupsPane" aria-selected="true">
            Groups
          </button>
        </li>
        <li v-if="showSubscriptionRef" class="nav-item">
          <button type="button" id="subscriptionTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#subscriptionPane" role="tab" aria-controls="subscriptionPane" aria-selected="true">
            Subscription
          </button>
        </li>
        <li v-if="showSupervisorRef" class="nav-item">
          <button type="button" id="supervisorTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#supervisorPane" role="tab" aria-controls="supervisorPane" aria-selected="true">
            Parent account
          </button>
        </li>
        <li v-if="showAdminRef" class="nav-item">
          <button type="button" id="adminTab" class="nav-link" data-bs-toggle="tab" data-bs-target="#adminPane" role="tab" aria-controls="adminPane" aria-selected="true">
            Admin
          </button>
        </li>
      </ul>
    </div>
    <div class="tab-content content-area">
      <section id="contactPane" class="tab-pane show active" role="tabpanel" aria-labelled-by="contactTab" tab-index="0">
        <SecurityInfo v-if="showSecurityRef" :user-id="userIdRef"/>
        <ContactInfo :user-id="userIdRef"/>
      </section>
      <section id="profilePane" class="tab-pane" role="tabpanel" aria-labelled-by="profileTab" tab-index="0">
        <div v-if="userProfileInViewRef" class="person-area">
          <!-- <h5 class="with-subhead">Your profile</h5> -->
          <p class="subhead">
            <template v-if="userRef?.isSelf">
              This profile is what others see about you in their family tree.
            </template>
            <template v-else>
              This profile is what others see about this user in their family tree.
            </template>
            It is only visible to groups with whom it has been shared.
          </p>
          <ProfileRelationshipCard :person-id="userPersonIdRef"/>
          <div class="profile-link">
            <router-link :to="Person.getPageUrl(userPersonIdRef)">View full profile</router-link>
          </div>
        </div>
        <div v-else class="alert alert-primary">
          This user's profile is not visible to you.
        </div>
      </section>
      <section v-if="showResearchGroupsRef" id="researchGroupsPane" class="tab-pane" role="tabpanel" aria-labelledby="researchGroupsTab" tab-index="0">
        <ResearchGroupList :user-id="userIdRef"/>
      </section>
      <section id="familyGroupsPane" class="tab-pane" role="tabpanel" aria-labelledby="familyGroupsTab" tab-index="0">
        <FamilyGroupList :user-id="userIdRef"/>
      </section>
      <section id="activityPane" class="tab-pane" role="tabpanel" aria-labelledby="activityTab" tab-index="0">
        <UserActivity :user-id="userIdRef"/>
      </section>
      <section v-if="showSubscriptionRef" id="subscriptionPane" class="tab-pane" role="tabpanel" aria-labelled-by="subscriptionTab" tab-index="0">
        <SubscriptionInfo :user-id="userIdRef"/>
      </section>
      <section id="supervisorPane" class="tab-pane" role="tabpanel" aria-labelled-by="supervisorTab" tab-index="0">
        <SupervisionEditor :user-id="props.userId"/>
      </section>
      <section id="adminPane" class="tab-pane" role="tabpanel" aria-labelled-by="adminTab" tab-index="0">
        <AccountAdmin :user-id="userIdRef"/>
      </section>
    </div>
    <div v-if="canResetUser">
      <button type="button" class="btn btn-link mt-3" data-bs-toggle="modal" data-bs-target="#resetUserModal">Reset test account</button>
      <Modal id="resetUserModal" ref="resetUserModalRef" ok-style="danger" @ok="resetUser">
        <template #title>Reset your account?</template>
        <div>
          <p class="mb-1">This will:</p>
          <ul>
              <li>Delete your Tree Crossing online trees</li>
              <li>Delete any groups you own</li>
              <li>Leave any groups you've joined</li>
              <li>Delete all data in your profile</li>
          </ul>
        </div>
        <template #okText>Reset now</template>
        <template #busyText>Resetting...</template>
      </Modal>
    </div>
    <UserSwitchModal ref="userSwitchModalRef"/>
  </div>
</template>

<style lang="scss" scoped>
.page-title-icon {
  opacity: 70%;
}

.supervise-alert {
  display: flex;
  align-items: center;

  button {
    margin-left: auto;
  }
}

.person-card {
  max-width: 550px;
}

.person-area {
  margin-bottom: 1rem;

  .profile-link {
    margin-top: 1rem;
    font-size: 0.875rem;
  }
}

.details-area {
  max-width: 550px;
}

.item-actions {
  font-size: 0.875rem;
}
</style>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { usePageTitle } from '@/util/AppUtil'
import { useInitialTabRouting } from '@/util/InitialTabRouting'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useUserStore } from '@/gp/UserStore'
import { usePersonStore } from '@/rd/PersonStore'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { DataGroup, ItemPermissions } from '@/gp/GroupAdminModel'
import { Person } from '@/rd/ResearchDataModel'
import { AsyncUtil } from '@/util/AsyncUtil'
import { LoadMode } from '@/util/AsyncData'
import { TokenManager, UserPrivilege } from '@/auth/TokenManager'
import { LockIcon } from '@zhuowenli/vue-feather-icons'
import SecurityInfo from '@/account/SecurityInfo.vue'
import ContactInfo from '@/account/ContactInfo.vue'
import ProfileRelationshipCard from '@/manage/ProfileRelationshipCard.vue'
import UserActivity from '@/manage/UserActivity.vue'
import SubscriptionInfo from '@/account/SubscriptionInfo.vue'
import AccountAdmin from '@/account/AccountAdmin.vue'
import Modal from '@/util/Modal.vue'
import ResultIndicator from '@/util/ResultIndicator.vue'
import UserSwitchModal from './UserSwitchModal.vue'
import FamilyGroupList from './FamilyGroupList.vue'
import ResearchGroupList from './ResearchGroupList.vue'
import SupervisionEditor from './SupervisionEditor.vue'

const props = defineProps<{
  userId?: string
}>();

const userProfileInViewRef = ref(false)
const userProfileGroupRef = ref<DataGroup>()
const userPersonIdRef = ref<string>()

const resetSuccessAlert = ref<InstanceType<typeof ResultIndicator>>()
const userSwitchModalRef = ref<InstanceType<typeof UserSwitchModal>>()
const resetUserModalRef = ref<InstanceType<typeof Modal>>()

const route = useRoute()
const userStore = useUserStore()
const viewPersonStore = useViewPersonStore()
const dataGroupStore = useDataGroupStore()
const personStore = usePersonStore()

const userIdRef = computed(() => props.userId ?? TokenManager.userId!)
const isSelfRef = computed(() => userIdRef.value == TokenManager.userId)
const canReadAllUsersRef = computed(() => TokenManager.hasPrivilege(UserPrivilege.ReadAllUsers))
const showSecurityRef = computed(() => isSelfRef.value || canReadAllUsersRef.value)
const showResearchGroupsRef = computed(() => !isSelfRef.value)
const showSubscriptionRef = computed(() => isSelfRef.value || canReadAllUsersRef.value)
const showAdminRef = computed(() => TokenManager.hasPrivilege(UserPrivilege.ReadAllUsers))
const userRef = computed(() => userStore.getAsyncUser(userIdRef.value, LoadMode.EnsureLoaded)?.data)
const permissionsRef = computed(() => userStore.getAsyncPermissions(userIdRef.value, LoadMode.EnsureLoaded)?.data ?? ItemPermissions.None)
const isUserAdminRef = computed(() => (permissionsRef.value & ItemPermissions.Modify) > 0)
const isUserSupervisorRef = computed(() => !isSelfRef.value && isUserAdminRef.value)
const isSupervisedRef = computed(() => isSelfRef.value && !isUserAdminRef.value)
const showSupervisorRef = computed(() => isSelfRef.value || isUserSupervisorRef.value)
const canResetUser = computed(() => userRef.value?.isTestUser)

const title = usePageTitle(() => isSelfRef.value ? "My account" : "User", () => isSelfRef.value ? undefined : userRef.value?.displayName, true)
useInitialTabRouting('contact')

watch(userRef, async () => {
  userProfileInViewRef.value = false
  userProfileGroupRef.value = undefined
  userPersonIdRef.value = undefined

  if (userRef.value) {
    const selfViewGroups = await dataGroupStore.getViewGroupsForUserAsync()
    userProfileInViewRef.value = selfViewGroups.some(g => g.id == userRef.value?.profileDataGroupId)

    if (userProfileInViewRef.value) {
      userProfileGroupRef.value = await dataGroupStore.getAsyncGroup(userRef.value.profileDataGroupId, LoadMode.EnsureLoaded)!.whenLoadCompleted
      userPersonIdRef.value = userProfileGroupRef.value?.startItemId

      if (userProfileGroupRef.value) {        
        const vp = await viewPersonStore.getAsyncPerson(userPersonIdRef.value, LoadMode.EnsureLoaded)!.whenLoadCompleted
        await Promise.all([
          personStore.getPersonListAsync(vp?.matchIds ?? []),
          dataGroupStore.getGroupListAsync(vp?.groupIds ?? []),
        ])
      }
    }
  }
}, { immediate: true })

function onSwitchToUser(userId: string) {
  userSwitchModalRef.value?.open(userId)
}

async function resetUser() {
  await AsyncUtil.atLeast(500, userStore.resetAsync(userIdRef.value!))

  resetUserModalRef.value?.close()
  resetSuccessAlert.value?.show()
}
</script>
