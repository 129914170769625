export class CompositeId {
    readonly groupId: string
    readonly itemId: string
    readonly value: string

    constructor(value: string) {
        const parts = value.split('-', 2)
        this.groupId = parts[0]
        this.itemId = parts[1]
        this.value = value
    }

    static getGroupId(value: string | undefined) {
        return value ? new CompositeId(value).groupId : undefined
    }

    static hasGroupId(itemId: string, groupId: string) {
        return new CompositeId(itemId).groupId == groupId
    }

    static isCompositeId(value: string | undefined) {
        return value?.includes('-') ?? false
    }
}

