import { MaybeRefOrGetter, computed, ref, toValue, watch } from "vue"
import { useViewPersonStore } from "@/rd/ViewPersonStore"
import { useDataGroupStore } from "@/gp/DataGroupStore"
import { CompositeId } from "@/rd/CompositeId"
import { DataGroup } from "@/gp/GroupAdminModel"
import { PersonSet } from "@/rd/ResearchDataModel"
import { ProfileUtils } from "./ProfileUtils"

export function useProfileMatches(personId: MaybeRefOrGetter<string | undefined>) {
    const viewPersonStore = useViewPersonStore()
    const dataGroupStore = useDataGroupStore()

    const profileMatchIdsRef = ref<CompositeId[]>([])
    const personSetOwnerGroupsRef = ref<Map<string, DataGroup>>(new Map())
    const personSetsByOwnerIdRef = ref<Map<string, PersonSet>>(new Map())
    const otherPersonSetOwnerGroupsRef = computed(() => {
        return [...personSetOwnerGroupsRef.value.values()].filter(g => !profileMatchIdsRef.value.find(id => id.groupId == g.id))
    })
    
    watch(() => personId, async newPersonId => {
        profileMatchIdsRef.value = []
        personSetOwnerGroupsRef.value.clear()
        personSetsByOwnerIdRef.value.clear()
      
        const personIdValue = toValue(personId)
        const vp = (await viewPersonStore.getPersonListAsync([personIdValue])).at(0)
        if (!personIdValue || !vp)
            return
      
        // TODO: include all set members in matchIds, including those that didn't make the cut due to non-matches
        const personSets = await viewPersonStore.getPersonSetsAsync(toValue(personId))
        const dataGroupIds = [...new Set([...vp.groupIds, ...personSets.map(s => s.groupId)])]
        const dataGroups = await dataGroupStore.getGroupListAsync(dataGroupIds) // also loads data group owners
        const profileIds = ProfileUtils.getNonPlaceholderIds(vp.matchIds, dataGroups)
            .map(id => new CompositeId(id))
      
        profileMatchIdsRef.value = profileIds
      
        for (const set of personSets) {
            // exclude placeholders
            const profileIdsInSet = profileIds.filter(id => set.memberIds.includes(id.value))

            // Internally, the "owner" of a person set is always a group. However, the UI usually identifies a 
            // specific person/profile as the "owner" of the set. The simple rule for determining the owner person is,
            // if the set includes a person from the owner group, then that person is treated as the set owner. 
            // Otherwise, the owner is the group itself. Note that a set can include AT MOST ONE person from the 
            // owner group, so the owner person is never ambiguous.

            // For example:
            // - If the owner group is a system group (e.g. AutoMatch), none of the set members ever
            //   belong to the group, so the owner is always the group itself.
            // - If the owner group is a profile group, the set MUST include exactly one person from
            //   that group, so the owner is always that person.

            const ownerId = set.memberIds.find(id => CompositeId.hasGroupId(id, set.groupId!)) ?? set.groupId!
            personSetsByOwnerIdRef.value.set(ownerId, set)
        
            const ownerGroup = dataGroups.find(dg => dg.id == set.groupId)
            if (ownerGroup) {
                personSetOwnerGroupsRef.value.set(ownerGroup.id!, ownerGroup)
            }
        }
    }, { immediate: true })

    return {
        profileMatchIdsRef,
        personSetOwnerGroupsRef,
        personSetsByOwnerIdRef,
        otherPersonSetOwnerGroupsRef,
    }
}