<template>
  <div ref="modalRef" class="modal add-related-profile-modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <form class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">
            Add parents
          </h3>
          <button type="button" class="btn-close" :disabled="busyRef" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div>Select a family:</div>
          <FamilySelector
            v-model="selectedFamilyId"
            placeholder="Type a parent's name"
            :disabled="busyRef"
            :post-filter="getSelectableRelatedPersonsAsync">
          </FamilySelector>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btn-primary" :disabled="!canAddRef" @click.prevent="addParents">
            Add parents
          </button>
          <button type="button" class="btn btn-outline-primary" :disabled="busyRef" data-bs-dismiss="modal">
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
  <div>
  </div>
</template>

<style lang="scss" scoped>
.modal-body {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 1.5rem;
}

.family-selector {
  min-height: 60px;
}
</style>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { ViewPerson, livingWindowStart } from '@/rd/ResearchDataModel'
import { getOrCreateChildRelationshipAsync } from './ProfileManager'
import { Modal } from 'bootstrap'
import FamilySelector from './FamilySelector.vue'

const childIdRef = ref<string>()
const selectedFamilyId = ref<string>()
const busyRef = ref(false)
const modalRef = ref<HTMLElement>()

const canAddRef = computed(() => !!selectedFamilyId.value && !busyRef.value)

const emit = defineEmits([
  'added',
])

defineExpose({
  open,
})

function open(childId: string) {
  if (modalRef.value) {
    childIdRef.value = childId
    Modal.getOrCreateInstance(modalRef.value).show()  
  }
}

async function getSelectableRelatedPersonsAsync(vps: ViewPerson[]) {
  return vps.filter(vp => (vp.birthYear ?? vp.deathYear ?? 9999) >= livingWindowStart)
}

async function addParents() {
  if (!childIdRef.value || !selectedFamilyId.value)
    throw 'Missing child or family id'

  await getOrCreateChildRelationshipAsync(childIdRef.value, selectedFamilyId.value)
  emit('added')

  if (modalRef.value) {
    Modal.getOrCreateInstance(modalRef.value).hide()
  }
}
</script>
