import { reactive } from 'vue'
import { defineStore } from 'pinia'
import { FamilyGroupMember, FamilyGroupMemberKey, GroupMemberRole, PrincipalType } from '@webapp/gp/GroupAdminModel'
import { gp } from './GroupAdminApi'
import { AsyncData, LoadMode } from '@webapp/util/AsyncData'
import { AsyncDataStore, VersionedKeyList } from '@webapp/util/AsyncDataStore'
import { useUserStore } from './UserStore'
import { useFamilyGroupStore } from './FamilyGroupStore'
import { useDataGroupMemberStore } from './DataGroupMemberStore'
import { TokenManager } from '@webapp/auth/TokenManager'
import { DateTime } from 'luxon'
import { DateTimeUtil } from '@webapp/util/LuxonUtil'
import { assignExisting, isDefined } from '@webapp/util/TypeScriptUtil'
import { PatchChange } from '@/util/Api'

export const useFamilyGroupMemberStore = defineStore('familyGroupMembers', () => {
    const asyncFamilyGroupMembers = reactive(new Map<string, AsyncData<FamilyGroupMember>>())

    const familyGroupStore = useFamilyGroupStore()
    const dataGroupMemberStore = useDataGroupMemberStore()
    const userStore = useUserStore()

    function getAsyncMember(key: string | FamilyGroupMemberKey | undefined, loadMode = LoadMode.TrackOnly) {
        return getAsyncMemberList([key], loadMode).at(0)
    }

    function getAsyncMemberList(keys: (string | FamilyGroupMemberKey | undefined)[], loadMode = LoadMode.TrackOnly) {
        return getAsyncFamilyGroupMembersByKeys(keys, loadMode)
    }

    async function getMemberListAsync(keys: (string | FamilyGroupMemberKey | undefined)[]) {
        return (await Promise.all(getAsyncMemberList(keys, LoadMode.EnsureLoaded).map(a => a.whenLoadCompleted))).filter(isDefined)
    }

    function getAsyncKeyListForFamilyGroup(familyGroupId: string | undefined, loadMode = LoadMode.TrackOnly) {
        return getAsyncKeyListForFamilyGroupMembers(familyGroupId, "FamilyGroup", familyGroupStore.asyncMemberKeys, loadMode, 
            `familygroups/${familyGroupId}/members`, "user/profiledatagroup") // NOTE: user is group owner, so no need to expand
    }

    function getAsyncKeyListForUser(userId?: string, loadMode = LoadMode.TrackOnly) {
        userId ??= TokenManager.userId
        return getAsyncKeyListForFamilyGroupMembers(userId, "User", userStore.asyncFamilyGroupMemberKeys, loadMode, 
            `users/${userId}/familygroups`, "familygroup/owner")
    }

    function getAsyncMembersForFamilyGroup(familyGroupId: string | undefined, loadMode = LoadMode.TrackOnly) {
        return getAsyncMemberList(getAsyncKeyListForFamilyGroup(familyGroupId, loadMode)?.data?.keys ?? [])
    }

    async function getMembersForFamilyGroupAsync(familyGroupId: string | undefined) {
        const keyList = await getAsyncKeyListForFamilyGroup(familyGroupId, LoadMode.EnsureLoaded).whenLoadCompleted
        return await getMemberListAsync(keyList?.keys ?? []) // should be loaded
    }

    function getMembersForUser(userId?: string | undefined, loadMode = LoadMode.TrackOnly) {
        return getAsyncMemberList(getAsyncKeyListForUser(userId, loadMode).data?.keys ?? [])
            .map(a => a.data).filter(isDefined)
    }

    async function getMembersForUserAsync(userId?: string | undefined) {
        const keyList = await getAsyncKeyListForUser(userId, LoadMode.EnsureLoaded).whenLoadCompleted
        return await getMemberListAsync(keyList?.keys ?? []) // should be loaded
    }

    async function addAsync(member: FamilyGroupMember) {
        const addCoreAsync = async () => {
            const p = await gp.postPlainAsync(`familygroups/${member.familyGroupId}/members`, member)
            return plainToFamilyGroupMember(p)
        }            
        const key = await AsyncDataStore.addToStoreMapAsync(asyncFamilyGroupMembers, "FamilyGroupMember", m => m.id, addCoreAsync)
        const newMember = asyncFamilyGroupMembers.get(key)?.data // should be present

        invalidateKeyLists(newMember!)
        return key
    }

    async function patchAsync(key: string | FamilyGroupMemberKey, changes: PatchChange[]) {
        const keyStr = typeof key == "string" ? key : FamilyGroupMember.toKeyString(key)
        const patchCoreAsync = async (keyStr: string, changes: PatchChange[]) => {
            const parsedKey = FamilyGroupMember.parseKey(keyStr)
            const p = await gp.patchPlainAsync(`familygroups/${parsedKey.familyGroupId}/members/${parsedKey.id}`, changes)
            return plainToFamilyGroupMember(p)
        }
        await AsyncDataStore.patchFromStoreAsync(asyncFamilyGroupMembers, keyStr, changes, patchCoreAsync)
        getAsyncMember(key, LoadMode.Reload) // trigger reload, but don't wait for it
    }

    async function deleteAsync(key: string | FamilyGroupMemberKey) {
        const parsedKey = typeof key == "string" ? FamilyGroupMember.parseKey(key) : key
        const member = await getAsyncMember(key, LoadMode.EnsureLoaded)?.whenLoadCompleted
        if (!member)
            return

        const memberUser = await userStore.getAsyncUser(member.userId, LoadMode.EnsureLoaded)?.whenLoadCompleted
        const profileMember = memberUser
            ? await dataGroupMemberStore.getMemberByPrincipalAsync(memberUser.profileDataGroupId!, PrincipalType.FamilyGroup, parsedKey.familyGroupId)
            : undefined

        // delete member
        await gp.deleteAsync(`familygroups/${parsedKey.familyGroupId}/members/${parsedKey.id}`)
        AsyncData.invalidate(asyncFamilyGroupMembers.get(FamilyGroupMember.toKeyString(parsedKey)))

        invalidateKeyLists(member)

        if (profileMember) {
            // NOTE: the server also "unshared" the user's profile data group, so invalidate that too
            dataGroupMemberStore.invalidateKeyLists(profileMember)
        }
        
        // TODO: clear other stores of cached data related to this member?
    }
    
    function getAsyncFamilyGroupMembersByKeys(keys: (string | FamilyGroupMemberKey | undefined)[], loadMode = LoadMode.TrackOnly) {
        const ids = keys.filter(isDefined).map(id => typeof id == "string" ? id : FamilyGroupMember.toKeyString(id))
        const loadAsync = async (idsToLoad: string[], requestTime: DateTime) => {
            const parsedKeys = idsToLoad.filter(isDefined).map(FamilyGroupMember.parseKey)
            const familyGroupId = parsedKeys[0].familyGroupId
            const plainItems = (parsedKeys.every(k => k.familyGroupId == familyGroupId))
                ? await gp.getPlainByIdsAsync(`familygroups/${familyGroupId}/members`, parsedKeys.map(k => k.id))
                : await Promise.all(parsedKeys.map(k => gp.getPlainAsync(`familygroups/${k.familyGroupId}/members/${k.id}`)))
            console.log(`Loaded ${plainItems.length} family group members by id`)
            return processExpandedFamilyGroupMembers(plainItems, requestTime)
        }
        const memberStore = useFamilyGroupMemberStore()
        return AsyncDataStore.getAsyncItemsByIds(memberStore.asyncFamilyGroupMembers, ids, "FamilyGroupMember", loadMode, loadAsync)
    }

    function plainToFamilyGroupMember(p: any) {
        const member = assignExisting(new FamilyGroupMember(), p)
        member.role = p.role ?? GroupMemberRole.Member // default
        member.createdDate = DateTimeUtil.fromAPI(p.createdDate);
        member.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate);
        return member
    }

    function processExpandedFamilyGroupMembers(plainItems: any[], requestTime: DateTime) {
        userStore.addUsersToStore(plainItems.map(p => p.user).filter(isDefined), requestTime)
        familyGroupStore.addFamilyGroupsToStore(plainItems.map(p => p.familyGroup).filter(isDefined), requestTime)
        return AsyncDataStore.createItemMap(plainItems, "FamilyGroupMember", plainToFamilyGroupMember, m => m.key!)
    }

    function getAsyncKeyListForFamilyGroupMembers(sourceItemKey: string | undefined,
        sourceTypeName: string,
        relatedKeysStoreMap: Map<string, AsyncData<VersionedKeyList>>,
        loadMode: LoadMode,
        relatedItemsPath: string,
        relatedItemsExpand: string) {

        const loadRelatedItemsAsync = async (sourceKeys: string[], requestTime: DateTime) => {
            const plainItems = await gp.getPlainCollectionAsync(relatedItemsPath, relatedItemsExpand)
            console.log(`Loaded ${plainItems.length} family group members at ${relatedItemsPath}`)
            return processExpandedFamilyGroupMembers(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncKeyListForRelatedItems(sourceItemKey, sourceTypeName, relatedKeysStoreMap,
            asyncFamilyGroupMembers, "FamilyGroupMember", loadMode, loadRelatedItemsAsync)
    }

    function invalidateKeyLists(member: FamilyGroupMember) {
        AsyncData.invalidate(userStore.asyncFamilyGroupMemberKeys.get(member.userId!))

        AsyncData.invalidate(familyGroupStore.asyncMemberKeys.get(member.familyGroupId!))

        AsyncData.invalidate(familyGroupStore.asyncDataGroupMemberKeys.get(member.familyGroupId!))
    }

    return {
        asyncFamilyGroupMembers,

        getAsyncMember,
        getAsyncMemberList,
        getMemberListAsync,
        getAsyncKeyListForFamilyGroup,
        getAsyncMembersForFamilyGroup,
        getMembersForFamilyGroupAsync,
        getAsyncKeyListForUser,
        getMembersForUser,
        getMembersForUserAsync,
        addAsync,
        patchAsync,
        deleteAsync,
    }
})

