import { ref, Ref, watchEffect } from "vue"
import _ from "lodash"

export function useLiveSearch<T>(getResults: (text: string) => Promise<T[]>) {

    const searchTextRef = ref('')
    const searchResultsRef = ref<T[]>([]) as Ref<T[]>
    let lastSearchText = '' // used to prevent duplicate searches

    // invoke on leading edge to be responsive, and on trailing edge to ensure final results are returned
    const debouncedUpdateSearchResultsAsync = _.debounce(updateSearchResultsAsync, 200, { leading: true, trailing: true })

    watchEffect(async () => {
        // ignore leading/trailing whitespace
        const newText = searchTextRef.value.trim()

        if (newText.length >= 3) {
            if (newText != lastSearchText) {
                debouncedUpdateSearchResultsAsync(newText) // don't await
                lastSearchText = newText
            }
        }
        else {
            searchResultsRef.value = []
        }
    })

    async function updateSearchResultsAsync(text: string) {
        searchResultsRef.value = await getResults(text)
    }

    return {
        searchTextRef,
        searchResultsRef,
    }
}

export type Predicate<T> = (item: T) => boolean

export type AsyncFilter<T> = (items: T[]) => Promise<T[]>
