<template>
  <div class="search-input">
    <span class="search-icon"><SearchIcon size="1x"></SearchIcon></span>
    <input type="textbox" ref="inputRef" class="form-control" 
      :placeholder="props.placeholder" 
      :value="props.modelValue" 
      v-bind="{ disabled: props.disabled }"
      @focus="emit('focus')"
      @blur="emit('blur')"
      @input="updateModelValue"
      @keydown="inputKeyDown" />
    <div ref="dropdownRef" class="dropdown">
      <button type="button" class="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
        Show results
      </button>
      <ul ref="dropdownMenuRef" class="dropdown-menu">
        <li v-for="item in props.searchResults" :key="item">
          <button type="button" class="dropdown-item" @click="searchResultSelected(item)">
            <slot :item="item"></slot>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-input {
  position: relative;

  .search-icon {
    position: absolute;
    top: 0.3em;
    left: 0.5em;

    svg {
      stroke: #aaa;
    }
  }

  input {
    padding-left: 2em;
  }
}

.dropdown-toggle {
  display: block;
  height: 0px;
  padding: 0;
  border: none;
  background-color: pink; //transparent;
  overflow-y: hidden;
}

.dropdown-menu {
  padding: 0.2rem 0;
  border-radius: 3px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3);

  li {
    padding: 0.15rem 0.5rem;

    button.dropdown-item {
      padding: 0;
    }
  }
}

</style>

<script lang="ts" setup>
import { ref, watchEffect } from "vue"
import { SearchIcon } from '@zhuowenli/vue-feather-icons'
import { Dropdown } from "bootstrap";

const props = defineProps<{
  modelValue: string
  searchResults: string[]
  placeholder: string
  disabled?: boolean
}>()

const emit = defineEmits([
  'update:modelValue',
  'resultSelected',
  'focus',
  'blur',
  'keydown',
])

defineExpose({
  focus,
  reset
})

const inputRef = ref<HTMLInputElement>()
const dropdownRef = ref<HTMLElement>()
const dropdownMenuRef = ref<HTMLElement>()

function updateModelValue(e: Event) {
  emit('update:modelValue', (e.target as HTMLInputElement).value)
}

function clearModelValue() {
  emit('update:modelValue', '')
}

watchEffect(() => {
  showDropdown(props.searchResults?.length > 0)
})

function searchResultSelected(item: string) {
  clearModelValue()
  emit('resultSelected', item)
  showDropdown(false)
}

function showDropdown(show: boolean) {
  if (dropdownRef.value) {
    const dropdown = new Dropdown(dropdownRef.value)
    if (show) {
      dropdown.show()
    }
    else {
      dropdown.hide()
    }
  }
}

function isDropdownShown() {
  return dropdownMenuRef.value?.classList.contains('show') ?? false
}

function focusDropdown() {
  const firstButton = dropdownRef.value?.querySelector('button.dropdown-item') as HTMLButtonElement | null
  firstButton?.focus()
}

function inputKeyDown(e: KeyboardEvent) {
  switch (e.key) {
    case 'Enter': {
      if (!isDropdownShown()) {
        showDropdown(true)
        e.preventDefault()
        e.stopPropagation()
      }
      else {
        focusDropdown()
        e.preventDefault()
        e.stopPropagation()
      }
      break
    }
    case 'Escape': {
      if (isDropdownShown()) {
        showDropdown(false)
        e.stopPropagation()
      }
      else if (props.modelValue.length > 0) {
        clearModelValue()
        e.stopPropagation()
      }
      break
    }
    case 'ArrowDown': {
      if (props.modelValue.length > 0) {
        e.preventDefault()
        focusDropdown()
      }
      break
    }
  }
}

function focus() {
  inputRef.value?.focus()
}

function reset() {
  showDropdown(false)
}

</script>