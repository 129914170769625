<template>
  <div class="local-payment-card-info">
    <div class="alert alert-warning">
      Development environment. Do not use real card information.
    </div>
    <div class="container">
      <div class="row mb-2">
        <div class="card-number col-6">
          <label class="form-label">Card number</label>
          <input class="form-control" maxlength="16" v-model="cardNumberRef" @keypress="e => onlyChars(e, /[0-9]/)"/>
        </div>
      </div>
      <div class="row">
        <div class="card-expires col-3">
          <label class="form-label">Expires</label>
          <input class="form-control" maxlength="5" v-model="cardExpRef" @keypress="e => onlyChars(e, /[0-9/]/)"/>
        </div>
        <div class="card-code col-3">
          <label class="form-label">CVV</label>
          <input class="form-control" maxlength="3" v-model="cardCodeRef" @keypress="e => onlyChars(e, /[0-9]/)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.local-payment-card-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .alert {
    padding: 0.5rem;
    font-size: 0.875rem;
  }
}
</style>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { PaymentCardInfoChange, PaymentCardInfoSubmitResult } from './PaymentCardInfo'
import { usePaymentMethodStore } from '@/gp/PaymentMethodStore'

const emit = defineEmits([
  'change',
])

const readyRef = ref(true)
const cardNumberRef = ref('')
const cardExpRef = ref('')
const cardCodeRef = ref('')

const paymentMethodStore = usePaymentMethodStore()

const cardExpMonthRef = computed(() => cardExpRef.value?.split('/')[0])
const cardExpYearRef = computed(() => cardExpRef.value?.split('/').at(1) ?? '0')
const cardNumberErrorRef = computed(() => cardNumberRef.value.length < 16 ? 'Incomplete card number' : undefined)
const cardExpErroRef = computed(() => cardExpRef.value?.match(/^\d{2}\/\d{2}$/) ? undefined : 'Invalid expiration date')
const cardCodeErrorRef = computed(() => cardCodeRef.value.length < 3 ? 'Incomplete CVV' : undefined)
const isValidRef = computed(() => !cardNumberErrorRef.value && !cardExpErroRef.value && !cardCodeErrorRef.value)

defineExpose({
  ready: readyRef,
  submitAsync,
})

function onlyChars(e: KeyboardEvent, pattern: RegExp) {
  if (!e.key.match(pattern) && e.key != 'Backspace') {
    e.preventDefault()
  }
}

watch([cardNumberRef, cardExpRef, cardCodeRef], () => {
  const errorMessage = cardNumberErrorRef.value ?? cardExpErroRef.value ?? cardCodeErrorRef.value
  const event: PaymentCardInfoChange = {
    error: errorMessage ? { message: errorMessage } : undefined,
    complete: isValidRef.value,
  }
  emit('change', event)
}, { immediate: true})

async function submitAsync(intentId: string, clientSecret: string): Promise<PaymentCardInfoSubmitResult> {
  if (!readyRef.value)
    throw 'Component not ready'

  if (!isValidRef.value)  
    return { error: { message: 'Invalid card information' } }
  
  const paymentInfo = {
    cardNumber: cardNumberRef.value,
    expirationMonth: +cardExpMonthRef.value,
    expirationYear: +cardExpYearRef.value + 2000,
  }
  await paymentMethodStore.confirmLocalPayment(paymentInfo, intentId, clientSecret)
  return {}
}

</script>