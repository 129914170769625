import { DataGroup, DataGroupType, User } from "@/gp/GroupAdminModel"
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useUserStore } from '@/gp/UserStore'
import { CompositeId } from "@/rd/CompositeId"
import { LoadMode } from "@/util/AsyncData"

const nonProfileGroupIds = new Set<string>()
const profileUserIdCache = new Map<string, string | undefined>() // personId -> userId

export function useProfileUserCache() {

    /**
     * Gets the user (if any) for whom the specified person is their user profile, if both the person group and 
     * person group owner are loaded. If not loaded, the function immediately returns undefined, but loading is 
     * triggered, and a later call will return the correct result.
     */
    function getProfileUser(personId: string) {
        return getProfileUserCore(personId, false)
    }

    function getProfileUserAsync(personId: string) {
        return getProfileUserCore(personId, true)
    }

    function getProfileUserCore(personId: string, async: boolean) {
        const groupId = CompositeId.getGroupId(personId)

        if (!groupId || nonProfileGroupIds.has(groupId))
            return undefined

        const userStore = useUserStore()

        if (profileUserIdCache.has(personId))
        {
            const profileUserId = profileUserIdCache.get(personId)
            if (profileUserId) {
                return userStore.getAsyncUser(profileUserId, LoadMode.EnsureLoaded)?.data
            }
            return undefined
        }

        const dataGroupStore = useDataGroupStore()

        // TODO: this code doesn't make any sense
        // Assert non-null is not valid, don't use async data before loaded, don't trigger load after call that will load inline...

        // NOTE: data group owners are always loaded inline with their data groups
        const asyncDataGroup = dataGroupStore.getAsyncGroup(groupId, LoadMode.EnsureLoaded)!
        const asyncOwner = userStore.getAsyncUser(asyncDataGroup?.data?.ownerId, LoadMode.EnsureLoaded)!

        if (async) {
            return Promise.all([asyncDataGroup?.whenLoadCompleted, asyncOwner?.whenLoadCompleted]).then(() => {
                return getProfileUserFromGroup(personId, asyncDataGroup.data, asyncOwner.data)
            })
        }
        if (asyncDataGroup?.loadComplete && asyncOwner?.loadComplete) {
            return getProfileUserFromGroup(personId, asyncDataGroup.data, asyncOwner.data)
        }
        return undefined // don't know yet
    }

    function getProfileUserFromGroup(personId: string, dataGroup?: DataGroup, owner?: User) {
        if (!dataGroup || !owner)
            return undefined

        if (dataGroup.groupType != DataGroupType.Profile) {
            nonProfileGroupIds.add(dataGroup.id!)
            return undefined // not a profile group
        }

        const isOwnerProfile = owner.profileDataGroupId == dataGroup.id && dataGroup.startItemId == personId
        if (isOwnerProfile) {
            console.log(`Found profile for user ${owner.id}`)
        }

        const profileUserId = isOwnerProfile ? owner.id : undefined
        profileUserIdCache.set(personId, profileUserId)

        return isOwnerProfile ? owner : undefined
    }

    return {
        getProfileUser,
        getProfileUserAsync,
    }
}
