const env = new Map<string, string | undefined>()

export class Environment {
    static set(key: string, value?: string) {
        env.set(key, value)
    }

    static get(key: string) {
        return env.get(key) ?? ''
    }

    static isDevelopment() {
        return Environment.get('ENVIRONMENT') == 'dev'
    }
}
