<template>
  <CompactItem class="research-group-item" :pageUrl="workspaceRef?.pageUrl">
    <template #icon><img :src="logoRef"/></template>
    <template #title>{{ dataGroupRef?.name }}</template>
    <template #subtitle>
        {{ workspaceRef?.workspaceTypeInfo?.description }} managed by {{ ownerRef?.displayName }}
    </template>
  </CompactItem>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useUserStore } from '@/gp/UserStore'
import { useWorkspaceStore } from '@/rd/WorkspaceStore'
import { LoadMode } from '@/util/AsyncData'
import { researchGroupLogos } from './ResearchGroupLogos'
import CompactItem from '@/util/CompactItem.vue'

const props = defineProps({
  dataGroupId: String,
  small: Boolean,
  ensureLoaded: Boolean,
  selectMode: Boolean,
})

const workspaceStore = useWorkspaceStore()
const dataGroupStore = useDataGroupStore()
const userStore = useUserStore()

const loadModeRef = computed(() => props.ensureLoaded ? LoadMode.EnsureLoaded : LoadMode.TrackOnly)
const dataGroupRef = computed(() => dataGroupStore.getAsyncGroup(props.dataGroupId, loadModeRef.value)?.data)
const ownerRef = computed(() => userStore.getAsyncUser(workspaceRef.value?.ownerId, loadModeRef.value)?.data)
const workspaceRef = computed(() => workspaceStore.getAsyncWorkspacesForGroups([props.dataGroupId], loadModeRef.value).at(0)?.data)
const logoRef = computed(() => researchGroupLogos[workspaceRef.value?.dataFileBaseType ?? 'TestData'])

</script>