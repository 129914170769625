<template>
  <div class="expandable-item">
    <div class="expand-item">
      <button type="button" ref="expandButtonRef" class="expand-button" :class="{ expanded: props.expanded }" @click="toggleExpand">
        <ChevronRightIcon></ChevronRightIcon>
      </button>
      <input type="checkbox" v-if="props.checkbox"
        :checked="props.checked" 
        :indeterminate="props.indeterminate" 
        :disabled="props.checkDisabled"
        @input="emit('check')" 
        class="form-check-input"/>
      <button type="button" class="btn btn-inline btn-link link-dark" 
        aria-expanded="false" 
        :aria-controls="contentId"
        @click="toggleExpand">
        <slot name="item"></slot>
      </button>
    </div>
    <div :id="contentId" ref="expandContentRef" class="expand-content collapse" :class="{ show: props.expanded }">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.expandable-item {
  .expand-item {
    display: flex;
  }

  .expand-button {
    all: unset; // remove all default button styling
    width: 16px;
    height: 16px;
    margin-top: 0.25rem;
    margin-right: 0.25rem; // icon is 16, total width is 20px
    position: relative;
    transition: transform 0.1s ease-in-out;

    &.expanded {
      transform: rotate(90deg);
      transform-origin: center;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  input {
    margin-top: 0.25rem;
    margin-right: 0.5rem;
    font-size: 1rem;
  }
}
</style>

<script lang="ts" setup>
import { onMounted, onBeforeUnmount, ref } from 'vue'
import { ChevronRightIcon } from '@zhuowenli/vue-feather-icons'
import { Collapse } from 'bootstrap'
import { DOMUtil } from './DOMUtil';

const props = defineProps({
  checkbox: Boolean,
  checked: Boolean,
  indeterminate: Boolean,
  checkDisabled: Boolean,
  expanded: Boolean,
})

const emit = defineEmits([
  'check',
  'update:expanded',
])

const expandButtonRef = ref<HTMLButtonElement>()
const expandContentRef = ref<HTMLElement>()

const contentId = DOMUtil.randomElementId("content")

onMounted(() => {
  expandContentRef.value!.addEventListener('show.bs.collapse', onExpand)
  expandContentRef.value!.addEventListener('hide.bs.collapse', onCollapse)
})

onBeforeUnmount(() => {
  expandContentRef.value!.removeEventListener('show.bs.collapse', onExpand)
  expandContentRef.value!.removeEventListener('hide.bs.collapse', onCollapse)
})

function toggleExpand() {
  new Collapse(expandContentRef.value!) // toggles on init
}

function onExpand(e: Event) {
  if (e.target == e.currentTarget) {
    expandButtonRef.value!.classList.add('expanded')
    emit('update:expanded', true)
  }
}

function onCollapse(e: Event) {
  if (e.target == e.currentTarget) {
    expandButtonRef.value!.classList.remove('expanded')
    emit('update:expanded', false)
  }
}

</script>
