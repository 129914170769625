<template>
  <div>
    <label :for="id" class="form-label">Share with</label>
    <select class="form-select" :id="id" :disabled="disabled" v-model="modelValue">
      <option v-for="level in sortedLevels" :value="level" :key="level">{{ getDisplayText(level) }}</option>
    </select>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { PrivacyLevel } from '@/gp/GroupAdminModel'

const props = defineProps({
  id: String,
  modelValue: String,
  disabled: Boolean,
})
const emit = defineEmits([
  'update:modelValue'
])

const modelValue = computed({
  get() { return props.modelValue },
  set(value) { emit('update:modelValue', value) }
})

const sortedLevels = [
  PrivacyLevel.Me, 
  PrivacyLevel.Friends, 
  PrivacyLevel.Groups, 
  PrivacyLevel.Everyone
]

function getDisplayText(level: PrivacyLevel) {
  return level
}
</script>
