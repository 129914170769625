import { defineStore } from 'pinia'
import { FamilyGroup, ItemPermissions } from '@webapp/gp/GroupAdminModel'
import { gp } from './GroupAdminApi'
import { AsyncData, LoadMode } from '@webapp/util/AsyncData'
import { AsyncDataStore, VersionedKeyList } from '@webapp/util/AsyncDataStore'
import { useUserStore } from './UserStore'
import { getAsyncPermissionsCore } from './Permissions'
import { DateTime } from 'luxon'
import { DateTimeUtil } from '@webapp/util/LuxonUtil'
import { assignExisting, isDefined } from '@webapp/util/TypeScriptUtil'
import { PatchChange } from '@webapp/util/Api'
import { reactive } from 'vue'
import { TokenManager } from '@webapp/auth/TokenManager'


export const useFamilyGroupStore = defineStore('familyGroups', () => {
    const asyncFamilyGroups = reactive(new Map<string, AsyncData<FamilyGroup>>())
    const asyncMemberKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())
    const asyncDataGroupMemberKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())
    const asyncInviteKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())
    const asyncDataGroupInviteKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())
    const asyncPermissions = reactive(new Map<string, AsyncData<ItemPermissions>>())

    const userStore = useUserStore()

    function getAsyncGroup(id: string | undefined, loadMode = LoadMode.TrackOnly) {
        return id ? getAsyncGroupList([id], loadMode)[0] : undefined
    }

    function getAsyncGroupList(ids: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const loadAsync = async (ids: string[], requestTime: DateTime) => {
            const plainItems = await gp.getPlainByIdsAsync("familygroups", ids, familyGroupExpand)
            console.log(`Loaded ${plainItems.length} family groups by id: ${plainItems.map(p => p.id).join(", ")}`)
            return processExpandedFamilyGroups(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncItemsByIds(asyncFamilyGroups, ids, "FamilyGroup", loadMode, loadAsync)
    }

    function getGroupList(ids: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        return getAsyncGroupList(ids, loadMode).map(a => a.data).filter(isDefined)
    }

    async function getGroupListAsync(ids: (string | undefined)[]) {
        return (await Promise.all(getAsyncGroupList(ids, LoadMode.EnsureLoaded).map(a => a.whenLoadCompleted))).filter(isDefined)
    }

    function getAsyncPermissions(id: string | undefined, loadMode = LoadMode.TrackOnly) {
        return getAsyncPermissionsCore(asyncPermissions, id, "FamilyGroup", "familygroups", loadMode)
    }
    
    async function addAsync(group: FamilyGroup) {
        const addCoreAsync = async () => {
            const p = await gp.postPlainAsync("familygroups", group)
            return plainToFamilyGroup(p)
        }            
        const newId = await AsyncDataStore.addToStoreMapAsync(asyncFamilyGroups, "FamilyGroup", fg => fg.id, addCoreAsync)

        const userKeyList = userStore.asyncFamilyGroupMemberKeys.get(TokenManager.userId!)
        AsyncData.invalidate(userKeyList)

        return newId
    }

    async function patchAsync(id: string, changes: PatchChange[]) {
        const patchCoreAsync = async (id: string, changes: PatchChange[]) => {
            const p = await gp.patchPlainAsync(`familygroups/${id}`, changes);
            return plainToFamilyGroup(p);
        }
        await AsyncDataStore.patchFromStoreAsync(asyncFamilyGroups, id, changes, patchCoreAsync)
    }

    async function deleteAsync(id: string) {
        await gp.deleteAsync(`familygroups/${id}`)
        AsyncData.setDeleted(asyncFamilyGroups.get(id))
        AsyncData.setDeleted(asyncMemberKeys.get(id))
        AsyncData.invalidate(userStore.asyncFamilyGroupMemberKeys.get(TokenManager.userId!))

        // TODO: clear other stores of cached data belonging to this group?
    }

    function addFamilyGroupsToStore(plainItems: any[], requestTime: DateTime) {
        const familyGroupMap = processExpandedFamilyGroups(plainItems, requestTime)
        AsyncDataStore.addItemMapToStore(asyncFamilyGroups, "FamilyGroup", familyGroupMap, requestTime)
    }

    const familyGroupExpand = "owner"

    function plainToFamilyGroup(p: any) {
        const group = assignExisting(new FamilyGroup(), p)
        group.createdDate = DateTimeUtil.fromAPI(p.createdDate)
        group.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate)

        if (p.owner) {
            group.ownerId = p.owner?.id
        } else {
            throw `Family group ${group.id} loaded without owner`
        }
        return group
    }

    function processExpandedFamilyGroups(plainItems: any[], requestTime: DateTime) {
        userStore.addUsersToStore(plainItems.map(p => p.owner).filter(isDefined), requestTime)
        return AsyncDataStore.createItemMap(plainItems, "FamilyGroup", plainToFamilyGroup, fg => fg.id!)
    }

    return {
        asyncFamilyGroups,
        asyncMemberKeys,
        asyncDataGroupMemberKeys,
        asyncInviteKeys,
        asyncDataGroupInviteKeys,
        asyncPermissions,

        getAsyncGroup,
        getAsyncGroupList,
        getGroupList,
        getGroupListAsync,
        getAsyncPermissions,
        addAsync,
        patchAsync,
        deleteAsync,
        addFamilyGroupsToStore,
    }
})
