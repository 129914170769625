<template>
  <div>
    <h1>Manage users</h1>
    <UserSelector @user-selected="onResultSelected"/>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { User } from '@/gp/GroupAdminModel'
import UserSelector from '@/manage/UserSelector.vue'

const router = useRouter()

function onResultSelected(userId: string) {
  router.push(User.getPageUrl(userId))
}

</script>
