import { Ref, onMounted, onUnmounted } from 'vue'
import { useAppInsights } from 'vue3-application-insights'


export function useTabsTelemetry(tabsRef: Ref<HTMLElement | undefined>) {
    const appInsights = useAppInsights()
    const onTabShown = (e: Event) => {
        appInsights.trackEvent({ 
            name: 'TabView', 
            properties: { tabId: (e.target as HTMLElement).id }
        })
    }        
    onMounted(() => {
        tabsRef.value?.addEventListener('show.bs.tab', onTabShown)
    })
    onUnmounted(() => {
        tabsRef.value?.removeEventListener('show.bs.tab', onTabShown)
    })
}
