export function some<T>(it: Iterable<T>, predicate?: (e: T) => boolean) {
    for (const e of it) {
        if (!predicate || predicate(e))
            return true 
    }
    return false
}

export function every<T>(it: Iterable<T>, predicate: (e: T) => boolean) {
    for (const e of it) {
        if (!predicate(e))
            return false
    }
    return true
}
