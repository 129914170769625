<template>
  <Modal ref="modalRef" ok-style="danger" @ok="removeSupervisor">
    <template #title>
      <template v-if="isSelfRef">Stop managing child</template>
      <template v-else>Remove parent account</template>
    </template>
    <template v-if="isSelfRef">
      <p>
        Are you sure you want to stop managing this child account?
      </p>
      <UserItem :user-id="userSupervisorRef?.forUserId" select-mode class="supervised"/>
      <template v-if="otherSupervisorsRef.length">
        <p class="help-text">
          The account will still be supervised by {{ pluralizeWithCount(otherSupervisorsRef.length, "other parent", "other parents") }}.
        </p>
        <!-- <ul class="list-unstyled supervisor-list">
          <li v-for="sup in otherSupervisorsRef" :key="sup.supervisorId">
            <UserItem :user-id="sup.supervisorId" select-mode/>
          </li>
        </ul> -->
      </template>
      <template v-else>
        <p class="help-text">
          You are the only parent managing this account. 
          If you stop managing it, the child will regain full control, 
          and you will not be able to manage it again without their consent.
        </p>
      </template>
    </template>
    <template v-else>
      <p>
        Are you sure you want to remove this parent?
      </p>
      <UserItem :user-id="userSupervisorRef?.supervisorId"/>
      <p class="help-text">
        The parent will no longer have any special access or control over the child account.
        This will not affect any parent relationship they may have in the family tree.
        You and any other parents accounts that have been added will continue to manage the child account.
      </p>
    </template>
    <template #okText>
      <template v-if="isSelfRef">Stop managing</template>
      <template v-else>Remove parent</template>
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
.user-item {
  margin: 1rem;
}
</style>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useUserSupervisorStore } from '@/gp/UserSupervisorStore'
import { TokenManager } from '@/auth/TokenManager'
import { LoadMode } from '@/util/AsyncData'
import { pluralize, pluralizeWithCount } from '@/util/LanguageUtil'
import Modal from '@/util/Modal.vue'
import UserItem from './UserItem.vue'

const userSupervisorIdRef = ref<string>()
const modalRef = ref<InstanceType<typeof Modal>>()

const userSupervisorStore = useUserSupervisorStore()

const userSupervisorRef = computed(() => userSupervisorStore.getAsyncUserSupervisor(userSupervisorIdRef.value, LoadMode.EnsureLoaded)?.data)
const isSelfRef = computed(() => userSupervisorRef.value?.supervisorId == TokenManager.userId)
const allUserSupervisorsRef = computed(() => userSupervisorStore.getForSupervisedUser(userSupervisorRef.value?.forUserId))
const otherSupervisorsRef = computed(() => allUserSupervisorsRef.value.filter(sup => sup.id != userSupervisorIdRef.value))

defineExpose({
  open,
})

function open(userSupervisorId: string) {
  userSupervisorIdRef.value = userSupervisorId
  modalRef.value?.open()
}

async function removeSupervisor() {
  if (userSupervisorIdRef.value) {
    await userSupervisorStore.deleteAsync(userSupervisorIdRef.value)
    modalRef.value?.close()
  }
}

</script>
