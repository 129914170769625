
interface YearRangePopulation {
    startYear: number
    endYear: number
    population: number
}

const placePopulations = new Map<string, YearRangePopulation[]>([
    ['Sugar City, Fremont, Idaho, United States', [
        { startYear: 1915, endYear: 1925, population: 683 }
    ]],
    ['Thatcher, Graham, Arizona, United States', [
        { startYear: 1915, endYear: 1925, population: 899 }
    ]],
])

export function getPlacePopulation(place: string, year: number | undefined) {
    if (year) {
        const pops = placePopulations.get(place)
        return pops?.find(p => p.startYear <= year && p.endYear > year)?.population
    }
}
