<template>
  <div ref="timelineContainerRef" class="timeline-container">
    <div class="timeline" :style="{ left: yearWidth(currentYear, maxViewYearRef) }">
      <div class="ticks">
        <div v-for="t in ticks" :key="t" class="tick" :isMajor="t % majorTickInterval == 0" :style="{ left: yearPosition(t) }">
          <div v-if="t % majorTickInterval == 0" class="major-tick-label">
            {{ t }}
          </div>
        </div>
      </div>
      <div class="events">
        <div v-for="e in eventsRef" :key="e.id" class="event" :style="{ left: yearPosition(e.year), width: yearWidth(e.startYear, e.year) }">
          <div :style="{ opacity: e.showIcon ? '100%': '0' }" class="event-icon"><img :src="getEventIcon(e)"/></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.timeline-container {
  position: relative;
  height: 2.5rem;
  overflow: hidden;
}

.timeline {
  position: absolute;
  width: calc(500 * 0.4rem); // view range is 500 years
  height: 1rem;
  transition: left 1.5s ease-in-out;

  .events {
    position: absolute;
    top: 0;
    width: 100%;
    height: 1rem;
  }

  .event {
    position: absolute;
    height: 100%;
    background-color: rgba(120,120,120,0.1);
    transition: left 1.0s ease-in-out, width 1.0s ease-in-out;

    .event-icon {
      position: absolute;
      top: 1rem;
      left: -0.75rem;
      display: flex;
      padding-top: 0.2rem;
      transition: opacity 0.5s ease-in-out;

      img {
        width: 1.2rem;
        opacity: 20%;
      }

      &.hide {
        opacity: 0;
      }
    }
  }

  .ticks {
    position: relative;
    height: 1rem;
    z-index: 1;
  }

  .tick {
    position: absolute;
    height: 0.5rem;
    border-left: 1px solid #ccc;
    padding-left: 0.1rem;

    &[isMajor="true"] {
      height: 100%;
    }
  }
  .major-tick-label {
    font-size: 0.7rem;
    color: #888;
  }
}
</style>

<script lang="ts" setup>
import { computed, ref, watch, onMounted } from 'vue'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { DateTime } from 'luxon'
import { AssertionType } from '@/rd/ResearchDataModel'
import { AssertionTypeIcons } from './AssertionTypeIcons'

const props = defineProps({
  personId: String,
})

const timelineContainerRef = ref<HTMLElement | null>(null)
const viewWidthInYears = ref(0)
const resizeObserver = new ResizeObserver(() => {
  if (timelineContainerRef.value) {
    const viewWidthInRem = timelineContainerRef.value.clientWidth / 16 // 1rem = 16px
    viewWidthInYears.value = viewWidthInRem / remPerYear
  }
})

const currentYear = DateTime.now().year
const timelineRange = 500 // years
const minorTickInterval = 10 // years
const majorTickInterval = 100 // years
const remPerYear = 0.2 // 4 rem / 10 years
const ticks: number[] = []
const firstTickYear = Math.floor(currentYear / minorTickInterval) * minorTickInterval;
for (let t = firstTickYear; t > currentYear - timelineRange; t -= minorTickInterval) {
  ticks.push(t)
}

const viewPersonStore = useViewPersonStore()
const viewPersonRef = computed(() => viewPersonStore.getAsyncPerson(props.personId)?.data)
const deathYearRef = computed(() => viewPersonRef.value?.displayProperties.deathSortDate?.date1.year)
const birthYearRef = computed(() => viewPersonRef.value?.displayProperties.birthSortDate?.date1.year)

const midYearRef = computed(() => {
  if (viewPersonRef.value?.displayProperties.living)
    return currentYear
  if (birthYearRef.value && deathYearRef.value)
    return (birthYearRef.value + deathYearRef.value) / 2
  if (birthYearRef.value)
    return birthYearRef.value + 40
  if (deathYearRef.value)
    return deathYearRef.value - 40
  return undefined
})
const maxViewYearRef = ref(currentYear)
watch (midYearRef, () => {
  if (midYearRef.value) {
    maxViewYearRef.value = Math.min(Math.floor(midYearRef.value + (viewWidthInYears.value / 2)), currentYear)
  }
})

interface TimelineEvent {
  id: string
  type: string
  year: number
  startYear: number
  showIcon: boolean
}

const eventsRef = computed(() => {
  const events: TimelineEvent[] = []
  if (birthYearRef.value && (deathYearRef.value || viewPersonRef.value?.displayProperties.living)) {
    events.push({
      id: 'lifespan',
      type: 'lifespan',
      year: deathYearRef.value ?? currentYear,
      startYear: birthYearRef.value,
      showIcon: false,
    })
  }
  if (birthYearRef.value) {
    events.push({
      id: 'birth',
      type: AssertionType.Birth,
      year: birthYearRef.value,
      startYear: birthYearRef.value,
      showIcon: true,
    })
  }
  if (deathYearRef.value) {
    events.push({
      id: 'death',
      type: AssertionType.Death,
      year: deathYearRef.value,
      startYear: deathYearRef.value,
      showIcon: true,
    })
  }
  else if (viewPersonRef.value?.displayProperties.living) {
    events.push({
      id: 'death',
      type: AssertionType.Death,
      year: currentYear,
      startYear: currentYear,
      showIcon: false,
    })
  }
  return events
})

function yearPosition(year: number) {
  const yearOffset = currentYear - year
  return `${yearOffset * remPerYear}rem`
}

function yearWidth(startYear: number, endYear: number) {
  const years = endYear - startYear
  return `${years * remPerYear}rem`
}

function getEventIcon(event: TimelineEvent) {
  return AssertionTypeIcons.getIcon(event.type)
}

onMounted(() => {
  if (timelineContainerRef.value) {
    resizeObserver.observe(timelineContainerRef.value)
  }
})
</script>
