<template>
  <span>
    <router-link v-if="userRef" :to="userRef.pageUrl" class="user-link" :class="{ 'dimmed': props.dimmed }">
      {{ displayNameRef }}
    </router-link>
  </span>
</template>

<style lang="scss" scoped>
.user-link {
  color: #333; // approx $gray-900

  &.dimmed {
    color: #888;

    &:hover {
      color: #333;
    }
  }
}
</style>

<script setup lang="ts">
import { computed } from 'vue'
import { useUserStore } from '@/gp/UserStore'

const props = defineProps({
  userId: String,
  dimmed: Boolean,
})

const userStore = useUserStore()

const userRef = computed(() => userStore.getAsyncUser(props.userId)?.data) // should be loaded by parent component
const displayNameRef = computed(() => userRef.value?.isSystemUser ? "System" : userRef.value?.displayName)

</script>
