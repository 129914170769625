import { Environment } from '@/util/Environment'

// this code is derived from the official docs
// https://developers.google.com/people/quickstart/js#set_up_the_sample
// https://developers.google.com/people/api/rest/v1/people

let gapiInited = false;
let googleInited = false;

const CLIENT_ID = Environment.get('GOOGLE_CLIENT_ID')
const API_KEY = Environment.get('GOOGLE_API_KEY')
let DISCOVERY_DOC: string
let SCOPES: string
let tokenClient: any;

export class GoogleIdentityApi {

    loadScripts(discoveryDoc: string, scopes: string) {
        SCOPES = scopes
        DISCOVERY_DOC = discoveryDoc

        // add google JS to document
        const apiScript = document.createElement('script');
        apiScript.src = 'https://apis.google.com/js/api.js';
        apiScript.onload = this.loadGoogleApiClient;
        document.head.appendChild(apiScript);

        const authScript = document.createElement('script');
        authScript.src = 'https://accounts.google.com/gsi/client';
        authScript.onload = this.loadGoogleAuthClient;
        document.head.appendChild(authScript);

        console.log("Google GSI and API scripts loaded into DOM")
    }

    signIn(){
        if (gapi.client.getToken() === null) {
            // trigger sign-in popup with consent screen
            tokenClient.requestAccessToken({prompt: 'consent'}); // select_account | consent
        }
        else {
            // Skip display of account chooser and consent dialog for an existing session.
            tokenClient.requestAccessToken({prompt: ''});
        }
    }

    signOut() {
        const token = gapi.client.getToken();
        if (token !== null) {
            google.accounts.oauth2.revoke(token.access_token);
            gapi.client.setToken('');
        }
    }

    async isSignedInAsync() {
        return (await gapi.client.getToken() !== null)
    }

    getGapiInited(){
        return gapiInited;
    }

    getGoogleInited(){
        return googleInited;
    }

    // called on page load once the api.js is loaded
    private loadGoogleApiClient = async() =>  {
        gapi.load('client', async () => {
            await this.initGapiClient()
          })
    }

    // Callback after the API client is loaded (loadGoogleApiClient). 
    // Loads the discovery doc to initialize the API client
    private initGapiClient = async() => {
        await gapi.client.init({
            apiKey: API_KEY,
            discoveryDocs: [DISCOVERY_DOC],
          });

          gapiInited = true;
      };

    // called on page load once the JS of /gsi/client is loaded
    private loadGoogleAuthClient = () => {
        tokenClient = google.accounts.oauth2.initTokenClient({
            client_id: CLIENT_ID,
            scope: SCOPES,
            callback: '', // defined later
        });

        googleInited = true;
    }
}