<template>
  <div class="family-group-role-option">
    <div class="form-check">
      <input id="canInviteOption" type="checkbox"
        :checked="props.canInvite"
        :disabled="props.disabled"
        class="form-check-input"
        @change="onCanInviteChanged">
      <label for="canInviteOption" class="form-check-label">
        Can invite other people
      </label>
      <InfoButton @click="showInfoRef = !showInfoRef"/>
    </div>
    <div v-if="showInfoRef" class="help-text option-info" :class="{ 'help-text-sm': props.small }">
      This includes inviting people whose profiles the group owner has already shared with the group.
    </div>
  </div>
</template>

<style lang="scss" scoped>
input[type="checkbox"] {
  margin-right: 0.25rem;
}

.recruiter-icon {
  margin-top: -5px;
  margin-left: 0.15rem;
  width: 1.1rem;
  opacity: 40%;
}

.option-info {
  margin-left: 1.5rem;
}
</style>

<script lang="ts" setup>
import { ref } from 'vue'
import InfoButton from '@/util/InfoButton.vue'

const props = defineProps<{
  canInvite: boolean
  disabled?: boolean
  small?: boolean
}>();

const showInfoRef = ref(false)

const emit = defineEmits([
  'update:canInvite'
])

function onCanInviteChanged(e: Event) {
  const inputElem = e.target as HTMLInputElement
  emit('update:canInvite', inputElem.checked)
}
</script>
