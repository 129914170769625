import { watch, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import { Tab } from 'bootstrap'

export function useInitialTabRouting(defaultTab: string) {
  const route = useRoute()

  function switchTab(tabName: string) {
    const tabElem = document.getElementById(`${tabName}Tab`)
    if (tabElem) {
      new Tab(tabElem).show()
    }
  }

  watch(route, async (newRoute) => {
    const initialTab = (newRoute.meta.initialTab as string | undefined) ?? defaultTab
    await nextTick()
    switchTab(initialTab)
  }, { immediate: true })
}