<template>
    <div class="doc-page">
      <h1 class="with-subhead">Frequently Asked Questions</h1>
      <p class="subhead">Updated Feb 9, 2024</p>

      <h4 id="what-is-tree-crossing">What is Tree Crossing?</h4>
    <p>We help genealogists share their research with family members, offering them a fun and personalized way to learn about their family history.
    You can read more about our company on our <router-link :to="{ name: 'AboutPage' }">About page</router-link>.</p>

    <h4 id="what-is-sync-app">What is the Sync app?</h4>
    <p>The Tree Crossing Sync app lets genealogists easily publish their data from their favorite genealogy program. When any new changes are made in their genealogy program, it can
        automatically sync the changes to treecrossing.com. The sync app never makes changes to the tree data file on the computer.</p>
   <p>Supported platforms and data we sync is available on the <router-link :to="{ name: 'sync' }">sync page</router-link>.</p>

    <h4 id="what-is-explore">What app do family members use?</h4>
    <p>Family members simply visit TreeCrossing.com after getting an invitation from a tree owner. Mobile devices are supported, however, your family will find that 
        the best way to have a discovery-focused experience is on a larger screen, such as a tablet or computer.</p> 

            
    <h4 id="free-trial">Do family members need a paid subscription to access my tree(s)?</h4>
    <p>No. Family members that you invite do not need to pay, and are free to explore your trees.</p>

    <h4 id="will-my-tree-be-public">Will my tree data be visible to the public?</h4>
    <p>No. Only you can see it by default, and you decide who else you allow to view your tree. We may also offer you the option to share limited portions of your data to 
        the public and to search engines, which is commonly referred to as "<a href="#cousin-bait">cousin bait</a>". If you are interested in this, please <router-link :to="{ name: 'support', hash: '#contact' }">let us know</router-link>!</p>

    <h4 id="how-different">How is Tree Crossing different from other online tree services?</h4>
    <p>Other services provide features best suited for genealogy enthusiasts and experts, whereas we provide experts with features better suited for their family members. 
    </p>

    <h4 id="research-tool">Is Tree Crossing a research tool?</h4>
    <p>No. You build it, we make it shareable in the best possible way for your family.</p>
    <p>
        We are not in the online records business or the research and tree-building business.
        Other <router-link :to="{ name: 'research' }">existing software and services</router-link> do a great job in those areas. We help you do something amazing with your research <em>after</em> you have family data you want to share.
    </p>

    <h4 id="data-limits">Is there a limit to the number of trees or names in a tree?</h4>
    <p>There is currently a limit of 5 trees you can upload.</p>
    <p>There is no limit of names per tree. You may, however, want to split your trees into multiple family lines using your desktop software before uploading, so that you can manage the data differently, while still having it all appear as one tree for your family members.</p>
        <p>If these limitations seem small or makes things difficult, please <router-link :to="{ name: 'support', hash: '#contact'  }">let us know</router-link>. We are always looking at ways to improve the service.</p>

  <h4 id="build-tree">I don't have tree data. Can I create my tree using Tree Crossing?</h4>
    <p>Tree Crossing is designed for trees that have already been built using popular <router-link :to="{ name: 'research' }">tree-building software</router-link>. 
        We do not offer a tree-building tool. If you are just getting started building your tree, please consider using compatible software, 
        and then come visit us as soon as you are ready with some of your own research to share!</p>
        
        <p>If you are not a genealogical researcher building a tree, we recommend reaching out to a family member that is. Have them join Tree Crossing, and share their tree with you!</p>

  <h4 id="tree-copy">Can I use a family tree I found online?</h4>
    <p>Ideal candidates for Tree Crossing are genealogists that have unique, high-quality data that they themselves have spent time curating. 
        Such data is typically managed in a tree-building software. If that sounds like you, great!  
        If not, you might have a family member that has a tree relevant to you. Consider asking them to host it with Tree Crossing to share it with you.</p>

    <h4 id="compatibility">What software is compatible with Tree Crossing?</h4>
    <p>
        Our explore experience (tree browsing) is web-based, so it works everywhere.  Please see our <router-link :to="{ name: 'sync' }">sync app page</router-link> for full details on compatibility for desktop computers and family tree software.
    </p>
  
    <h4 id="fs-compatibility">Is Tree Crossing compatible with FamilySearch?</h4>
    <p>
        Our sync app is compatible with desktop apps that already integrate with FamilySearch. This allows you to sync to both FamilySearch and Tree Crossing as desired!
    </p>

    <!-- <h4 id="service-end">What happens to my data if Tree Crossing ends services?</h4>
    <p>
        Nothing. We don't hold the "master copy" of your data. You do.  That is the beauty of this service.  It adds value to the great tools you already use, but it does not replace them, or make you give up ownership and control. 
        Your data remains with you, safely on your computer or other trusted online service provider.
    </p> -->

    <h4 id="cousin-bait">What is "cousin bait"?</h4>
    <p>
        "Cousin bait" is a popular term in the industry that refers to putting a family tree online, publicly visible, possibly hosted with multiple sites and services, in hopes that a relative (distant cousin) might discover and reach out to you. 
        The idea is to try and connect with others that might have more information about your family lines.
    </p>

    <h4 id="free-trial">What are the limitations of the free trial?</h4>
    <p>You can get started for free and upload one tree.  Once you are ready to start a free trial, you will have the ability to create family groups, invite family members, upload additional trees, and add high resolution photos (in a future release).</p>

    
    </div>
</template>
<style lang="scss" scoped>
    h4 {
        margin-top: 2rem;
        color: rgb(214, 96, 33);
    }
    p {
        max-width: 900px;
    }
</style>

  