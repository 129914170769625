import { useDataGroupMemberStore } from "@/gp/DataGroupMemberStore"
import { useDataGroupStore } from "@/gp/DataGroupStore"
import { DataGroup, DataGroupType, ItemPermissions } from "@/gp/GroupAdminModel"
import { CompositeId } from "@/rd/CompositeId"
import { useFamilyStore } from "@/rd/FamilyStore"
import { usePersonStore } from "@/rd/PersonStore"
import { ItemType, Person, TrackingLink, ViewPerson, Workspace } from "@/rd/ResearchDataModel"
import { useTrackingLinkStore } from "@/rd/TrackingLinkStore"
import { useViewFamilyStore } from "@/rd/ViewFamilyStore"
import { useViewPersonStore } from "@/rd/ViewPersonStore"
import { useWorkspaceStore } from "@/rd/WorkspaceStore"
import { AsyncData, LoadMode } from "@/util/AsyncData"
import { isDefined } from "@/util/TypeScriptUtil"
import { ProfileUtils } from "./ProfileUtils"
import { VersionedKeyList } from "@/util/AsyncDataStore"

export interface ProfilePageData {
    asyncPerson?: AsyncData<Person>
    asyncViewPerson?: AsyncData<ViewPerson>
    asyncDataGroup?: AsyncData<DataGroup>
    asyncDataGroupMemberKeys?: AsyncData<VersionedKeyList>
    asyncPermissions?: AsyncData<ItemPermissions>
    asyncWorkspace?: AsyncData<Workspace>
    asyncTrackedItemLink?: AsyncData<TrackingLink>
    trackingItemId?: string
}

export async function loadProfilePageDataAsync(personId: string): Promise<ProfilePageData> {
    if (!personId)
        return {}

    const personStore = usePersonStore()
    const viewPersonStore = useViewPersonStore()
    const dataGroupStore = useDataGroupStore()
    const workspaceStore = useWorkspaceStore()
    const dataGroupMemberStore = useDataGroupMemberStore()
    
    const groupId = CompositeId.getGroupId(personId)
    const asyncPerson = personStore.getAsyncPerson(personId, LoadMode.EnsureLoaded)
    const asyncViewPerson = viewPersonStore.getAsyncPerson(personId, LoadMode.EnsureLoaded)
    const asyncDataGroup = dataGroupStore.getAsyncGroup(groupId, LoadMode.EnsureLoaded)
    const asyncDataGroupMemberKeys = dataGroupMemberStore.getAsyncKeyListsForDataGroups([groupId], LoadMode.EnsureLoaded).at(0)
    const asyncPermissions = dataGroupStore.getAsyncPermissions(groupId, LoadMode.EnsureLoaded)
    const asyncWorkspace = workspaceStore.getAsyncWorkspacesForGroups([groupId], LoadMode.EnsureLoaded).at(0)
    await Promise.all([
        asyncPerson?.whenLoadCompleted, 
        asyncViewPerson?.whenLoadCompleted,
        asyncDataGroup?.whenLoadCompleted, 
        asyncDataGroupMemberKeys?.whenLoadCompleted,
        asyncPermissions?.whenLoadCompleted,
        asyncWorkspace?.whenLoadCompleted
    ])

    const asyncTrackedItemLink = getAsyncTrackedItemLink(personId, asyncDataGroup?.data?.workspaceId)
    const getTrackingItemId = getTrackingItemIdAsync(personId, asyncDataGroup?.data?.groupType)

    const viewPersonId = asyncViewPerson?.data?.id
    const loadRelationships = viewPersonId ? loadRelationshipsAsync(viewPersonId) : undefined
    const loadDetails = viewPersonId ? viewPersonStore.ensureDetailsLoadedAsync(viewPersonId) : undefined

    await Promise.all([
        asyncTrackedItemLink?.whenLoadCompleted,
        getTrackingItemId,
        loadRelationships,
        loadDetails,
    ])

    return {
        asyncPerson,
        asyncViewPerson,
        asyncDataGroup,
        asyncDataGroupMemberKeys,
        asyncPermissions,
        asyncWorkspace,
        asyncTrackedItemLink,
        trackingItemId: await getTrackingItemId,
    }
}

function getAsyncTrackedItemLink(personId: string, workspaceId?: string) {
    if (!workspaceId)
        return undefined

    const trackingLinkStore = useTrackingLinkStore()
    return trackingLinkStore.getAsyncTrackingLink(ItemType.Person, personId, LoadMode.EnsureLoaded)
}

async function getTrackingItemIdAsync(personId: string, groupType?: DataGroupType) {
    if (groupType != DataGroupType.Research)
        return undefined

    const trackingLinkStore = useTrackingLinkStore()
    const dataGroupStore = useDataGroupStore()

    const trackingLinks = await trackingLinkStore.getTrackingLinksForTrackedItemAsync(ItemType.Person, personId)
    const profileIds = trackingLinks.map(t => t.itemId!)
    const profileGroups = await dataGroupStore.getGroupListAsync(profileIds.map(CompositeId.getGroupId))
    const primaryIds = ProfileUtils.getNonPlaceholderIds(profileIds, profileGroups)
    if (primaryIds.length > 1) {
        console.warn(`Multiple primary profiles linked to ${personId}: ${primaryIds.join(', ')}`)
    }
    return primaryIds.at(0)
}

async function loadRelationshipsAsync(viewPersonId?: string) {
    if (!viewPersonId)
        return

    const viewPersonStore = useViewPersonStore()
    const viewFamilyStore = useViewFamilyStore()
    const familyStore = useFamilyStore()
    const personStore = usePersonStore()
    const dataGroupStore = useDataGroupStore()

    // HACK: use view person id to load relationships so they have the right cache key (should be fixed in the store)
    const getSpousesAndChildren = viewPersonStore.ensureDescendantsLoadedAsync(viewPersonId, 1)
    const getParentsAndSiblings = viewPersonStore.ensureAncestorsLoadedAsync(viewPersonId, 1, 1)
    await Promise.all([getSpousesAndChildren, getParentsAndSiblings])

    // load group info for all spouse families, parent families, children, and siblings
    const spouseViewFamilies = viewFamilyStore.getLoadedSpouseFamilies(viewPersonId).spouseFamilies
    const parentViewFamilies = viewPersonStore.getLoadedParentFamilies(viewPersonId).parentFamilies
    const allFamilyIds = [...spouseViewFamilies, ...parentViewFamilies].flatMap(f => f.matchIds)
    const getFamilies = familyStore.getFamilyListAsync(allFamilyIds)

    // HACK: getLoadedDescendants doesn't return the start person's loaded spouse(s), so get manually
    const spouseViewPersons = spouseViewFamilies.map(sf => sf.spouseOf(viewPersonId))
        .map(id => viewPersonStore.getLoadedPerson(id))
        .filter(isDefined)
    const descendantViewPersons = [...spouseViewPersons, ...viewPersonStore.getLoadedDescendants(viewPersonId, 1)]
    const ancestorViewPersons = [...viewPersonStore.getLoadedAncestors(viewPersonId, 1, 1)]
    const allPersonIds = [...descendantViewPersons, ...ancestorViewPersons].flatMap(vp => vp.matchIds)
    const getPersons = personStore.getPersonListAsync(allPersonIds)

    const allGroupIds = [...new Set([...allFamilyIds, ...allPersonIds].map(id => CompositeId.getGroupId(id)!))]
    const getDataGroups = dataGroupStore.getGroupListAsync(allGroupIds)

    await Promise.all([getFamilies, getPersons, getDataGroups])
}
