<template>
  <Modal ref="modalRef" :ok-disabled="!canShareRef" @ok="onShare">
    <template #title>
      <template v-if="props.profile">Share profile</template>
      <template v-else>Share your tree</template>
    </template>
    <div class="subtitle">
      <template v-if="props.profile">
        Choose a group to share this profile with.
        If the group is managed by someone else, we'll help you create a sharing request.
      </template>
      <template v-else>
        Choose a group to share this tree with.
        If the group is managed by someone else, we'll help you create a sharing request.
      </template>
    </div>
    <div class="family-group-list">
      <div v-if="familyGroupsRef.length == 0" class="alert alert-primary">
        No groups are available to share with.
      </div>
      <div v-else-if="availableFamilyGroupsRef.length == 0" class="alert alert-primary">
        <template v-if="props.profile">
          This profile has already been shared with, or has a pending sharing request for, all groups visible to you.
        </template>
        <template v-else>
          This tree has already been shared with, or has a pending sharing request for, all groups visible to you.
        </template>
      </div>
      <ul class="list-unstyled">
        <li v-for="fg in availableFamilyGroupsRef" :key="fg.id" :class="{ 'selected-item': fg == selectedFamilyGroupRef }">
          <FamilyGroupItem 
            :family-group-id="fg.id" 
            select-mode 
            @click="onSelected(fg)">
          </FamilyGroupItem>
        </li>
      </ul>
    </div>
    <template #okText>{{ shareTextRef }}</template>
    <template #busyText>Sharing...</template>
  </Modal>
</template>

<style lang="scss" scoped>
.subtitle {
  margin-bottom: 1rem;
  font-size: 0.875rem;
  color: #888;
}

.family-group-list {
  min-height: 150px;

  ul {
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  .alert {
    font-size: 0.875rem;
  }

  li {
    padding: 0 4px 4px;

    &.selected-item {
      border-radius: 6px;
      box-shadow: 0 0 0 4px var(--bs-primary);
    }
  }
}
</style>

<script setup lang="ts">
import { computed, ref } from "vue"
import { useDataGroupStore } from "@/gp/DataGroupStore"
import { useDataGroupMemberStore } from '@/gp/DataGroupMemberStore'
import { useFamilyGroupStore } from "@/gp/FamilyGroupStore"
import { useFamilyGroupMemberStore } from "@/gp/FamilyGroupMemberStore"
import { TokenManager } from "@/auth/TokenManager"
import { DataGroupMember, FamilyGroup, PrincipalType } from "@/gp/GroupAdminModel"
import { hideModalAsync } from "@/util/AppUtil"
import { LoadMode } from "@/util/AsyncData"
import { isDefined } from "@/util/TypeScriptUtil"
import Modal from '@/util/Modal.vue'
import FamilyGroupItem from "./FamilyGroupItem.vue"

const props = defineProps({
  profile: Boolean
})

const emit = defineEmits([
  'requestShare'
])

defineExpose({
  open
})

const dataGroupIdRef = ref<string>()
const excludeIdsRef = ref<string[]>([])
const selectedFamilyGroupRef = ref<FamilyGroup>()

const modalRef = ref<InstanceType<typeof Modal>>()

const dataGroupStore = useDataGroupStore()
const dataGroupMemberStore = useDataGroupMemberStore()
const familyGroupStore = useFamilyGroupStore()
const familyGroupMemberStore = useFamilyGroupMemberStore()

const dataGroupRef = computed(() => dataGroupStore.getAsyncGroup(dataGroupIdRef.value, LoadMode.EnsureLoaded)?.data)
const familyGroupsRef = computed(() => {
  const members = familyGroupMemberStore.getMembersForUser(undefined, LoadMode.EnsureLoaded)
  const familyGroupIds = members.map(fm => fm.familyGroupId!)
  return familyGroupStore.getAsyncGroupList(familyGroupIds, LoadMode.EnsureLoaded).map(a => a.data).filter(isDefined)
})
const availableFamilyGroupsRef = computed(() => familyGroupsRef.value.filter(fg => !excludeIdsRef.value.includes(fg.id!)))
const canShareRef = computed(() => !!dataGroupRef.value && !!selectedFamilyGroupRef.value)
const isFamilyGroupOwnedRef = computed(() => selectedFamilyGroupRef.value?.ownerId == TokenManager.userId)
const shareTextRef = computed(() => !selectedFamilyGroupRef.value || isFamilyGroupOwnedRef.value ? "Share" : "Request share")

function open(dataGroupId: string, excludeFamilyGroupIds?: string[]) {
  dataGroupIdRef.value = dataGroupId
  excludeIdsRef.value = excludeFamilyGroupIds ?? []
  selectedFamilyGroupRef.value = undefined
  modalRef.value?.open()
}

function onSelected(fg: FamilyGroup) {
  if (fg == selectedFamilyGroupRef.value) {
    selectedFamilyGroupRef.value = undefined // unselect
  } else {
    selectedFamilyGroupRef.value = fg
  }
}

async function onShare() {
  if (selectedFamilyGroupRef.value!.ownerId == TokenManager.userId) {
    // share now
    const member = new DataGroupMember()
    member.dataGroupId = dataGroupIdRef.value
    member.principalType = PrincipalType.FamilyGroup
    member.principalId = selectedFamilyGroupRef.value?.id
    await dataGroupMemberStore.addAsync(dataGroupIdRef.value!, member)
    modalRef.value?.close()
  }
  else {
    await hideModalAsync(modalRef.value!.$el)
    emit('requestShare', selectedFamilyGroupRef.value!.id)
  }
}
</script>