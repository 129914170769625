import { NewsItem } from "./NewsItem"
import { User } from "@/gp/GroupAdminModel"
import { useUserStore } from '@/gp/UserStore'
import { Sync, Workspace } from "@/rd/ResearchDataModel"
import { useSyncStore } from "@/rd/SyncStore"
import { useWorkspaceStore } from "@/rd/WorkspaceStore"
import { isDefined } from "@/util/TypeScriptUtil"
import _ from "lodash"


export function useResearchDataNews() {
   
    async function getNewsAsync() {
        return (await Promise.all([
            getSyncNewsAsync(), 
        ])).flat()
    }

    async function getSyncNewsAsync() {
        const workspaceStore = useWorkspaceStore()
        const syncStore = useSyncStore()
        const userStore = useUserStore()

        const workspaces = await workspaceStore.getAllWorkspacesAsync()
        const lastSyncs = syncStore.getAsyncSyncList(workspaces.map(w => w.lastSyncId)).map(a => a.data).filter(isDefined)

        const syncUserIds = _.uniq(lastSyncs.map(s => s.createdUserId))
        const syncUsers = await userStore.getUserListAsync(syncUserIds)
        return lastSyncs.map(s => syncToNewsItem(s, 
                workspaces.find(w => w.id == s.workspaceId),
                syncUsers.find(u => u.id == s.createdUserId)))
            .filter(isDefined)
    }

    function syncToNewsItem(sync: Sync, workspace: Workspace | undefined, syncUser: User | undefined) {
        if (!workspace || !syncUser)
            return undefined

        // TODO: Find a better way to determine if this is the first sync
        const isFirstSync = sync.createdDate!.diff(workspace.modifiedDate!).as('minutes') < 10
        const displayText = isFirstSync
            ? `${syncUser?.displayName} uploaded a tree`
            : `${syncUser?.displayName} synced a tree`

        return {
            type: isFirstSync ? 'FirstSync' : 'Sync',
            timestamp: sync.createdDate!,
            displayText,
            url: workspace.pageUrl
        } as NewsItem
    }

    return {
        getNewsAsync
    }
}
