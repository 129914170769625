<template>
  <div class="compact-item">
    <component :is="elementTypeRef" :href="pageUrlRef" @click="onSelect" :class="classRef" class="item-button">
      <div class="item-icon">
        <slot name="icon"></slot>
      </div>
      <div class="item-body">
        <div class="item-title no-overflow">
          <slot name="title"></slot>
        </div>
        <div class="item-subtitle no-overflow">
          <slot name="subtitle"></slot>
        </div>
      </div>
    </component>
  </div>
</template>

<style lang="scss">
.compact-item {
  min-height: 45px;
  padding: 0.25rem 0;

  .item-button {
    display: grid;
    column-gap: 0.5rem;
    grid-template-columns: auto 1fr;
    align-items: center;
  }

  .item-icon {
    padding: 2px;

    img {
      width: 31px;
      height: 31px;
      object-fit: contain;
    }
  }

  .item-body {
    display: flex;
    flex-direction: column;    
    min-width: 0; // allow content to shrink (will trigger ellipsis)

    .item-subtitle {
      font-size: 0.75rem;
      color: #888;
    }
  }
}
</style>

<script lang="ts" setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

const props = defineProps({
  pageUrl: String,
  selectMode: Boolean,
})

const emit = defineEmits([
  'click',
])

const router = useRouter()

const elementTypeRef = computed(() => props.selectMode ? 'button' : 'a')
const pageUrlRef = computed(() => props.selectMode ? undefined : props.pageUrl)
const classRef = computed(() => props.selectMode ? 'btn btn-inline' : 'link-dark')

function onSelect(e: Event) {
  e.preventDefault()
  if (props.selectMode) {
    emit('click')
  }
  else if (props.pageUrl) {
    router.push(props.pageUrl)
  }
}

</script>
