<template>
    <div class="doc-page">
      <h1>Research</h1>
  
      <p>Here are apps and services that are great for family history research and buiding family trees.</p>
  
      <h4>Desktop apps</h4>
      
      <ul>
        <li><a href="https://www.rootsmagic.com/" target="_blank">RootsMagic</a><img src="@/assets/tree-crossing-icon-128.png" class="app-support"></li>
        <li><a href="https://legacyfamilytree.com/" target="_blank">Legacy Family Tree</a><img src="@/assets/tree-crossing-icon-128.png" class="app-support"></li>
        <li><a href="https://www.mackiev.com/ftm/" target="_blank">Family Tree Maker</a></li>

        <li><a href="https://www.syniumsoftware.com/macfamilytree/" target="_blank">MacFamilyTree</a></li>


      </ul>

      <div class="legend">
        <img src="@/assets/tree-crossing-icon-128.png"> = currently supported by Tree Crossing
      </div>

      <h4>Online records and DNA</h4>
      <ul>
        <li><a href="https://www.americanancestors.org/" target="_blank">American Ancestors</a></li>
        <li><a href="https://www.ancestry.com/" target="_blank">Ancestry</a></li>
        <li><a href="https://www.familysearch.org/" target="_blank">FamilySearch</a></li>
        <li><a href="https://www.findmypast.com/" target="_blank">Findmypast</a></li>
        <li><a href="https://www.myheritage.com/" target="_blank">MyHeritage</a></li>
      </ul>
    </div>
  </template>
  <style lang="scss" scoped>
  h4 {
    margin-top: 2rem;
    color: rgb(214, 96, 33);
  }
  p {
    max-width: 900px;
  }
  
  .app-support {
    width: 25px;
    margin-left: .4rem;
  }
  .legend img, .app-support .cell img {
    width: 25px;
   
  }

  .legend {
    margin-top: 2rem;     
    text-align: start;
    font-size: 0.875rem;
    color: #888;
  }

  </style>
  <script setup lang="ts">

  </script>