import { reactive } from 'vue'
import { ViewFamily, ViewPerson } from "./ResearchDataModel"
import { useAssertionStore } from './AssertionStore'
import { rd } from './ResearchDataApi'
import { AsyncData, DataError, LoadMode } from "@webapp/util/AsyncData"
import { AsyncDataStore, VersionedKeyList, VersionedPartialKeyList } from "@webapp/util/AsyncDataStore"
import { defineStore } from "pinia"
import { DateTime } from "luxon"
import { assignExisting, isDefined } from "@webapp/util/TypeScriptUtil"
import { ViewGraph } from './ViewGraph'
import { plainToFamilyDisplayProperties, useFamilyStore } from './FamilyStore'
import { useViewPersonStore, viewGraphExpand } from './ViewPersonStore'
import { flatten, uniq } from 'lodash'
import { ApiError } from '@webapp/util/Api'

const viewFamilyExpand = "displayProperties,mother/displayProperties,father/displayProperties"

export const useViewFamilyStore = defineStore("viewFamilyStore", () => {
    const asyncViewFamilies = reactive(new Map<string, AsyncData<ViewFamily>>())
    // NOTE: In a complete child key list the order of keys in the list represents the view child order (not just a set!)
    const asyncChildKeys = reactive(new Map<string, AsyncData<VersionedPartialKeyList>>())

    const familyStore = useFamilyStore()
    const viewPersonStore = useViewPersonStore()
    const assertionStore = useAssertionStore()

    function getAsyncFamily(id: string | undefined, loadMode = LoadMode.TrackOnly) {
        return id ? getAsyncFamilyList([id], loadMode)[0] : undefined
    }

    function getAsyncFamilyList(ids: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const loadAsync = async (idsToLoad: string[], requestTime: DateTime) => {
            const plainItems = await rd.getPlainByIdsAsync("viewfamilies", idsToLoad, viewFamilyExpand)
            return processExpandedViewFamilies(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncItemsByIds(asyncViewFamilies, ids, "ViewFamily", loadMode, loadAsync)
    }

    async function getFamilyAsync(id: string | undefined) {
        return await getAsyncFamily(id, LoadMode.EnsureLoaded)?.whenLoadCompleted
    }

    async function searchByNameAsync(text: string) {
        const requestTime = DateTime.utc()
        const expand = rd.injectExpandItems(viewFamilyExpand)
        const plainItems = await rd.getPlainAsync("viewfamilies", { q: `spouse:"${text}"`, expand }) as any[]
        addViewFamiliesToStore(plainItems, requestTime)

        // return reactive async proxies from store map
        return plainItems.map(p => asyncViewFamilies.get(p.id!)!)
    }

    function areAssertionsLoaded(familyId: string) {
        const vf = asyncViewFamilies.get(familyId)
        if (!vf || !vf.loadComplete)
            return false

        // check matches loaded
        const matchIds = vf.data?.matchIds ?? []
        if (!AsyncDataStore.areItemsLoaded(familyStore.asyncFamilies, matchIds))
            return false

        // check assertion key lists loaded
        const asyncAssertionKeyLists = AsyncDataStore.getAsyncItemsIfAllLoaded(familyStore.asyncAssertionKeys, matchIds)
        if (!asyncAssertionKeyLists)
            return false

        // check assertions loaded
        const assertionIds = asyncAssertionKeyLists.flatMap(akl => akl.data?.keys ?? [])
        const assertionStore = useAssertionStore()
        return assertionStore.areExpandedAssertionsLoaded(assertionIds)
    }

    async function ensureAssertionsLoadedAsync(familyId: string) {
        if (areAssertionsLoaded(familyId)) {
            console.log(`Assertions for ${familyId} already loaded`)
            return
        }
        const expand = "assertions/place,assertions/site"
        const requestTime = DateTime.utc()
        const plainViewFamilies = await rd.getPlainByIdsAsync("viewfamilies", [familyId], expand)

        // NOTE: don't process expanded view families because we didn't request the usual expanded items

        // just grab the assertions
        processViewFamilyAssertions(plainViewFamilies, requestTime)
    }

    function hasLoadedChildren(familyId: string) {
        const childIds = asyncChildKeys.get(familyId)?.data?.keys ?? []
        return childIds.some(id => viewPersonStore.asyncViewPersons.get(id)?.data)
    }

    function *getLoadedDescendants(startFamilyId: string, generations: number): Iterable<ViewPerson> {
        if (generations > 0) {
            const children = getLoadedChildren(startFamilyId).children
            for (const child of children) {
                yield child

                if (generations > 1) {
                    const spouseFams = getLoadedSpouseFamilies(child.id!).spouseFamilies
                    for (const spouseFam of spouseFams) {
                        const spouseId = spouseFam.spouseOf(child.id!)
                        const spouse = viewPersonStore.getAsyncPerson(spouseId)?.data
                        if (spouse) {
                            yield spouse
                        }
                        for (const descendant of getLoadedDescendants(spouseFam.id!, generations - 1)) {
                            yield descendant
                        }
                    }
                }
            }
        }
    }

    function areDescendantsLoaded(startFamilyId: string, generations: number): boolean {
        const asyncViewFamily = asyncViewFamilies.get(startFamilyId)
        if (!asyncViewFamily || asyncViewFamily.shouldLoad()) {
            return false
        }

        if (generations > 0) {
            const asyncChildKeyList = asyncChildKeys.get(startFamilyId)
            if (!asyncChildKeyList || asyncChildKeyList.shouldLoad()) {
                return false
            }
            if (!asyncChildKeyList.data) {
                return true // can't load
            }

            const childIds = asyncChildKeyList.data.keys
            if (!childIds.every(id => viewPersonStore.areDescendantsLoaded(id, generations - 1))) {
                return false
            }
        }
        return true // nothing more to load
    }

    async function ensureDescendantsLoadedAsync(familyId: string | undefined, generations: number, reload = false) {
        if (!familyId || (!reload && areDescendantsLoaded(familyId, generations)))
            return

        try {
            const requestTime = DateTime.utc()
            const path = `viewfamilies/${familyId}/descendants`
            const graph = <ViewGraph> await rd.getPlainAsync(path, { generations, expand: viewGraphExpand })
            viewPersonStore.addViewGraphToStores(graph, requestTime, reload)
            return true
        }
        catch (err) {
            if (err instanceof ApiError && (err.statusCode == DataError.NotFound || err.statusCode == DataError.Forbidden)) {
                return false
            }
            throw err
        }
    }

    /**
     * Enumerates the loaded ancestor families of the specified person. The result MAY include duplicates.
     */
    function *getLoadedAncestorFamilies(startPersonId: string, generations: number): Iterable<AncestorFamily> {
        // TODO: remember visited families to avoid traversing cycles ("my own grandpa") and diamonds ("my own cousin")
        if (generations > 0) {
            const parentFams = viewPersonStore.getLoadedParentFamilies(startPersonId).parentFamilies
            for (const parentFam of parentFams) {
                yield { family: parentFam, directChildId: startPersonId }
            }
            const parentIds = uniq(parentFams.flatMap(pf => pf.spouseIds))
            for (const parentId of parentIds) {
                for (const ancestorFam of getLoadedAncestorFamilies(parentId, generations - 1)) {
                    yield ancestorFam
                }
            }
        }
    }

    /**
     * Gets all loaded spouse families of the specified person.
     */
    function getLoadedSpouseFamilies(personId: string) {
        const spouseFamilyKeyList = viewPersonStore.asyncSpouseFamilyKeys.get(personId)?.data
        if (!spouseFamilyKeyList) {
            return { spouseFamilies: [], isComplete: false } // spouse family ids not loaded
        }
        const spouseFamilies = spouseFamilyKeyList.keys.map(id => asyncViewFamilies.get(id)?.data)
        const loadedSpouseFamilies = spouseFamilies.filter(isDefined)
        const isComplete =  spouseFamilyKeyList.isComplete && spouseFamilies.every(isDefined)

        return { spouseFamilies: loadedSpouseFamilies, isComplete }
    }

    /**
     * Gets the ids of all spouses in the loaded spouse families of the specified person.
     */
    function getLoadedSpouseIds(personId: string) {
        const loadedSpouseFamilies = getLoadedSpouseFamilies(personId)
        const spouseIds = loadedSpouseFamilies.spouseFamilies.map(f => f.spouseOf(personId)).filter(isDefined)

        return { 
            spouseIds: [...new Set(spouseIds)], 
            isComplete: loadedSpouseFamilies.isComplete
        }
    }

    /**
     * Gets all loaded children of the specified family.
     */
    function getLoadedChildren(familyId: string) {
        const childKeyList = asyncChildKeys.get(familyId)?.data
        if (!childKeyList) {
            return { children: [], isComplete: false } // child ids not loaded
        }
        const children = childKeyList.keys.map(id => viewPersonStore.asyncViewPersons.get(id)?.data)
        const loadedChildren = children.filter(isDefined)
        const isComplete = childKeyList.isComplete && children.every(isDefined)

        return { children: loadedChildren, isComplete }
    }

    function clearCacheFor(familyId: string | undefined) {
        // extra work to do here because we add an entry to the store for each match, plus the family itself may be cached
        asyncViewFamilies.get(familyId ?? '')?.data?.matchIds.forEach(id => {
            AsyncData.invalidate(asyncViewFamilies.get(id))
            AsyncData.invalidate(familyStore.asyncFamilies.get(id))
        })
    }

    function addViewFamiliesToStore(plainViewFamilies: any[], requestTime: DateTime) {
        const matchMap = processExpandedViewFamilies(plainViewFamilies, requestTime)
        AsyncDataStore.addItemMapToStore(asyncViewFamilies, "ViewFamily", matchMap, requestTime)
    }

    function processExpandedViewFamilies(plainItems: any[], requestTime: DateTime) {
        familyStore.processExpandedFamilyDisplayProperties(plainItems.map(p => p.displayProperties), requestTime)
        viewPersonStore.addViewPersonsToStore(plainItems.map(p => p.father).filter(isDefined), requestTime)
        viewPersonStore.addViewPersonsToStore(plainItems.map(p => p.mother).filter(isDefined), requestTime)

        const viewFamilies = plainItems.map(plainToViewFamily)

        // add an entry for EVERY match to the store (all returning the same view family)
        return new Map(flatten(viewFamilies.map(vf => vf.matchIds.map(id => [id, vf]))))
    }

    /**
     * Adds assertion key lists and assertions for each view family match to the store. Assertion lists are assumed 
     * to be complete.
     */
    function processViewFamilyAssertions(plainViewFamilies: any[], requestTime: DateTime) {
        const assertionMap = assertionStore.addAssertionsToStore(flatten(plainViewFamilies.map(p => p.assertions).filter(isDefined)), requestTime)

        // get the complete list of assertion IDs for each family (may be empty)
        const familyAssertionMap = new Map<string, string[]>()
        for (const a of assertionMap.values()) {
            const familyId = a.subjectId!
            let ids = familyAssertionMap.get(familyId)
            if (!ids) {
                ids = []
                familyAssertionMap.set(familyId, ids)
            }
            ids.push(a.id!)
        }

        // add the assertion IDs lists to the person store
        for (const vf of plainViewFamilies) {
            for (const familyId of vf.matchIds as string[]) {
                const assertionIds = familyAssertionMap.get(familyId) ?? []
                let asyncKeyList = familyStore.asyncAssertionKeys.get(familyId)
                if (!asyncKeyList) {
                    asyncKeyList = new AsyncData<VersionedKeyList>(familyId, "Family.AssertionKeys")
                    familyStore.asyncAssertionKeys.set(familyId, asyncKeyList)

                    // get reactive proxy from store
                    asyncKeyList = familyStore.asyncAssertionKeys.get(familyId)!
                }
                const keyList = {
                    keys: assertionIds,
                    modifiedDate: requestTime,
                } as VersionedKeyList
                AsyncData.load(asyncKeyList, keyList, requestTime)
            }
        }
    }

    return {
        asyncViewFamilies,
        asyncChildKeys,

        getAsyncFamily,
        getAsyncFamilyList,
        getFamilyAsync,
        searchByNameAsync,
        areAssertionsLoaded,
        ensureAssertionsLoadedAsync,
        hasLoadedChildren,
        getLoadedDescendants,
        areDescendantsLoaded,
        ensureDescendantsLoadedAsync,
        getLoadedAncestorFamilies,
        getLoadedSpouseFamilies,
        getLoadedSpouseIds,
        getLoadedChildren,
        clearCacheFor,
        addViewFamiliesToStore,
    }
})

export interface AncestorFamily {
    family: ViewFamily
    directChildId: string
}

export interface FamilyAndChildren {
    family: ViewFamily
    childIds: string[]
}
  
function plainToViewFamily(p: any) {
    const viewFamily = assignExisting(new ViewFamily(), p)
    viewFamily.displayProperties = plainToFamilyDisplayProperties(p.displayProperties)
    return viewFamily
}
