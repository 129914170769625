<template>
  <div class="security-info">
    <form v-if="identityRef">
      <div>
        <label for="familyNameInput" class="form-label">Sign in as</label>
        <div v-for="a in identityRef.addresses" :key="a.address" class="input-value">{{ a.address }}</div>
      </div>
      <section v-if="showGenerateCodeRef" class="generate-code">
        <label class="form-label">Sign in codes</label>
        <div class="input-area">
          <select class="form-select" v-model="codeLifetimeRef">
            <option key="default" :value="0">Default lifetime</option>
            <option v-for="o in codeLifetimeOptions" :key="o" :value="o">{{ o }} days</option>
          </select>
          <button type="button" class="btn btn-warning" @click="generateCode">Get code</button>
        </div>
        <div v-if="codeRef" class="code-value">{{ codeRef.code }} (expires in {{ DateTimeUtil.toRelativeFutureDate(codeRef.expirationDate) }})</div>
      </section>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.security-info {
  section.generate-code {
    margin-bottom: 2rem;

    .input-area {
      display: flex;
      gap: 1rem;
    }

    select {
      max-width: 200px;
    }

    .code-value {
      margin-top: 1rem;
    }
  }
}
</style>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useUserStore } from '@/gp/UserStore'
import { IdentityApi, SignInCode, UserIdentity } from '@/auth/IdentityApi'
import { LoadMode } from '@/util/AsyncData';
import { TokenManager, UserPrivilege } from '@/auth/TokenManager';
import { Duration } from 'luxon';
import { DateTimeUtil } from '@/util/LuxonUtil';

const props = defineProps({
  userId: String,
})

const userStore = useUserStore()

const userRef = computed(() => userStore.getAsyncUser(props.userId, LoadMode.EnsureLoaded)?.data)
const identityRef = ref<UserIdentity | undefined>(undefined)
const showGenerateCodeRef = computed(() => TokenManager.hasPrivilege(UserPrivilege.ImpersonateUser) && userRef.value?.email?.startsWith('apptest@'));
const codeLifetimeRef = ref(0)
const codeLifetimeOptions = [1, 2, 3, 4, 5, 7, 10, 14]
const codeRef = ref<SignInCode | undefined>(undefined)

watch(userRef, async () => {
  if (userRef.value) {
    identityRef.value = await IdentityApi.getIdentityAsync(userRef.value.subject!)
  }
}, { immediate: true })

async function generateCode() {
  if (userRef.value) {
    const lifetime = codeLifetimeRef.value ? Duration.fromObject({ days: codeLifetimeRef.value }) : undefined
    codeRef.value = await IdentityApi.generateSignInCodeAsync(userRef.value.subject!, lifetime)
  }
}

</script>
