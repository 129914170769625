<template>
  <div class="doc-page">
    <h1 class="with-subhead">Privacy Notice</h1>
    <p class="subhead">Updated Aug 14, 2023</p>
    <p>
      At Tree Crossing, we <b>earn</b> your respect by <b>protecting</b> your data and ensuring <b>you</b> understand how we handle it.
    </p>
    
    <h4>What personal data does Tree Crossing collect?</h4>
    <p>We may collect:</p>
    <ul>
      <li>Your name and contact information</li>
      <li>Genealogical data on deceased individuals</li>
      <li>Limited information on living family members and individuals</li>
    </ul>

    <p>You can read more about what data is uploaded by our Sync app on our <a href="/learn/sync">Sync page</a>.</p>
    
    <h4>How does Tree Crossing use your data?</h4>
    <p>
      We use your data to verify your identity, communicate with you, and enhance your and your family's learning and sharing experiences. 
      We facilitate sharing with other users when you've given us express permission to do so.
    </p>

    <h4>Will Tree Crossing share data with third parties?</h4>
    <p>We will not share your data except:</p>
    <ul>
      <li>With your explicit consent</li>
      <li>As mandated by law, to protect rights, safety, or confidentiality</li>
      <li>With service providers under strict agreements, limiting data to be used only for services you requested</li>
    </ul>
    
    <h4>Is Tree Crossing for minors?</h4>
    <p>
      Our services are intended for adults and their family members of all ages. 
      We do not allow children under age 13 to create their own accounts. 
      Parents/guardians determine appropriateness, and may create and manage accounts for their children. 
      Minors' data is collected with parental consent, adhering to COPPA regulations.
    </p>
    
    <h4>Cookies and tracking technologies</h4>
    <p>
      We use tracking technologies as an essential part of providing necessary functionality of our services.
      We use analytics technology to optomize our services for the best customer experience. 
      We do not using tracking for ads. 
      We do not sell your product usage information to third parties.
    </p>

    <h4>Managing privacy settings</h4>
    <p>You can manage privacy settings on your account page.</p>
    
    <h4>Your rights</h4>
    <p>
      You have control over submissions, changes, and deletions of your data.
      Contact us to address unauthorized sharing.
    </p>
  
    <h4>Securing and storing personal information</h4>
    <p>
      We may store your personal data in data centers (cloud storage) in the United States, or on the premises of Tree Crossing offices.
      We adhere to industry best practices to secure data against loss or misuse.
      Payment information is not shared with or stored by Tree Crossing.
      Financial data is encrypted and managed by compliant third parties.
    </p>
    
    <h4>Updates to Privacy Statement</h4>
    <p>
      Significant changes will be emailed prior to taking effect.
      If you disagree with any updates, you may opt to close your account.
    </p>

    <h4>Contact Us</h4>
    <p>
      For privacy concerns or questions, visit our Contact page.
    </p>
  </div>
</template>
<style lang="scss" scoped>
  h4 {
    margin-top: 2rem;
    color: rgb(214, 96, 33);
  }
  p {
    max-width: 900px;
  }
</style>