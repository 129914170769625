import { isDefined } from '@/util/TypeScriptUtil'
import { GooglePeopleApi } from './GooglePeopleApi'
import { MSGraphApi } from './MSGraphApi'
import { MSIdentityApi } from './MSIdentityApi'
import { Environment } from '@/util/Environment'

const google = new GooglePeopleApi()
google.initializePeopleApi()

const msIdentity = new MSIdentityApi()
msIdentity.loadScripts(['openid', 'profile', 'User.Read', 'Contacts.Read'])

const msGraph = new MSGraphApi()

const testContactProvider = {
    id: 'test',
    name: 'Test contacts',
    signIn: async () => {
        return new Promise<boolean>((resolve) => {
            // nothing to do
            resolve(true)
        })
    },
    getContactsAsync: async () => {
        return [
            { id: '1', names: [{ givenName: 'Jan', familyName: 'Meyers' }], gender: 'female', emailAddresses: [{ value: 'jan@nowhere.com' }] },
            { id: '2', names: [{ givenName: 'Rick', familyName: 'Pennington' }], gender: 'male', emailAddresses: [{ value: 'rick@nowhere.com' }] },
            { id: '3', names: [{ givenName: 'Sarah', familyName: 'Meyers' }], gender: 'female', emailAddresses: [{ value: 'artlady@nowhere.com' }] },
            { id: '4', names: [{ givenName: 'Joe', familyName: 'Stranger' }], emailAddresses: [] },
        ] as Contact[]
    }
}

const googleContactProvider = {
    id: 'google',
    name: 'Google Contacts', // "Contacts" is part of the product name and must be capitalized
    signIn: async () => {
        return new Promise<boolean>((resolve) => {
            google.signIn()
            resolve(true)
        })
    },
    getContactsAsync: async () => {
        const contacts = await google.getContactsAsync(1000) // max allowed, should be enough
        return contacts.map(c => ({
            id: c.resourceName,
            names: c.names.map(n => n as ContactName),
            emailAddresses: c.emailAddresses.map(e => ({
                value: e.value,
                displayName: e.displayName,
            } as ContactEmailAddress)),
        } as Contact))
    },
}

const microsoftContactProvider = {
    id: 'microsoft',
    name: 'Outlook contacts',
    signIn: async () => {
        return new Promise<boolean>((resolve) => {
          msIdentity.signIn(async (token) => {
            if (token) {
              msGraph.setAccessToken(token);
              resolve(true);
            } else {
              resolve(false);
            }
          });
        });
      },
    getContactsAsync: async () => {
        const contacts = await msGraph.getContactsAsync(1000) // max allowed?
        return contacts.map(c => ({
            id: c.id,
            names: [{ displayName: c.displayName, givenName: c.givenName, familyName: c.surname, middleName: c.middleName }],
            emailAddresses: c.emailAddresses.map(e => ({
                value: e.address,
                displayName: e.name,
            } as ContactEmailAddress)),
        })) as Contact[]
    },
}

export const contactProviders: ContactProvider[] = [
    googleContactProvider,
    microsoftContactProvider,
]

if (Environment.isDevelopment()) {
    contactProviders.push(testContactProvider)
}

export interface ContactProvider {
    readonly id: string
    readonly name: string
    signIn(): Promise<boolean>
    getContactsAsync(): Promise<Contact[]>
}

export interface Contact {
    readonly id: string
    readonly names: ContactName[]
    readonly emailAddresses: ContactEmailAddress[]
}

export function getContactDisplayName(c: Contact) {
    const n = c.names.at(0)
    return n ? (n.displayName ?? [n.givenName, n.familyName].filter(isDefined).join(' ')) : undefined
}

export interface ContactName {
    readonly displayName?: string
    readonly givenName?: string
    readonly familyName?: string
    readonly middleName?: string
}

export interface ContactEmailAddress {
    readonly value: string
    readonly displayName?: string
}
