<template>
  <CompactItem class="family-group-invite-item" select-mode @click="emit('click', props.inviteId)">
    <template #icon><img src="@/assets/icons/mail_gray_60.png"/></template>
    <template #title>Invitation to join {{ familyGroupRef?.name }} group</template>
    <template #subtitle>Sent by {{ senderUserRef?.displayName }}</template>
  </CompactItem>
</template>

<style lang="scss" scoped>
.family-group-invite-item .item-icon img {
  filter: brightness(0) saturate(100%) invert(63%) sepia(76%) saturate(5097%) hue-rotate(184deg) brightness(100%) contrast(94%);
}
</style>

<script lang="ts" setup>
import { computed } from 'vue';
import { useFamilyGroupInviteStore } from '@/gp/FamilyGroupInviteStore'
import { useFamilyGroupStore } from '@/gp/FamilyGroupStore'
import { useUserStore } from '@/gp/UserStore'
import CompactItem from '@/util/CompactItem.vue'
import { LoadMode } from '@/util/AsyncData'
    
  const props = defineProps({
    inviteId: String,
    selectMode: Boolean,
  })

  const emit = defineEmits([
    'click'
])
  
const inviteStore = useFamilyGroupInviteStore()
const familyGroupStore = useFamilyGroupStore()
const userStore = useUserStore()

const inviteRef = computed(() => inviteStore.getAsyncInvite(props.inviteId, LoadMode.EnsureLoaded)?.data)
const familyGroupRef = computed(() => familyGroupStore.getAsyncGroup(inviteRef.value?.familyGroupId)?.data) // loaded with invite
const senderUserRef = computed(() => userStore.getAsyncUser(inviteRef.value?.senderUserId)?.data) // loaded with invite

</script>