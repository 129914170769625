import _ from "lodash"
import { FuzzyDate } from "./FuzzyDate"
import { HistoricalDateType } from "./HistoricalDateEnums"

/**
 * Provides display formatting, user input parsing, and validation of protocol sort date values.
*/
export class SortDate {
    static SortYearOffset = 6000; // -5999...3999 => 1...9999 (0 = empty)

    static Empty = new SortDate()

    readonly date1: FuzzyDate
    readonly date2: FuzzyDate
    readonly type: HistoricalDateType
    readonly value: string

    get isEmpty() { return this.date1.isEmpty }

    constructor(date1?: FuzzyDate, date2?: FuzzyDate, type = HistoricalDateType.Date) {
        if (type == HistoricalDateType.Freeform)
            throw 'SortDate type Freeform is not allowed'

        this.date1 = date1 ?? FuzzyDate.Empty
        this.date2 = date2 ?? FuzzyDate.Empty
        this.type = type

        // protocol format: YYYYMMDDRYYYYMMDDT
        // Y/M/D = year/month/day, R = reserved for future use, T = type
        const sortEmptyLast = (this.type == HistoricalDateType.After);
        this.value = this.date1.getSortValue(sortEmptyLast).toString().padStart(8, '0') +
            '0' +
            this.date2.getSortValue(sortEmptyLast).toString().padStart(8, '0') +
            SortDate.getTypeDigit(this.type)
    }

    clone() {
        return new SortDate(this.date1, this.date2, this.type)
    }

    toString() {
        return this.value
    }

    private static getTypeDigit(type: HistoricalDateType) {
        switch (type) {
            case HistoricalDateType.Before: return '0'
            case HistoricalDateType.Date: return '4'
            case HistoricalDateType.Between: return '5'
            case HistoricalDateType.Span: return '6'
            case HistoricalDateType.After: return '9'
        }
        throw `SortDate type ${type} is not allowed`
    }

    private static getTypeFromDigit(typeDigit: string) {
        switch (typeDigit) {
            case '0': return HistoricalDateType.Before
            case '4': return HistoricalDateType.Date
            case '5': return HistoricalDateType.Between
            case '6': return HistoricalDateType.Span
            case '9': return HistoricalDateType.After
        }
        throw `SortDate type digit ${typeDigit} is not valid`
    }

    static today() {
        return new SortDate(FuzzyDate.today())
    }

    static parse(value: string | undefined) {
        if (!value)
            return SortDate.Empty

        function isEmptyValue(value: string) {
            // NOTE: Okay to treat 00 and 99 the same, because the date type type controls which should be used
            return value === _.repeat('0', value.length) || value === _.repeat('9', value.length)
        }

        function emptyToUndefined(value: string) {
            return isEmptyValue(value) ? undefined : +value;
        }

        // match with protocol format
        const m = value.match(/^(\d{4})(\d\d)(\d\d)0(\d{4})(\d\d)(\d\d)([0|4|5|6|9])$/)
        if (!m) throw `SortDate format '${value}' is not valid`

        if (isEmptyValue(m[1]))
            return SortDate.Empty

        const fd1 = new FuzzyDate(
            +(m[1]) - SortDate.SortYearOffset,
            emptyToUndefined(m[2]),
            emptyToUndefined(m[3]))

        const fd2 = isEmptyValue(m[4])
            ? FuzzyDate.Empty
            : new FuzzyDate(
                +(m[4]) - SortDate.SortYearOffset,
                emptyToUndefined(m[5]),
                emptyToUndefined(m[6]));

        const type = SortDate.getTypeFromDigit(m[7])

        return new SortDate(fd1, fd2, type)
    }

    static compare(a: SortDate, b: SortDate) {
        // empty sort dates (for which the date type is meaningless) sort FIRST
        if (a.isEmpty) {
            return b.isEmpty ? 0 : -1
        }
        if (b.isEmpty) {
            return 1
        }
        // compare non-empty sort dates based on the date type
        const aSort1 = a.date1.getSortValue(a.type == HistoricalDateType.After)
        const bSort1 = b.date1.getSortValue(b.type == HistoricalDateType.After)
        if (aSort1 != bSort1) {
            return aSort1 > bSort1 ? 1 : -1
        }
        const aSort2 = a.date2.getSortValue(a.type == HistoricalDateType.After)
        const bSort2 = b.date2.getSortValue(b.type == HistoricalDateType.After)
        if (aSort2 != bSort2) {
            return aSort2 > bSort2 ? 1 : -1
        }
        return 0
    }
    
}
