// this code is derived from the official docs
// https://developers.google.com/people/quickstart/js#set_up_the_sample
// https://developers.google.com/people/api/rest/v1/people

import {GoogleIdentityApi} from './GoogleIdentityApi'

const googleApi = new GoogleIdentityApi();
const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/people/v1/rest'
const SCOPES_DEFAULT = 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/contacts.readonly'

export class GooglePeopleApi extends GoogleIdentityApi{
    private scopes = ''

    constructor(scopes: string = SCOPES_DEFAULT) {
        super()
        this.scopes = scopes;
    }

    async initializePeopleApi(){
        this.loadScripts(DISCOVERY_DOC,this.scopes)
    }

    async getMe(){
        let response;
        try {
            response = await gapi.client.people.people.get({
            resourceName: 'people/me',
            personFields: 'names,emailAddresses',
            });
        } catch (err) {
            // TODO: handle error
            console.error(err)
            return;
        }

        return response.result
    }
    async getContactsAsync(pageSize = 10){
        let response;
        try {
            response = await gapi.client.people.people.connections.list({
            resourceName: 'people/me',
            pageSize: pageSize,
            personFields: 'names,emailAddresses',
            });
        } catch (err) {
            // TODO: handle error
            console.error(err)
            return [];
        }

        const connections = response.result.connections;
        // console.log("Response", connections);
        return connections as GooglePerson[]
    }

    async searchContactsAsync(query: string){

        let response;
        try {
            response = await gapi.client.people.people.searchContacts({
            query: query,
            readMask: 'names,emailAddresses',
            });
        } catch (err) {
            // TODO: handle error
            console.error(err)
            return;
        }

        return response.result.results
    }
}

export interface GooglePerson {
    readonly resourceName: string
    readonly names: GooglePersonName[]
    readonly emailAddresses: GoogleEmailAddress[]
    readonly genders: GoogleGender[]
}

export interface GooglePersonName {
    readonly metadata: GoogleFieldMetadata
    readonly displayName: string
    readonly familyName: string
    readonly givenName: string
    readonly middleName: string
}

export interface GoogleGender {
    readonly metadata: GoogleFieldMetadata
    readonly value: 'male' | 'female' | 'unspecified'
}

export interface GoogleEmailAddress {
    readonly metadata: GoogleFieldMetadata
    readonly value: string
    readonly displayName: string
    readonly type: 'home' | 'work' | 'other'
}

export interface GoogleFieldMetadata {
    readonly primary: boolean
}
