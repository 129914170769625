<template>
  <div v-if="viewPersonRef" class="person-link">
    <router-link v-if="!props.disabled" :to="viewPersonRef.pageUrl" class="link-dark">
      <span>{{ displayName }}</span>
      <slot></slot>
    </router-link>
    <span v-else class="link-placeholder">{{ displayName }}</span>
  </div>
</template>

<style lang="scss" scoped>
.person-link {
  display: inline; // same as default for router-link or span
}

.link-placeholder {
  opacity: 0;
}
</style>

<script setup lang="ts">
import { computed } from 'vue'
import { useViewPersonStore } from '@/rd/ViewPersonStore'

const props = defineProps({
    personId: String,
    disabled: Boolean,
})

const viewPersonStore = useViewPersonStore()
const viewPersonRef = computed(() => viewPersonStore.getAsyncPerson(props.personId)?.data)
const displayName = computed(() => viewPersonRef.value?.displayName)

</script>
