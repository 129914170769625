<template>
  <div class="person-card" :selected="selectedRef" :class="cardClassRef">
    <button type="button" v-if="useCardButtonRef && selectModeRef" class="overlay" :disabled="props.placeholder" @click="onClick"></button>
    <router-link v-if="useCardButtonRef && linkModeRef"  class="overlay" :disabled="props.placeholder" :to="linkUrlRef"></router-link>
    <div class="gender-bar" :class="genderClassRef"></div>
    <div class="person-card-image">
      <img :src="personImageRef"/>
    </div>
    <div class="person-card-body">
      <div class="title-area">
        <button type="button" v-if="!useCardButtonRef && selectModeRef" class="overlay" :disabled="props.placeholder" @click="onClick"></button>
        <router-link v-if="!useCardButtonRef && linkModeRef"  class="overlay" :disabled="props.placeholder" :to="linkUrlRef"></router-link>
        <div class="title">
          {{ cardTitleRef }}
        </div>
        <div v-if="props.profile && props.placeholder" class="subtitle">
          (this profile)
        </div>
        <div v-else-if="showProfileTypeRef" class="subtitle">
          <LinkIcon v-if="profileTypeRef == ProfileType.ManagedLinked" size="1x"></LinkIcon>
          <LockIcon v-if="personRef?.restricted" size="1x"></LockIcon>
          {{ profileTypeTextRef }}
          <UserLink v-if="ownerRef" :userId="ownerRef?.id" dimmed></UserLink>
        </div>
      </div>
      <div v-if="showLifespanRef" class="lifespan">{{ lifespanTextRef }}</div>
      <div v-if="showProfileCountRef" class="profiles">
        <button type="button" v-if="profileCountRef" class="btn btn-link btn-inline link-secondary" @click="onShowProfiles">
          {{ profileCountRef }} {{ profilesTextRef }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$transition-duration: 0.3s;
$transition-timing: ease-in-out;
$transition-late-delay: 0.2s;
$transition-late-duration: 0.1s;

.person-card {
  position: relative; // for whole-card overlay button
  display: flex;
  width: 100%;
  border-top: 1px solid #f8f8f8;
  background-color: white;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  transition-property: width, height;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing;

  .gender-bar {
    position: relative;
    flex: 0 0 8px;
    height: 100%;
    background-color: #e9e9e9;
    transition: flex-basis $transition-duration $transition-timing;

    &.male {
      background-color: #c3d5ea;
    }

    &.female {
      background-color: #fadce1;
    }
  }

  .person-card-image {
    position: relative;
    flex: 0 0 60px;
    background-color: #f4f4f4;
    transition: flex-basis $transition-duration $transition-timing;
    display: flex; // only to center placeholder image
    padding: 6px;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
      flex: 0 0 100px;
      padding: 15px;
    }

    img {
      width: 100%;
      object-fit: cover;
      opacity: 10%;
    }
  }

  .person-card-body {
    align-self: center;
    height: 100%;
    width: 100%;
    min-width: 0; // for some reason this is needed to enable overflow control in .person-name
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0.5em;
    line-height: 1.3; // tight line spacing but don't clip glyph descenders (1.2 is too tight)
    overflow: hidden;
    transition: padding $transition-duration $transition-timing;
    transition-property: padding, height;
    
    .title-area {
      position: relative; // for title-only overlay button
      display: flex;
      flex-direction: column;
    }

    .subtitle {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 0.75rem;
      font-weight: 400;
      color: #888;
    }
    
    .lifespan, .profiles {
      max-height: 0;
      opacity: 0;
      font-size: 0.75rem;
      font-weight: 400;
      color: #888;
      transition: opacity $transition-late-duration $transition-timing;
    }
  }

  .card-button {
    display: block;    
    width: 100%; // force it to size to parent instead of content

    &:hover {
      color: inherit;
    }
  }

  .title {
    display: block; // to get max clickable area
    width: 100%;
    min-height: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: font-size $transition-duration $transition-timing;
    transition-property: font-size, padding;
  }

  .birth-year {
    margin: 0;
    margin-left: 0.25em;
    font-size: 0.875em; // relative size
    color: #aaa;
  }

  &.empty, &.dangling {
    .person-card-image {
      background-color: inherit;

        img {
          opacity: 60%;
        }
    }

    .title {
      color: #aaa;
    }
  }

  &.card-full {
    height: 75px;

    @media (min-width: 768px) {
      height: 100px;
    }

    .person-card-body {
      padding: 0.1em 0.5em 0.3em;
    }

    .title {
      padding: 0.25rem 0; // ensure descenders are not clipped
      display: -webkit-box; // to get max clickable area
      white-space: normal;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      pointer-events: none;
      cursor: default;
    }

    @media (min-width: 768px) {
      font-size: 1.25rem;
      font-weight: 300;
    }

    .birth-year {
      display: none;
    }

    .lifespan {
      margin-top: auto;
    }

    .lifespan, .profiles {
      max-height: none;
      opacity: 1;
      transition-delay: $transition-late-delay; // show late when this is the final state
    }
  }

  &.card-small {
    height: 40px;
    max-width: 400px;

    .title {
      font-size: 0.875rem;
    }

    .person-card-image {
      flex: 0 0 36px;
      padding: 4px;
    }

    .lifespan {
      margin-top: 0;
    }

    .lifespan, .profiles {
      max-height: none;
      opacity: 1;
      transition-delay: $transition-late-delay; // show late when this is the final state
    }
  }

  &.card-tiny {
    //width: 80px;
    height: 26px; // ~1.6rem

    .gender-bar {
      flex: 0 0 6px;
    }

    .title {
      font-size: 0.75rem;
    }

    .person-card-image {
      flex: 0 0 0;
      padding: 0;
    }
  }

  &.card-dot {
    width: 12px;
    height: 8px;
    border-top: none;
    box-shadow: none;
    font-size: 0.75rem;

    .gender-bar {
      flex: 0 0 12px;
    }

    .person-card-image {
      flex: 0 0 0;
      padding: 0;
    }

    .title {
      text-overflow: clip; // for animation performance
    }
  }

  &.restricted {
    .person-card-image {
      background-color: transparent;

      img {
        opacity: 40%;
      }
    }
  }

  &.card-placeholder {
    box-shadow: none;
    border: 1px solid #f4f4f4;

    &.profile {
      border: 1px dashed #ccc;
    }

    // apply opacity to child elements so parent opacity can be animated for fade in/out transitions
    .gender-bar, .person-card-image {
      opacity: 0;
    }
    
    .person-card-body {
      .title {
        color: #aaa;
      }
      .subtitle, .lifespan {
        color: #bbb;
      }
    }
    
    button.btn.card-button:disabled {
      // override btn.disabled styles, since we want to dim the card as a whole
      color: inherit;
      opacity: 1;
    }
  }

  &.current {
    box-shadow: 0 0.125rem 0.35rem rgba(0,0,0,0.25); // stronger shadow
    transform: translate(-2px, -2px);
  }

  &.highlight:not(.card-placeholder) {
    background-color: #fefcd5;

    .gender-bar::before,
    .person-card-image::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(yellow, 0.1)
    }

    .card-button {
      cursor: default;

      &:hover {
        color: rgb(33, 37, 41);
      }
    }
  }
  
  &.dimmed:not(.card-placeholder) {
    .gender-bar {
      opacity: 0.4;
    }

    .person-card-image {
      opacity: 0.4;
    }

    .card-button {
      cursor: default;
    }

    .title {
      color: #aaa !important;

      .birth-year small {
        color: #ddd !important;
      }
    }

    .select-checkbox {
      background-color: white;
    }
  }  
}
</style>

<script setup lang="ts">
import { computed, PropType } from 'vue'
import { useRouter } from 'vue-router'
import { Gender, Person, ViewPerson } from '@/rd/ResearchDataModel'
import { usePersonStore } from '@/rd/PersonStore'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useUserStore } from '@/gp/UserStore'
import { useExploreStore } from '@/explore/ExploreStore'
import { CompositeId } from '@/rd/CompositeId'
import { isDefined } from '@/util/TypeScriptUtil'
import { FuzzyDate } from '@/rd/FuzzyDate'
import { DurationUtil } from '@/util/LuxonUtil'
import { LoadMode } from '@/util/AsyncData'
import { ProfileUtils, ProfileType } from './ProfileUtils'
import { LinkIcon, LockIcon } from '@zhuowenli/vue-feather-icons'
import UserLink from './UserLink.vue'

const props = defineProps({
  personId: String,
  profile: Boolean,
  layout: String as PropType<"full" | "small" | "tiny" | "dot">,
  placeholder: Boolean,
  selectMode: Boolean,
  selectedId: String,
  highlightMode: Boolean,
  highlight: Boolean,
  current: Boolean,
  showProfileType: Boolean,
  showProfileCount: String as PropType<'always' | 'multiple'>,
  linkSingleton: Boolean,
})

const emit = defineEmits([
  'click',
  'showProfiles',
  'update:selectedId',
])

const genderImages: any = {}
genderImages[Gender.Male] = require("@/assets/icons/male_gray_150.png")
genderImages[Gender.Female] = require("@/assets/icons/female_gray_150.png")
genderImages["default"] = require("@/assets/icons/person_gray_150.png")
// eslint-disable-next-line @typescript-eslint/no-var-requires
const dottedPersonImage = require("@/assets/icons/person_dotted_80.png")

const router = useRouter()
const viewPersonStore = useViewPersonStore()
const personStore = usePersonStore()
const dataGroupStore = useDataGroupStore()
const userStore = useUserStore()
const exploreStore = useExploreStore()

const layoutRef = computed(() => props.layout ?? 'full')
const viewPersonRef = computed(() => props.profile ? undefined : viewPersonStore.getAsyncPerson(props.personId)?.data)
const personRef = computed(() => props.profile || isSingletonRef.value ? personStore.getAsyncPerson(props.personId, LoadMode.EnsureLoaded)?.data : undefined)
const personLikeRef = computed(() => props.profile ? personRef.value : viewPersonRef.value)
const displayPropsRef = computed(() => personLikeRef.value?.displayProperties)
const cardTitleRef = computed(() => 
  !props.personId ? '(none)' :
  isDanglingRef.value ? '(deleted or not visible)' : 
  displayPropsRef.value?.displayName ?? "(no name)"
)

const dataGroupRef = computed(() => props.profile || isSingletonRef.value ? dataGroupStore.getAsyncGroup(CompositeId.getGroupId(props.personId), LoadMode.EnsureLoaded)?.data : undefined)
const ownerRef = computed(() => userStore.getAsyncUser(dataGroupRef.value?.ownerId, LoadMode.EnsureLoaded)?.data)
const profileTypeRef = computed(() => ProfileUtils.getProfileType(personRef.value, dataGroupRef.value, ownerRef.value))
const profileTypeTextRef = computed(() => ProfileUtils.getProfileSubtitle(profileTypeRef.value))
const showProfileTypeRef = computed(() => props.profile || (isSingletonRef.value && props.showProfileType))

const cardClassRef = computed(() => ({ 
  'card-placeholder': props.placeholder,
  'highlight': props.highlightMode && props.highlight,
  'dimmed': props.highlightMode && !props.highlight,
  'current': props.current,
  ['card-' + layoutRef.value]: true,
  'profile': props.profile,
  'restricted': personRef.value?.restricted,
  'empty': !props.personId,
  'dangling': isDanglingRef.value,
}))
const genderClassRef = computed(() => [displayPropsRef.value?.gender?.toLowerCase()])
const personImageRef = computed(() => layoutRef.value == 'dot' ? undefined : 
  props.profile && personRef.value?.restricted ?  dottedPersonImage :
  profileCountRef.value == 0 ? dottedPersonImage :
  genderImages[displayPropsRef.value?.gender ?? "default"])

const showLifespanRef = computed(() => layoutRef.value == 'full' || !showProfileCountRef.value)
const lifespanTextRef = computed(() => {
  const birthDate = displayPropsRef.value?.birthSortDate.date1
  const deathDate = displayPropsRef.value?.deathSortDate.date1
  const living = displayPropsRef.value?.living

  if (birthDate && !birthDate.isEmpty && deathDate && !deathDate.isEmpty) {
    const age = deathDate.diff(birthDate)
    return `${birthDate.year}-${deathDate.year} (${DurationUtil.toPersonAge(age, true)})`
  }

  if (living) {
    if (birthDate && !birthDate.isEmpty) {
      const age = FuzzyDate.today().diff(birthDate)
      return `${birthDate.year}- (age ${age.years})`
    }
    return "Living"
  }

  return displayPropsRef.value?.lifespan
})
const profileIdsRef = computed(() => {
  if (!props.personId)
    return undefined

  if (props.profile)
    return [props.personId] // profiles don't have profiles

  const matchIds = viewPersonRef.value?.matchIds ?? []
  const matchGroupIds = [...new Set(viewPersonRef.value?.groupIds)]
  const matchGroups = dataGroupStore.getAsyncGroupList(matchGroupIds, LoadMode.EnsureLoaded)
    .map(a => a.data).filter(isDefined)
  if (matchGroups.length < matchGroupIds.length) {
    return undefined // not all groups loaded yet
  }
  return ProfileUtils.getNonPlaceholderIds(matchIds, matchGroups)
})
const profileCountRef = computed(() => profileIdsRef.value?.length ?? 0)
const showProfileCountRef = computed(() => 
  props.showProfileCount == 'always' || 
  (props.showProfileCount == 'multiple' && profileCountRef.value > 1))
const isDanglingRef = computed(() => props.personId && profileCountRef.value == 0)
const isSingletonRef = computed(() => profileCountRef.value == 1)
const isEmptyRef = computed(() => profileCountRef.value == 0)
const profilesTextRef = computed(() => profileCountRef.value == 1 ? "profile" : "profiles")

const selectModeRef = computed(() => props.placeholder || (props.selectMode && !(props.linkSingleton && isSingletonRef.value) && !isEmptyRef.value))
const linkModeRef = computed(() => !selectModeRef.value && !isDanglingRef.value && !isEmptyRef.value)
const linkUrlRef = computed(() => (props.profile || (props.linkSingleton && isSingletonRef.value)) 
  ? Person.getPageUrl(profileIdsRef.value?.at(0))
  : ViewPerson.getPageUrl(props.personId ?? ''))
const useCardButtonRef = computed(() => props.layout != 'full')

const selectedRef = computed({
  get: () => props.selectMode && props.selectedId == props.personId,
  set: (value) => {
    emit('update:selectedId', value ? props.personId : undefined) // toggle selection of this item
  }
})

function onClick() {
  // do this even if no personId specified
  emit('click', props.personId) // for stateless selection
  selectedRef.value = false // for stateful selection (click deselects, state can only be set by parent)
}

function onShowProfiles() {
  emit('showProfiles')
}

function setStartPerson() {
  if (props.personId) {
    exploreStore.setStartPersonId(props.personId)
  }
}
</script>
