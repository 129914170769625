<template>
  <div class="sync-app-page container">
    <h1>Do we support your tree yet?</h1>
    <div class="app-support">
      <div class="header-row">
        <div></div>
        <div v-for="os in oses" :key="os.id" class="os">
          <img :src="osIcons[os.id]">
        </div>
      </div>
      <div v-for="a in apps" :key="a.id" class="app-row">
        <div class="app">
          <img :src="appIcons[a.id!]">
          <div>{{ a.name }}</div>
        </div>
        <div v-for="os in oses" :key="os.id" class="cell">
          <img v-if="isAvailableOn(a, os.id)" src="@/assets/tree-crossing-icon-128.png" :class="{ supported: isSupportedOn(a, os.id) }">
        </div>
      </div>
    </div>
    <div class="container app-support wide">
      <div class="row header-row">
        <div class="col-1">
        </div>
        <div v-for="a in apps" :key="a.id" class="col-2 app">
          <img :src="appIcons[a.id!]">
          <div class="app-name">{{ a.name }}</div>
          <div class="versions">{{ a.versions }}</div>
        </div>
      </div>
      <div v-for="os in oses" :key="os.id" class="row os-row">
        <div class="col-1 os">
          <img :src="osIcons[os.id]">
          <div class="versions">{{ os.versions }}</div>
        </div>
        <div v-for="a in apps" :key="a.id" class="col-md-2 cell">
          <img v-if="isAvailableOn(a, os.id)" src="@/assets/tree-crossing-icon-128.png" :class="{ supported: isSupportedOn(a, os.id) }">
        </div>
      </div>
    </div>
    <div class="legend">
      <img src="@/assets/tree-crossing-icon-128.png"> = working on it
    </div>
    <!-- <div class="more-info">
      <div class="inline-question">
        <button type="button" class="btn btn-inline btn-link" data-bs-toggle="collapse" data-bs-target="#otherSources">
          What about other trees?
        </button>
        <div id="otherSources" class="collapse">
          We'd like to add more!
          If there's one you'd like to see soon, <router-link to="/support">let us know!</router-link>
        </div>
      </div>
    </div> -->
    <h1>Use the Sync app to upload it.</h1>
    <div class="alert alert-warning alert-sm">
      <div>
        We're in private preview. If you're not approved yet, you won't be able to sign in to the Sync app.
        You can still <router-link to="/signup">sign up</router-link>, though, and we'll let you know as soon as you can try it out!
      </div>
    </div>
    <div class="sync-app">
      <img src="@/assets/logos/Sync_app.png">
      <div>
        <h3>Tree Crossing Sync</h3>
        <div class="publisher">Published by Tree Crossing, LLC</div>
      </div>
    </div>
    <div class="download-badges">
      <a v-if="osRef" :href="msStoreLink"><img src="@/assets/logos/GetFromMicrosoft.png"></a>
      <a v-else href="#"><img src="@/assets/logos/GetFromMacStore.svg"></a>
    </div>
    <div class="more-info">
      <div>
        <router-link :to="{ name: 'sync' }">How does the Sync app work?</router-link>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sync-app-page {
  max-width: 700px;
  text-align: center;

  h1 {
    margin: 2rem 0;
    font-size: 2rem;

    @media (min-width: 768px) {
      margin: 4rem 0 3rem;
    }
  }

  $app-icon-height: 50px;

  .legend img, .app-support .cell img {
    width: 25px;
      
    &:not(.supported) {
      opacity: 0.3;
      filter: grayscale(100%);
    }
  }

  .legend {
    margin-left: 1rem;
    text-align: start;
    font-size: 0.875rem;
    color: #888;
  }

  .app-support {
    margin-top: 2rem;

    .header-row {
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #ddd;
      text-align: center;
    }
   
    .app {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 0.75rem;

      img {
        display: block;
        height: $app-icon-height;
        border-radius: 6px;
      }

      .app-name {
        min-height: 36px;
      }
    }

    .versions {
      font-size: 0.75rem;
      color: #aaa;
    }

    .cell {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .app-support:not(.wide) {

    @media (min-width: 768px) {
      display: none;
    }

    .header-row, .app-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  
    .header-row {
      .os {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        img {
          width: 30px;
        }
      }
    }

    .app-row {
      margin: 1rem 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .app-support.wide {
    display: none;
    
    @media (min-width: 768px) {
      display: block;
    }

    .header-row {
      .legend {
        padding-top: $app-icon-height;
        text-align: start;
      }
    }

    .os-row {
      padding: 1rem 0;
      .os {
        padding: 0;
        text-align: center;

        img {
          margin-top: -4px;
          width: 25px;
        }
      }
    }
  }

  .alert {
    margin-bottom: 2rem;
  }

  .sync-app {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    text-align: start;

    img {
      height: 52px;
    }

    h3 {
      margin: 0;
      font-size: 1.5rem;
    }

    .publisher {
      font-size: 0.875rem;
      color: #aaa;
    }
  }

  .download-badges {
    margin: 1.5rem 2rem 3rem;

    img {
      height: 50px;
      border-radius: 10px;

      @media (min-width: 768px) {
        height: 65px;
      }
    }
  }

  .more-info {
    margin-top: 2rem;
    text-align: center;

    .inline-question {
      margin-bottom: 1rem;

      button {
        margin-bottom: 0.5rem;
      }

      .collapse, .collapsing {
        padding: 1rem;
        background-color: #fefdeb;
        border: 1px solid #ddd;
        border-radius: 0.25rem;
        font-size: 0.85rem;
        //color: #bbb;
      }
    }
  }
}
</style>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { usePageTitle } from '@/util/AppUtil'

usePageTitle('Get the Sync app')

type OS = "Windows" | "Mac"
interface SupportedOS {
  id: OS
  name: string
  versions: string
}

const oses: SupportedOS[] = [
  { id: "Windows", name: 'Windows', versions: "10, 11" },
  { id: "Mac", name: 'Mac', versions: "10" },
]
const osRef = ref<OS>();
const msStoreLink = computed(() => osRef.value == 'Windows'
  ? "ms-windows-store://pdp?ocid=pdpshare&hl=en-us&gl=us&productid=9P50KNCSGK41"
  : "https://www.microsoft.com/store/productId/9P50KNCSGK41?ocid=pdpshare")

const osIcons: any = {
  "Windows": require('@/assets/logos/Windows_150.png'),
  "Mac": require('@/assets/logos/Mac_150.png'),
}

interface SupportedApp {
  id: string
  name: string
  versions: string
  availableOn: OS[]
  supportedOn?: OS[]
}

const apps: SupportedApp[] = [
  { id: 'RootsMagic', name: 'RootsMagic®', versions: "7, 8, 9", availableOn: ['Windows', 'Mac'], supportedOn: ['Windows']},
  { id: 'Legacy', name: 'Legacy Family Tree®', versions: "8, 9", availableOn: ['Windows'], supportedOn: ['Windows'] },
  { id: 'FamilyTreeMaker', name: 'Family Tree Maker®', versions: "2019, 2024", availableOn: ['Windows', 'Mac'] },
  { id: 'MacFamilyTree', name: 'MacFamilyTree®', versions: "10", availableOn: ['Mac'] },
  { id: 'FamilyHistorian', name: 'Family Historian®', versions: "7", availableOn: ['Windows'] },
]

const appIcons: any = { // 'any' prevents TS from complaining about arbitrary string as index
  "RootsMagic": require('@/assets/logos/RootsMagic_app.png'),
  "Legacy": require('@/assets/logos/Legacy_app.png'),
  "FamilyTreeMaker": require('@/assets/logos/FamilyTreeMaker_app.png'),
  "MacFamilyTree": require('@/assets/logos/MacFamilyTree_app.png'),
  "FamilyHistorian": require('@/assets/logos/FamilyHistorian_app.png'),
}

function isAvailableOn(app: SupportedApp, os: OS) {
  return apps.find(a => a.id == app.id)?.availableOn?.includes(os)
}

function isSupportedOn(app: SupportedApp, os: OS) {
  return apps.find(a => a.id == app.id)?.supportedOn?.includes(os)
}

onMounted(() => {
  osRef.value = 
    /Win/.test(navigator.userAgent) ? 'Windows'  : 
    /Mac/.test(navigator.userAgent) ? 'Mac' :
    undefined
});
</script>