<template>
  <div class="member-item" :class="itemClassRef.classes">
    <button type="button" class="btn btn-inline" @click="onClick">
      <div class="member-icon">
        <img v-if="isSuggestedRef" src="@/assets/icons/person_dotted_80.png">
        <img v-else src="@/assets/icons/person_gray_150.png">
      </div>
      <div class="member-name">
        {{ displayNameRef }}
        <span v-if="props.pm.viewPerson?.assumeDeceased" class="deceased">(deceased)</span>
      </div>
      <div class="member-status">
        <div class="status-icon">
          <PlusIcon v-if="props.pm.member?.role == GroupMemberRole.Recruiter"></PlusIcon>
        </div>
        <span class="show-md">{{ itemClassRef.text }}</span>
      </div>
    </button>
  </div>
</template>

<style lang="scss" scoped>

button {
  display: grid;
  grid-template-columns: 25px 25px max-content;

  @media (min-width: 768px) {
    grid-template-columns: 25px 300px max-content;
  }
}

.member-icon img {
  width: 18px;
  opacity: 60%;
  transform: translateY(-2px);
}

.member-name .deceased {
  font-size: 0.875rem;
  color: #aaa;
}

.member-status {
  grid-row: 1;
  grid-column: 1;
  position: relative;

  @media (min-width: 768px) {
    grid-column: 3;
  }

  .status-icon {
    position: absolute;
    width: 1.2rem;
    height: 1.2rem;
    top: 0.2rem;
    left: 0.2rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
      left: -1.5rem;
    }
  }
}

.member-item {
  color: #333 !important;

  &.owner .status-icon {
    background-image: url('../assets/icons/crown.svg');
    filter: contrast(0.5) sepia(1) hue-rotate(5deg) saturate(3);
  }

  &.share-requested, &.invited {
    .status-icon {
      background-image: url('../assets/icons/mail_gray_60.png');
      filter: brightness(0) saturate(100%) invert(63%) sepia(76%) saturate(5097%) hue-rotate(184deg) brightness(100%) contrast(94%);
    }
  }

  &.recruiter .status-icon {
    svg {
      color: #888;
    }
  }

  &.profile {
    .member-icon img {
      opacity: 30%;
    }

    .member-name, .member-status {
      color: #888 !important;
    }
  }

  &.suggested-profile, &.suggested-member {
    .member-icon img {
      opacity: 80%;
    }
  }

  &.suggested-profile {
    .member-name, .member-status {
      color: #bbb !important;
    }
  }

  &.suggested-member {
    .member-name, .member-status {
      color: #bbb !important;
    }

    .member-status {
      color: #888 !important;
    }
  }
}
</style>

<script setup lang="ts">
import { computed } from 'vue'
import { usePersonStore } from '@/rd/PersonStore'
import { useViewFamilyStore } from '@/rd/ViewFamilyStore'
import { useFamilyGroupStore } from '@/gp/FamilyGroupStore'
import { PotentialMember } from '@/manage/FamilyGroupMemberManager'
import { GroupMemberRole, InvitationResult } from '@/gp/GroupAdminModel'
import _ from 'lodash'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { isDefined } from '@/util/TypeScriptUtil'
import { PlusIcon } from '@zhuowenli/vue-feather-icons'

const props = defineProps<{
  familyGroupId: string,
  pm: PotentialMember,
  profileId: string
}>()

const emit = defineEmits([
  'click',
  'action',
])

const personStore = usePersonStore()
const familyGroupStore = useFamilyGroupStore()
const viewFamilyStore = useViewFamilyStore()
const viewPersonStore = useViewPersonStore()

const profileRef = computed(() => personStore.getAsyncPerson(props.profileId)?.data) // loaded by parent

const isScopePersonRef = computed(() => {
  const familyGroup = familyGroupStore.getAsyncGroup(props.familyGroupId)?.data // loaded by parent
  const scopeFamily = viewFamilyStore.getAsyncFamily(familyGroup?.scopeFamilyId)?.data // loaded by parent
  const scopeParents = viewPersonStore.getAsyncPersonList(scopeFamily?.spouseIds ?? []).map(a => a.data).filter(isDefined) // loaded by parent
  return scopeParents.some(vp => vp.matchIds.includes(props.profileId))
})

const showShareRequestedRef = computed(() => !props.pm.isShared && props.pm.sharingInvite?.result == InvitationResult.Pending)
const showUserInvitedRef = computed(() => !props.pm.member && props.pm.lastUserInvite?.result == InvitationResult.Pending)
const isSuggestedRef = computed(() => !props.pm.member && !props.pm.isShared)

const itemClassRef = computed(() => {
  const classes: string[] = []
  let text = ''
  if (props.pm.member?.role == GroupMemberRole.Owner) {
    classes.push('owner')
    text = 'Owner'
  }
  else if (props.pm.member?.role == GroupMemberRole.Recruiter) {
    classes.push('recruiter')
    text = 'Member'
  }
  else if (props.pm.member) {
    classes.push('member')
    text = 'Member'
  }
  else if (props.pm.isShared) {
    classes.push('profile')
    text = 'Profile'
  }
  else {
    classes.push(props.pm.user ? 'suggested-member' : 'suggested-profile')
    text = 'Suggested'
  }

  if (showShareRequestedRef.value) {
    classes.push('share-requested')
    text = 'Share requested'
  }
  if (showUserInvitedRef.value) {
    classes.push('invited')
    text = 'Invited'
  }

  return { classes, text }
})

const displayNameRef = computed(() => props.pm.displayName ?? '(no name)')

function onClick() {
  emit('click', props.pm, props.profileId)
}
</script>