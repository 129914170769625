<template>
  <div ref="modalRef" class="modal add-related-profile-modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">
            {{ titleRef }}
          </h3>
          <button type="button" class="btn-close" :disabled="busyRef" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <ProfileCreatorSelector
            ref="creatorSelectorRef"
            :choose-gender="chooseGenderRef"
            :relationship="relationshipRef"
            :related-item-id="relatedItemIdRef"
            v-model:use-existing="useExistingRef"
            :disabled="busyRef">
          </ProfileCreatorSelector>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" :disabled="!canAddRef" @click="addRelationship">
            {{ addButtonTextRef }}
          </button>
          <button type="button" class="btn btn-outline-primary" :disabled="busyRef" data-bs-dismiss="modal">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { 
  NewProfileRelationship, 
  isChildRelationship,
} from './ProfileManager'
import { Modal as BsModal } from 'bootstrap'
import ProfileCreatorSelector from './ProfileCreatorSelector.vue'

const emit = defineEmits([
  'added',
])

const relationshipRef = ref(NewProfileRelationship.Son)
const relatedItemIdRef = ref<string>()
const useExistingRef = ref(true)
const busyRef = ref(false)
const modalRef = ref<HTMLElement>()
const creatorSelectorRef = ref<InstanceType<typeof ProfileCreatorSelector>>()

const titleRef = computed(() => isChildRelationship(relationshipRef.value) ? 'Add child' : 
  relationshipRef.value == NewProfileRelationship.Husband ? 'Add husband' : 'Add wife')
const chooseGenderRef = computed(() => isChildRelationship(relationshipRef.value))
const addButtonTextRef = computed(() => busyRef.value ? 'Adding...' :
  useExistingRef.value ? 'Add relationship' : titleRef.value)
const canAddRef = computed(() => !busyRef.value && creatorSelectorRef.value?.isValid)

defineExpose({
  open,
})

function open(rel: NewProfileRelationship, relatedItemId: string) {
  relationshipRef.value = rel
  relatedItemIdRef.value = relatedItemId
  creatorSelectorRef.value?.reset()

  BsModal.getOrCreateInstance(modalRef.value!).show()
}

function close() {
  BsModal.getOrCreateInstance(modalRef.value!).hide()
}

async function addRelationship() {
  if (!creatorSelectorRef.value)
    throw 'No creator selector'

  busyRef.value = true

  const profilePersonId = await creatorSelectorRef.value.getOrCreateProfileAsync()
  await creatorSelectorRef.value.getOrCreateRelationshipAsync(profilePersonId)

  close()
  emit('added')

  busyRef.value = false
}
</script>