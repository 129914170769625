import { Environment } from '@/util/Environment'

// IMPORTANT: The MSAL JS library refers to and utlize this specific object name
// It is declared (not defined) in shims-msal.d.ts
// because it must be "visible" to the MSAL library for ALL api calls
//declare const msal: any; 

// this code is derived from the official docs
// https://learn.microsoft.com/en-us/azure/active-directory/develop/quickstart-v2-javascript

const CLIENT_ID = Environment.get('MS_GRAPH_CLIENT_ID')
let SCOPES: string[]
// eslint-disable-next-line
let SCOPES_DEFAULT = ['openid', 'profile', 'User.Read']
let myMSALObj: any;
let msalInited = false;

type AccessTokenCallback = (accessToken: string) => void;

export class MSIdentityApi {

    loadScripts(scopes: string[] = SCOPES_DEFAULT) {
        SCOPES = scopes

        // add MSAL JS to document
        const authScript = document.createElement('script');
        authScript.src = 'https://alcdn.msauth.net/browser/2.31.0/js/msal-browser.min.js';
        authScript.onload = this.initMSAL;
        document.head.appendChild(authScript);

        // Other options that MS recommends when loading script into html page (needs more investigation)
        // <script
        //         id="load-msal"
        //         src="https://alcdn.msauth.net/browser/2.31.0/js/msal-browser.js"
        //         integrity="sha384-BO4qQ2RTxj2akCJc7t6IdU9aRg6do4LGIkVVa01Hm33jxM+v2G+4q+vZjmOCywYq"
        //         crossorigin="anonymous"
        // ></script>

        
        // Note: there is also a GRAPH API JS library, but we're just making plain REST calls for now.
        // If we need more complex API calls than just grabbing profile or contacts in the future, that might warrant lib use

        // const apiScript = document.createElement('script');
        // apiScript.src = 'https://cdn.jsdelivr.net/npm/@microsoft/microsoft-graph-client/lib/graph-js-sdk.js';
        // apiScript.onload = this.loadMSGraphClient;
        // document.head.appendChild(apiScript);
    }

    signIn(callback?: AccessTokenCallback){
        const loginRequest = {
            scopes: SCOPES, 
        };

        myMSALObj.loginPopup(loginRequest)
        .then((response: any) => {
            // Now that the user is authenticated (has ID token), let's get the access token
            return myMSALObj.acquireTokenSilent({
            scopes: ['https://graph.microsoft.com/User.Read'],
            account: response.account,
            });
        })
        .then((accessTokenResponse:any) => {
            // Access token is available in accessTokenResponse.accessToken
            // Notify the caller and pass the access token along
            if (callback) {
                callback(accessTokenResponse.accessToken);
            }
        })
        .catch((err:any) => {
            // TODO: handle error
            console.error(err);
        });
    }

    // Signs the user out from the ID provider, and the entire browser session (not just this app, but any app the user is signed in to)
    async signOut() {
        myMSALObj.logout()
        .then(() => {
            // Logout successful
            // console.log('Logout successful.');
        })
        .catch((error:any) => {
            // Handle logout error
            console.error('Logout error:', error);
        });
    }

    isSignedInAsync() {
        const accounts = myMSALObj.getAllAccounts();
        return accounts.length > 0;
    }

    getMSALInited(){
        return msalInited;
    }

    // Callback after the API client is loaded (loadGoogleApiClient). 
    // Loads the discovery doc to initialize the API client
    private initMSAL() {
        const msalConfig = {
            auth: {
              clientId: CLIENT_ID,
              // consumers | common see "authority" section here: https://learn.microsoft.com/en-us/azure/active-directory/develop/msal-client-application-configuration
              authority: 'https://login.microsoftonline.com/common', 
              redirectUri: 'https://www.treecrossing.com', 
            },
            cache: {
                cacheLocation: 'localStorage', // OPTIONAL: This configures where your cache will be stored
                storeAuthStateInCookie: false, // OPTIONAL: Set this to "true" if you are having issues on IE11 or Edge
            },
          };

          myMSALObj = new msal.PublicClientApplication(msalConfig);
          msalInited = true;
      }
}