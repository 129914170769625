<template>
  <div class="doc-page">
    <h1>{{ title }}</h1>
    <h4>What is the Sync app?</h4>
    <p>
      The Sync app helps you upload your tree, then keep the online tree up-to-date as you continue making changes.
      It's easy to use, and it only takes a few moments to sync.
    </p>
    <p>
      <router-link :to="{ name: 'syncapp' }">Get the Sync app</router-link>
    </p>
    <h4>How does it work?</h4>
    <p>
      The Sync app reads your genealogy data file on your computer, looking for new or updated information that hasn't been synced yet.
      It then updates your online tree with any changes it finds.
    </p>
    <p> 
      No changes are <b>ever</b> made to any files on your computer.
      Your copy is <b>always</b> the master copy, so you are always in control.
    </p>
    <p>
      It's easy to sync, and you can do it as often as you want.
      The Sync app is smart enough only upload what has changed, so you won't have to upload you entire tree very time.
    </p>
    <h4>What types of trees does the Sync app support?</h4>
    <p>Tree Crossing Sync supports a number of different types of trees today, with more on the way.</p>
    <p>
      <router-link :to="{ name: 'syncapp' }">See which trees are supported</router-link>
    </p>
    <h4>What gets synced?</h4>
    <h5>Data types</h5>
    <p>
      The Sync app will upload the following data from your tree:
      <ul>
        <li>People</li>
        <li>Families</li>
        <li>Events/Facts</li>
        <li>Places</li>
        <li>Addresses/Sites</li>
      </ul>
    </p>
    <div class="inline-question">
      <button type="button" class="btn btn-inline btn-link" data-bs-toggle="collapse" data-bs-target="#otherDataTypes">What about sources, documents, photos, etc.?</button>
      <div id="otherDataTypes" class="collapse">
        We're working on supporting more data types! <router-link to="/support">Let us know</router-link> what you'd like to see next.
      </div>
    </div>
    <h4 id="privacy">What about privacy?</h4>
    <h5>Living people</h5>
    <p>
      We try to provide a great experience for everyone in your family, while keeping the right people in control of their own data.
    </p>
    <p>
      Here's what the Sync app does, before it uploads any data from your tree:
      <ul>
        <li>The app identifies "living or recently living" people.</li>
        <li>It blocks upload of any details about living people in this group, except their <b>name</b>, <b>gender</b>, and <b>relationships</b>.</li>
        <li>It gives you the option to omit specific people completely.</li>
      </ul>
    </p>
    <p>
      Here's what happens with living or recently living people once your tree has been uploaded:
      <ul>
        <li>Separate profiles are created for them that can be managed by individual owners.</li>
        <li>When you invite someone to join a group and start exploring your tree, you include their profile in the invitation.</li>
        <li>When they accept, they become the owner of that profile.</li>
      </ul>
      As the profile owner, they can then decide what (if any) information they want to continue sharing, and with whom.
      If they add more information to their profile, it won't be synced back to your computer.
    </p>
    <p>
      The Sync app treats a person in your tree as "living or recently living" if:
      <ul>
        <li>No death (or similar event, e.g. burial or cremation) is recorded, <b>AND</b></li>
        <li>The date of their birth (or similar event, e.g. christening) was less than 110 years ago, <b>OR</b></li>
        <li>No birth event is recorded, and their estimated* birth year is less than 110 years ago</li>
      </ul>
    </p>
    <P>
      <small>* The Sync app estimates birth years based on birth and death dates of the person's closest relatives.</small>
    </P>
    <h5>Ancestors</h5>
    <p>
      Data about everyone else in your tree, and shared data such as places that are associated with both living and 
      deceased people, are uploaded as-is, unless you've specifically marked them as "private" in your tree.
    </p>
    <p>
      <router-link to="/privacy">Learn more about privacy</router-link>
    </p>
    <h4>Who can see my tree?</h4>
    <p>
      When you upload your tree, it is only visible to you by default.
      You can choose to share it with others by creating a group, sharing your tree with the group, and inviting others to join the group.
    </p>
  </div>
</template>

<style lang="scss" scoped>
h4 {
  margin-top: 3rem;
  color: rgb(214, 96, 33);
}

h4::before {
  content: "";
  display: block;
  height: 60px;
  margin-top: -60px;
  visibility: hidden;
}

p {
  max-width: 900px;
}
.file-type-list {
  display: grid;
  grid-auto-columns: 6rem;
  grid-auto-flow: column;
  column-gap: 1rem;
  justify-items: center;

  img {
    width: 5rem;
    height: 5rem;
    margin: 0 1rem;
    border-radius: 18px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3);
  }

  div {
    font-size: 0.875rem;
    text-align: center;
  }

  .not-supported {
    img {
      opacity: 0.2;
      filter: saturate(40%);
      box-shadow: none;
    }
    div {
      color: #bbb;
    }
  }
}

.inline-question {
  margin-bottom: 1rem;

  button {
    margin-bottom: 0.5rem;
    font-size: 0.85rem;
  }

  .collapse, .collapsing {
    padding: 1rem;
    background-color: #fefdeb;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    font-size: 0.85rem;
    //color: #bbb;
  }
}

.download-app {
  width: 8rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  
  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }

  .app-logo {
    // display: block;
    // margin: 1rem 5rem 0 0;
    width: 5rem;
    border-radius: 18px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.3);
  }

  .download-badge {
    // display: block;
    height: 2.5rem; // MS guideline is min 32px
    // margin: 1rem 0;
  }
}

.platform-alert svg {
  color: orange;
}
</style>

<script lang="ts" setup>
import { usePageTitle } from '@/util/AppUtil'
import { PlatformUtil } from '@/util/PlatformUtil'
import { AlertTriangleIcon } from '@zhuowenli/vue-feather-icons';

const title = usePageTitle('How does the Sync app work?')

const isWindows = PlatformUtil.isWindows()

</script>