<template>
  <div>
    <h1>Signing out...</h1>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { usePageTitle } from '@/util/AppUtil'
import { TokenManager } from "@/auth/TokenManager"

usePageTitle("Signing out")

onMounted(() => {
    TokenManager.clearToken();
    window.location.href = "/";
  }
)
</script>