<template>
  <div class="profile-relationships">
    <section class="spouses-area">
      <h5>{{ pluralize(spouseViewFamiliesRef.length, "Spouse", "Spouses", "Spouse") }}</h5>
      <ul v-if="spouseViewFamiliesRef.length" class="list-unstyled spouses-list">
        <li v-for="sf in spouseViewFamiliesRef" :key="sf.family.id">
          <ProfileSpouseRelationship
            :person-id="props.personId"
            :spouse-family="sf"
            v-model:expanded-id="expandedFamilyIdRef"
            @add-child="onAddChild(sf.family)"
            @remove="onRemoveSpouse(sf)">
          </ProfileSpouseRelationship>
        </li>
      </ul>
      <div v-else-if="!canEditRef" class="no-spouses">
        None
      </div>
      <div class="action-row">
        <button type="button" v-if="canEditRef" class="btn btn-inline btn-link action" @click="addSpouse">
          Add a spouse
        </button>
      </div>
    </section>
    <section class="parents-area">
      <h5>Parents</h5>
      <ul v-if="parentViewFamiliesRef.length" class="list-unstyled parents-list">
        <li v-for="pf in parentViewFamiliesRef" :key="pf.family.id">
          <ProfileParentRelationship
            :person-id="props.personId"
            :parent-family="pf"
            @add-sibling="onAddSibling(pf.family)"
            @remove="onRemoveParents">
          </ProfileParentRelationship>
        </li>
      </ul>
      <div v-else-if="!canEditRef" class="no-parents">
        None
      </div>
      <div class="action-row">
        <button type="button" v-if="canEditRef" class="btn btn-inline btn-link action" @click="addParents">
          Add parents
        </button>
      </div>
    </section>
    <ProfileAddRelatedModal ref="addRelatedModal" @added="emit('reload')"></ProfileAddRelatedModal>
    <ProfileSpouseRemoveModal ref="removeSpouseModalRef"></ProfileSpouseRemoveModal>
    <ProfileChildRemoveModal ref="removeChildModalRef" @removed="emit('reload')"></ProfileChildRemoveModal>
    <ProfileAddParentsModal ref="addParentsModalRef" @added="emit('reload')"></ProfileAddParentsModal>
  </div>
</template>

<style lang="scss" scoped>
.profile-relationships {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  
  h5 {
    font-size: 1rem;
    font-weight: normal;
  }

  .no-spouses, .no-parents {
    margin-left: 1rem;
    font-size: 0.875rem;
    color: #aaa;
  }

  .action-row {
    margin-left: 1rem;
    display: flex;
    gap: 1rem;

    .action {
      font-size: 0.875rem;
    }
  }

  .spouses-list {
    margin: 0 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  .parents-list {
    margin: 0 0 1rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  label {
    font-size: 0.875rem;
    color: #888;

    input[type="checkbox"] {
      margin-right: 0.5rem;
    }
  }
}
</style>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import { Gender, ViewFamily } from '@/rd/ResearchDataModel'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { useFamilyStore } from '@/rd/FamilyStore'
import { FamilyAndChildren, useViewFamilyStore } from '@/rd/ViewFamilyStore'
import { usePersonStore } from '@/rd/PersonStore'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { ItemPermissions } from '@/gp/GroupAdminModel'
import { CompositeId } from '@/rd/CompositeId'
import { pluralize } from '@/util/LanguageUtil'
import { NewProfileRelationship } from './ProfileManager'
import { notImplemented } from '@/util/AppUtil'
import _ from 'lodash'
import ProfileSpouseRelationship from './ProfileSpouseRelationship.vue'
import ProfileParentRelationship from './ProfileParentRelationship.vue'
import ProfileAddRelatedModal from './ProfileAddRelatedModal.vue'
import ProfileSpouseRemoveModal from './ProfileSpouseRemoveModal.vue'
import ProfileChildRemoveModal from './ProfileChildRemoveModal.vue'
import ProfileAddParentsModal from './ProfileAddParentsModal.vue'

const props = defineProps<{
  personId: string,
}>()

const emit = defineEmits([
  'reload',
])

const viewPersonStore = useViewPersonStore()
const viewFamilyStore = useViewFamilyStore()
const personStore = usePersonStore()
const dataGroupStore = useDataGroupStore()

const profileGroupIdRef = computed(() => CompositeId.getGroupId(props.personId))
const personRef = computed(() => personStore.getAsyncPerson(props.personId)?.data)
const viewPersonRef = computed(() => viewPersonStore.getLoadedPerson(props.personId))
const spouseViewFamiliesRef = computed(() => {
  console.log('Compute spouseViewFamiliesRef')
  const vpId = viewPersonRef.value?.id // use view person id to get related families
  if (!vpId)
    return []

  const spouseViewFamilies = viewFamilyStore.getLoadedSpouseFamilies(vpId).spouseFamilies
  const spouseFamAndChildren: FamilyAndChildren[] = []
  for (const sf of spouseViewFamilies) {
    const children = viewFamilyStore.getLoadedChildren(sf.id!).children
    spouseFamAndChildren.push({
      family: sf,
      childIds: children.map(ch => ch.id!),
    })
  }
  return spouseFamAndChildren
})
const parentViewFamiliesRef = computed(() => {
  console.log('Compute parentViewFamiliesRef')
  const vpId = viewPersonRef.value?.id // use view person id to get related families
  if (!vpId)
    return []

  const parentViewFamilies = viewPersonStore.getLoadedParentFamilies(vpId).parentFamilies
  const parentFamAndChildren: FamilyAndChildren[] = []
  for (const pf of parentViewFamilies) {
    const children = viewFamilyStore.getLoadedChildren(pf.id!).children
    parentFamAndChildren.push({
      family: pf,
      childIds: children.map(ch => ch.id!),
    })
  }
  return parentFamAndChildren
})
const permissionsRef = computed(() => dataGroupStore.getAsyncPermissions(profileGroupIdRef.value)?.data ?? ItemPermissions.None)
const canEditRef = computed(() => (permissionsRef.value & ItemPermissions.Modify) > 0)

const expandedFamilyIdRef = ref<string>()
const addRelatedModal = ref<InstanceType<typeof ProfileAddRelatedModal>>()
const removeSpouseModalRef = ref<InstanceType<typeof ProfileSpouseRemoveModal>>()
const removeChildModalRef = ref<InstanceType<typeof ProfileChildRemoveModal>>()
const addParentsModalRef = ref<InstanceType<typeof ProfileAddParentsModal>>()

function addSpouse() {
  const personGender = personRef.value?.displayProperties.gender
  if (!personGender) {
    alert('Please enter the gender for this profile before adding a spouse.')
    return
  }

  const spouseRel = personGender == Gender.Male
    ? NewProfileRelationship.Wife
    : NewProfileRelationship.Husband

  addRelatedModal.value?.open(spouseRel, props.personId)
}

function onAddChild(spouseFamily: ViewFamily) {
  addRelatedModal.value?.open(NewProfileRelationship.Son, spouseFamily.id!)
}

function onRemoveSpouse(spouseFamily: FamilyAndChildren) {
  removeSpouseModalRef.value?.open(props.personId, spouseFamily)
}

function onAddSibling(parentFamily: ViewFamily) {
  addRelatedModal.value?.open(NewProfileRelationship.Son, parentFamily.id!)
}

function addParents() {
  addParentsModalRef.value?.open(props.personId)
}

function onRemoveParents(parentFamily: ViewFamily) {
  removeChildModalRef.value?.open(props.personId, parentFamily, true)
}

// list matches
// match/unmatch
// show person sets and owners
// don't confuse matches and profile relationships (match placeholder to primary)

// show view spouse?
// show direct spouse relationships (defined via placeholder match by this or the other profile)
// show family details somehow?
// add/remove spouses
// ISSUE: spouse family is replicated in child profiles, so all copies would need to be "disputed"

// show direct parent relationships (defined via placeholder match in this profile)
// add/remove parent relationships

</script>
