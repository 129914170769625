<template>
  <div class="signup-page container" :class="{ embedded: isEmbeddedRef }">
    <form>
      <section v-if="!signUpSucceededRef" class="info-section">
        <div v-if="isEmbeddedRef" class="instruction">
          Let's create an account for you.
        </div>
        <div v-else>
          <h1 class="page-title with-subhead">Get started</h1>
          <p class="subhead">Tell us your name, and where to send a sign-in code.</p>
        </div>
        <div class="alert alert-warning alert-sm">
          <div>
            We're in private preview. If you're not approved yet, you can still sign up. 
            We'll let you know soon when you can try it out!
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <label for="givenNameInput" class="form-label">Given name</label>
            <input type="text" class="form-control" id="givenNameInput" ref="givenNameInputRef" :readonly="!canEditRef" v-model="givenNameRef"/>
          </div>
          <div class="col-sm-6">
            <label for="familyNameInput" class="form-label">Surname</label>
            <input type="text" class="form-control" id="familyNameInput" :readonly="!canEditRef" v-model="familyNameRef"/>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-8">
            <label for="addressInput" class="form-label">Email address</label>
            <input type="text" class="form-control" id="addressInput" ref="addressInputRef" :readonly="!canEditRef" v-model="addressRef"/>
          </div>
        </div>
        <vue-turnstile :site-key="tsKey" :appearance="captchaAppearanceRef" theme="light" v-model="captchaCodeRef" style="display: flex"  />
        <button type="submit" class="btn btn-primary" :disabled="!canSignUpRef" @click="signUp">Sign up</button>
      </section>
      <section v-else class="disabled-section">
        <h1>Thanks for signing up!</h1>
        <p>As soon as we enable your account, we'll send you an email so you can try things out.</p>
      </section>
    </form>
    <section class="secondary-actions">
      <router-link v-if="!signUpSucceededRef" to="/signin">I already have an account</router-link>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.signup-page.embedded {
  max-width: none;
}

.signup-page:not(.embedded) {
  max-width: 600px;
  min-height: 500px;
  margin-top: 4rem;
  border: 1px solid #ddd;
  border-radius: 1rem;
  padding: 1rem 2rem 2rem 2rem;
}

.instruction {
  line-height: 2;
  font-size: 14pt;
  margin-bottom: 0.5rem;
}

.row {
  margin-bottom: 1rem;
}

button[type="submit"] {
  margin-top: 1rem;
}

.secondary-actions {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-items: start;
  font-size: 0.875rem;
}
</style>

<script setup lang="ts">
import { ref, computed, onMounted, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { useAppHostStore } from '@webapp/AppHostStore'
import { usePageTitle } from '@webapp/util/AppUtil'
import { useSignInStore } from '@webapp/auth/SignInStore'
import { IdentityApi, SignUpRequest } from '@webapp/auth/IdentityApi'
import { AddressUtil } from '@webapp/util/AddressUtil'
import { Environment } from '@webapp/util/Environment'
import VueTurnstile from 'vue-turnstile'

usePageTitle("Sign up")

const appHostStore = useAppHostStore()
const isEmbeddedRef = computed(() => appHostStore.isEmbedded)
const signInStore = useSignInStore()
const router = useRouter()

const captchaCodeRef = ref("")
const givenNameRef = ref(signInStore.givenName)
const familyNameRef = ref(signInStore.familyName)
const addressRef = ref(signInStore.address)
const canEditRef = computed(() => !busyRef.value)
const canSignUpRef = computed(() => !busyRef.value && (givenNameRef.value || familyNameRef.value) && AddressUtil.isValidEmail(addressRef.value) && (isDevEnv || captchaCodeRef.value))
const signUpSucceededRef = ref(false)
const busyRef = ref(false)
const isDevEnv = Environment.isDevelopment()
const captchaAppearanceRef = computed(() => isDevEnv && router.currentRoute.value.query.forceCaptcha ? "always" : "interaction-only")
const tsKey = Environment.get('TURNSTILE_KEY')

const givenNameInputRef = ref<HTMLInputElement | null>(null)
const addressInputRef = ref<HTMLInputElement | null>(null)

async function signUp(e: Event) {
  e.preventDefault()
  busyRef.value = true
  
  const request = {
    givenName: givenNameRef.value,
    familyName: familyNameRef.value,
    address: addressRef.value,
    captchaCode: captchaCodeRef.value,
  } as SignUpRequest

  const newIdent = await IdentityApi.signUpAsync(request)
  try {
    await IdentityApi.sendSignInCodeAsync(addressRef.value)

    // store this so the next page can use it
    signInStore.givenName = givenNameRef.value

    // store this so the next page known to come back here if requested
    signInStore.isNewUser = true

    // store these so we can reload them if they come back
    signInStore.familyName = familyNameRef.value
    signInStore.address = addressRef.value

    router.push("/signincode")
  }
  catch (err: any) {
    if (err.statusCode == 403) {
      // sign up succeeded, but don't redirect, because sign in is not allowed
      signUpSucceededRef.value = true
    }
    busyRef.value = false
  }
}

onMounted(() => {
  if (givenNameRef.value) {
    addressInputRef.value!.focus()
    addressInputRef.value!.select()
  }
  else {
    givenNameInputRef.value!.focus()
  }
})

</script>
