<template>
  <Modal ref="modalRef" @ok="removeChild">
    <template #title>
      {{ titleRef }}
    </template>
    <p>{{ messageRef }}</p>
    <div class="parents-card">
      <FamilyCard
        :family-id="parentFamilyRef?.id">
      </FamilyCard>
    </div>
    <div v-if="!removeParentsRef" class="child-card">
      <PersonCard 
        :person-id="childIdRef"
        layout="small"
        show-profile-type
        show-profile-count="multiple"
        select-mode>
      </PersonCard>
    </div>
    <p>Only the relationship will be deleted.</p>
    <template #okText>Remove</template>
  </Modal>
</template>

<style lang="scss" scoped>
.parents-card {
  margin-left: 2rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.child-card {
  margin-left: 4rem;
  margin-bottom: 1rem;

  .person-card {
    width: 400px;
  }
  
}
</style>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { AssertionType, ViewFamily } from '@/rd/ResearchDataModel'
import { useAssertionStore } from '@/rd/AssertionStore'
import { CompositeId } from '@/rd/CompositeId'
import { AsyncUtil } from '@/util/AsyncUtil'
import Modal from '@/util/Modal.vue'
import PersonCard from './PersonCard.vue'
import FamilyCard from './FamilyCard.vue'

const assertionStore = useAssertionStore()

const childIdRef = ref<string>()
const parentFamilyRef = ref<ViewFamily>()
const removeParentsRef = ref(false)
const modalRef = ref<InstanceType<typeof Modal>>()

const titleRef = computed(() => removeParentsRef.value ? 'Remove parents' : 'Remove child')
const messageRef = computed(() => removeParentsRef.value 
  ? "Are you sure you want to remove the child's relationship to these parents?"
  : 'Are you sure you want to remove this child?')

defineExpose({
  open,
})

const emit = defineEmits([
  'removed',
])

function open(childId: string, parentFamily: ViewFamily, removeParents = false) {
  childIdRef.value = childId
  parentFamilyRef.value = parentFamily
  removeParentsRef.value = removeParents
  modalRef.value?.open()
}

async function removeChild() {
  if (!childIdRef.value || !parentFamilyRef.value)
    throw 'Child or parent family not set'

  const profileGroupId = CompositeId.getGroupId(childIdRef.value)!
  const familyId = parentFamilyRef.value?.matchIds.find(id => CompositeId.hasGroupId(id, profileGroupId))
  if (!familyId)
    throw `Family match not found in profile ${profileGroupId}`

  const childAssertion = assertionStore.getLoadedForPerson(childIdRef.value)
    .find(a => a.assertionType == AssertionType.Child && parentFamilyRef.value?.matchIds.includes(a.relatedItemId!))
  if (!childAssertion)
    throw `Child assertion in ${childIdRef.value} not found for family ${parentFamilyRef.value.id}`

  await AsyncUtil.atLeast(500, assertionStore.deleteAsync(childAssertion.id!))

  emit('removed')

  modalRef.value?.close()
}
</script>
