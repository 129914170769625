import { ref, computed, MaybeRef, toValue } from 'vue'
import { AsyncUtil } from './AsyncUtil'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useEditMode<T extends {[key:string]:any}>(
    origObj: MaybeRef<T | undefined>, 
    editObj: T, 
    editProperties: string[], 
    allowEdit?: MaybeRef<boolean>,
    allowSave?: MaybeRef<boolean>) {

    const _isEditing = ref(false)
    const _busy = ref(false)

    const isEditing = computed(() => _isEditing.value)
    const busy = computed(() => _busy.value)
    const canEdit = computed(() => !isEditing.value && (toValue(allowEdit) ?? true))
    const canEditValues = computed(() => isEditing.value && !busy.value)

    const hasChanges = computed(() => {
        const unrefObj = toValue(origObj)
        if (unrefObj) {
            for (const key of editProperties) {
                if (editObj[key] != unrefObj[key]) {
                    return true
                }
            }
            return false
        }
        return true
    })
    
    const canSave = computed(() => isEditing.value && hasChanges.value && !busy.value && (toValue(allowSave) ?? true))
    const canCancel = computed(() => isEditing.value && !busy.value)
    const saveText = computed(() => busy.value ? "Saving..." : "Save")

    function edit() {
        if (canEdit.value) {
            if (toValue(origObj)) {
                Object.assign(editObj, toValue(origObj)) // if editObj is a reactive proxy, this will trigger reactive effects
            }
            _isEditing.value = true
        }
    }

    async function saveChanges(doSave: () => void | Promise<void>) {
        _busy.value = true

        try {
            await AsyncUtil.atLeast(500, doSave())
            _isEditing.value = false;
        } finally {
            _busy.value = false
        }
    }

    function cancel() {
        _isEditing.value = false;
    }
      
    return {
        isEditing,
        busy,
        canEdit,
        canEditValues,
        edit,
        saveText,
        hasChanges,
        canSave,
        saveChanges,
        canCancel,
        cancel,
    }    
}