import { DataGroup, DataGroupType } from "@/gp/GroupAdminModel"
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { Assertion, AssertionType, Family, FamilyRelationshipRole, Gender, ItemType, PersonName, PersonSet, ViewFamily, ViewPerson } from "@/rd/ResearchDataModel"
import { useAssertionStore } from "@/rd/AssertionStore"
import { useFamilyStore } from "@/rd/FamilyStore"
import { usePersonStore } from "@/rd/PersonStore"
import { useViewFamilyStore } from "@/rd/ViewFamilyStore"
import { useViewPersonStore } from "@/rd/ViewPersonStore"
import { CompositeId } from "@/rd/CompositeId"
import { isDefined } from "@/util/TypeScriptUtil"
import { TokenManager } from "@/auth/TokenManager"
import { PatchChange } from "@/util/Api"
import { AsyncData, LoadMode } from "@/util/AsyncData"
import _ from "lodash"
import { ProfileUtils } from "./ProfileUtils"

export enum NewProfileRelationship {
    Son = 1, // ensure truthy
    Daughter,
    Husband,
    Wife,
    Father,
    Mother,
}

export const newProfileRelationships: ReadonlyArray<NewProfileRelationship> = [
    NewProfileRelationship.Son,
    NewProfileRelationship.Daughter,
    NewProfileRelationship.Husband,
    NewProfileRelationship.Wife,
    NewProfileRelationship.Father,
    NewProfileRelationship.Mother,
]

export function isChildRelationship(rel: NewProfileRelationship) {
    return rel == NewProfileRelationship.Son || rel == NewProfileRelationship.Daughter
}

export function isSpouseRelationship(rel: NewProfileRelationship) {
    return rel == NewProfileRelationship.Husband || rel == NewProfileRelationship.Wife
}

export function isParentRelationship(rel: NewProfileRelationship) {
    return rel == NewProfileRelationship.Father || rel == NewProfileRelationship.Mother
}

export function getNewProfileRelationshipGender(rel: NewProfileRelationship) {
    switch (rel) {
        case NewProfileRelationship.Son:
        case NewProfileRelationship.Husband:
        case NewProfileRelationship.Father:
            return Gender.Male

        case NewProfileRelationship.Daughter:
        case NewProfileRelationship.Wife:
        case NewProfileRelationship.Mother:
            return Gender.Female

        default: throw 'Invalid relationship'
    }
}

export function getNewProfileRelationshipText(rel: NewProfileRelationship) {
    switch (rel) {
        case NewProfileRelationship.Son:        return 'Son'
        case NewProfileRelationship.Daughter:   return 'Daughter'
        case NewProfileRelationship.Husband:    return 'Husband'
        case NewProfileRelationship.Wife:       return 'Wife'
        case NewProfileRelationship.Father:     return 'Father'
        case NewProfileRelationship.Mother:     return 'Mother'
        default: throw 'Invalid relationship'
    }
}

export function getNewProfileSearchRelatedPlaceholder(rel: NewProfileRelationship) {
    switch (rel) {
        case NewProfileRelationship.Son:        return "Type a parent's name"
        case NewProfileRelationship.Daughter:   return "Type a parent's name"
        case NewProfileRelationship.Husband:    return "Type wife's name"
        case NewProfileRelationship.Wife:       return "Type husband's name"
        case NewProfileRelationship.Father:     return "Type child's name"
        case NewProfileRelationship.Mother:     return "Type child's name"
        default: throw 'Invalid relationship'
    }
}

export function canCreateChildRelationship(childId: string | undefined, parentFamily: ViewFamily) {
    // user must be owner of at least one parent family spouse profile (tree profile okay, not placeholder)
    const viewPersonStore = useViewPersonStore()
    const dataGroupStore = useDataGroupStore()

    const fa = viewPersonStore.getLoadedPerson(parentFamily.fatherId)
    const mo = viewPersonStore.getLoadedPerson(parentFamily.motherId)
    const parentIds = [...new Set([...(fa?.matchIds ?? []), ...(mo?.matchIds ?? [])])]
    const groupIds = parentIds.map(id => CompositeId.getGroupId(id)!)
    const dataGroups = dataGroupStore.getLoadedGroupList(groupIds)
    const parentProfileIds = ProfileUtils.getNonPlaceholderIds(parentIds, dataGroups)
    const parentGroupIds = parentProfileIds.map(id => CompositeId.getGroupId(id)!)
    const parentProfileGroups = dataGroups.filter(g => parentGroupIds.includes(g.id!))
    return parentProfileGroups.some(g => g.ownerId == TokenManager.userId)
}

/**
 * Get or create a relationship defined in the child's profile group between the child and the specified parent view family.
 * @param childId id of an existing profile person representing the child
 * @param parentFamilyId id of an existing parent view family to which the relationship will be created (using a matching family in the child's profile group)
 */
export async function getOrCreateChildRelationshipAsync(childId: string, parentFamilyId: string) {
    // NOTE: Caller should warn user if adding child to incomplete parent family, because child's placeholder
    // parent family (used to match spouse family) will not have both spouses

    const viewPersonStore = useViewPersonStore()
    const viewFamilyStore = useViewFamilyStore()

    const viewChild = await viewPersonStore.getAsyncPerson(childId, LoadMode.EnsureLoaded)?.whenLoadCompleted
    if (!viewChild)
        throw `Error loading view person for child ${childId}`

    const viewFamily = await viewFamilyStore.getAsyncFamily(parentFamilyId, LoadMode.EnsureLoaded)?.whenLoadCompleted
    if (!viewFamily)
        throw `Error loading parent family ${parentFamilyId}`

    const childProfileGroupId = CompositeId.getGroupId(childId)!

    const profileFamily = await getOrCreateProfileFamilyAsync(childProfileGroupId, viewFamily.fatherId, viewFamily.motherId)

    // check if child assertion already exists (not possible if family was just created)
    const personStore = usePersonStore()
    const assertionStore = useAssertionStore()
    await personStore.loadDetailsAsync(childId, LoadMode.EnsureLoaded)
    let childAssertion = assertionStore.getLoadedForPerson(childId)
        .find(a => a.assertionType == AssertionType.Child && a.relatedItemId == profileFamily.id)
    if (!childAssertion) {
        childAssertion = await createProfileChildAssertionAsync(childId, profileFamily.id!)

        // invalidate child keys for family (must use view family id as key)
        viewFamilyStore.clearCacheFor(viewFamily.id)
        AsyncData.invalidate(viewFamilyStore.asyncChildKeys.get(viewFamily.id!))

        // invalidate parent family keys for child (must use view person id as key)
        AsyncData.invalidate(viewPersonStore.asyncParentFamilyKeys.get(viewChild.id!))
    }
    
    return childAssertion.id
}

export async function getOrCreateProfileFamilyAsync(profileGroupId: string, fatherId: string | undefined, motherId: string | undefined) {
    const familyStore = useFamilyStore()

    const { matchingViewFamily, father, mother } = await getMatchingViewFamilyAsync(fatherId, motherId)

    const sameGroupFamilyId = matchingViewFamily?.matchIds.find(id => CompositeId.hasGroupId(id, profileGroupId))
    if (sameGroupFamilyId) {
        const existingFamily = await familyStore.getAsyncFamily(sameGroupFamilyId, LoadMode.EnsureLoaded)?.whenLoadCompleted
        if (!existingFamily)
            throw `Existing family ${sameGroupFamilyId} not found`

        return existingFamily
    }

    const sameGroupFatherId = father ? await getOrCreateSameGroupPersonAsync(profileGroupId, father) : undefined
    const sameGroupMotherId = mother ? await getOrCreateSameGroupPersonAsync(profileGroupId, mother) : undefined
    const sameGroupFamily =
    {
        fatherId: sameGroupFatherId,
        motherId: sameGroupMotherId,
    }
    const newFamilyId = await familyStore.addAsync(profileGroupId, sameGroupFamily)

    if (matchingViewFamily) {
        // we created a new matching same-group family, so reload the updated view family (eager)
        const viewFamilyStore = useViewFamilyStore()
        await viewFamilyStore.getAsyncFamily(matchingViewFamily.id, LoadMode.Reload)?.whenLoadCompleted
    }
    else {
        // we created a new view family, so invalidate spouse family keys (lazy)
        const viewPersonStore = useViewPersonStore()
        if (father) {
            AsyncData.invalidate(viewPersonStore.asyncSpouseFamilyKeys.get(father.id!))
        }
        if (mother) {
            AsyncData.invalidate(viewPersonStore.asyncSpouseFamilyKeys.get(mother.id!))
        }
    }

    return familyStore.getAsyncFamily(newFamilyId)!.data! // just added, so should be present
}

async function getMatchingViewFamilyAsync(fatherId: string | undefined, motherId: string | undefined) {
    if (!fatherId && !motherId)
        throw 'Either father or mother must be specified'

    const viewPersonStore = useViewPersonStore()
    const viewFamilyStore = useViewFamilyStore()

    const spouseId = (fatherId ?? motherId)!

    const familyKeyList = await viewPersonStore.getAsyncSpouseFamiliesKeyList(spouseId, LoadMode.EnsureLoaded)?.whenLoadCompleted
    const asyncViewFamilies = viewFamilyStore.getAsyncFamilyList(familyKeyList?.keys ?? [], LoadMode.EnsureLoaded)
    const viewFamilies = (await Promise.all(asyncViewFamilies.map(async f => f.whenLoadCompleted)))
        .filter(isDefined)

    // the spouse ids passed in are not necessarily the same as the view family spouse ids, and the view family
    // doesn't include a list of all spouse match ids. So, to find the right family, we need to get the view
    // person ids of each spouse (these WILL match the view family spouse ids).
    const spouses = await viewPersonStore.getPersonListAsync([fatherId, motherId])
    const father = fatherId ? spouses.find(vp => vp.matchIds.includes(fatherId)) : undefined
    const mother = motherId ? spouses.find(vp => vp.matchIds.includes(motherId)) : undefined
    if (fatherId && !father)
        throw `Father ${fatherId} not found`
    if (motherId && !mother)
        throw `Mother ${motherId} not found`

    const matchingViewFamily = (father?.id && mother?.id)
        ? viewFamilies.find(vf => vf.motherId == mother.id) // these are father's spouse families, so match mother
        : viewFamilies.find(vf => !vf.spouseOf(spouseId)) // other spouse not present

    return {
        matchingViewFamily,
        father,
        mother,
    }
}

async function getOrCreateSameGroupPersonAsync(profileGroupId: string, vp: ViewPerson) {
    const sameGroupPersonId = vp.matchIds.find(id => CompositeId.hasGroupId(id, profileGroupId))
    if (sameGroupPersonId)
        return sameGroupPersonId

    const personStore = usePersonStore()
    const viewPersonStore = useViewPersonStore()

    const placeholderId = await personStore.addPlaceholderAsync(profileGroupId)
    const allMatches = await personStore.getPersonListAsync(vp.matchIds)

    // don't include placeholders because:
    // - doing so is probably redundant (if also including the target primary person, and is normally the case)
    // - it would mean the new profile is expressing an opinion not only on its relationship to another primary person,
    //   but also on the relationship between some other primary person and that primary person, which is not intended

    // don't include restricted research group persons because the new profile is likely to be handed off to some other
    // user (likely as their user profile) who can't see the restricted persons anyway, so it will be confusing to them 
    // to see a non-visible match from day 1 in the list of matches for their own profie.
    // ("What is this? Is it a problem? How do I make it go away? Will I break something if I delete it?")

    const placeholderMatchIds = allMatches.filter(isDefined)
        .filter(p => (p.profile && !p.placeholder) || (!p.profile && !p.restricted))
        .map(p => p.id!)

    await viewPersonStore.extendPersonSetAsync([[placeholderId, ...placeholderMatchIds]], profileGroupId)
    viewPersonStore.clearCacheFor(vp.id)

    return placeholderId
}

/**
 * Get or create a spouse relationship to the specified spouse in a person's profile group, creating a 
 * spouse placeholder and profile family as needed.
 * @param personId id of an existing profile person for which the spouse relationship will be created
 * @param spouseId id of an existing person representing the spouse (may or may not be in the same group)
 * @param spouseRole role of the spouse in the family relationship
 */
export async function getOrCreateSpouseRelationshipAsync(personId: string, spouseId: string, spouseRole: FamilyRelationshipRole) {
    const spouseIsFather = spouseRole == FamilyRelationshipRole.Father
    if (!spouseIsFather && spouseRole != FamilyRelationshipRole.Mother)
        throw 'Invalid spouse role'

    const profileGroupId = CompositeId.getGroupId(personId)!
    const profileViewFamily = spouseIsFather
        ? await getOrCreateProfileFamilyAsync(profileGroupId, spouseId, personId)
        : await getOrCreateProfileFamilyAsync(profileGroupId, personId, spouseId)

    return profileViewFamily.id!
}

export async function getModifiableProfilePersonIdsAsync(personId: string) {
    const viewPersonStore = useViewPersonStore()
    const dataGroupStore = useDataGroupStore()

    const viewPerson = await viewPersonStore.getAsyncPerson(personId)!.whenLoadCompleted
    if (!viewPerson)
        throw 'Existing person not found'

    const existingPersonGroups = await dataGroupStore.getGroupListAsync(viewPerson.groupIds)
    const modifiableProfilePersonGroupIds = new Set(existingPersonGroups
        .filter(g => g.groupType == DataGroupType.Profile && g.ownerId == TokenManager.userId)
        .filter(g => !g.workspaceId) // can't modify a linked profile
        .map(g => g.id!))
    return viewPerson.matchIds.filter(id => modifiableProfilePersonGroupIds.has(CompositeId.getGroupId(id)!))
}

export async function createProfilePersonAsync(np: NewProfile) {
    if (!np.givenName && !np.surname)
        throw 'Either given name or surname must be specified'

    const dataGroupStore = useDataGroupStore()
    const assertionStore = useAssertionStore()

    // create profile group (automatically creates person)
    const dg = {
        name: `${np.givenName} ${np.surname}`.trim(),
        groupType: DataGroupType.Profile,
    } as DataGroup
    const profileGroupId = await dataGroupStore.addAsync(dg)
    const dataGroup = dataGroupStore.getAsyncGroup(profileGroupId)!.data!
    const personId = dataGroup.startItemId!

    // add name assertion
    const name = new PersonName()
    name.given = np.givenName
    name.surname = np.surname
    const nameAssertion = {
        subjectType: ItemType.Person,
        subjectId: personId,
        assertionType: 'Name',
        value: name.getValue(),
    } as Assertion
    await assertionStore.addAsync(profileGroupId, nameAssertion)

    // add gender assertion
    const genderAssertion = {
        subjectType: ItemType.Person,
        subjectId: personId,
        assertionType: 'Gender',
        value: np.gender,
    } as Assertion
    await assertionStore.addAsync(profileGroupId, genderAssertion)

    return personId
}

export async function setProfileSpouseAsync(profileFamilyId: string, viewSpouseId: string, role: FamilyRelationshipRole) {
    const dataGroupStore = useDataGroupStore()
    const familyStore = useFamilyStore()
    const personStore = usePersonStore()
    const viewPersonStore = useViewPersonStore()

    const profileGroupId = CompositeId.getGroupId(profileFamilyId)!
    const profileGroup = await dataGroupStore.getAsyncGroup(profileGroupId)?.whenLoadCompleted
    const profileFamily = await familyStore.getAsyncFamily(profileFamilyId)?.whenLoadCompleted
    if (!profileFamily)
        throw `Family ${profileFamilyId} not found`

    // get or create spouse placeholder in profile group
    let profileSpouseId = profileFamily.getSpouseId(role)
    let profileCurrentSet: PersonSet | undefined = undefined
    if (profileSpouseId) {
        if (profileSpouseId == profileGroup?.startItemId)
            throw 'Cannot change spouse representing the profile person'

        const currentSets = await viewPersonStore.getPersonSetsAsync(profileSpouseId)
        profileCurrentSet = currentSets.find(set => set.groupId == profileGroupId)
        // if (!profileCurrentSet)
        //     throw 'Spouse placeholder person set not owned by profile group'
        // if (currentSets.length > 1)
        //     throw 'Spouse placeholder has multiple person sets'
        // profileCurrentSet = currentSets.at(0) // may be empty
    }
    else {
        const placeholderId = await personStore.addPlaceholderAsync(profileGroupId)
        const spousePath = role == FamilyRelationshipRole.Father ? '/fatherId' : '/motherId'
        const change = new PatchChange(spousePath, placeholderId)
        await familyStore.patchAsync(profileFamilyId, [change])
        profileSpouseId = profileFamily.getSpouseId(role)!
    }

    // (re)define spouse placeholder person set be non-restricted profile ids in groups owned by current user
    const viewSpouse = await viewPersonStore.getAsyncPerson(viewSpouseId)?.whenLoadCompleted
    if (!viewSpouse)
        throw `Spouse ${viewSpouseId} not found`
    const spouseMatchGroupIds = viewSpouse.matchIds.map(id => CompositeId.getGroupId(id)!)
    const spouseMatchGroups = await dataGroupStore.getGroupListAsync(spouseMatchGroupIds)
    const spouseMatchIds = ProfileUtils.getProfilePersonIds(viewSpouse.matchIds, spouseMatchGroups)
    spouseMatchIds.push(profileSpouseId)

    const dissolveCurrentSets = profileCurrentSet?.memberIds
        .filter(id => id != profileSpouseId)
        .map(id => [id]) ?? [] // place each member (except profile spouse) in its own set
    const newSets = [...dissolveCurrentSets, spouseMatchIds]
    await viewPersonStore.definePersonSetsAsync(newSets, profileGroupId)
}

async function createProfileChildAssertionAsync(subjectId: string, parentFamilyId: string) {
    const assertionStore = useAssertionStore()

    const subjectGroupId = CompositeId.getGroupId(subjectId)
    if (!subjectGroupId)
        throw 'Subject must have a group ID'

    const parentFamilyGroupId = CompositeId.getGroupId(parentFamilyId)
    if (!parentFamilyGroupId)
        throw 'Parent family must have a group ID'
    if (parentFamilyGroupId != subjectGroupId)
        throw 'Subject and parent family must have the same group ID'

    const childAssertion = {
        subjectType: ItemType.Person,
        subjectId,
        assertionType: 'Child',
        relatedItemType: ItemType.Family,
        relatedItemId: parentFamilyId,
        // TODO: what about child order?
    }
    const assertionId = await assertionStore.addAsync(subjectGroupId, childAssertion)

    return assertionStore.getAsyncAssertion(assertionId)!.data! // just added, so should be present
}

export interface NewProfile {
    gender?: Gender
    givenName?: string
    surname?: string
}
