import { computed } from "vue";
import { useDataGroupStore } from '@/gp/DataGroupStore'
import { useDataGroupMemberStore } from '@/gp/DataGroupMemberStore'
import { useFamilyGroupStore } from "@/gp/FamilyGroupStore"
import { DataGroupMember, DataGroupType, FamilyGroup, GroupMemberRole, PrincipalType } from '@/gp/GroupAdminModel'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { useWorkspaceStore } from '@/rd/WorkspaceStore'
import { isDefined } from '@/util/TypeScriptUtil'
import { LoadMode } from '@/util/AsyncData'
import { TokenManager } from "@/auth/TokenManager";
import { CompositeId } from "@/rd/CompositeId";
import { ViewFamily } from "@/rd/ResearchDataModel";
import { PatchChange } from "@/util/Api";


export function useFamilyGroupCreator() {
    const familyGroupStore = useFamilyGroupStore()
    const dataGroupStore = useDataGroupStore()
    const dataGroupMemberStore = useDataGroupMemberStore()
    const workspaceStore = useWorkspaceStore()
    const viewPersonStore = useViewPersonStore()

    const defaultShareWorkspaceRef = computed(() => getDefaultShareWorkspace())
    
    function getDefaultShareWorkspace() {
        // default is the only workspace owned by the user, which is a research group, which has no family group members
        const ownedGroupIds = dataGroupMemberStore.getAsyncMembersForUser(TokenManager.userId, LoadMode.EnsureLoaded)
            .map(a => a.data).filter(isDefined)
            .filter(dm => dm.role == GroupMemberRole.Owner)
            .map(dm => dm.dataGroupId!)
        
        const ownedResearchGroups = dataGroupStore.getAsyncGroupList(ownedGroupIds, LoadMode.EnsureLoaded)
            .map(a => a.data).filter(isDefined)
            .filter(g => g.groupType == DataGroupType.Research)
        
        if (ownedResearchGroups.length != 1)
            return undefined // action only available if unambiguous
        
        const famGrpMembers = dataGroupMemberStore.getMembersForDataGroups([ownedResearchGroups[0].id], LoadMode.EnsureLoaded)
            .filter(dm => dm.principalType == PrincipalType.FamilyGroup)
        
        if (famGrpMembers.length > 0)
            return undefined // already shared with a family group
        
        const workspaces = workspaceStore.getAsyncWorkspacesForGroups([ownedResearchGroups[0].id], LoadMode.EnsureLoaded)
            .map(a => a.data).filter(isDefined)
        
        if (workspaces.length != 1)
            return undefined // future scenario of many workspaces for one research group
        
        return workspaces[0]
    }

    async function createFamilyGroupAsync() {
        const newGroup = new FamilyGroup()
        const newGroupId = await familyGroupStore.addAsync(newGroup)
        const familyGroup = familyGroupStore.getAsyncGroup(newGroupId)!.data! // should be present

        const ownedResearchGroups = await dataGroupStore.getOwnedResearchGroupsAsync()
        if (ownedResearchGroups.length == 1) { // only if unambiguous
            const dataGroupId = ownedResearchGroups[0].id!
            await shareDataGroupWithFamilyGroup(familyGroup.id!, dataGroupId)

            const defaultScopeFamily = await getDefaultScopeFamilyAsync(dataGroupId)
            if (defaultScopeFamily) {
                const defaultGroupName = getDefaultFamilyGroupName(defaultScopeFamily)

                const changes = [
                  new PatchChange("/scopeFamilyId", defaultScopeFamily.id),
                  new PatchChange("/name", defaultGroupName)
                ]
                await familyGroupStore.patchAsync(newGroupId, changes)
            }
        }

        return familyGroup
    }

    async function shareDataGroupWithFamilyGroup(familyGroupId: string, dataGroupId: string) {
        const member = new DataGroupMember()
        member.dataGroupId = dataGroupId
        member.principalType = PrincipalType.FamilyGroup
        member.principalId = familyGroupId

        const key = await dataGroupMemberStore.addAsync(dataGroupId, member)

        return dataGroupMemberStore.getAsyncMember(key)!.data // just added
    }

    async function getDefaultScopeFamilyAsync(sharedDataGroupId: string) {
        const asyncSelfProfileGroup = dataGroupStore.getAsyncGroup(TokenManager.userProfileId, LoadMode.EnsureLoaded)
        if (!asyncSelfProfileGroup)
            throw `Self profile group ${TokenManager.userProfileId} not found`

        const selfProfileGroup = await asyncSelfProfileGroup.whenLoadCompleted
        if (!selfProfileGroup)
            throw `Self profile group ${asyncSelfProfileGroup.key} failed to load`
        if (!selfProfileGroup.startItemId)
            throw `Self profile group ${asyncSelfProfileGroup.key} has no start item`
        
        await viewPersonStore.ensureAncestorsLoadedAsync(selfProfileGroup.startItemId, 1)

        const parentFamilies = viewPersonStore.getLoadedParentFamilies(selfProfileGroup.startItemId).parentFamilies
        const bestParentFamily = parentFamilies.filter(f => f.matchIds.some(id => CompositeId.hasGroupId(id, sharedDataGroupId))).at(0)

        console.log(`Found ${parentFamilies.length} parent families for self profile person ${selfProfileGroup.startItemId}`)
        console.log(`Best default scope family: ${bestParentFamily?.id}`)

        return bestParentFamily
    }

    function getDefaultFamilyGroupName(scopeFamily: ViewFamily | undefined) {
        const father = viewPersonStore.getAsyncPerson(scopeFamily?.fatherId)?.data
        const mother = viewPersonStore.getAsyncPerson(scopeFamily?.motherId)?.data
        return ViewFamily.getDisplayName(father, mother)
    }

    return {
        defaultShareWorkspaceRef,
        createFamilyGroupAsync,
    }
}