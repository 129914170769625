import { DateTime, Duration } from 'luxon'

export class DateTimeUtil {
    static fromAPI(value: string): DateTime {
        return DateTime.fromISO(value, { zone: 'utc' })
    }

    static toUserActionDate(value: DateTime | undefined, dateOnly = false) {
        if (value) {
            if (dateOnly) {
                // ex: Thu, Apr 20, 2023
                return value.toLocal().toLocaleString({ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })
            }
            // ex: Thu, Apr 20, 2023 11:27 AM
            return value.toLocal().toLocaleString({ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit', hourCycle: "h12" })
        }
    }

    static toRelativeDate(value: DateTime | undefined) {
        return (value && value.diffNow().valueOf() < 0)
            ? this.toRelativePastDate(value)
            : this.toRelativeFutureDate(value)
    }

    static toRelativeFutureDate(value: DateTime | undefined) {
        if (!value)
            return undefined
        
        const a = value.diffNow().shiftToAll()
        if (a.years > 0) return a.toFormat("'in' y 'yrs'")
        if (a.months > 0) return a.toFormat("'in' M 'mo'")
        if (a.days > 0) return a.toFormat("'in' d 'days'")
        if (a.hours > 0) return a.toFormat("'in' h 'hrs'")
        return a.toFormat("'in' m 'min'")
    }

    static toRelativePastDate(value: DateTime | undefined) {
        if (!value)
            return undefined
            
        const a = value.diffNow().negate().shiftToAll()
        if (a.years > 1) return a.toFormat("y 'years ago'")
        if (a.years > 0) return a.toFormat("y 'year ago'")
        if (a.months > 1) return a.toFormat("M 'months ago'")
        if (a.months > 0) return a.toFormat("M 'month ago'")
        if (a.weeks > 1) return `${a.weeks} weeks ago`
        if (a.weeks > 0) return `${a.weeks} week ago`
        if (a.days > 1) return a.toFormat("d 'days ago'")
        if (a.days > 0) return a.toFormat("d 'day ago'")
        if (a.hours > 1) return a.toFormat("h 'hrs ago'")
        if (a.hours > 0) return a.toFormat("h 'hr ago'")
        if (a.minutes > 0) return a.toFormat("m 'min ago'")
        return "just now"
    }
}

export class DurationUtil {
    
    static fromReadable(value: string) {
        // [-][d.]hh:mm:ss[.fffffff]
        const pattern = /^(?<neg>-)?((?<days>\d+)\.)?(?<hours>\d\d):(?<minutes>\d\d):(?<seconds>\d\d(\.\d+)?)$/;
        const m = value.match(pattern);
        if (!m?.groups) {
            throw `The string '${value}' is not in the readable duration format`;
        }

        const parts = { 
            days: 0,
            hours: parseInt(m.groups.hours),
            minutes: parseInt(m.groups.minutes),
            seconds: parseInt(m.groups.seconds),
        };
        if (m.groups.days) {
            parts.days = parseInt(m.groups.days);
        }
        const d = Duration.fromObject(parts);
        return m.groups.neg ? d.negate() : d;
    }

    static toReadable(value: Duration | undefined) {
        if (!value) {
            return undefined
        }
        if (value.days != 0) {
            return value.toFormat('d.hh:mm:ss');
        }
        return value.toFormat('hh:mm:ss');
    }

    static getMaxUnit(value: Duration) {
        const a = value.shiftToAll()
        if (a.years > 0) return "years"
        if (a.months > 0) return "months"
        if (a.days > 0) return "days"
        if (a.hours > 0) return "hours"
        if (a.minutes > 0) return "minutes"
        return "seconds"
    }

    static toPersonAge(value: Duration, long = false) {
        const a = value.shiftToAll()
        if (a.years > 1) return a.toFormat(long ? "y 'years'" : "y")
        if (a.years > 0) return a.toFormat(long ? "y 'year'" : "y")
        if (a.months > 1) return a.toFormat(long ? "M 'months'" : "M'm'")
        if (a.months > 0) return a.toFormat(long ? "M 'month'" : "M'm'")
        if (a.days > 1) return a.toFormat(long ? "d 'days'" : "d'd'")
        if (a.days > 0) return a.toFormat(long ? "d 'day'" : "d'd'")
        return "0"
    }

}