<template>
  <Modal ref="modalRef" title="Choose a family" hide-actions>
    <form>
      <div id="searchInputWrapper">
        <span class="search-icon"><search-icon size="1x"></search-icon></span>
        <input type="textbox"  class="search-input form-control" placeholder="Search by name..." v-model="searchText" @input="textUpdated"/>
      </div>
      <div class="search-result-list mb-1 px-2">
        <button type="button" class="search-result btn btn-link link-dark d-block" v-for="vf in searchResults" :key="vf.id" @click="selectResult(vf.id)">
          {{ vf.displayName }}
        </button>
      </div>
    </form>
  </Modal>
</template>

<style scoped>
#searchInputWrapper {
  position: relative;
  margin-bottom: 1rem;
}

.search-icon {
  position: absolute;
  top: 0.3em;
  left: 0.5em;
}

.search-input {
  padding-left: 2em;
}

.search-result-list {
  height: 200px;
  overflow-y: auto;
}

.search-result {
  border: none;
  padding: 0.2em 0;
}
</style>

<script setup lang="ts">
import { ref } from "vue"
import { ViewFamily } from '@/rd/ResearchDataModel'
import { useViewFamilyStore } from '@/rd/ViewFamilyStore'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import _ from "lodash"
import Modal from '@/util/Modal.vue'
import { SearchIcon } from '@zhuowenli/vue-feather-icons'
import { LoadMode } from "@/util/AsyncData"

const viewFamilyStore = useViewFamilyStore()
const viewPersonStore = useViewPersonStore()

const modalRef = ref<InstanceType<typeof Modal>>()
const searchText = ref("")
const searchResults = ref<SearchResult[]>([])

const debouncedUpdateSearchResultsAsync = _.debounce(updateSearchResultsAsync, 200)

async function textUpdated() {
  if (searchText.value.length >= 3) {
    await debouncedUpdateSearchResultsAsync(searchText.value)
  }
  else {
    searchResults.value = []
  }
}

async function updateSearchResultsAsync(text: string) {
  const asyncViewFamilies = await viewFamilyStore.searchByNameAsync(text)
  const results = asyncViewFamilies.map(a => ({
    id: a.key, // all loaded by search
    displayName: ViewFamily.getDisplayName(
      viewPersonStore.getAsyncPerson(a.data!.fatherId, LoadMode.EnsureLoaded)?.data,
      viewPersonStore.getAsyncPerson(a.data!.motherId, LoadMode.EnsureLoaded)?.data),
  }) as SearchResult)
  const sortedResults = _.sortBy(results, "name")

  searchResults.value = sortedResults
}

function selectResult(id: string) {
  modalRef.value!.ok(id)
  modalRef.value!.close()
}

interface SearchResult {
  id: string
  displayName: string
}
</script>
