import { defineStore } from "pinia";

export const useManageStore = defineStore("manageStore", {
    state: () => ({
        inviteAddresses: new Map<string, string | undefined>(),
        senderMessage: '',
    }),
    getters: {
    },
    actions: {
    },
})