<template>
  <div class="subscription-info">
    <LoadingIndicator v-if="loadingRef"/>
    <div v-else>
      <div v-if="isDefaultPlanRef">
        <div class="no-sub">
          No active subscription.
        </div>
        <div>
          <router-link to="/plans" class="btn btn-primary">Choose a plan</router-link>
        </div>
      </div>
      <div v-else class="sub-details">
        <div class="sub-plan">
          <label class="form-label">Subscription plan</label>
          <SubscriptionPlanCard v-if="planRef" :plan="planRef"/>
          <div>
            <router-link to="/plans" class="action-link">View all plans</router-link>
          </div>
        </div>
        <div class="sub-status">
          <label class="form-label">Status</label>
          <div class="status-value">
            <img v-if="isActiveRef" src="@/assets/icons/green_check_circle_64.png" />
            {{ statusTextRef }}
          </div>
        </div>
        <div v-if="isPaidPlanRef" class="next-payment">
          <label class="form-label">Next payment</label>
          <div>
            ${{ numeral(nextPaymentAmountRef).format('0.00') }}
            <span class="payment-date">
              on {{ DateTimeUtil.toUserActionDate(nextPaymentDateRef, true) }}
            </span>
          </div>
        </div>
        <div class="payment-method">
          <label class="form-label">Payment method</label>
          <div v-if="paymentMethodRef">
            <div v-if="paymentDoomedRef" class="alert alert-warning">
              <img src="@/assets/icons/status_warn.png" />
              This card will expire before your next payment.
            </div>
            <PaymentCard :payment-method="paymentMethodRef" />
            <button type="button" v-if="isPaidPlanRef" class="btn btn-inline btn-link action-link" data-bs-toggle="modal" data-bs-target="#paymentModal">
              Change
            </button>
          </div>
          <div v-else>(none)</div>
        </div>
      </div>
    </div>
    <PaymentModal id="paymentModal" :subscription-id="subRef?.id">
    </PaymentModal>
  </div>
</template>

<style lang="scss" scoped>
.subscription-info {
  max-width: 600px;

  .no-sub {
    margin-bottom: 1.5rem;
    font-size: 0.875rem;
    color: #aaa;
  }

  .sub-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.sub-plan {
  .subscription-plan-card {
    margin: 0.5rem 0;
  }
}

.alert {
  padding: 0.5rem;
  font-size: 0.875rem;

  img {
    margin-right: 0.25rem;
    width: 20px;
  }
}

.action-link {
  font-size: 0.875rem;
}

.currency-symbol {
  font-size: 0.875rem;
  vertical-align: 2px;
}
.plan-term {
  font-size: 0.875rem;
}

.sub-status {
  .status-value {
    img {
      margin-top: -4px;
      width: 1rem;
    }
  }
}

.next-payment {
  .payment-date {
    font-size: 0.875rem;
    color: #888;
  }
}

.payment-method {
  .payment-card {
    margin: 0.5rem 0;
  }
}
</style>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useUserStore } from '@/gp/UserStore'
import { useSubscriptionStore } from '@/gp/SubscriptionStore'
import { usePaymentMethodStore } from '@/gp/PaymentMethodStore'
import { subscriptionPlans, SubscriptionStatus, defaultPlanId, PaymentMethod, Subscription, ItemPermissions } from '@/gp/GroupAdminModel'
import { DateTimeUtil } from '@/util/LuxonUtil'
import { TokenManager } from '@/auth/TokenManager'
import numeral from 'numeral'
import PaymentCard from './PaymentCard.vue'
import PaymentModal from '@/account/PaymentModal.vue'
import SubscriptionPlanCard from './SubscriptionPlanCard.vue'
import LoadingIndicator from '@/util/LoadingIndicator.vue'

const props = defineProps<{
  userId: string
}>()

const loadingRef = ref(false)
const userPermissionsRef = ref(ItemPermissions.None)
const subRef = ref<Subscription>()
const paymentMethodRef = ref<PaymentMethod>()

const userStore = useUserStore()
const subStore = useSubscriptionStore()
const paymentMethodStore = usePaymentMethodStore()

const isActiveRef = computed(() => subRef.value?.status == SubscriptionStatus.Active)
const statusTextRef = computed(() => subRef.value?.status)
const priceRef = computed(() => subRef.value?.items.at(0)?.price)
const planRef = computed(() => subscriptionPlans.find(p => p.id == priceRef.value?.productId))
const isDefaultPlanRef = computed(() => !subRef.value || planRef.value?.id == defaultPlanId)
const isPaidPlanRef = computed(() => !!planRef.value?.price)
const nextPaymentDateRef = computed(() => subRef.value?.currentPeriodEnd)
const nextPaymentAmountRef = computed(() => priceRef.value?.unitPrice)
const paymentDoomedRef = computed(() => paymentMethodRef.value?.expirationDate && nextPaymentDateRef.value && paymentMethodRef.value.expirationDate < nextPaymentDateRef.value)
const canEditRef = computed(() => props.userId == TokenManager.userId)

onMounted(async () => {
  loadingRef.value = true

  userPermissionsRef.value = ItemPermissions.None
  subRef.value = undefined
  paymentMethodRef.value = undefined

  userPermissionsRef.value = await userStore.getPermissionsAsync(props.userId)
  if ((userPermissionsRef.value & ItemPermissions.Administrator) == ItemPermissions.Administrator) {
    subRef.value = (await subStore.getSubscriptionListForUserAsync(props.userId)).at(0)
    paymentMethodRef.value = paymentMethodStore.getLoadedPaymentMethod(subRef.value?.paymentMethodId) // loaded with subscription
  }

  loadingRef.value = false
})

</script>
