<template>
  <div v-if="exploreStore.relationPath" class="relation-path" :layout="showFullRef ? 'full' : 'dot'">
    <div class="relation-text-area">
      <button type="button" class="btn btn-inline btn-link link-secondary relation-text" @click="showFullRef = !showFullRef">
        <div>your {{ getRelationText(exploreStore.relationPath) }}</div>
      </button>
    </div>
    <div class="relation-path-visual">
      <button type="button" v-if="!showFullRef" class="overlay" @click="showFullRef = true"></button>
      <div class="relation-arm-area">
        <ul v-if="hasUpPathRef" class="relation-arm list-unstyled">
          <li v-for="pi in exploreStore.relationPath.startPersonPath" :key="pi.ancestorId">
            <PersonCard 
              :personId="pi.ancestorId"
              :layout="cardLayoutRef" 
              :current="showAsCurrent(pi.ancestorId)"/>
            <div class="parent-connector"></div>
          </li>
        </ul>
        <ul v-if="hasDownPathRef" class="relation-arm list-unstyled">
          <li v-for="pi in exploreStore.relationPath.toPersonPath" :key="pi.ancestorId">
            <PersonCard 
              :personId="pi.ancestorId" 
              :layout="cardLayoutRef" 
              :current="showAsCurrent(pi.ancestorId)"/>
            <div class="parent-connector"></div>
          </li>
        </ul>  
      </div>
      <div class="common-ancestor-area">
        <div v-if="hasUpPathRef && hasDownPathRef" class="relation-arm-connector">
          <div class="vertical-bar"></div>
          <div class="horizontal-bar"></div>
        </div>
        <div v-if="commonAncestorFamilyIdRef" class="common-ancestor-family">
          <FamilyCard 
            :familyId="commonAncestorFamilyIdRef" 
            :layout="cardLayoutRef" 
            no-connector/>
        </div>
        <div v-else class="common-ancestor" v-for="id in [exploreStore.relationPath.commonAncestorId]" :key="id">
          <PersonCard 
            :personId="id" 
            :layout="cardLayoutRef" 
            :current="showAsCurrent(id)"/>
        </div>
      </div>
    </div>
  </div>
  <!-- <div v-else class="no-relation">
    <PersonCard :personId="startPersonIdRef" layout="tiny" />
    <div class="no-relation-label">
      <small>(unknown relationship)</small>
    </div>
    <PersonCard :personId="props.personId" layout="tiny" current />
  </div> -->
</template>

<style lang="scss" scoped>
$resize-duration: 0.2s;

.no-relation {
  display: flex;

  .no-relation-label {
    margin: 0 0.5em;
  }
}

.relation-path {
  .relation-text-area {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .relation-text {
    margin: 0.25rem 0 0;
    font-size: 0.8rem;
    color: #888;
  }

  .relation-path-visual {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0 1.25rem;
    transition: padding $resize-duration;

    & > button.overlay {
      z-index: 200; // higher than person card overlays
    }
  }

  .show-full-button {
    position: absolute;
    top: 0;
    width: 100%;
    height: calc(100% - 0.25rem);
    z-index: 100;
  }

  .relation-arm-area {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    transition: gap $resize-duration;
  }

  ul.relation-arm {
    display: flex;
    justify-content: flex-end;
    margin: 0;

    li {
      display: flex;

      .person-card.card-tiny {
        width: 80px;        
      }
    }
  }

  .parent-connector {
      grid-column: 2;
      width: 0.5rem;
      border-top: 2px solid #ddd;
      margin-top: 0.7em;
      transition: width $resize-duration;
  }
  
  .common-ancestor-area {
    display: flex;

    .common-ancestor {
      .person-card.card-tiny {
        width: 80px;        
      }
    }

    .relation-arm-connector {
      display: flex;
      align-items: center;

      .vertical-bar {
        margin-top: -1px; // nudge to align with last relation arm parent connectors
        height: calc(26px + 1rem + 2px); // tiny person card height + arm spacing + 2*line width
        border-left: 2px solid #ddd;
        transition: margin height $resize-duration;
      }

      .horizontal-bar {
        width: 0.5rem;
        border-top: 2px solid #ddd;
        transition: margin width $resize-duration;
      }
    }

    .common-ancestor-family {
      max-width: 90px;
      transition: margin $resize-duration;

      .family-card[layout='tiny'] {
        width: 90px;
        transition: width $resize-duration;
      }
    }
  }

  &[layout='dot'] {
    .relation-path-visual {
      padding-bottom: 0.75rem;
    }

    .parent-connector {
      width: 3px;
      border: none;
      margin: 0;
    }

    .relation-arm-area {
      gap: 2px;
    }

    .common-ancestor-area {
      .relation-arm-connector {

        .vertical-bar {
          margin: 0;
          height: 0;
          border: none;
        }
        
        .horizontal-bar {
          margin: 0;
          width: 1px;
          border: none;
        }
      }

      .common-ancestor-family {
        margin: 0;
        width: 12px;
      }
    }
  }
}
</style>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { useExploreStore } from './ExploreStore'
import { useRelationTextBuilder } from '@/rd/RelationPath'
import PersonCard from '@/manage/PersonCard.vue'
import FamilyCard from '@/manage/FamilyCard.vue'

const props = defineProps<{
  personId?: string
}>()

const viewPersonStore = useViewPersonStore()
const exploreStore = useExploreStore()
const { getRelationText } = useRelationTextBuilder()

const startPersonIdRef = computed(() => exploreStore.startPersonId)
const isSelfRef = computed(() => exploreStore.relationPath && 
  (exploreStore.relationPath.startPersonPath.length == 0 && 
   exploreStore.relationPath.toPersonPath.length == 0))

const showFullRef = ref(false)
const cardLayoutRef = computed(() => showFullRef.value ? 'tiny' : 'dot')

const hasUpPathRef = computed(() => (exploreStore.relationPath?.startPersonPath.length ?? 0) > 0)
const hasDownPathRef = computed(() => (exploreStore.relationPath?.toPersonPath.length ?? 0) > 0)
const lastUpPathFamilyIdRef = computed(() => exploreStore.relationPath?.startPersonPath.at(-1)?.parentFamilyId)
const lastDownPathFamilyIdRef = computed(() => exploreStore.relationPath?.toPersonPath.at(-1)?.parentFamilyId)
const commonAncestorFamilyIdRef = computed(() => hasUpPathRef.value && lastUpPathFamilyIdRef.value === lastDownPathFamilyIdRef.value ? lastUpPathFamilyIdRef.value : undefined)

watch([props, startPersonIdRef], async () => {
  exploreStore.relationPath = undefined

  if (!startPersonIdRef.value || !props.personId) {
    console.log(`RelationPath: no startPersonId and/or personId`)
    return
  }

  // TODO: check if relation path known for previous person. If known not to exist, and new person is a
  // child or parent of previous person, conclude relation path does not exist for new person either.

  let path = viewPersonStore.getLoadedRelationPath(startPersonIdRef.value, props.personId)
  console.log(`RelationPath loaded: ${!!path}`)
  if (!path) {
    await viewPersonStore.ensureRelationPathLoadedAsync(startPersonIdRef.value, props.personId)
    path = viewPersonStore.getLoadedRelationPath(startPersonIdRef.value, props.personId)
    console.log(`RelationPath loaded: ${!!path}`)
  }

  exploreStore.relationPath = path
}, { immediate: true})

function showAsCurrent(personId: string) {
  return showFullRef.value && personId == props.personId
}

</script>