<template>
  <div class="dropdown use-contacts">
    <button type="button" class="btn btn-inline btn-link dropdown-toggle" data-bs-toggle="dropdown">
      <span v-if="selectedContactProviderRef" class="contact-provider-text" :class="{ [selectedContactProviderRef.id]: true }">
        Using your {{ selectedContactProviderRef.name }}
      </span>
      <span v-else>Use my contacts</span>
    </button>
    <ul class="dropdown-menu dropdown-menu-sm-end">
      <li v-for="cp in contactProviders" :key="cp.id">
        <button type="button" class="dropdown-item" :class="{ [cp.id]: true, active: cp.id == selectedContactProviderRef?.id }" @click="useContacts(cp)">
          Use {{ cp.name }}
        </button>
      </li>
      <li><hr class="dropdown-divider"></li>
      <li key="none">
        <button type="button" class="dropdown-item" :disabled="!selectedContactProviderRef" @click="useContacts(undefined)">
          Don't use my contacts
        </button>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.use-contacts {
  font-size: 0.85rem;

  .contact-provider-text {
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 0 center;
    padding-left: 1.5rem;
  }

  .dropdown-toggle::after {
    display: none; // no caret
  }

  .dropdown-item {
    height: 2rem;
    min-width: 170px;
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 0.75rem center;
    padding-left: 3rem;
  }

  .contact-provider-text, .dropdown-item {
    &.google {
      background-image: url('@/assets/logos/google_contacts_150.png');
    }

    &.microsoft {
      background-image: url('@/assets/logos/outlook_150.png');
    }

    &.test {
      background-image: url('@/assets/icons/flask_gray_150.png');
    }
  }
}
</style>

<script lang="ts" setup>
import { computed, nextTick } from "vue"
import { ContactProvider, contactProviders } from "@/manage/contacts/ContactProviders"
import { useContactStore } from "./ContactStore"

const contactStore = useContactStore()

const selectedContactProviderRef = computed(() => contactProviders.find(cp => cp.id == contactStore.contactProviderId))

async function useContacts(cp: ContactProvider | undefined) {
  contactStore.contactProviderId = cp?.id ?? ''
  await nextTick()
  if (cp) {
    try {
      const authorized = await cp.signIn()

      if (authorized) {
        await contactStore.ensureContactsLoadedAsync()
      } else {
        // TODO: Handle the case where signIn returned false
        // change display selection?
      }
    } catch (error) {
      // is this possible?
    }
  }
}

</script>