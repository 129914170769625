import { defineStore } from 'pinia'
import { reactive } from 'vue'
import { ApprovalStatus, DataGroupInvite, GroupMemberRole, InvitationResult, ItemPermissions } from '@webapp/gp/GroupAdminModel'
import { gp } from './GroupAdminApi'
import { AsyncData, LoadMode } from '@webapp/util/AsyncData'
import { AsyncDataStore } from '@webapp/util/AsyncDataStore'
import { useUserStore } from './UserStore'
import { useDataGroupStore } from './DataGroupStore'
import { useDataGroupMemberStore } from './DataGroupMemberStore'
import { useFamilyGroupStore } from './FamilyGroupStore'
import { DateTime } from 'luxon'
import { DateTimeUtil } from '@webapp/util/LuxonUtil'
import { assignExisting, isDefined } from '@webapp/util/TypeScriptUtil'
import { getAsyncPermissionsCore } from './Permissions'


export const useDataGroupInviteStore = defineStore('dataGroupInvites', () => {
    const asyncInvites = reactive(new Map<string, AsyncData<DataGroupInvite>>())
    const asyncPermissions = reactive(new Map<string, AsyncData<ItemPermissions>>())

    const dataGroupStore = useDataGroupStore()
    const memberStore = useDataGroupMemberStore()
    const userStore = useUserStore()
    const familyGroupStore = useFamilyGroupStore()

    function getAsyncInvite(id: string | undefined, loadMode = LoadMode.TrackOnly) {
        return id ? getAsyncInviteList([id], loadMode)[0] : undefined
    }
    
    function getAsyncInviteList(ids: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const loadAsync = async (idsToLoad: string[], requestTime: DateTime) => {
            const plainItems = await gp.getPlainByIdsAsync("datagroupinvites", idsToLoad, dataGroupInviteExpand)
            console.log(`Loaded ${plainItems.length} data group invites by id: ${plainItems.map(p => p.id).join(", ")}`)
            return processExpandedDataGroupInvites(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncItemsByIds(asyncInvites, ids, "DataGroupInvite", loadMode, loadAsync)
    }
    
    async function getInviteListAsync(ids: (string | undefined)[]) {
        return (await Promise.all(getAsyncInviteList(ids, LoadMode.EnsureLoaded).map(a => a.whenLoadCompleted))).filter(isDefined)
    }

    function getAsyncKeyListForDataGroup(dataGroupId: string | undefined, loadMode = LoadMode.TrackOnly) {
        return getAsyncKeyListsForDataGroups([dataGroupId], loadMode).at(0)
    }

    function getInvitesForDataGroup(dataGroupId: string | undefined, loadMode = LoadMode.TrackOnly) {
        return getAsyncInviteList(getAsyncKeyListForDataGroup(dataGroupId, loadMode)?.data?.keys ?? [])
            .map(a => a.data).filter(isDefined)
    }

    function getAsyncKeyListsForDataGroups(dataGroupIds: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const loadInvitesAsync = async (sourceKeys: string[], requestTime: DateTime) => {
            const getInvitesPath = (id: string) => `datagroups/${id}/invites`
            const expandItems = gp.injectExpandItems(dataGroupInviteExpand)
            const plainItems = await gp.getPlainCollectionByIdsAsync(getInvitesPath, sourceKeys, expandItems)
            console.log(`Loaded ${plainItems.length} data group invites at ${getInvitesPath('$list')}`)
            return processExpandedDataGroupInvites(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncKeyListsForRelatedItems(dataGroupIds, "DataGroup", dataGroupStore.asyncInviteKeys,
            asyncInvites, "DataGroupInvite", loadMode, loadInvitesAsync, inv => inv.dataGroupId!)
    }

    async function getInvitesForDataGroupAsync(dataGroupId: string | undefined) {
        const keyList = await getAsyncKeyListForDataGroup(dataGroupId, LoadMode.EnsureLoaded)?.whenLoadCompleted
        return await getInviteListAsync(keyList?.keys ?? []) // should be loaded
    }

    function getAsyncKeyListForFamilyGroup(familyGroupId: (string | undefined), loadMode = LoadMode.TrackOnly) {
        const loadInvitesAsync = async (sourceKeys: string[], requestTime: DateTime) => {
            const invitesPath = `familygroups/${familyGroupId}/datagroupinvites`
            const plainItems = await gp.getPlainCollectionAsync(invitesPath, dataGroupInviteExpand)
            console.log(`Loaded ${plainItems.length} data group invites at ${invitesPath}`)
            return processExpandedDataGroupInvites(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncKeyListForRelatedItems(familyGroupId, "FamilyGroup", familyGroupStore.asyncDataGroupInviteKeys,
            asyncInvites, "DataGroupInvite", loadMode, loadInvitesAsync)
    }

    async function getInvitesForFamilyGroupAsync(familyGroupId: string | undefined) {
        const keyList = await getAsyncKeyListForFamilyGroup(familyGroupId, LoadMode.EnsureLoaded).whenLoadCompleted
        return await getInviteListAsync(keyList?.keys ?? []) // should be loaded
    }

    function getAsyncPermissions(id: string | undefined, loadMode = LoadMode.TrackOnly) {
        return getAsyncPermissionsCore(asyncPermissions, id, "DataGroupInvite", "datagroupinvites", loadMode)
    }
    
    async function sendAsync(invite: DataGroupInvite) {
        const sendCoreAsync = async () => {
            const plainItem = await gp.postPlainAsync(`datagroupinvites`, invite)
            return plainToDataGroupInvite(plainItem)
        }
        // NOTE: Stored key list for family group will not be updated automatically
        const inviteId = await AsyncDataStore.addToStoreMapAsync(asyncInvites, "DataGroupInvite", inv => inv.id, sendCoreAsync)
        
        const asyncKeyList = dataGroupStore.asyncInviteKeys.get(invite.dataGroupId!)
        AsyncData.invalidate(asyncKeyList)

        const asyncFamilyGroupKeyList = familyGroupStore.asyncDataGroupInviteKeys.get(invite.principalId!)
        AsyncData.invalidate(asyncFamilyGroupKeyList)

        return inviteId
    }

    async function cancelAsync(id: string) {
        await gp.postPlainAsync(`datagroupinvites/${id}/cancel`)
        getAsyncInvite(id, LoadMode.Reload) // trigger reload, but don't wait for it
    }

    async function resendAsync(id: string, message?: string) {
        await gp.postPlainAsync(`datagroupinvites/${id}/resend`, { message })
        getAsyncInvite(id, LoadMode.Reload) // trigger reload, but don't wait for it
    }
    
    async function approveAsync(id: string) {
        await gp.postPlainAsync(`datagroupinvites/${id}/approve`)
        const invite = await getAsyncInvite(id, LoadMode.Reload)?.whenLoadCompleted
        memberStore.getAsyncKeyListsForDataGroups([invite?.dataGroupId], LoadMode.Reload) // trigger reload, but don't wait for it
        memberStore.getAsyncKeyListsForFamilyGroups([invite?.principalId], LoadMode.Reload) // trigger reload, but don't wait for it
    }
    
    async function rejectAsync(id: string) {
        await gp.postPlainAsync(`datagroupinvites/${id}/reject`)
        await getAsyncInvite(id, LoadMode.Reload)?.whenLoadCompleted
    }
    
    const dataGroupInviteExpand = "senderUser,dataGroup/owner,familyGroup/owner"

    function plainToDataGroupInvite(p: any) {
        const invite = assignExisting(new DataGroupInvite(), p)
        invite.role = p.role ?? GroupMemberRole.Viewer // default
        invite.dataGroupApproval = p.dataGroupApproval ?? ApprovalStatus.Pending // default
        invite.principalApproval = p.principalApproval ?? ApprovalStatus.Pending // default
        invite.result = p.result ?? InvitationResult.Pending // default
        invite.lastSentDate = DateTimeUtil.fromAPI(p.lastSentDate)
        invite.expirationDate = DateTimeUtil.fromAPI(p.expirationDate)
        invite.closedDate = DateTimeUtil.fromAPI(p.closedDate)
        invite.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate)
        return invite
    }

    function processExpandedDataGroupInvites(plainInvites: any[], requestTime: DateTime) {
        userStore.addUsersToStore(plainInvites.map(p => p.senderUser).filter(isDefined), requestTime)
        dataGroupStore.addDataGroupsToStore(plainInvites.map(p => p.dataGroup).filter(isDefined), requestTime)
        familyGroupStore.addFamilyGroupsToStore(plainInvites.map(p => p.familyGroup).filter(isDefined), requestTime)
        return AsyncDataStore.createItemMap(plainInvites, "DataGroupInvite", plainToDataGroupInvite, inv => inv.id!)
    }

    return {
        asyncInvites,
        asyncPermissions,

        getAsyncInvite,
        getAsyncInviteList,
        getInviteListAsync,
        getAsyncKeyListForDataGroup,
        getAsyncKeyListsForDataGroups,
        getInvitesForDataGroup,
        getInvitesForDataGroupAsync,
        getAsyncKeyListForFamilyGroup,
        getInvitesForFamilyGroupAsync,
        getAsyncPermissions,
        sendAsync,
        cancelAsync,
        resendAsync,
        approveAsync,
        rejectAsync,
    }
})
