import { defineStore } from "pinia";

export const useSignInStore = defineStore("signInStore", {
    state: () => ({
        givenName: "",
        familyName: "",
        address: "",
        isNewUser: false,
    }),
    actions: {
    },
})