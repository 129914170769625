<template>
  <div class="site-footer">
    <div class="footer-content container">
      <div class="row align-items-stretch">
        <div class="col-md-5 d-flex flex-column">
          <div class="flex-grow-1">
            <div class="copyright flex-grow-1 align-self-stretch">&copy; Copyright {{ new Date().getFullYear() }} Tree Crossing, LLC</div>
          </div>
        </div>
        <div class="col-md d-flex flex-column"><router-link to="/about" class="link-secondary align-self-stretch">About</router-link></div>
        <div class="col-md d-flex flex-column"><router-link to="/plans" class="link-secondary align-self-stretch">Plans</router-link></div>
        <div class="col-md d-flex flex-column"><router-link to="/support" class="link-secondary align-self-stretch">Support</router-link></div>
        <div class="col-md d-flex flex-column"><router-link to="/privacy" class="link-secondary align-self-stretch">Privacy</router-link></div>
        <div class="col-md d-flex flex-column"><router-link to="/terms" class="link-secondary align-self-stretch">Terms</router-link></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.site-footer{
  border-top: 1px solid rgb(233, 236, 239); // $border-color
  background-color: rgb(248, 249, 250); // $navbar-light-color
}

.footer-content {
  height: auto;
  min-height: 5rem;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;

  .copyright {
    color: #ccc;
    flex-shrink: 0; // don't wrap
    white-space: nowrap;
  }
}
.flex-grow-1 {
  flex-grow: 1;
}
.align-self-stretch {
  align-self: stretch;
}
@media (max-width: 767.98px) {
  .row.align-items-stretch > div {
    margin-bottom: 1rem; /* gap between stacked columns */
  }
}
</style>
