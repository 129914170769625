import { VersionedData } from '@webapp/util/AsyncData'
import { DateTime, DateTimeUnit, Duration } from 'luxon'
import { Environment } from '@webapp/util/Environment'
import { TokenManager } from '@webapp/auth/TokenManager'

export class User implements VersionedData {
    id?: string
    issuer?: string
    subject?: string
    givenName?: string
    surname?: string
    namePrivacy: PrivacyLevel = PrivacyLevel.Me
    gender?: UserGender
    genderPrivacy: PrivacyLevel = PrivacyLevel.Me
    photoUrl?: string
    photoPrivacy: PrivacyLevel = PrivacyLevel.Me
    email?: string
    emailPrivacy: PrivacyLevel = PrivacyLevel.Me
    email2?: string
    email2Privacy: PrivacyLevel = PrivacyLevel.Me
    mobileNumber?: string
    mobileNumberPrivacy: PrivacyLevel = PrivacyLevel.Me
    profileDataGroupId?: string
    metadata?: UserMetadata
    limits?: UserLimits
    limitsDisabled = false
    createdUserId?: string
    createdDate?: DateTime
    modifiedUserId?: string
    modifiedDate?: DateTime

    get isSelf() { return this.id == TokenManager.userId }

    get isTestUser() {
        return this.issuer == Environment.get('TEST_IDENTITY_URL');
    }

    get isSystemUser() {
        return this.issuer != Environment.get('IDENTITY_URL');
    }

    get displayName() {
        if (this.givenName && this.surname) {
            return this.givenName + ' ' + this.surname;
        }
        return (this.givenName ?? '') + (this.surname ?? '');
    }

    get pageUrl() { return User.getPageUrl(this.id) }

    static getPageUrl(userId: string | undefined) {
        return `/users/${userId}`
    }
}

export class UserMetadata {
    nameConfirmed = false
}

export class UserLimits {
    researchGroupLimit = 0
    sharingLimit = 0
}

export class UserSupervisor implements VersionedData {
    id?: string
    supervisorId?: string
    forUserId?: string
    createdDate?: DateTime
    createdUserId?: string
    modifiedDate?: DateTime // NOTE: this is not currently supported by the server (always undefined)
}

export class DataGroup implements VersionedData {
    id?: string
    groupType = DataGroupType.Profile
    name?: string
    description?: string
    startItemId?: string
    workspaceId?: string
    isChild = false
    createdUserId?: string
    createdDate?: DateTime
    modifiedUserId?: string
    modifiedDate?: DateTime
    ownerId?: string
    workspaceType?: string
    disabled = false

    get pageUrl() { return this.groupType == DataGroupType.Profile ? DataGroup.getProfilePageUrl(this.startItemId) : DataGroup.getResearchPageUrl(this.id) }

    static getProfilePageUrl(profilePersonId: string | undefined) {
        return `/profiles/${profilePersonId}`
    }

    static getResearchPageUrl(dataGroupId: string | undefined) {
        return `/trees/${dataGroupId}`
    }
}

export class DataGroupMember {
    id?: string
    dataGroupId?: string
    principalType = PrincipalType.User
    principalId?: string
    role = GroupMemberRole.Viewer
    disabled = false
    modifiedUserId?: string
    modifiedDate?: DateTime

    get key() { 
        return this.dataGroupId && this.id ? DataGroupMember.buildKey(this.dataGroupId, this.id) : undefined
    }

    static buildKey(dataGroupId: string, id: string) {
        return `${dataGroupId}|${id}`
    }

    static parseKey(key: string) {
        const parts = key.split("|", 2)
        return { dataGroupId: parts[0], id: parts[1] } as DataGroupMemberKey
    }

    static toKeyString(key: DataGroupMemberKey) {
        return DataGroupMember.buildKey(key.dataGroupId, key.id)
    }
}

export interface DataGroupMemberKey {
    id: string
    dataGroupId: string
}

export class DataGroupInvite {
    id?: string
    senderUserId?: string
    dataGroupId?: string
    principalType = PrincipalType.FamilyGroup
    principalId?: string
    role = GroupMemberRole.Viewer
    senderMessage?: string    
    lastSentDate?: DateTime    
    expirationDate?: DateTime    
    dataGroupApproval = ApprovalStatus.Pending
    principalApproval = ApprovalStatus.Pending
    closedDate?: DateTime    
    result = InvitationResult.Pending
    modifiedDate?: DateTime    
    modifiedUserId?: string

    get isExpired() { return !this.expirationDate || DateTime.utc() > this.expirationDate }

    get status() {
        return this.dataGroupApproval == ApprovalStatus.Rejected ? DataGroupInviteStatus.Rejected : // always show even if expired
            this.result == InvitationResult.Accepted ? DataGroupInviteStatus.Approved : // only true if invite did not expire
            this.isExpired ? DataGroupInviteStatus.Expired :
            DataGroupInviteStatus.Pending
    }

    get statusText() {
        const status = this.status
        return status == DataGroupInviteStatus.Rejected ? "Declined" : status.toString()
    }
}

export type TrustLevel = 0 | 1 | 2

export class ViewDataGroup {
    id?: string
    groupType =  DataGroupType.Profile
    startItemId?: string
    workspaceId?: string
    trustLevel: TrustLevel = 2 // least by default
    permissions = ItemPermissions.None
}

export class FamilyGroup implements VersionedData {
    id?: string
    name?: string
    description?: string
    scopeFamilyId?: string
    photoUrl?: string
    createdUserId?: string
    createdDate?: DateTime
    modifiedUserId?: string
    modifiedDate?: DateTime
    ownerId?: string

    get pageUrl() { return FamilyGroup.getPageUrl(this.id) }

    static getPageUrl(familyGroupId: string | undefined) {
        return `/groups/${familyGroupId}`
    }

    static compareName(a: FamilyGroup, b: FamilyGroup) {
        return (a.name ?? '').localeCompare(b.name ?? '')
    }
}

export class FamilyGroupMember implements VersionedData {
    id?: string
    familyGroupId?: string
    userId?: string
    role = GroupMemberRole.Member
    visibility = GroupMemberVisibility.Limited
    createdDate?: DateTime
    modifiedUserId?: string
    modifiedDate?: DateTime

    get key() { 
        return this.familyGroupId && this.id ? FamilyGroupMember.buildKey(this.familyGroupId, this.id) : undefined
    }

    get userPageUrl() { return User.getPageUrl(this.userId) }

    static buildKey(familyGroupId: string, id: string) {
        return `${familyGroupId}|${id}`
    }

    static parseKey(key: string) {
        const parts = key.split("|", 2)
        return { familyGroupId: parts[0], id: parts[1] } as FamilyGroupMemberKey
    }

    static toKeyString(key: FamilyGroupMemberKey) {
        return FamilyGroupMember.buildKey(key.familyGroupId, key.id)
    }
   
}

export interface FamilyGroupMemberKey {
    id: string
    familyGroupId: string
}

export class FamilyGroupInvite implements VersionedData {
    id?: string
    senderUserId?: string
    familyGroupId?: string
    profileDataGroupId?: string
    profileDisplayName?: string
    profileGender?: UserGender
    recipientEmail?: string
    recipientMobileNumber?: string
    recipientUserId?: string
    recipientRole = GroupMemberRole.Member
    recipientFirstName?: string
    recipientLastName?: string
    senderMessage?: string
    activationCode?: string
    encourageToInvite?: boolean
    lastSentDate?: DateTime
    expirationDate?: DateTime
    closedDate?: DateTime
    result = InvitationResult.Pending
    modifiedDate?: DateTime
    modifiedUserId?: string
    senderUser?: User
    familyGroup?: FamilyGroup
    profileDataGroup?: DataGroup
    recipientUser?: User
    workspaceType?: string

    get recipientDisplayName() { return `${this.recipientFirstName} ${this.recipientLastName}` }
    
    getAcceptUrl(code?: string) { return `/groupinvites/${this.id}/accept?code=${encodeURIComponent(code ?? '')}` }
}

export class Subscription implements VersionedData {
    id?: string
    providerSubscriptionId?: string
    providerCustomerId?: string
    startDate?: DateTime
    status = SubscriptionStatus.Cancelled
    paymentMethodId?: string
    currentPeriodStart?: DateTime
    currentPeriodEnd?: DateTime
    createdDate?: DateTime
    createdUserId?: string
    modifiedDate?: DateTime // NOTE: this is not currently supported by the server (always undefined)
    items: SubscriptionItem[] = []
}

export class SubscriptionItem {
    id?: string
    priceId?: string
    quantity = 0
    price?: ProductPrice // nested resource
}

export class ProductPrice {
    id?: string
    productId?: string
    currency = "USD"
    unitPrice = 0
    intervalUnit: DateTimeUnit = 'month'
    intervalCount = 1
}

export interface AddSubscriptionResponse {
    subscriptionId: string
    providerSubscriptionId: string
    paymentIntentId?: string
    clientSecret?: string
}

export class PaymentMethod {
    id?: string
    providerCustomerId?: string
    paymentType: PaymentType = PaymentType.Card
    brand?: string // e.g. "Visa ***1234"
    expirationDate?: DateTime
    lastDigits?: string
    billingName?: string
    modifiedDate?: DateTime

    get identifier() { return `${this.brand} (xxx${this.lastDigits})` }
}

export class Payment {
    id?: string
    subscriptionId?: string
    methodId?: string
    date?: DateTime
    amount = 0
    status = PaymentStatus.Pending
}

export enum UserGender {
    Male = "Male",
    Female = "Female",
}

export enum PrivacyLevel {
    Everyone = "Everyone",
    Groups = "Groups",
    Friends = "Friends",
    Me = "Me",
}

export enum DataGroupType {
    System = "System",
    Research = "Research",
    Profile = "Profile",
}

export enum GroupMemberRole {
    Viewer = "Viewer",
    Member = "Member",
    Contributor = "Contributor",
    Recruiter = "Recruiter",
    Administrator ="Administrator",
    Owner = "Owner",
}

export enum PrincipalType {
    User = "User",
    FamilyGroup = "FamilyGroup",
}

export enum GroupMemberVisibility {
    None = "None",
    Limited = "Limited",
    Full = "Full",
}

export enum ApprovalStatus {
    Pending = "Pending",
    Approved = "Approved",
    Rejected = "Rejected",
}

export enum DataGroupInviteStatus {
    Pending = "Pending",
    Approved = "Approved",
    Rejected = "Rejected",
    Expired = "Expired",
}

export enum InvitationResult {
    Pending = "Pending",
    Cancelled = "Cancelled",
    Accepted = "Accepted",
}

export enum ItemPermissions {
    None = 0,
    Read = 1 << 0,
    ReadContent = 1 << 1,
    AddContent = 1 << 2,
    ModifyContent = 1 << 3,
    ListItems = 1 << 4,
    ReadItems = 1 << 5,
    ModifyItems = 1 << 6,
    Modify = 1 << 7,
    AddItems = 1 << 8,
    DeleteItems = 1 << 9,
    Delete = 1 << 10,
    ReadPermissions = 1 << 11,
    ModifyPermissions = 1 << 12,    
    Owner = 1 << 13,

    AllRead = Read | ReadContent | ListItems | ReadItems,
    AllEdit = AllRead | ModifyItems | ModifyContent | Modify | AddItems | DeleteItems,
    Administrator = AllEdit | ReadPermissions | ModifyPermissions,
}

export enum SubscriptionStatus {
    Active = "Active",
    Cancelled = "Cancelled",
    PastDue = "PastDue",
    Trial = "Trial",
    Incomplete = "Incomplete",
    IncompleteExpired = "IncompleteExpired",
}

export enum PaymentType {
    Card = "Card",
}

export enum PaymentStatus {
    Pending = "Pending",
    Succeeded = "Succeeded",
    Failed = "Failed",
}

export interface SubscriptionPlan {
    id: string
    displayName: string
    description: string
    price?: number
    termMonths?: number
    trialDays?: number
}

export const subscriptionPlans: SubscriptionPlan[] = [{ 
    id: 'mem.1',
    displayName: 'Member', 
    description: 'For family members and beginners',
  }, {
    id: 'gen.1',
    displayName: 'Genealogist',
    description: 'For genealogists who want to share one tree with a few people',
    price: 2.50,
    termMonths: 6,
    trialDays: 14,
}, {
    id: 'plus.1',
    displayName: 'Genealogist Plus',
    description: 'For genealogists who want to share their trees with the whole family',
    price: 7.50,
    termMonths: 6,
    trialDays: 14,
  }, {
    id: 'pro.1',
    displayName: 'Professional',
    description: 'For professional genealogists who build family trees for others',
    price: 25,
    termMonths: 6,
    trialDays: 14,
  }
]

export const defaultPlanId = "mem.1"
