<template>
  <div class="assertion-list" :class="{ ['card-style']: props.cardStyle }">
    <div class="assertion-list-header">
      <div class="text-end">date</div>
      <div></div>
      <div></div>
      <div>type</div>
      <div>details</div>
      <div v-if="props.profile" class="actions-spacer"></div>
    </div>
    <ul class="list-unstyled assertion-list-area">
      <li v-for="set in assertionSetsRef" :key="set[0].id">
        <div v-if="showTimeGap(set[0].id!) || showDistance(set[0].id!)" class="assertion-diff">
          <div v-if="showTimeGap(set[0].id!)" class="assertion-time-gap">
            <div>{{ getTimeGapText(set[0].id!) }}</div>
          </div>
          <div v-if="showTimeGap(set[0].id!) || showDistance(set[0].id!)" class="time-ellipsis">
            <div>&#8942;</div>
          </div>
          <div v-if="showDistance(set[0].id!)" class="assertion-distance">
            <div><div class="place-ellipsis">&#8942;</div><span class="distance-text">{{ getDistanceText(set[0].id!) }}</span></div>
          </div>
        </div>
        <AssertionSet 
          :assertionIds="set.map(a => a.id!)" 
          :key="set[0].id"
          :baseDate="birthDateRef"
          :profile="props.profile"
          :card-style="props.cardStyle"
          @edit="editAssertion">
        </AssertionSet>
      </li>
      <li v-if="allowEditRef">
        <div class="add-row">
          <button type="button" class="btn btn-inline btn-link" @click="onAdd">
            Add detail
          </button>
        </div>
      </li>
      <li v-for="i in fillerRowCountRef" :key="i" class="filler-row"></li>
    </ul>
    <AssertionModal ref="assertionModalRef">
    </AssertionModal>
  </div>
</template>

<style lang="scss" scoped>
.assertion-list.card-style {
  border: 1px solid #f4f4f4;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.assertion-list {
  .assertion-list-header {
    border-bottom: 1px solid rgb(254, 203, 209); // pink
  }

  .assertion-diff, .add-row, .filler-row {
    border-bottom: 1px solid rgb(237, 239, 240); // pale blue-gray
  }
}

.assertion-list-header,
.assertion-diff,
.add-row {
  // same grid columns as AssertionRow.assertion-fields
  display: grid;
  grid-template-columns: 18fr 5fr 4fr 19fr 50fr;
  column-gap: 0.5rem;
  padding: 0 0.5rem;
  padding-bottom: 2px;
}

.assertion-list-header {
  padding: 1.5rem 0.5rem 0;
  font-size: 0.75rem;
  color: #888;

  .actions-spacer {
    width: 1rem;
  }
}

.assertion-list-area {
  margin-bottom: 1.25rem;
}

.assertion-diff {
  padding-bottom: 0.05rem;
  margin-bottom: 0.05rem;
  font-size: 0.75rem;
  color: #888;
}

.time-ellipsis {
  grid-column: 2;
  text-align: center;
}
.assertion-time-gap {
  grid-column: 1;
  text-align: end;
  font-style: italic;
}
.place-ellipsis {
  display: inline-block;
  width: 1.5rem;
  padding-left: 0.4rem;
}
.assertion-distance {
  grid-column: 5;
  text-align: start;
}
.distance-text {
  font-style: italic;
}

.add-row {
  button {
    grid-column: 4;
    font-size: 0.875rem;
  }
}

.filler-row {
  height: 1.5rem;
}
</style>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { Assertion, AssertionType, ItemType } from '@/rd/ResearchDataModel'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { useViewFamilyStore } from '@/rd/ViewFamilyStore'
import { usePersonStore } from '@/rd/PersonStore'
import { useFamilyStore } from '@/rd/FamilyStore'
import { useAssertionStore } from '@/rd/AssertionStore'
import { usePlaceStore } from '@/rd/PlaceStore'
import { isDefined } from '@/util/TypeScriptUtil'
import _ from 'lodash'
import AssertionSet from '@/explore/AssertionSet.vue'
import { GeoUtil } from '@/util/GeoUtil'
import { useDataGroupStore } from '@/gp/DataGroupStore'
import AssertionModal from '@/manage/AssertionModal.vue'
import { ItemPermissions } from '@/gp/GroupAdminModel'
import { CompositeId } from '@/rd/CompositeId'

const props = defineProps<{
  personId?: string,
  includeRelated?: boolean,
  profile?: boolean,
  cardStyle?: boolean,
}>()

const dataGroupStore = useDataGroupStore()
const viewPersonStore = useViewPersonStore()
const viewFamilyStore = useViewFamilyStore()
const personStore = usePersonStore()
const familyStore = useFamilyStore()
const assertionStore = useAssertionStore()
const placeStore = usePlaceStore()

const orderedTypes: string[] = [
  AssertionType.Living,
  AssertionType.Name,
  AssertionType.Gender,
  AssertionType.Child,
]

const permissionsRef = computed(() =>  dataGroupStore.getAsyncPermissions(CompositeId.getGroupId(props.personId))?.data ?? ItemPermissions.None) // loaded by parent
const allowEditRef = computed(() => (permissionsRef.value & ItemPermissions.Modify) != 0)
const singlePersonRef = computed(() => personStore.getAsyncPerson(props.personId)?.data) // loaded by parent
const viewPersonRef = computed(() => viewPersonStore.getAsyncPerson(props.personId)?.data) // loaded by parent
const birthDateRef = computed(() => (props.profile ? singlePersonRef.value?.displayProperties : viewPersonRef.value?.displayProperties)?.birthDate.date1)
const fillerRowCountRef = computed(() => Math.max(6 - assertionSetsRef.value.length, 0))

const assertionSetsRef = ref<Assertion[][]>([])

const areDetailsLoadedRef = computed(() => !!props.personId && (props.profile
  ? personStore.areDetailsLoaded(props.personId)
  : viewPersonStore.areDetailsLoaded(props.personId)))

watch([
  props,
  viewPersonRef,
  areDetailsLoadedRef,
], async () => {
  assertionSetsRef.value = []

  if (!props.personId || !areDetailsLoadedRef.value)
    return

  const viewPersonIdMap = new Map<string, string>()
  
  if (props.profile) {
    viewPersonIdMap.set(props.personId, props.personId) // 1:1
  }
  else if (viewPersonRef.value) {
    for (const matchId of viewPersonRef.value.matchIds) {
      viewPersonIdMap.set(matchId, props.personId) // *:1
    }
  }

  if (viewPersonIdMap.size == 0)
    return

  // person assertions should be loaded by parent as part of view person details
  const asyncPersonAssertionIdLists = personStore.getAsyncAssertionKeyLists([...viewPersonIdMap.keys()])
  await Promise.all(asyncPersonAssertionIdLists.map(a => a.whenLoadCompleted))

  const personAssertionIdLists = asyncPersonAssertionIdLists.map(a => a.data?.keys).filter(isDefined)
  console.log(`AssertionList: personIds: ${viewPersonIdMap.size}, assertionIdLists: ${personAssertionIdLists.length}`)

  const allAssertionIds = personAssertionIdLists.flat()
 
  const viewFamilyIdMap = new Map<string, string>()

  if (props.includeRelated) {
    // spouse family keys, spouse families, and spouse family assertions should be loaded by parent
    const asyncSpouseViewFamilyIds = viewPersonStore.getAsyncSpouseFamiliesKeyList(props.personId)!
    await asyncSpouseViewFamilyIds.whenLoadCompleted

    const spouseViewFamilyIds = asyncSpouseViewFamilyIds.data?.keys ?? []
    const asyncSpouseViewFamilies = spouseViewFamilyIds.map(id => viewFamilyStore.getAsyncFamily(id))
    await Promise.all(asyncSpouseViewFamilies.map(a => a?.whenLoadCompleted))

    const spouseViewFamilies = asyncSpouseViewFamilies.map(a => a!.data).filter(isDefined)
    const spouseFamilyIds = spouseViewFamilies.flatMap(vf => vf.matchIds)
    const asyncSpouseFamilyAssertionKeyLists = familyStore.getAsyncAssertionKeyLists(spouseFamilyIds)
    await Promise.all(asyncSpouseFamilyAssertionKeyLists.map(a => a.whenLoadCompleted))

    const spouseFamilyAssertionIdLists = asyncSpouseFamilyAssertionKeyLists.map(a => a.data?.keys).filter(isDefined)
    console.log(`AssertionList: familyIds: ${spouseFamilyIds.length}, assertionIdLists: ${spouseFamilyAssertionIdLists.length}`)

    allAssertionIds.push(...(spouseFamilyAssertionIdLists.flat()))

    for (const vf of spouseViewFamilies) {
      for (const id of vf.matchIds) {
        viewFamilyIdMap.set(id, vf.id!)
      }
    }
  }

  // all assertions should be loaded by now, but await anyway for kicks and giggles
  const asyncAssertions = assertionStore.getAsyncAssertionList(allAssertionIds)
  await Promise.all(asyncAssertions.map(a => a.whenLoadCompleted))

  const hiddenAssertionTypes = ["Child", "Living"]
  const assertions = asyncAssertions.map(a => a.data).filter(isDefined)
    .filter(a => !hiddenAssertionTypes.includes(a.assertionType))

  const assertionSets = props.profile
    ? assertions.map(a => [a]) // no sets
    : buildViewAssertionSets(assertions, viewPersonIdMap, viewFamilyIdMap)

  for (const set of assertionSets) {
    set.sort(Assertion.comparePrecedence)
  }
  assertionSets.sort((a, b) => Assertion.compareDate(a[0], b[0]) || compareType(a[0], b[0]))
  
  assertionSetsRef.value = assertionSets
})

function buildViewAssertionSets(assertions: Assertion[], viewPersonIdMap: Map<string, string>, viewFamilyIdMap: Map<string, string>) {
  const assertionSetMap = new Map<string, Assertion[]>()
  for (const a of assertions) {
    const subjectViewId = a.subjectType == "Person" 
      ? viewPersonIdMap.get(a.subjectId!)
      : viewFamilyIdMap.get(a.subjectId!)

    const key = `${a.subjectType}:${subjectViewId}:${a.assertionType}:${a.precedence?.instanceId ?? 1}`
    const set = assertionSetMap.get(key)
    if (set) {
      set.push(a)
    } else {
      assertionSetMap.set(key, [a])
    }
  }
  return Array.from(assertionSetMap.values())
}

const assertionTimeGapMap = computed(() => {
  const map = new Map<string, number>()
  let current = assertionSetsRef.value.at(0)
  for (let i = 1; i < assertionSetsRef.value.length; i++) {
    const prev = current!
    current = assertionSetsRef.value[i]

    if (!prev[0].sortDate.isEmpty && !current[0].sortDate.isEmpty) {
      // don't include gap between birth and first assertion since the gap is equal to the age, which is already shown
      if (birthDateRef.value && prev[0].sortDate.date1.diff(birthDateRef.value).years > 1) {
        const gap = current[0].sortDate.date1.diff(prev[0].sortDate.date1)
        map.set(current[0].id!, gap.years)
      }
    }
  }
  return map
})

const assertionDistanceMap = computed(() => {
  const map = new Map<string, number>()
  for (let i = 1; i < assertionSetsRef.value.length; i++) {
    const prev = assertionSetsRef.value[i - 1]
    const current = assertionSetsRef.value[i]

    if (prev[0].placeId && current[0].placeId) {
      const prevPlace = placeStore.trackAsyncPlace(prev[0].placeId)!.data
      const currentPlace = placeStore.trackAsyncPlace(current[0].placeId)!.data

      if (prevPlace && currentPlace) {
        const distance = prevPlace.distanceTo(currentPlace)
        if (distance) {
          map.set(current[0].id!, distance)
        }
      }
    }
  }
  return map
})

function showTimeGap(id: string) {
  const gap = assertionTimeGapMap.value.get(id)
  return gap && gap > 10 // years
}

function getTimeGapText(id: string) {
  const gap = assertionTimeGapMap.value.get(id)
  return `${Math.floor(gap ?? 0)} years`
}

function showDistance(id: string) {
  const distance = assertionDistanceMap.value.get(id)
  return distance && distance > 150 // km, or ~100 miles
}

function getDistanceText(id: string) {
  const distance = assertionDistanceMap.value.get(id)
  return `${Math.floor(GeoUtil.kmToMi(distance ?? 0)).toLocaleString()} miles`
}

function compareType(a:Assertion, b:Assertion) {
  const aIndex = orderedTypes.indexOf(a.assertionType)
  const bIndex = orderedTypes.indexOf(b.assertionType)
  return (aIndex == -1 ? 999 : aIndex) - (bIndex == -1 ? 999 : bIndex) // ordered types sort first
}

const assertionModalRef = ref<InstanceType<typeof AssertionModal>>()

function editAssertion(id: string) {
  assertionModalRef.value?.openEdit(id)
}

function onAdd() {
  assertionModalRef.value?.openNew(props.personId!, ItemType.Person)
}

</script>
