import { Environment } from './util/Environment'

// Set these so consumers can get them at runtime from the Environment class instead of process.env (which only exists at build time)
Environment.set('ENVIRONMENT',              process.env.VUE_APP_ENVIRONMENT)
Environment.set('IDENTITY_URL',             process.env.VUE_APP_IDENTITY_URL)
Environment.set('GROUP_ADMIN_URL',          process.env.VUE_APP_GROUP_ADMIN_URL)
Environment.set('RESEARCH_DATA_URL',        process.env.VUE_APP_RESEARCH_DATA_URL)
Environment.set('IDENTITY_TOKEN_AUDIENCE',  process.env.VUE_APP_IDENTITY_TOKEN_AUDIENCE)
Environment.set('LOCAL_TOKEN_AUDIENCE',     process.env.VUE_APP_LOCAL_TOKEN_AUDIENCE)
Environment.set('APPINSIGHTS_CS',           process.env.VUE_APP_APPINSIGHTS_CS)
Environment.set('TURNSTILE_KEY',            process.env.VUE_APP_TURNSTILE_KEY)
Environment.set('GOOGLE_API_KEY',           process.env.VUE_APP_GOOGLE_API_KEY)
Environment.set('GOOGLE_CLIENT_ID',         process.env.VUE_APP_GOOGLE_CLIENT_ID)
Environment.set('MS_GRAPH_CLIENT_ID',       process.env.VUE_APP_MS_GRAPH_CLIENT_ID)
Environment.set('STRIPE_PUBLIC_KEY',        process.env.VUE_APP_STRIPE_PUBLIC_KEY)
