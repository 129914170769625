import { defineStore } from 'pinia'
import { DataGroupMember, DataGroupMemberKey, GroupMemberRole, PrincipalType } from './GroupAdminModel'
import { gp } from './GroupAdminApi'
import { AsyncData, LoadMode } from '@webapp/util/AsyncData'
import { AsyncDataStore, VersionedKeyList } from '@webapp/util/AsyncDataStore'
import { useUserStore } from './UserStore'
import { useDataGroupStore } from './DataGroupStore'
import { useFamilyGroupStore } from './FamilyGroupStore'
import { DateTime } from 'luxon'
import { DateTimeUtil } from '@webapp/util/LuxonUtil'
import { assignExisting, isDefined } from '@webapp/util/TypeScriptUtil'
import { reactive, ref } from 'vue'
import { addRedactRule } from '@webapp/util/RedactRules'

// SECURITY: don't log code parameter when loading data groups for a family group invite
addRedactRule({ pattern: new RegExp('^/familygroups/[^/]+/datagroups$'), params: ['code'] })

export const useDataGroupMemberStore = defineStore('dataGroupMembers', () => {
    const asyncDataGroupMembers = reactive(new Map<string, AsyncData<DataGroupMember>>())

    const dataGroupStore = useDataGroupStore()
    const dataGroupMemberStore = useDataGroupMemberStore()
    const userStore = useUserStore()
    const familyGroupStore = useFamilyGroupStore()

    function getAsyncMember(key: string | DataGroupMemberKey | undefined, loadMode = LoadMode.TrackOnly) {
        return getAsyncMemberList([key], loadMode)[0]
    }
        
    function getAsyncMemberList(keys: (string | DataGroupMemberKey | undefined)[], loadMode = LoadMode.TrackOnly) {
        const ids = keys.filter(isDefined).map(key => typeof key == "string" ? key : DataGroupMember.toKeyString(key))
        const loadAsync = async (idsToLoad: string[], requestTime: DateTime) => {
            const parsedKeys = idsToLoad.filter(isDefined).map(DataGroupMember.parseKey)
            const dataGroupId = parsedKeys[0].dataGroupId
            const expand = "familyGroup/owner,user"
            const plainItems = (parsedKeys.every(k => k.dataGroupId == dataGroupId))
                ? await gp.getPlainByIdsAsync(`datagroups/${dataGroupId}/members`, parsedKeys.map(k => k.id), expand)
                : await Promise.all(parsedKeys.map(k => gp.getPlainAsync(`datagroups/${k.dataGroupId}/members/${k.id}`, { expand })))
            console.log(`Loaded ${plainItems.length} data group members by id`)
            return processExpandedDataGroupMembers(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncItemsByIds(asyncDataGroupMembers, ids, "DataGroupMember", loadMode, loadAsync)
    }
        
    async function getMemberListAsync(keys: (string | DataGroupMemberKey | undefined)[]) {
        return (await Promise.all(getAsyncMemberList(keys, LoadMode.EnsureLoaded).map(a => a.whenLoadCompleted))).filter(isDefined)
    }

    async function getMemberByPrincipalAsync(dataGroupId: string, type: PrincipalType, principalId: string) {
        const expand = "familyGroup/owner,user"
        const requestTime = DateTime.utc()
        const plainItem = await gp.getPlainAsync(`datagroups/${dataGroupId}/members/$lookup`, { principalType: type, principalId, expand })
        const itemMap = processExpandedDataGroupMembers([plainItem], requestTime)
        AsyncDataStore.addItemMapToStore(asyncDataGroupMembers, "DataGroupMember", itemMap, requestTime)
        return itemMap.values().next().value
    }

    function getAsyncKeyListsForDataGroups(dataGroupIds: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        return getAsyncKeyListsForDataGroupMembers(dataGroupIds, "DataGroup", dataGroupStore.asyncMemberKeys, loadMode, 
            id => `datagroups/${id}/members`, "familyGroup/owner,user", dm => dm.dataGroupId!)
    }

    function getAsyncKeyListsForUsers(userIds: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        return getAsyncKeyListsForDataGroupMembers(userIds, "User", userStore.asyncDataGroupMemberKeys, loadMode, 
            id => `users/${id}/datagroups`, "dataGroup/owner", dm => dm.principalId!)
    }

    function getAsyncKeyListsForFamilyGroups(familyGroupIds: (string | undefined)[], loadMode = LoadMode.TrackOnly, inviteId?: string, code?: string) {
        return getAsyncKeyListsForDataGroupMembers(familyGroupIds, "FamilyGroup", familyGroupStore.asyncDataGroupMemberKeys, loadMode, 
            id => `familygroups/${id}/datagroups`, "dataGroup/owner", dm => dm.principalId!, inviteId, code)
    }


    function getMembersForDataGroups(dataGroupIds: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const keys = getAsyncKeyListsForDataGroups(dataGroupIds, loadMode).flatMap(a => a?.data?.keys ?? [])
        return getAsyncMemberList(keys).map(a => a.data).filter(isDefined)
    }

    function getMembersForUsers(userIds: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const keys = getAsyncKeyListsForUsers(userIds, loadMode).flatMap(a => a?.data?.keys ?? [])
        return getAsyncMemberList(keys).map(a => a.data).filter(isDefined)
    }

    function getMembersForFamilyGroups(familyGroupIds: (string | undefined)[], loadMode = LoadMode.TrackOnly) {
        const keys = getAsyncKeyListsForFamilyGroups(familyGroupIds, loadMode).flatMap(a => a?.data?.keys ?? [])
        return getAsyncMemberList(keys).map(a => a.data).filter(isDefined)
    }


    function getAsyncMembersForDataGroup(dataGroupId: string | undefined, loadMode = LoadMode.TrackOnly) {
        return getAsyncMemberList(getAsyncKeyListsForDataGroups([dataGroupId], loadMode).at(0)?.data?.keys ?? [])
    }

    function getAsyncMembersForUser(userId: string | undefined, loadMode = LoadMode.TrackOnly) {
        return getAsyncMemberList(getAsyncKeyListsForUsers([userId], loadMode).at(0)?.data?.keys ?? [])
    }

    function getAsyncMembersForFamilyGroup(familyGroupId: string | undefined, loadMode = LoadMode.TrackOnly) {
        return getAsyncMemberList(getAsyncKeyListsForFamilyGroups([familyGroupId], loadMode).at(0)?.data?.keys ?? [])
    }


    async function getMembersForDataGroupAsync(dataGroupId: string | undefined) {
        const keyList = await getAsyncKeyListsForDataGroups([dataGroupId], LoadMode.EnsureLoaded).at(0)?.whenLoadCompleted
        return getMemberListAsync(keyList?.keys ?? []) // should be loaded
    }

    async function getMembersForUserAsync(userId: string | undefined) {
        const keyList = await getAsyncKeyListsForUsers([userId], LoadMode.EnsureLoaded).at(0)?.whenLoadCompleted
        return getMemberListAsync(keyList?.keys ?? []) // should be loaded
    }

    async function getMembersForFamilyGroupAsync(familyGroupId: string | undefined) {
        const keyList = await getAsyncKeyListsForFamilyGroups([familyGroupId], LoadMode.EnsureLoaded).at(0)?.whenLoadCompleted
        return getMemberListAsync(keyList?.keys ?? []) // should be loaded
    }

    async function addAsync(dataGroupId: string, member: DataGroupMember) {
        const addCoreAsync = async () => {
            const p = await gp.postPlainAsync(`datagroups/${dataGroupId}/members`, member)
            return plainToDataGroupMember(p)
        }            
        const key = await AsyncDataStore.addToStoreMapAsync(asyncDataGroupMembers, "DataGroupMember", m => m.id, addCoreAsync)
        const newMember = asyncDataGroupMembers.get(key)?.data // should be present

        invalidateKeyLists(newMember)
        return key
    }

    async function deleteAsync(key: string | DataGroupMemberKey) {
        const member = await getAsyncMember(key, LoadMode.EnsureLoaded).whenLoadCompleted // usually present
        if (member) {
            const keyObj = typeof key == "string" ? DataGroupMember.parseKey(key) : key
            await gp.deleteAsync(`datagroups/${keyObj.dataGroupId}/members/${keyObj.id}`)
    
            AsyncData.setDeleted(asyncDataGroupMembers.get(member.key!))
            invalidateKeyLists(member)
        }
    }
    
    function plainToDataGroupMember(p: any) {
        const member = assignExisting(new DataGroupMember(), p)
        member.role = p.role ?? GroupMemberRole.Viewer // default
        member.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate);
        return member
    }

    function processExpandedDataGroupMembers(plainItems: any[], requestTime: DateTime) {
        dataGroupStore.addDataGroupsToStore(plainItems.map(p => p.dataGroup).filter(isDefined), requestTime)
        familyGroupStore.addFamilyGroupsToStore(plainItems.map(p => p.familyGroup).filter(isDefined), requestTime)
        userStore.addUsersToStore(plainItems.map(p => p.user).filter(isDefined), requestTime)
        return AsyncDataStore.createItemMap(plainItems, "DataGroupMember", plainToDataGroupMember, m => m.key!)
    }

    function getAsyncKeyListsForDataGroupMembers(sourceItemKeys: (string | undefined)[],
        sourceTypeName: string,
        relatedKeysStoreMap: Map<string, AsyncData<VersionedKeyList>>,
        loadMode: LoadMode,
        relatedItemsPath: (id: string) => string,
        expand: string | undefined,
        getSourceKey: (dm: DataGroupMember) => string,
        inviteId?: string,
        code?: string) {

        const loadAsync = async (sourceKeys: string[], requestTime: DateTime) => {
            const ids = sourceKeys.filter(isDefined)
            const path = relatedItemsPath(sourceKeys.length == 1 ? sourceKeys[0] : '$list')
            const query = { inviteId, code, ids: sourceKeys.length == 1 ? undefined : ids.join() }
            const plainItems = await gp.getPlainCollectionAsync(path, expand, query)
            console.log(`Loaded ${plainItems.length} data group members at ${relatedItemsPath}`)
            return processExpandedDataGroupMembers(plainItems, requestTime)
        }
        return AsyncDataStore.getAsyncKeyListsForRelatedItems(sourceItemKeys, sourceTypeName, relatedKeysStoreMap,
            dataGroupMemberStore.asyncDataGroupMembers, "DataGroupMember", loadMode, loadAsync, getSourceKey)
    }

    function invalidateKeyLists(member: DataGroupMember | undefined) {
        if (!member)
            return
        
        const asyncKeys = dataGroupStore.asyncMemberKeys.get(member.dataGroupId!)
        AsyncData.invalidate(asyncKeys)
    
        if (member.principalType == PrincipalType.User) {
            const asyncKeysForUser = userStore.asyncDataGroupMemberKeys.get(member.principalId!)
            AsyncData.invalidate(asyncKeysForUser)
        }
        else if (member.principalType == PrincipalType.FamilyGroup) {
            const asyncKeysForFamilyGroup = familyGroupStore.asyncDataGroupMemberKeys.get(member.principalId!)
            AsyncData.invalidate(asyncKeysForFamilyGroup)
        }
    }

    return {
        asyncDataGroupMembers,

        getAsyncMember,
        getAsyncMemberList,
        getMemberListAsync,
        getMemberByPrincipalAsync,
        getAsyncKeyListsForDataGroups,
        getAsyncKeyListsForUsers,
        getAsyncKeyListsForFamilyGroups,
        getMembersForDataGroups,
        getMembersForUsers,
        getMembersForFamilyGroups,
        getAsyncMembersForDataGroup,
        getAsyncMembersForUser,
        getAsyncMembersForFamilyGroup,
        getMembersForDataGroupAsync,
        getMembersForUserAsync,
        getMembersForFamilyGroupAsync,
        addAsync,
        deleteAsync,
        invalidateKeyLists,
    }
})
