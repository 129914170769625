import { reactive } from 'vue'
import { AsyncData } from "@webapp/util/AsyncData"
import { VersionedKeyList } from "@webapp/util/AsyncDataStore"
import { defineStore } from "pinia"

export const useGroupStore = defineStore("groupStore", () => {
    const asyncWorkspaceKeys = reactive(new Map<string, AsyncData<VersionedKeyList>>())

    return {
        asyncWorkspaceKeys,
    }
})
