<template>
  <div class="person-highlights">
    <div class="row highlight-row">
      <div v-if="highlightsRef.length == 0" class="empty-list col">
        No fun facts found (yet)
      </div>
      <div v-for="h in highlightsRef" :key="h.id" class="col-3">
        <PopulationHighlight v-if="h.id.startsWith('population:')" :highlight="h"></PopulationHighlight>
        <PersonNameHighlight v-else-if="h.id.startsWith('sameName:')" :highlight="h"></PersonNameHighlight>
        <div v-else class="other-highlight">
          <div class="highlight-visual">
            <div v-if="h.image" class="highlight-icon"><img :src="h.image"/></div>
            <div v-if="h.value" class="highlight-value" :style="{ fontSize: getFittedFontSize(h.value) }">{{ h.value }}</div>
            <div v-if="h.flag" class="highlight-flag fi" :class="[`fi-${h.flag}`]"></div>
          </div>
          <div class="highlight-text">{{ h.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.person-highlights {
  padding-top: 1rem;
}

.highlight-row {
  justify-content: start;
}

.empty-list {
  font-size: 0.875rem;
  color: #aaa;
}

.other-highlight {
  display: flex;
  flex-direction: column;
  align-items: center;

  .highlight-visual {
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .highlight-icon {
    opacity: 60%;

    img {
      width: 50px;
    }
  }

  .highlight-value {
    line-height: 1;
  }

  .highlight-flag {
    width: 56px; // 4:3 ratio
    height: 42px;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.15);
  }

  .highlight-text {
    text-align: center;
    font-size: 0.75rem;
    color: #888;
  }
}
</style>

<script lang="ts" setup>
import { Ref, computed, ref, watch } from 'vue'
import { usePlaceStore } from '@/rd/PlaceStore'
import { useViewPersonStore } from '@/rd/ViewPersonStore'
import { getPlacePopulation } from './PlacePopulation'
import { usePersonNameHighlights } from './PersonNameHighlights'
import { HighlightItem } from './HighlightItem'
import { getPossessivePhrase } from '@/util/LanguageUtil'
import PopulationHighlight from './PopulationHighlight.vue'
import PersonNameHighlight from './PersonNameHighlight.vue'
import { HistoricalDateType } from '@/rd/HistoricalDateEnums'
import { DurationUtil } from '@/util/LuxonUtil'

const props = defineProps({
  personId: String
})

const viewPersonStore = useViewPersonStore()
const placeStore = usePlaceStore()
const { getNameHighlights } = usePersonNameHighlights()

const viewPersonRef = computed(() => viewPersonStore.getAsyncPerson(props.personId)?.data)
const highlightsRef = ref([]) as Ref<HighlightItem[]>

watch(viewPersonRef, () => {
  highlightsRef.value = []

  if (!viewPersonRef.value)
    return

  // lifespan
  const birthDate = viewPersonRef.value.displayProperties.birthDate
  const deathDate = viewPersonRef.value.displayProperties.deathDate
  if (!birthDate.isEmpty && 
    !deathDate.isEmpty && 
    birthDate.type == HistoricalDateType.Date && 
    deathDate.type == HistoricalDateType.Date) {
    
    const lifespan = deathDate.date1!.diff(birthDate.date1!).shiftTo("years", "months", "days")
    if (lifespan.years < 30) {
      highlightsRef.value.push({
        id: `lifespan:short:${lifespan.toISO()}`,
        text: `only lived ${DurationUtil.toPersonAge(lifespan, true)}`,
        value: DurationUtil.toPersonAge(lifespan),
      })
    }
  }
    
  const birthPlaceId = viewPersonRef.value.displayProperties.birthPlaceId
  if (birthPlaceId) {
    const birthPlace = placeStore.asyncPlaces.get(birthPlaceId)?.data
    if (birthPlace?.name) {

      // birth place population
      const pop = getPlacePopulation(birthPlace.name, viewPersonRef.value.birthYear)
      if (pop) {
        highlightsRef.value.push({
          id: `population:${viewPersonRef.value.displayProperties.birthPlaceId}:birth:${viewPersonRef.value.birthYear}`,
          text: `hometown population at ${getPossessivePhrase(viewPersonRef.value.displayProperties.gender, 'birth')}`,
          value: pop,
        })
      }

      // birth place country
      if (birthPlace.countryCode) {
        highlightsRef.value.push({
          id: `birthPlace:${birthPlace.countryCode}`,
          text: `born in ${birthPlace.countryName}`,
          flag: birthPlace.countryCode.toLowerCase(),
        })
      }

      // death place country (if different)
      const deathPlaceId = viewPersonRef.value.displayProperties.deathPlaceId
      if (deathPlaceId) {
        const deathPlace = placeStore.asyncPlaces.get(deathPlaceId)?.data
        if (deathPlace?.countryCode && deathPlace.countryCode != birthPlace.countryCode) {
          highlightsRef.value.push({
            id: `deathPlace:${deathPlace.countryCode}`,
            text: `died in ${deathPlace.countryName}`,
            flag: deathPlace.countryCode.toLowerCase(),
          })
        }
      }
    }
  }

  // name highlights
  highlightsRef.value.push(...getNameHighlights(props.personId!))
})

function getFittedFontSize(value: string | number) {
  const valueStr = value.toString()

  if (valueStr.length < 3) {
    return '2rem'
  } else if (valueStr.length < 4) {
    return '1.3rem'
  } else {
    return '1.1rem'
  }
}

</script>