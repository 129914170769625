import { reactive } from 'vue'
import { Place } from "./ResearchDataModel"
import { AsyncData, LoadMode } from "@webapp/util/AsyncData"
import { AsyncDataStore, VersionedKeyList } from "@webapp/util/AsyncDataStore"
import { defineStore } from "pinia"
import { DateTime } from "luxon"
import { DateTimeUtil } from "@webapp/util/LuxonUtil"
import _ from "lodash"
import { assignExisting, isDefined } from "@webapp/util/TypeScriptUtil"


export const usePlaceStore = defineStore("placeStore", () => {
    const asyncPlaces = reactive(new Map<string, AsyncData<Place>>())

    function trackAsyncPlace(placeId: string | undefined) {
        return placeId ? trackAsyncPlaces([placeId])[0] : undefined
    }

    function trackAsyncPlaces(placeIds: string[]) {
        AsyncDataStore.getAsyncItemsToLoad(asyncPlaces, placeIds, "Place", LoadMode.TrackOnly)
        return placeIds.map(id => asyncPlaces.get(id)!)
    }

    function addPlacesToStore(plainPlaces: any[], requestTime: DateTime) {
        const placeMap = AsyncDataStore.createItemMap(plainPlaces, "Place", plainToPlace, pl => pl.id!)
        AsyncDataStore.addItemMapToStore(asyncPlaces, "Place", placeMap, requestTime)
    }

    return {
        asyncPlaces,

        trackAsyncPlace,
        trackAsyncPlaces,
        addPlacesToStore,
    }
})

function plainToPlace(p: any) {
    const place = assignExisting(new Place(), p)
    place.modifiedDate = DateTimeUtil.fromAPI(p.modifiedDate)
    return place
}
