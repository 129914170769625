import { IDependencyTelemetry } from "@microsoft/applicationinsights-web"

export interface RedactRule {
    pattern: string | RegExp
    params: string[]
}

const redactRules: RedactRule[] = []

export function addRedactRule(rule: RedactRule) {
    redactRules.push(rule)
}

export function redact(dep: IDependencyTelemetry) {
    if (dep.name) {
        const oldName = dep.name
        dep.name = redactProperty(oldName)

        if (dep.data == oldName) {
            dep.data = dep.name // reuse redacted value
        }
        else if (dep.data) {
            dep.data = redactProperty(dep.data)
        }
    }
}

function redactProperty(value: string) {
    const [method, target] = value.split(' ', 2)
    const url = new URL(target)
    for (const rule of redactRules) {
        if (rule.pattern instanceof RegExp) {
            if (rule.pattern.test(url.pathname)) {
                redactParams(url, rule.params)
                return url.toString()
            }
        } else if (rule.pattern == url.pathname) {
            redactParams(url, rule.params)
            return url.toString()
        }
    }
    return `${method} ${url.toString()}`
}

function redactParams(url: URL, params: string[]) {
    for (const p of params) {
        if (url.searchParams.has(p)) {
            url.searchParams.set(p, 'REDACTED')
        }
    }
}
